import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './style';
import { Box, Typography } from '../shared';

const RequiredInfoStaticLabel = (): ReactElement => {
  const { classes } = useStyles();
  const { t } = useTranslation('translation', { keyPrefix: 'common' });
  return (
    <Box>
      <Typography variant='captionLabel'>
        <Typography component='span' className={classes.asterisk}>
          *
        </Typography>
        {t('required-info')}
      </Typography>
    </Box>
  );
};

export default RequiredInfoStaticLabel;
