import { makeStyles } from '../../hooks';

export const useStyles = makeStyles()(theme => ({
  menuMainContainer: {
    boxShadow: theme.customShadows.default,
    minWidth: '324px',
    borderRadius: '10px',
    transform: 'translate(-10px, 10px) !important',
    transformOrigin: 'unset !important',
  },
  modalHeader: {
    paddingBottom: '8px',
  },
  modalHeaderText: {
    fontSize: '20px',
  },
  modalCloseBtn: {
    paddingRight: '16px',
  },
  listWrapper: {
    paddingTop: '18px',
    paddingBottom: '18px',
  },
  list: {
    padding: '8px 16px',
    ':focus-visible': {
      outline: 'none',
    },
    ':hover': {
      backgroundColor: theme.palette.common.white,
      cursor: 'default',
    },
  },
  reviewerDetails: {
    fontWeight: theme.typography.fontWeightLight,
  },
}));
