import { ReactElement } from 'react';
import usePagination from '@mui/material/usePagination';
import { useTranslation } from 'react-i18next';
import { Button, IconButton } from '../button';
import { useStyle } from './style';
import { Box } from '../box';
import { ArrowLeftIcon, ArrowRIghtIcon } from '../../../assets/icons';

interface Props {
  currentPage: number;
  totalPageCount: number;
  onPageChange: (newPage: number) => void;
  position?: 'start' | 'center' | 'end';
}

const Pagination = (props: Props): ReactElement => {
  const { currentPage, totalPageCount, onPageChange, position = 'end' } = props;
  const { classes } = useStyle({ position });
  const { t: tCommon } = useTranslation('translation', { keyPrefix: 'common' });
  const { items } = usePagination({
    count: totalPageCount ?? 0,
    page: currentPage ?? 0,
    onChange: (event: React.ChangeEvent<unknown>, value: number) => onPageChange(value),
  });

  return (
    <nav aria-label='pagination'>
      <ul className={classes.paginationContainer} data-testid='pagination-container'>
        {items.map(({ page, type, selected, disabled, ...item }) => {
          let children = null;

          if (type === 'start-ellipsis' || type === 'end-ellipsis') {
            children = (
              <Box className={classes.item} data-testid='page-ellipsis'>
                …
              </Box>
            );
          } else if (type === 'previous') {
            children = (
              <IconButton
                {...item}
                className={`${!disabled ? classes.selectedItem : classes.defaultItem} ${classes.previousItem}`}
                disableRipple
                disabled={disabled}
                data-testid='previous-btn'
              >
                <ArrowLeftIcon />
                <Box>{tCommon('previous')}</Box>
              </IconButton>
            );
          } else if (type === 'next') {
            children = (
              <IconButton
                {...item}
                className={`${!disabled ? classes.selectedItem : classes.defaultItem}  ${classes.nextItem}`}
                disableRipple
                disabled={disabled}
                data-testid='next-btn'
              >
                <Box>{tCommon('next')}</Box>
                <ArrowRIghtIcon />
              </IconButton>
            );
          } else if (type === 'page') {
            children = (
              <Button
                type='button'
                {...item}
                className={`${selected ? classes.selectedPage : classes.defaultItem} ${classes.item}`}
                data-testid='page-number'
              >
                {page}
              </Button>
            );
          } else {
            children = (
              <Button type='button' {...item} className={classes.item}>
                {type}
              </Button>
            );
          }

          return (
            <li key={`${page}_${type}`} data-testid='page-element'>
              {children}
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Pagination;
