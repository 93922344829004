import { makeStyles } from '../../../hooks';

export const useStyles = makeStyles()({
  navigationWrapper: {
    position: 'relative',
  },
  titleWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    display: 'inherit',
  },
  link: {
    fontSize: '1.5rem',
    fontWeight: 500,
    lineHeight: '32px',
    '& .MuiSvgIcon-root': {
      width: '24px',
      height: '24px',
    },
  },
});
