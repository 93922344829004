import { ReactElement } from 'react';
import { Box, IconButton } from '../shared';
import { FileSuccessDefaultIcon, FileSuccessGreenIcon } from '../../assets/icons';
import { useStyles } from './style';

interface Props {
  onClick?: () => void;
  id: string;
  isMenuOpen?: boolean;
  isReportReviewed?: boolean;
}

const ReviewButtonIcon = (props: Props): ReactElement => {
  const { onClick = (): void => {}, id, isMenuOpen = false, isReportReviewed = false } = props;
  const { classes } = useStyles({ isMenuOpen });

  return (
    <Box>
      <IconButton className={classes.iconButton} id={id} data-testid='report-review-list-btn' onClick={onClick}>
        {isReportReviewed ? <FileSuccessGreenIcon /> : <FileSuccessDefaultIcon />}
      </IconButton>
    </Box>
  );
};

export default ReviewButtonIcon;
