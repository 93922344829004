import { Stepper as MuiStepper, StepperProps } from '@mui/material';
import { ReactElement, ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

type AppStepperProps = Props & StepperProps;

const Stepper = (props: AppStepperProps): ReactElement => {
  const { children, ...other } = props;
  return <MuiStepper {...other}>{children}</MuiStepper>;
};

export default Stepper;
