import { googleIcon, microsoftIcon } from '../../assets/icons';
import { makeStyles } from '../../hooks';

export const useStyles = makeStyles()(theme => ({
  loginPageRoot: {
    height: '100%',
    position: 'relative',
    background: theme.palette.grey['A100'],
    [theme.breakpoints.up('md')]: {
      overflow: 'hidden',
    },
  },
  loginPageWrapper: {
    height: '100%',
  },
  loginFormWrapper: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    alignItems: 'center',
  },
  loginLogo: {
    width: '208px',
    height: '65px',
  },
  loginButton: {
    width: '100%',
    position: 'relative',
    border: `1px solid ${theme.palette.grey['A200']}`,
    backgroundColor: theme.palette.common.white,
    borderRadius: '10px',
    color: theme.palette.info.main,
    fontWeight: 'bold',
    textTransform: 'capitalize',
    letterSpacing: '0px',
    fontSize: '14px',
    justifyContent: 'flex-start',
    paddingLeft: '52px',
    paddingRight: '16px',
    textWrap: 'nowrap',
    lineHeight: '24px',
    overflow: 'hidden',
    maxWidth: '208px',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '52px',
      maxWidth: '210px',
    },
    '::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      overflow: 'hidden',
      width: '40px',
      borderRight: `1px solid ${theme.palette.grey['A200']}`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: '32px',
      backgroundColor: theme.palette.common.white,
    },
  },
  loginButtonMicrosoft: {
    ':hover': {
      border: `1px solid ${theme.palette.background.microsoftHover}`,
      backgroundColor: theme.palette.background.microsoftHover,
    },
    ':active': {
      border: `1px solid ${theme.palette.background.microsoftSelected}`,
      backgroundColor: theme.palette.background.microsoftSelected,
      color: theme.palette.common.white,
    },
    '::before': {
      background: `url(${microsoftIcon})`,
    },
  },
  loginButtonGoogle: {
    ':hover': {
      border: `1px solid ${theme.palette.background.googleHover}`,
      backgroundColor: theme.palette.background.googleHover,
    },
    ':active': {
      border: `1px solid ${theme.palette.background.googleSelected}`,
      backgroundColor: theme.palette.background.googleSelected,
      color: theme.palette.common.white,
    },
    '::before': {
      background: `url(${googleIcon})`,
    },
  },
  buttonWrapper: {
    [theme.breakpoints.down('md')]: {
      margin: 'auto',
    },
  },
}));
