import { useState } from 'react';
import { BlobServiceClient } from '@azure/storage-blob';
import { useTranslation } from 'react-i18next';
import { FILE_EXTENSION } from '../constants';

interface FileWithPreview extends File {
  preview?: string;
}
interface UploadFile {
  file: FileWithPreview;
  errorMessage?: string;
  error: boolean;
}

interface UseBlobUploadResult {
  uploadFiles: (
    folderName: string | undefined,
    prefix: string | number,
    filesArr: UploadFile[],
    fileName?: string | undefined,
  ) => Promise<string>;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  errorMessage: string | null;
}

export const useBlobUploadFiles = (): UseBlobUploadResult => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { REACT_APP_AZURE_BLOB_SAS_URL: sasUrl, REACT_APP_AZURE_BLOB_CONTAINER_NAME: containerName } = process.env;
  const { t } = useTranslation();
  const blobServiceClient = new BlobServiceClient(sasUrl as string);
  const containerClient = blobServiceClient.getContainerClient(containerName as string);

  const uploadFiles = async (
    folderName: string | undefined,
    prefix: string | number,
    filesArr: UploadFile[],
    fileName: string | undefined,
  ): Promise<string> => {
    setIsLoading(true);
    setIsError(false);
    setIsSuccess(false);
    setErrorMessage(null);
    return new Promise<string>((resolve, reject) => {
      try {
        if (filesArr.length > 0) {
          for (const file of filesArr) {
            if (!file.error) {
              const { type } = file.file;
              const pathName =
                folderName && fileName
                  ? `${folderName}/${prefix}/${fileName}${FILE_EXTENSION[type]}`
                  : `${prefix}_${file.file.size}_${file.file.name}`;

              const blockBlobClient = containerClient.getBlockBlobClient(pathName);
              blockBlobClient
                .uploadData(file.file, { blockSize: file.file.size })
                .then(() => {
                  setIsSuccess(true);
                  resolve(pathName);
                })
                .catch(error => {
                  const { message } = error as { message: string };
                  setIsError(true);
                  setErrorMessage(message || t('common.errors.file-upload-error'));
                  reject(t('common.errors.file-upload-error'));
                });
            }
          }
        }
      } catch (error) {
        const { message } = error as { message: string };
        setIsError(true);
        setErrorMessage(message || t('common.errors.file-upload-error'));
        reject(t('common.errors.file-upload-error'));
      } finally {
        setIsLoading(false);
      }
    });
  };

  return { uploadFiles, isError, isLoading, isSuccess, errorMessage };
};
