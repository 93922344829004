import { forwardRef, useCallback, useEffect, useState } from 'react';
import { useDropzone, DropzoneRef } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { Box } from '../box';
import { useStyles } from './style';
import { Button } from '../button';
import { Image } from '../image';
import { downloadGif } from '../../../assets/images';
import { Typography } from '../typography';
import { getAcceptedFileTypes } from '../../../utils';
import { FilesPreview } from '../files-preview';

interface FileWithPreview extends File {
  preview?: string;
}
interface ImageInfo {
  file: FileWithPreview;
  errorMessage?: string;
  error: boolean;
}
interface Props {
  isSingleUpload?: boolean;
  acceptedFileTypes?: string[];
  uploadFormatText?: string;
  noteText?: string;
  onImagesChange: (images: ImageInfo[]) => void;
  maxDimensions: { width: number; height: number };
  error?: boolean;
  helperText?: string | boolean;
  dataTestId?: string;
}

const Dropzone = forwardRef<DropzoneRef, Props>((props, ref) => {
  const { classes } = useStyles();
  const {
    isSingleUpload = false,
    acceptedFileTypes = ['image/jpeg', 'image/png', 'image/gif'],
    uploadFormatText = '',
    noteText = '',
    onImagesChange,
    maxDimensions,
    error,
    helperText,
    dataTestId = 'dropzone',
  } = props;
  const [files, setFiles] = useState<ImageInfo[]>([]);
  const { t } = useTranslation('translation', { keyPrefix: 'common.dropzone' });

  const validateImage = (file: File, maxDimension: { width: number; height: number }): Promise<ImageInfo> => {
    const img = new window.Image();
    img.src = URL.createObjectURL(file);
    return new Promise(resolve => {
      img.addEventListener('load', () => {
        if (img.width <= maxDimension.width && img.height <= maxDimension.height) {
          resolve({ file, error: false });
        } else {
          resolve({
            file,
            errorMessage: t('file-not-supported-error-massage'),
            error: true,
          });
        }
      });
    });
  };

  const onDrop = useCallback(
    (acceptedFiles: File[]): void => {
      Promise.all(acceptedFiles.map(file => validateImage(file, maxDimensions)))
        .then(filesArr => {
          const filesArrWithBlobPreview: ImageInfo[] = [];
          for (let index = 0; index < filesArr.length; index++) {
            const cloneObj = { ...filesArr[index] };
            cloneObj.file.preview = URL.createObjectURL(cloneObj.file);
            filesArrWithBlobPreview.push(cloneObj);
          }
          onImagesChange(filesArrWithBlobPreview);
          setFiles(filesArrWithBlobPreview);
        })
        .catch(err => {
          const { message } = err as { message: string };
          throw new Error(message);
        });
    },
    [onImagesChange, maxDimensions],
  );
  const { getRootProps, getInputProps, isDragActive, isDragReject, open } = useDropzone({
    onDrop,
    multiple: !isSingleUpload,
    accept: getAcceptedFileTypes(acceptedFileTypes),
    noClick: true,
    autoFocus: true,
  });

  const onCloseClick = (index: number): void => {
    const filesArr = [...files];
    filesArr.splice(index, 1);
    setFiles(filesArr);
  };

  useEffect(() => {
    onImagesChange(files);
  }, [files]);

  /* eslint-disable arrow-body-style */
  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount

    return (): void => files.forEach((file): void => URL.revokeObjectURL(file.file.preview || ''));
  }, []);
  /* eslint-enable arrow-body-style */

  return (
    <Box {...getRootProps({ 'aria-label': 'drag and drop area' })} ref={ref} data-testid={dataTestId}>
      <Box
        className={`${classes.dropzoneMainWrapper} ${error ? classes.dropzoneError : ''} ${
          isDragReject ? classes.dropzoneError : ''
        }`}
      >
        <input {...getInputProps()} data-testid='input-file' />

        <Box textAlign='center' my={1}>
          <Image src={downloadGif} alt='download-gif' className={classes.dropzoneIconImage} />
        </Box>

        {isDragActive ? (
          <Box textAlign='center'>
            <Typography variant='h2' className={classes.dropzoneInsideContainerText}>
              {t('drop-the-files-here')}
            </Typography>
          </Box>
        ) : (
          <Box textAlign='center' my={1}>
            <Typography variant='h2' className={classes.dropzoneInsideContainerText} data-testid='drag-drop-text'>
              {t('drag-and-drop-files-here')}
            </Typography>
          </Box>
        )}

        <Box textAlign='center' my={1}>
          <Button variant='contained' size='small' data-testid='select-file-btn' onClick={open}>
            {t('select-file')}
          </Button>
        </Box>
      </Box>
      {error && (
        <Box my={1}>
          <Typography color='error' data-testid='error-required-text'>
            {helperText}
          </Typography>
        </Box>
      )}
      {isDragReject && (
        <Box my={1}>
          <Typography variant='body1' color='error' data-testid='file-not-supported'>
            {t('file-type-not-supported')}
          </Typography>
        </Box>
      )}
      {files.length < 1 && (
        <Box>
          {uploadFormatText && (
            <Box my={2}>
              <Typography variant='body1' data-testid='upload-format-text'>
                {uploadFormatText}
              </Typography>
            </Box>
          )}
          {noteText && (
            <Box mt={2}>
              <Typography variant='subtitle2' data-testid='upload-note-text' className={classes.dropzoneNoteText}>
                {noteText}
              </Typography>
            </Box>
          )}
        </Box>
      )}

      <Box>
        <FilesPreview files={files} onCloseBtnClickHandler={onCloseClick} />
      </Box>
    </Box>
  );
});

export default Dropzone;
