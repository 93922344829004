import { MenuItem as MuiMenuItem, MenuItemProps } from '@mui/material';
import { ReactElement, ReactNode } from 'react';

interface Props {
  children: ReactNode;
}
type AppMenuItemProps = Props & MenuItemProps;
const MenuItem = (props: AppMenuItemProps): ReactElement => {
  const { children, ...other } = props;
  return <MuiMenuItem {...other}>{children}</MuiMenuItem>;
};

export default MenuItem;
