import { makeStyles } from '../../hooks';

export const useStyles = makeStyles()(theme => ({
  filterContainer: {
    backgroundColor: theme.palette.background.lightblue,
    transition: 'auto',
    borderRadius: '10px',
    '& .MuiGrid2-root:first-of-type': {
      paddingLeft: '16px',
    },
    '& .MuiGrid2-root:last-of-type': {
      paddingRight: '16px',
    },
  },
}));
