import { Grid2Props } from '@mui/material';
import { ReactElement, ReactNode } from 'react';
import { AppStyledGrid } from './style';

interface Props extends Grid2Props {
  children: ReactNode;
}

type AppGridContainerProps = Props & Grid2Props;

const GridContainer = (props: AppGridContainerProps): ReactElement => {
  const { children, ...others } = props;
  return (
    <AppStyledGrid container {...others}>
      {children}
    </AppStyledGrid>
  );
};

export default GridContainer;
