import { makeStyles } from '../../hooks';

export const useStyles = makeStyles()(theme => ({
  modalTitle: {
    padding: theme.spacing(4, 4, 1, 4),
  },
  closeIcon: {
    padding: 0,
    position: 'absolute',
    top: theme.spacing(3.5),
    right: theme.spacing(3.5),
    '& .MuiSvgIcon-root': {
      color: theme.palette.secondary.main,
      width: '32px',
      height: '32px',
    },
  },
  modalContent: {
    padding: theme.spacing(0, 3),
  },
  modalActions: {
    padding: theme.spacing(2, 4, 4, 4),
  },
}));
