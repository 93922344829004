import { ReactElement, ReactNode, RefAttributes, memo } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { AppDatePicker } from './style';
import { CalendarIcon } from '../../../assets/icons';
import { Box } from '../box';

interface Props {
  size?: 'small' | 'medium';
  error?: boolean;
  helperText?: ReactNode;
  required?: boolean;
  // eslint-disable-next-line  @typescript-eslint/naming-convention
  'data-testid'?: string;
}

type AppDatePickerProps = Props & DatePickerProps<Dayjs> & RefAttributes<HTMLDivElement>;

const DatePicker = (props: AppDatePickerProps): ReactElement => {
  const { size = 'medium', error = false, helperText = '', required = true, 'data-testid': testId, ...others } = props;
  const currentYear = dayjs().year();
  const nextYear = currentYear + 1;

  const minDate: dayjs.Dayjs = dayjs(`${currentYear}-01-01`);
  const maxDate: dayjs.Dayjs = dayjs(`${nextYear}-12-31`);
  const testEnv = process.env.NODE_ENV === 'test';

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {testEnv ? (
        <Box data-testid={testId}>
          <AppDatePicker
            {...others}
            slotProps={{
              textField: {
                helperText,
                error,
                size,
                fullWidth: true,
                required,
                onBeforeInput: (event: React.FormEvent<HTMLInputElement>) => event.preventDefault(),
              },
            }}
            slots={{ openPickerIcon: CalendarIcon }}
            minDate={minDate}
            maxDate={maxDate}
          />
        </Box>
      ) : (
        <AppDatePicker
          {...others}
          slotProps={{
            actionBar: {
              actions: ['clear'],
            },
            textField: {
              helperText,
              error,
              size,
              fullWidth: true,
              required,
              onBeforeInput: (event: React.FormEvent<HTMLInputElement>) => event.preventDefault(),
            },
          }}
          slots={{ openPickerIcon: CalendarIcon }}
          minDate={minDate}
          maxDate={maxDate}
        />
      )}
    </LocalizationProvider>
  );
};

export default memo(DatePicker);
