import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { ERROR_TEXT_CONSTANTS } from '../constants';
import { getAuthentication, removeAuthentication } from '../utils';

export const useIdleAutoLogout = (idleTimeout: number): void => {
  const { isAuthenticated, logout } = useAuth0();
  const [isIdle, setIsIdle] = useState(false);

  useEffect(() => {
    let idleTimer: number | NodeJS.Timeout;

    // Function to handle user activity and reset the idle timer
    const handleUserActivity = (): void => {
      setIsIdle(false);
      clearTimeout(idleTimer);
      idleTimer = setTimeout(() => setIsIdle(true), idleTimeout);
      const checkIsAuthenticated = getAuthentication();
      if (!checkIsAuthenticated) {
        logout({ logoutParams: { returnTo: window.location.origin } }).catch(() => {
          throw new Error(ERROR_TEXT_CONSTANTS.AUTO_LOGOUT_ERROR);
        });
      }
    };

    // Attach event listeners for user activity
    if (isAuthenticated) {
      window.addEventListener('mousemove', handleUserActivity);
      window.addEventListener('keydown', handleUserActivity);
    }

    // Clean up event listeners on unmount
    return () => {
      clearTimeout(idleTimer);
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
    };
  }, [isAuthenticated, idleTimeout]);

  useEffect(() => {
    // Auto-logout if the user is idle
    if (isIdle && isAuthenticated) {
      logout({ logoutParams: { returnTo: window.location.origin } })
        .then(() => {
          removeAuthentication();
        })
        .catch(() => {
          throw new Error(ERROR_TEXT_CONSTANTS.AUTO_LOGOUT_ERROR);
        });
    }
  }, [isIdle, isAuthenticated, logout]);
};
