import { ReactElement, useEffect, useMemo, useState } from 'react';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Drawer,
  Image,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Scrollbar,
  Tooltip,
} from '../../shared';
import { GET_DRAWER_ITEMS, ROUTES, GET_REPORTS, ROUTE_MODULE, IDrawerItems } from '../../../constants';
import { protectedRoutesConfig } from '../../../routes/routes-config';
import { useGetAllReportsByCompanyIdQuery } from '../../../services/reports/reports-service';
import { useAppSelector } from '../../../hooks';
import { getUserRole, getUserState } from '../../../redux/auth';
import { useStyles } from './style';
import { Logo, MiniLogo } from '../../../assets/logos';
import SideBarFooterMenu from './SideBarFooterMenu';
import { isUserSuperAdmin } from '../../../utils';
import { DefaultReportIcon, SearchFileIcon } from '../../../assets/icons';

interface Props {
  drawerOpen: boolean;
}

const Sidebar = (props: Props): ReactElement => {
  const { drawerOpen } = props;
  const { classes } = useStyles({ isDrawerOpen: drawerOpen });
  const location = useLocation();
  const [selectedItem, setSelectedItem] = useState<string>(ROUTES.DASHBOARD);
  const { company_id } = useAppSelector(getUserState);
  const { companyId, reportId } = useParams();
  const currentUserRole = useAppSelector(getUserRole);
  const companyIdOnRolesBasis = isUserSuperAdmin(currentUserRole) ? companyId : company_id;
  const { t: tCompany } = useTranslation('translation', { keyPrefix: 'company' });

  const {
    currentData: reportsData = [],
    isSuccess,
    isError,
  } = useGetAllReportsByCompanyIdQuery(companyIdOnRolesBasis as string, {
    skip: companyIdOnRolesBasis === undefined || companyIdOnRolesBasis === '',
  });

  const selectedReports = useMemo(() => {
    const reports = GET_REPORTS();
    const userSelectedReports = reportsData
      .filter(report => report.selected)
      .map(report => {
        const reportTitle = report.label;
        const reportIcon = reports[report.name]?.icon || DefaultReportIcon;
        return {
          text: reportTitle,
          icon: reportIcon,
          path: `/${ROUTES.COMPANY}/${companyIdOnRolesBasis}/${ROUTES.REPORT}/${report._id}`,
          module: report._id,
        };
      });
    if (companyId && isUserSuperAdmin(currentUserRole)) {
      userSelectedReports.unshift({
        text: tCompany('overview'),
        icon: SearchFileIcon,
        path: `/${ROUTES.COMPANY}/${companyIdOnRolesBasis}/${ROUTES.OVERVIEW}`,
        module: ROUTE_MODULE.OVERVIEW_MODULE,
      });
    }
    return userSelectedReports;
  }, [reportsData, companyId, currentUserRole]);

  if (selectedReports.length) {
    GET_DRAWER_ITEMS(selectedReports);
  }

  const getSelectedRoutePath = (path: string): string => {
    if (path.includes('/')) {
      const filterRoute = protectedRoutesConfig().find(value => {
        const routePath = value.path.split('/')[0];
        const routeCurrentPath = path.split('/')[1];
        return routePath === routeCurrentPath;
      });

      return filterRoute?.module || '';
    }
    return path;
  };

  useEffect(() => {
    if (reportId) {
      setSelectedItem(reportId);
    } else {
      const currentPath = location.pathname;
      const selectedRoutes = getSelectedRoutePath(currentPath);
      setSelectedItem(selectedRoutes);
    }
  }, [location.pathname, reportId, isSuccess, isError]);

  const handleItemClick = (path: string): void => {
    if (reportId) {
      setSelectedItem(reportId);
    } else {
      const currentPath = getSelectedRoutePath(path);
      setSelectedItem(currentPath);
    }
  };

  const generateListItem = (item: IDrawerItems): ReactElement => {
    const listItem = (
      <ListItem
        key={item.text}
        disablePadding
        className={`${classes.listItem} ${item.module === selectedItem ? classes.selectedListItem : ''}`}
      >
        <RouterLink to={item.path} className={classes.routerLink}>
          <ListItemButton
            className={classes.listItemButton}
            onClick={(): void => handleItemClick(item.path)}
            disableRipple
          >
            <ListItemIcon className={classes.listItemIcon}>
              {typeof item.icon === 'string' ? <Image src={item.icon} alt={item.text} /> : <item.icon />}
            </ListItemIcon>
            <ListItemText className={classes.listItemText}>{item.text}</ListItemText>
          </ListItemButton>
        </RouterLink>
      </ListItem>
    );
    if (drawerOpen) {
      return listItem;
    }
    return (
      <Tooltip key={item.text} title={item.text} transform='capitalize' placement='right' arrow variant='light'>
        {listItem}
      </Tooltip>
    );
  };

  return (
    <Drawer variant='permanent' open={drawerOpen} className={classes.drawer}>
      <Box className={classes.desktopHamburgerIconWrapper}>{drawerOpen ? <Logo /> : <MiniLogo />}</Box>
      <Box className={classes.sideBarMainContent}>
        <Box>
          <List>
            {GET_DRAWER_ITEMS(selectedReports).map((item, index) => {
              if (index === 1 && selectedReports.length) {
                return (
                  <Scrollbar className={classes.sideBarScrollbar} key={item.text}>
                    {generateListItem(item)}
                    {GET_DRAWER_ITEMS(selectedReports).map((innerItem, innerIndex) => {
                      if (innerIndex >= index + 1 && innerIndex < selectedReports.length + 1) {
                        return generateListItem(innerItem);
                      }
                      return null;
                    })}
                  </Scrollbar>
                );
              }
              if (index > 1 && index < selectedReports.length + 1) {
                return null;
              }
              return generateListItem(item);
            })}
          </List>
        </Box>

        <Box className={classes.sidebarFooter}>
          <SideBarFooterMenu drawerOpen={drawerOpen} />
        </Box>
      </Box>
    </Drawer>
  );
};
export default Sidebar;
