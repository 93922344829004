import { AppState } from '@auth0/auth0-react';
import { BaseQueryFn, FetchArgs, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_TAGS, FORM_DATA_ENDPOINTS } from '../constants';

const { USERS, COMMENT, REVIEW_REPORT } = API_TAGS;

export interface ResponseError {
  data: {
    error: string;
    errorMsg: string;
    errorCode: string;
  };
  status: number;
}

export const appApiBuilder = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/v1',
    prepareHeaders: (headers, { getState, endpoint, type }) => {
      const { auth } = getState() as AppState;
      const { accessToken } = auth;
      const { ADD_COMMENT, UPDATE_COMMENT } = FORM_DATA_ENDPOINTS;

      const EndPointsArr = [ADD_COMMENT, UPDATE_COMMENT];
      if (!EndPointsArr.includes(endpoint) && type === 'mutation') {
        headers.set('Content-Type', 'application/json; charset=utf-8');
        headers.set('Accept', 'application/json');
      }
      headers.set('authorization', `Bearer ${accessToken}`);
      return headers;
    },
  }) as BaseQueryFn<string | FetchArgs, unknown, ResponseError, object>,
  refetchOnMountOrArgChange: true,
  endpoints: () => ({}),
  tagTypes: [USERS, COMMENT, REVIEW_REPORT],
});
