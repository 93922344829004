import { makeStyles } from '../../hooks';

export const useStyles = makeStyles()(theme => ({
  loginImageWrapper: {
    height: '100vh',
    overflow: 'hidden',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      height: '512px',
    },
  },
  loginImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: '0% 20%',
    borderTopLeftRadius: '65px 65px',
    borderBottomLeftRadius: '65px 65px',
    [theme.breakpoints.down('md')]: {
      borderBottomLeftRadius: '40px',
      borderBottomRightRadius: '40px',
      borderTopLeftRadius: 0,
    },
  },
}));
