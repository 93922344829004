import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ErrorState {
  isServerError: boolean;
  isNotFoundError: boolean;
}

const initialState: ErrorState = {
  isNotFoundError: false,
  isServerError: false,
};

export const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    setIsServerError: (state, action: PayloadAction<boolean>) => {
      state.isServerError = action.payload;
    },
    setIsNotFoundError: (state, action: PayloadAction<boolean>) => {
      state.isNotFoundError = action.payload;
    },
  },
});
