import { makeStyles } from '../../hooks';
interface StyleProps {
  isChatBoxOpen?: boolean;
}

export const useStyles = makeStyles<StyleProps>()((theme, { isChatBoxOpen }) => ({
  iconButton: {
    borderRadius: '4px',
    backgroundColor: !isChatBoxOpen ? 'transparent' : theme.palette.background.lightblue,
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.main,
    },
  },
  iconWrapper: {
    display: 'flex',
    position: 'relative',
  },
  countBox: {
    width: '16px',
    height: '16px',
    borderRadius: '50%',
    backgroundColor: theme.palette.error.main,
    fontSize: '8px',
    color: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 600,
    position: 'absolute',
    right: '-4px',
    top: '-4px',
  },
}));
