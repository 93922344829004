import { Autocomplete as MuiAutocomplete, alpha } from '@mui/material';
import { withStyles } from '../../../hooks';

export const AppAutocomplete = withStyles(MuiAutocomplete, theme => ({
  root: {
    '& .MuiOutlinedInput-root': {
      padding: '12px 32px 12px 16px',
      '& .MuiAutocomplete-input': {
        padding: '0',
      },
    },
  },
  endAdornment: {
    '& .MuiSvgIcon-root': {
      color: theme.palette.secondary.main,
    },
  },
  paper: {
    filter: `drop-shadow(${theme.customShadows.drawer})`,
    borderRadius: '4px',
  },
  listbox: {
    '::-webkit-scrollbar': {
      width: '4px',
    },
    '::-webkit-scrollbar-track': {
      background: theme.palette.background.lightblue,
    },
    '::-webkit-scrollbar-thumb': {
      background: theme.palette.secondary.main,
      borderRadius: '6px',
      ':hover': {
        background: theme.palette.secondary.main,
      },
    },
    '& .MuiAutocomplete-option': {
      padding: '12px 16px',
      lineHeight: '24px',
      fontWeight: 400,
      fontSize: '16px',
      '&.Mui-focused': {
        backgroundColor: `${alpha(theme.palette.primary.dark, 0.1)}`,
      },
      '&.Mui-focused[aria-selected="true"]': {
        backgroundColor: `${theme.palette.primary.main}`,
        color: `${theme.palette.common.white}`,
      },
      '&[aria-selected="true"]': {
        backgroundColor: `${theme.palette.common.white}`,
        color: `${theme.palette.secondary.main}`,
      },
    },
  },
}));
