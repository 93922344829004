import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister, $getNearestNodeOfType } from '@lexical/utils';
import {
  $getSelection,
  $isRangeSelection,
  FORMAT_TEXT_COMMAND,
  SELECTION_CHANGE_COMMAND,
  COMMAND_PRIORITY_NORMAL,
  TextFormatType,
  $isTextNode,
  KEY_ENTER_COMMAND,
} from 'lexical';
import { $setBlocksType } from '@lexical/selection';

import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
  $isListNode,
  ListNode,
} from '@lexical/list';
import { ReactElement, memo, useCallback, useEffect, useState } from 'react';
import { useBeautifulMentions } from 'lexical-beautiful-mentions';
import { $createQuoteNode, $isHeadingNode, $isQuoteNode } from '@lexical/rich-text';
import { useTranslation } from 'react-i18next';
import { Divider } from '../divider';
import { Box } from '../box';
import { IconButton } from '../button';
import {
  BoldIcon,
  FormatListBulletedIcon,
  FormatListNumberedIcon,
  FormatQuoteIcon,
  ImageIcon,
  ItalicIcon,
  MentionIcon,
} from '../../../assets/icons';
import { DrawerButtonMenu } from '../../drawer-button-menu';
import { FORMAT_HEADING_COMMAND } from './HeadingPlugin';
import { KEYS } from '../../../constants';
import { Tooltip } from '../tooltip';

interface FormattingInterface {
  [key: string]: boolean;
  isBold: boolean;
  isItalic: boolean;
  isUnderline: boolean;
  isStrikeThrough: boolean;
  isCode: boolean;
  isSubscript: boolean;
  isSuperscript: boolean;
}

interface Props {
  isEditorEmpty: boolean;
}

const ToolbarPlugin = (props: Props): ReactElement => {
  const { isEditorEmpty } = props;
  const [editor] = useLexicalComposerContext();
  const { insertMention } = useBeautifulMentions();
  const { t: tEditor } = useTranslation('translation', { keyPrefix: 'editor' });
  const initialFormatting = {
    isBold: false,
    isItalic: false,
    isUnderline: false,
    isStrikeThrough: false,
    isCode: false,
    isSubscript: false,
    isSuperscript: false,
  };
  const [formattingSelectedItems, setFormattingSelectedItems] = useState<FormattingInterface>(initialFormatting);
  const [selectedTitle, setSelectedTitle] = useState<string>(tEditor('normal-text'));
  const [selectedTitleId, setSelectedTitleId] = useState<string>('p');
  const [blockType, setBlockType] = useState('paragraph');

  const [isListNode, setIsListNode] = useState<{ type: 'ul' | 'ol' | ''; isList: boolean }>({
    type: '',
    isList: false,
  });

  const [isQuote, setIsQuote] = useState(false);

  const headingMenuItems = [
    {
      id: 'p',
      text: tEditor('normal-text'),
      format: 'p',
    },
    {
      id: 'h1',
      text: tEditor('heading-1'),
      format: 'h1',
    },
    {
      id: 'h2',
      text: tEditor('heading-2'),
      format: 'h2',
    },
    {
      id: 'h3',
      text: tEditor('heading-3'),
      format: 'h3',
    },
    {
      id: 'h4',
      text: tEditor('heading-4'),
      format: 'h4',
    },
    {
      id: 'h5',
      text: tEditor('heading-5'),
      format: 'h5',
    },
    {
      id: 'h6',
      text: tEditor('heading-6'),
      format: 'h6',
    },
  ];

  const formatOptions = [
    {
      id: '1',
      text: tEditor('underline'),
      format: 'underline',
      selected: formattingSelectedItems.isUnderline,
    },
    {
      id: '2',
      text: tEditor('strike-through'),
      format: 'strikethrough',
      selected: formattingSelectedItems.isStrikeThrough,
    },
    {
      id: '3',
      text: tEditor('code'),
      format: 'code',
      selected: formattingSelectedItems.isCode,
    },
    {
      id: '4',
      text: tEditor('subscript'),
      format: 'subscript',
      selected: formattingSelectedItems.isSubscript,
    },
    {
      id: '5',
      text: tEditor('superscript'),
      format: 'superscript',
      selected: formattingSelectedItems.isSuperscript,
    },
  ];

  const clearFormat = useCallback(() => {
    setFormattingSelectedItems(initialFormatting);
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        selection.getNodes().forEach(node => {
          if ($isTextNode(node)) {
            node.setFormat(0);
          }
        });
      }
    });
  }, [editor]);

  const $updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element = anchorNode.getKey() === 'root' ? anchorNode : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        setIsQuote($isQuoteNode(element));
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setIsListNode({ type, isList: true });
          setSelectedTitle(tEditor('normal-text'));
          setSelectedTitleId('p');
        } else {
          setIsListNode({ type: '', isList: false });
          const type = $isHeadingNode(element) ? element.getTag() : element.getType();
          const headingTitle = headingMenuItems.find(item => item.id === type);
          setSelectedTitleId(headingTitle ? type : 'p');
          setSelectedTitle(headingTitle ? headingTitle.text : tEditor('normal-text'));
        }
      }

      setFormattingSelectedItems({
        isBold: selection.hasFormat('bold'),
        isItalic: selection.hasFormat('italic'),
        isUnderline: selection.hasFormat('underline'),
        isStrikeThrough: selection.hasFormat('strikethrough'),
        isCode: selection.hasFormat('code'),
        isSubscript: selection.hasFormat('subscript'),
        isSuperscript: selection.hasFormat('superscript'),
      });
    }
  }, []);

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          $updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (): boolean => {
          $updateToolbar();
          return false;
        },
        COMMAND_PRIORITY_NORMAL,
      ),
      editor.registerCommand(
        KEY_ENTER_COMMAND,
        (payload: KeyboardEvent): boolean => {
          if (payload.key === KEYS.ENTER) {
            setSelectedTitleId('p');
            setSelectedTitle(tEditor('normal-text'));
          }
          return false;
        },
        COMMAND_PRIORITY_NORMAL,
      ),
    );
  }, [editor, $updateToolbar]);

  const formatButtonClicked = (format: TextFormatType): void => {
    editor.dispatchCommand(FORMAT_TEXT_COMMAND, format);
  };

  const formatList = (listType: string): void => {
    if (listType === 'number' && blockType !== 'number') {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
      setBlockType('number');
    } else if (listType === 'bullet' && blockType !== 'bullet') {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
      setBlockType('bullet');
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
      setBlockType('paragraph');
    }
  };

  const quoteClickHandler = (): void => {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        $setBlocksType(selection, () => $createQuoteNode());
      }
    });
  };

  const mentionClickHandler = (): void => {
    insertMention({ trigger: '@', value: '' });
  };

  const imageClickHandler = (): void => {
    document.getElementById('file-upload-input')?.click();
  };

  const menuItems = formatOptions.map(({ id, text, format, selected }) => ({
    id,
    text,
    onItemClick: (): void => {
      formatButtonClicked(format as TextFormatType);
    },
    selected,
  }));

  const formatMenuItems = [
    ...menuItems,
    {
      id: '6',
      text: tEditor('clear-formatting'),
      selected: false,
      disabled: Object.values(formattingSelectedItems).every(value => !value),
      onItemClick: (): void => {
        clearFormat();
      },
    },
  ];

  const headingArr = headingMenuItems.map(({ id, text, format }) => ({
    id,
    text,
    onItemClick: (): void => {
      setSelectedTitle(text);
      setSelectedTitleId(id);
      editor.dispatchCommand(FORMAT_HEADING_COMMAND, format);
    },
    selected: selectedTitleId.includes(id),
  }));

  return (
    <Box className='toolbar'>
      <Box alignSelf='center'>
        <DrawerButtonMenu menuItems={headingArr} selectedTitle={selectedTitle} isSmallDropdown />
      </Box>
      <Tooltip title={tEditor('bold')} variant='dark'>
        <IconButton
          className={`toolbar-item spaced ${formattingSelectedItems.isBold ? 'active' : ''}`}
          aria-label={tEditor('bold')}
          onClick={(): void => {
            formatButtonClicked('bold');
          }}
        >
          <BoldIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={tEditor('italic')} variant='dark'>
        <IconButton
          className={`toolbar-item spaced ${formattingSelectedItems.isItalic ? 'active' : ''}`}
          aria-label={tEditor('italic')}
          onClick={(): void => {
            formatButtonClicked('italic');
          }}
        >
          <ItalicIcon />
        </IconButton>
      </Tooltip>
      <Box alignSelf='center' mr={1}>
        <DrawerButtonMenu menuItems={formatMenuItems} isSmallDropdown />
      </Box>

      <Divider className='divider' />
      <Box ml={1}>
        <Tooltip title={tEditor('bullet-list')} variant='dark'>
          <IconButton
            className={`toolbar-item spaced ${isListNode.type === 'ul' && isListNode.isList ? 'active' : ''}`}
            aria-label={tEditor('bullet-list')}
            onClick={(): void => {
              formatList('bullet');
            }}
            disabled={selectedTitleId !== 'p' || isEditorEmpty}
          >
            <FormatListBulletedIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Tooltip title={tEditor('numbered-list')} variant='dark'>
        <IconButton
          className={`toolbar-item spaced ${isListNode.type === 'ol' && isListNode.isList ? 'active' : ''}`}
          aria-label={tEditor('numbered-list')}
          onClick={(): void => {
            formatList('number');
          }}
          disabled={selectedTitleId !== 'p' || isEditorEmpty}
        >
          <FormatListNumberedIcon />
        </IconButton>
      </Tooltip>
      <Divider className='divider' />
      <Tooltip title={tEditor('add-image')} variant='dark'>
        <IconButton className='toolbar-item spaced' aria-label={tEditor('add-image')} onClick={imageClickHandler}>
          <ImageIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={tEditor('mention-@')} variant='dark'>
        <IconButton className='toolbar-item spaced' aria-label={tEditor('mention-@')} onClick={mentionClickHandler}>
          <MentionIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={tEditor('quote')} variant='dark'>
        <IconButton
          className={`toolbar-item spaced ${isQuote ? 'active' : ''}`}
          aria-label={tEditor('quote')}
          onClick={quoteClickHandler}
        >
          <FormatQuoteIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default memo(ToolbarPlugin);
