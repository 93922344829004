import { ReactElement, ReactNode } from 'react';
import { ListItemTextProps, ListItemText as MuiListItemText } from '@mui/material';
interface Props {
  children: ReactNode;
}
type AppListItemTextProps = Props & ListItemTextProps;
const ListItemText = (props: AppListItemTextProps): ReactElement => {
  const { children, ...other } = props;
  return <MuiListItemText {...other}>{children}</MuiListItemText>;
};

export default ListItemText;
