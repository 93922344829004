import { CardProps, Card as MuiCard } from '@mui/material';
import { ReactElement, ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

type AppCardProps = Props & CardProps;

const Card = (props: AppCardProps): ReactElement => {
  const { children, ...other } = props;
  return <MuiCard {...other}>{children}</MuiCard>;
};

export default Card;
