import { ElementType, ReactNode, forwardRef } from 'react';
import { IconButtonProps, IconButton as MuiIconButton } from '@mui/material';

interface Props {
  children: ReactNode;
  component?: ElementType;
  to?: string;
}

type AppIconButtonProps = Props & IconButtonProps;

const IconButton = forwardRef<HTMLButtonElement, AppIconButtonProps>((props, ref) => {
  const { children, ...other } = props;
  return (
    <MuiIconButton {...other} ref={ref} disableRipple>
      {children}
    </MuiIconButton>
  );
});

export default IconButton;
