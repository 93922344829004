import { ListItem as MuiListItem, ListItemProps } from '@mui/material';
import { ReactNode, forwardRef } from 'react';

interface Props {
  children?: ReactNode;
}
type AppListItemProps = Props & ListItemProps;
const ListItem = forwardRef<HTMLLIElement, AppListItemProps>((props, ref) => {
  const { children, ...other } = props;
  return (
    <MuiListItem {...other} ref={ref}>
      {children}
    </MuiListItem>
  );
});

export default ListItem;
