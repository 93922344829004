import { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { useParams, useSearchParams, Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useMutation, useQuery } from '@apollo/client';
import { Page, YearDropdown } from '../../../components';
import {
  Autocomplete,
  Box,
  Button,
  GridContainer,
  GridItem,
  Image,
  LoadingSpinner,
  Stack,
  TextField,
  Typography,
} from '../../../components/shared';
import { NavigationHeader } from '../../../components/layout';
import {
  useGetCompanyByIdQuery,
  useGetConceptsByCompanyIdQuery,
  useGetGlAccountsByCompanyIdQuery,
} from '../../../services/companies/companies-service';
import { useBlobGetFiles, useNavigationPathForPortal } from '../../../hooks';
import { FILTER_PARAMS, REPORT_TYPE, RESPONSE_TYPE, TOAST_TIMER } from '../../../constants';
import { thirteenWeekCashFlowSchema } from '../../../validation-schemas/portfolio-entry-schema';
import { GetOptions, pipeSeparatedStringForTextAndValue } from '../../../utils';
import {
  ThirteenWeekCfData,
  ThirteenWeekCfInput,
  ThirteenWeekCfResponse,
  ThirteenWeekCfVariables,
  UpsertThirteenWeekCfResponse,
} from '../../../graphql/types/thirteen-week-cf';
import { GET_REPORT_DATA, UPSERT_THIRTEEN_WEEK_CF } from '../../../graphql';
import { UseToast } from '../../../hooks/useToast';
import { useStyles } from './style';

const ThirteenWeekCashFlow = (): ReactElement => {
  const { classes } = useStyles();
  const { t: tCompany } = useTranslation('translation', { keyPrefix: 'company' });
  const { t: tCommon } = useTranslation('translation', { keyPrefix: 'common' });
  const { t: tCashFlow } = useTranslation('translation', {
    keyPrefix: 'company.manual-portal-entry.thirteen-week-cash-flow',
  });
  const { t: tManualPortal } = useTranslation('translation', { keyPrefix: 'company.manual-portal-entry' });
  const { t: tError } = useTranslation('translation', { keyPrefix: 'common.errors' });
  const { companyId = '', reportId = '' } = useParams();
  const [searchParams] = useSearchParams();
  const { YEAR } = FILTER_PARAMS;
  const yearParams = searchParams.get(YEAR);
  const { currentData: companyDetails, isError } = useGetCompanyByIdQuery(companyId, {
    skip: companyId === undefined || companyId === '',
  });
  const title = `${companyDetails?.name} - ${tCompany('manual-portal-entry.title')}`;
  const { getAzureBlob } = useBlobGetFiles();
  const companyLogo = companyDetails?.logo;
  const [conceptSearch, setConceptSearch] = useState<string>('');
  const [glAccountSearch, setGlAccountSearch] = useState<string>('');
  const {
    currentData: conceptsData,
    isLoading: conceptsLoading,
    isFetching: conceptsFetching,
  } = useGetConceptsByCompanyIdQuery(
    { companyId, search: conceptSearch },
    { skip: companyId === undefined || companyId === '' },
  );

  const {
    currentData: glAccountsData,
    isLoading: glAcLoading,
    isFetching: glAcFetching,
  } = useGetGlAccountsByCompanyIdQuery(
    { companyId, search: glAccountSearch },
    { skip: companyId === undefined || companyId === '' },
  );

  const [UpsertThirteenWeekCfApi, { loading: upsertLoading }] = useMutation<
    UpsertThirteenWeekCfResponse,
    { input: ThirteenWeekCfInput }
  >(UPSERT_THIRTEEN_WEEK_CF);

  const { showToast } = UseToast();

  const navigation = useNavigationPathForPortal(companyId);
  const formFields = Array(52).fill('');
  const initialValues =
    formFields.map((value, index) => ({
      week: index + 1,
      value: '',
    })) || [];

  const formik = useFormik({
    initialValues: {
      weeks: initialValues,
      concept: null,
      gl_account: null,
    },
    validationSchema: thirteenWeekCashFlowSchema(),
    onSubmit: values => {
      const concept = values.concept as unknown as GetOptions;
      const glAccount = values.gl_account as unknown as GetOptions;
      const weeksData = values.weeks
        .filter(week => week.value !== '')
        .map(({ week, value }) => ({ week, value: Number(value) })) as unknown as ThirteenWeekCfData[];
      const inputData = {
        report_type: REPORT_TYPE.THIRTEEN_WEEK_CF,
        report_id: reportId,
        company_id: companyId,
        year: Number(yearParams),
        concept: pipeSeparatedStringForTextAndValue({ text: concept.text, value: concept.value }),
        gl_account: pipeSeparatedStringForTextAndValue({ text: glAccount.text, value: glAccount.value }),
        data: weeksData,
      };
      UpsertThirteenWeekCfApi({ variables: { input: inputData } })
        .then(async () => {
          showToast(
            tManualPortal('success.upload-success'),
            tManualPortal('success.updates-published-shortly'),
            RESPONSE_TYPE.SUCCESS,
            TOAST_TIMER.MANUAL_PORTAL,
          );
          // eslint-disable-next-line  @typescript-eslint/no-use-before-define
          await refetch();
        })
        .catch(() => {
          showToast(tError('error'), tManualPortal('error.occurred'), RESPONSE_TYPE.ERROR);
        });
    },
    validateOnChange: false,
    validateOnBlur: true,
  });

  const { handleSubmit, touched, errors, handleChange, values, setFieldValue, setFieldError } = formik;
  const concept = values.concept ?? (values.concept as unknown as GetOptions);
  const glAccount = values.gl_account ?? (values.gl_account as unknown as GetOptions);
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { data, loading, refetch } = useQuery<ThirteenWeekCfResponse, ThirteenWeekCfVariables>(GET_REPORT_DATA, {
    variables: {
      filters: {
        report_id: reportId,
        company_id: companyId,
        report_type: REPORT_TYPE.THIRTEEN_WEEK_CF,
        year: Number(yearParams),
        concept: concept ? pipeSeparatedStringForTextAndValue({ text: concept.text, value: concept.value }) : '',
        gl_account: glAccount
          ? pipeSeparatedStringForTextAndValue({ text: glAccount.text, value: glAccount.value })
          : '',
      },
    },
    skip: !yearParams || !companyId || !reportId || !values.concept || !values.gl_account,
  });

  const weekDataResponse = data?.getReportData?.thirteenWeekCfData || [];

  /* eslint-disable @typescript-eslint/no-floating-promises */
  useEffect(() => {
    if (weekDataResponse.length > 0) {
      initialValues.forEach((week, index) => {
        const weekData = weekDataResponse.find(weekObj => weekObj.week === index + 1);
        if (weekData) {
          setFieldValue(`weeks[${index}].value`, weekData.value);
        } else {
          setFieldValue(`weeks[${index}].value`, '');
        }
      });
    } else {
      setFieldValue('weeks', initialValues);
    }
  }, [JSON.stringify(weekDataResponse)]);

  const autoCompleteChangeHandler = (name: string, value: GetOptions | null): void => {
    if (value) {
      setFieldValue(name, value);
      setFieldError(name, '');
    }
  };
  /* eslint-enable @typescript-eslint/no-floating-promises */

  return (
    <>
      <NavigationHeader
        title={
          <Typography variant='h1'>
            {companyDetails ? title : isError ? tCompany('manual-portal-entry.title') : ''}
          </Typography>
        }
        navigateTo={navigation}
      >
        {companyLogo && (
          <Image src={getAzureBlob(companyLogo)?.url} alt={companyLogo} className={classes.companyLogo} />
        )}
      </NavigationHeader>

      <Page>
        <Box pt={{ md: 6, sm: 8, xs: 5 }}>
          <GridContainer spacing={{ xs: 2, sm: 2, md: 2 }} justifyContent='center'>
            <GridItem xs={12} sm={10} md={8}>
              <GridContainer spacing={{ xs: 2, sm: 2, md: 2 }}>
                <GridItem xs={12}>
                  <Typography variant='h2'>{tCashFlow('wtd-revenue-forecast-details')}</Typography>
                </GridItem>
                <GridItem xs={6}>
                  <YearDropdown isFilter value={Number(yearParams)} />
                </GridItem>
                <GridItem xs={6}>
                  <Autocomplete
                    options={(conceptsData?.concepts as GetOptions[]) || []}
                    onChange={(event: ChangeEvent<object>, value: GetOptions | null): void =>
                      autoCompleteChangeHandler('concept', value)
                    }
                    value={values.concept}
                    label={tCashFlow('concept-location')}
                    loading={conceptsLoading || conceptsFetching}
                    onInputHandler={(value): void => {
                      setConceptSearch(value);
                    }}
                    error={Boolean(touched.concept && errors.concept)}
                    helperText={touched.concept && typeof errors.concept === 'string' ? errors.concept : ''}
                    onOpen={(): void => {
                      setConceptSearch('');
                    }}
                  />
                </GridItem>
                <GridItem xs={6}>
                  <Autocomplete
                    options={(glAccountsData?.glAccounts as GetOptions[]) || []}
                    onChange={(event: ChangeEvent<object>, value: GetOptions | null): void =>
                      autoCompleteChangeHandler('gl_account', value)
                    }
                    value={values.gl_account}
                    label={tCashFlow('gl-account')}
                    loading={glAcLoading || glAcFetching}
                    onInputHandler={(value): void => {
                      setGlAccountSearch(value);
                    }}
                    error={Boolean(touched.gl_account && errors.gl_account)}
                    helperText={touched.gl_account && typeof errors.gl_account === 'string' ? errors.gl_account : ''}
                    onOpen={(): void => {
                      setGlAccountSearch('');
                    }}
                  />
                </GridItem>
                <GridItem xs={12} pt={3}>
                  <Typography variant='h2'>{tCashFlow('weekly-values')}</Typography>
                </GridItem>
                {loading ? (
                  <LoadingSpinner />
                ) : (
                  <GridContainer xs={12} spacing={{ xs: 2, sm: 2, md: 3 }} alignItems='center'>
                    {values.weeks.map((week, index) => {
                      const value = `weeks[${index}].value`;
                      const weekNumber = `weeks[${index}].week`;

                      return (
                        <GridItem xs={4} sm={4} md={3} key={week.week}>
                          <TextField value={weekNumber} name={weekNumber} type='hidden' sx={{ display: 'none' }} />
                          <TextField
                            label={`${tCashFlow('week-short')}${week.week}`}
                            name={value}
                            value={week.value}
                            onChange={handleChange}
                            isNumberField
                            allowDecimalValue
                            fullWidth
                          />
                        </GridItem>
                      );
                    })}
                  </GridContainer>
                )}
                <GridItem xs={12} textAlign='right' py={3}>
                  <Stack spacing={2} direction='row' justifyContent='end'>
                    <Button variant='outlined' data-testid='cancel-btn' component={RouterLink} to={`/${navigation}`}>
                      {tCommon('back')}
                    </Button>
                    <Button
                      variant='contained'
                      data-testid='save-btn'
                      loading={upsertLoading}
                      onClick={(): void => {
                        handleSubmit();
                      }}
                    >
                      {tCommon('save')}
                    </Button>
                  </Stack>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </Box>
      </Page>
    </>
  );
};

export default ThirteenWeekCashFlow;
