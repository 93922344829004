import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Page, PortfolioCompanyDetails } from '../../../components';
import { Box, Image, Link, Stack, Typography } from '../../../components/shared';
import { AZURE_BLOB_STORAGE, HttpStatusCode, RESPONSE_TYPE, ROUTES } from '../../../constants';
import { CompanyDetailsForms, ImageInfo } from '../../../components/portfolio-company-details/PortfolioCompanyDetails';
import {
  useGetCompanyByIdQuery,
  useUpdatePortfolioCompanyDetailsByCompanyIdMutation,
} from '../../../services/companies/companies-service';
import { useBlobGetFiles, useBlobUploadFiles } from '../../../hooks';
import { UseToast } from '../../../hooks/useToast';
import { ResponseError } from '../../../services';
import { IUpdateCompanyByIdResponse } from '../../../services/companies/companies-interface';
import { NavigationHeader } from '../../../components/layout';
import { useStyles } from './style';

const UpdatePortfolioCompany = (): ReactElement => {
  const { classes } = useStyles();
  const { t: tCompany } = useTranslation('translation', { keyPrefix: 'company' });
  const { t: tReport } = useTranslation('translation', { keyPrefix: 'report' });
  const { t: tCommon } = useTranslation('translation', { keyPrefix: 'common' });
  const { t: tApiError } = useTranslation('translation');
  const [updateCompanyApi] = useUpdatePortfolioCompanyDetailsByCompanyIdMutation();
  const { uploadFiles } = useBlobUploadFiles();
  const urlParams = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { showToast } = UseToast();
  const { DIRECTORY_NAME, IMAGE_NAME } = AZURE_BLOB_STORAGE;
  const [companyExists, setCompanyExists] = useState(false);
  const { currentData: companyDetails, isError } = useGetCompanyByIdQuery(urlParams.id as string, {
    skip: urlParams.id === undefined,
  });

  const onUpdateClickHandler = async (values: CompanyDetailsForms): Promise<void> => {
    setLoading(true);
    let logo = '';
    if (values.companyLogo.length > 0 && (values.companyLogo[0] as ImageInfo).file) {
      try {
        logo = await uploadFiles(DIRECTORY_NAME, urlParams.id as string, values.companyLogo as ImageInfo[], IMAGE_NAME);
      } catch (error) {
        setLoading(false);
        showToast(tCommon('errors.error'), error as string, RESPONSE_TYPE.ERROR);
      }
    } else {
      logo = (values.companyLogo[0] as string) || '';
    }

    const data = {
      companyId: urlParams.id as string,
      name: values.companyName,
      url: values.companyUrl,
      logo,
    };
    updateCompanyApi(data)
      .unwrap()
      .then((response: IUpdateCompanyByIdResponse) => {
        setLoading(false);
        const { message } = response;
        showToast(tCommon('success'), message, RESPONSE_TYPE.SUCCESS);
        navigate(`/${ROUTES.DASHBOARD}`);
      })
      .catch((err: ResponseError) => {
        setLoading(false);
        if (err.status === HttpStatusCode.FORBIDDEN) {
          setCompanyExists(true);
        } else {
          showToast(tCommon('errors.error'), tApiError(err.data.errorCode, ''), RESPONSE_TYPE.ERROR);
        }
      });
  };

  const onCancelClickHandler = (): void => {
    navigate(`/${ROUTES.DASHBOARD}`);
  };

  const { getAzureBlob } = useBlobGetFiles();
  const companyLogo = companyDetails?.logo;

  return (
    <>
      <NavigationHeader
        title={
          <Typography variant='h1' data-testid='title'>
            {companyDetails ? companyDetails?.name : isError ? tCompany('update-details', { count: 2 }) : ''}
          </Typography>
        }
        navigateTo={ROUTES.DASHBOARD}
      >
        {companyLogo && (
          <Image src={getAzureBlob(companyLogo)?.url} alt={companyLogo} className={classes.companyLogo} />
        )}
      </NavigationHeader>
      <Box my={3}>
        <Stack direction='row' justifyContent='flex-end'>
          <Link to={`/${ROUTES.UPDATE_REPORTS}/${urlParams.id}`}>{tReport('update', { count: 2 })}</Link>
          {/*
              commented for JIST-1275
               <Link>{tUser('update', { count: 2 })}</Link> 
               */}
        </Stack>
      </Box>
      <Page>
        <PortfolioCompanyDetails
          onUpdateClick={onUpdateClickHandler}
          onCancelClick={onCancelClickHandler}
          isLoading={loading}
          isEdit
          companyId={urlParams.id as string}
          companyExists={companyExists}
        />
      </Page>
    </>
  );
};

export default UpdatePortfolioCompany;
