import { ReactElement, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Menu, MenuItem, Stack, Typography, IconButton, LoadingSpinner } from '../shared';
import { useStyles } from './style';
import { ReviewButtonIcon } from '../review-button-icon';
import { CancelCircleIcon } from '../../assets/icons';
import { useAppSelector } from '../../hooks';
import { getUserRole } from '../../redux/auth';
import { getFormattedDateInWords, isArrayWithLength, isUserSuperAdmin } from '../../utils';
import { useGetReviewedReportsByCompanyIdQuery } from '../../services/reports/reports-service';

interface Props {
  companyId: string;
  isReportReviewed?: boolean;
}

const ReviewListMenu = (props: Props): ReactElement => {
  const { companyId, isReportReviewed = false } = props;
  const { classes } = useStyles();
  const [showReports, setShowReports] = useState(false);
  const open = Boolean(showReports);
  const handleClick = (): void => {
    setShowReports(prevShowReports => !prevShowReports);
  };
  const currentUserRole = useAppSelector(getUserRole);
  const superAdminUser = isUserSuperAdmin(currentUserRole);
  const { t: tReviewReport } = useTranslation('translation', { keyPrefix: 'review-report' });

  const {
    currentData: reportsData,
    isLoading,
    isError,
  } = useGetReviewedReportsByCompanyIdQuery(companyId, {
    skip: !companyId || !superAdminUser,
  });

  const renderReportData = (): ReactNode => {
    if (isLoading) {
      return <LoadingSpinner />;
    }
    if (!reportsData || !isArrayWithLength(reportsData) || isError) {
      return (
        <MenuItem className={classes.list}>
          <Box>
            <Typography variant='h4'>{tReviewReport('review-not-found')}</Typography>
          </Box>
        </MenuItem>
      );
    }

    return reportsData.map(({ reviewed_date, reviewer_name }) => (
      <MenuItem className={classes.list} key={reviewed_date} data-testid='reviewed-menu'>
        <Box>
          <Typography variant='h4' color='secondary.main'>
            {tReviewReport('menu-list-title')}:
          </Typography>
          <Typography className={classes.reviewerDetails} variant='body1' color='common.black'>
            {reviewer_name} | {getFormattedDateInWords(reviewed_date)}
          </Typography>
        </Box>
      </MenuItem>
    ));
  };

  return (
    <>
      <Box>
        <ReviewButtonIcon
          id='review-positioned-button'
          onClick={handleClick}
          isMenuOpen={Boolean(showReports)}
          isReportReviewed={isReportReviewed}
        />
      </Box>
      <Menu
        id='review-positioned-menu'
        anchorEl={showReports ? document.getElementById('review-positioned-button') : null}
        open={open}
        onClose={handleClick}
        slotProps={{ paper: { className: classes.menuMainContainer } }}
        MenuListProps={{ className: classes.listWrapper, 'aria-labelledby': 'review-positioned-button' }}
      >
        <Stack direction='row' alignItems='center' justifyContent='space-between' className={classes.modalHeader}>
          <Typography
            className={classes.modalHeaderText}
            variant='h2'
            pl={2}
            color='secondary.main'
            data-testid='report-reviewed-title'
          >
            {tReviewReport('menu-title')}
          </Typography>
          <IconButton className={classes.modalCloseBtn} onClick={handleClick}>
            <CancelCircleIcon />
          </IconButton>
        </Stack>
        {renderReportData()}
      </Menu>
    </>
  );
};

export default ReviewListMenu;
