import { ReactElement, ReactNode, memo } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { ProtectedLayout, PublicLayout } from '../components/layout';
import { LoadingSpinner } from '../components/shared';
import { useIdleAutoLogout, useDynamicPageTitle, useAppSelector, useRestrictScrollOnNumberInput } from '../hooks';
import { RouteConfig, protectedRoutesConfig, publicRoutesConfig } from './routes-config';
import { ROUTES, TITLES } from '../constants/route-constants';
import { getUserRole } from '../redux/auth';
import { envToBool } from '../utils';

const AppRoutes = (): ReactElement => {
  const { isAuthenticated, isLoading, error } = useAuth0();
  const idleTimeout = Number(process.env.REACT_APP_IDLE_TIMEOUT_IN_MINUTES);
  const currentUserRole = useAppSelector(getUserRole);
  const isPenTestLogin = envToBool(process.env.REACT_APP_IS_PEN_TEST_LOGIN as string);
  const location = useLocation();

  useRestrictScrollOnNumberInput();

  useDynamicPageTitle(TITLES().DEFAULT);

  useIdleAutoLogout(idleTimeout);

  if (isLoading) {
    return <LoadingSpinner />;
  }
  const getAllowedRoutes = (routes: RouteConfig[], role: string[]): RouteConfig[] =>
    routes.filter(route => {
      if (!route?.permission?.length) {
        return true;
      }
      if (!role.length) {
        return true;
      }
      return route.permission.some(value => role.includes(value));
    });

  const conditionBasedNavigation = (): ReactNode => {
    if (error) {
      return <Navigate to={ROUTES.ACCESS_DENIED} />;
    }
    if (location.pathname === `/${ROUTES.PEN_TEST_LOGIN}` && isPenTestLogin) {
      return <Navigate to={ROUTES.PEN_TEST_LOGIN} />;
    }
    return <Navigate to={ROUTES.LOGIN} />;
  };

  if (isAuthenticated) {
    const allowedRoutes = getAllowedRoutes(protectedRoutesConfig(), currentUserRole);

    return (
      <Routes>
        <Route element={<ProtectedLayout />}>
          {allowedRoutes?.map(value => (
            <Route key={value.path} index={value.index} path={value.path} Component={value.component} />
          ))}
          <Route path='/' element={<Navigate to={ROUTES.DASHBOARD} />} />
          <Route path='*' element={<Navigate replace to={ROUTES.NOT_FOUND} />} />
        </Route>
      </Routes>
    );
  }
  return (
    <>
      <Routes>
        <Route element={<PublicLayout />}>
          {publicRoutesConfig()?.map(value => (
            <Route key={value.path} index={value.index} path={value.path} Component={value.component} />
          ))}
        </Route>
      </Routes>

      {conditionBasedNavigation()}
    </>
  );
};

export default memo(AppRoutes);
