import { ReactElement, ReactNode } from 'react';
import { BoxProps } from '@mui/material';
import { Box } from '../../shared';
import { useStyles } from './style';

interface Props {
  children: ReactNode;
  className?: string;
}

type AppContainerProps = Props & BoxProps;

const StickyHeader = (props: AppContainerProps): ReactElement => {
  const { children, className = '', ...other } = props;
  const { classes } = useStyles();

  return (
    <Box className={`${classes.headerContainer} ${className && className}`} {...other}>
      {children}
    </Box>
  );
};

export default StickyHeader;
