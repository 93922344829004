import { makeStyles } from '../../hooks';

export const useStyles = makeStyles()(theme => ({
  menuMainContainer: {
    boxShadow: theme.customShadows.default,
    margin: '8px 0px',
    minWidth: '230px',
    borderRadius: '10px',
  },
  listWrapper: {
    paddingTop: '18px',
    paddingBottom: '18px',
  },
  list: {
    padding: '14px 16px',
    ':focus-visible': {
      outline: 'none',
    },
    '&:hover': {
      backgroundColor: theme.palette.background.lightblue,
    },
  },
  downArrowIcon: {
    display: 'inherit',
    '& .MuiSvgIcon-root': {
      width: '24px',
      height: '24px',
    },
  },
}));
