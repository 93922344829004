import { User } from '@auth0/auth0-react';
import dayjs, { Dayjs } from 'dayjs';
import { BeautifulMentionsMenuItem } from 'lexical-beautiful-mentions';
import {
  CHECK_BEFORE_EXPIRE_TIME,
  ROLES,
  SIDEBAR_CONSTANTS,
  IS_AUTHENTICATED,
  NAVIGATE_FROM,
  DATE_FORMAT,
  COMMENT_FILE_MIME_TYPES,
  HTML_PARSER,
} from '../constants';

export const getNameInitials = (name: string): string => {
  if (!name) return '';
  const nameArr = name?.split(' ');
  const firstWord = nameArr?.[0];
  const extractFirstWordFirstLetter = firstWord[0];
  const lastWord = nameArr[nameArr.length - 1];
  const extractLastWordFirstLetter = lastWord?.[0];

  return extractFirstWordFirstLetter + extractLastWordFirstLetter;
};

export const getFileSize = (sizeInBytes: number): string => {
  const CONVERSION_FORMULA = 1000;
  const MAX_KB_UNIT_VALUE = 1024;
  const kilobytes = sizeInBytes / CONVERSION_FORMULA;
  if (kilobytes < MAX_KB_UNIT_VALUE) {
    return `${kilobytes.toFixed(2)} KB`;
  }
  const megabytes = kilobytes / CONVERSION_FORMULA;
  return `${megabytes.toFixed(2)} MB`;
};

export const isValidFileSize = (file: File, maxSizeInMB: number): boolean => {
  const SIZE_IN_MB = 1024 * 1024;
  const fileSizeInMB = file.size / SIZE_IN_MB;

  return fileSizeInMB <= maxSizeInMB;
};

interface ArrayValueBasedKeyObj {
  [key: string]: string[];
}

export const getAcceptedFileTypes = (acceptFiles: string[]): ArrayValueBasedKeyObj => {
  const acceptedObj: ArrayValueBasedKeyObj = {};
  acceptFiles.forEach(value => {
    acceptedObj[value] = [];
  });
  return acceptedObj;
};

export const getHeaderStyleWidth = (isDrawerOpen: boolean | undefined): string => {
  const { EXPANDED_WIDTH, MINI_WIDTH } = SIDEBAR_CONSTANTS.SIDEBAR_WIDTH.DESKTOP;

  const headerWidth = isDrawerOpen ? `calc(100% - ${EXPANDED_WIDTH}px)` : `calc(100% - ${MINI_WIDTH}px)`;

  return headerWidth;
};

export const isArrayWithLength = (arr: unknown[]): boolean => Array.isArray(arr) && arr.length > 0;

export interface GetOptions {
  id: string;
  text: string;
  value: string;
}

export const getOptions = <T extends { _id: string; name: string }>(arr: T[]): GetOptions[] => {
  const options = arr.map(value => ({
    id: value._id,
    text: value.name,
    value: value._id,
  }));
  return options;
};

export const isSearchParamsMatch = (arrayWithId: { id: string }[]): boolean => {
  const searchParams = new URLSearchParams(window.location.search);
  const hasMatch = Array.from(searchParams.keys()).some(key => arrayWithId.some(value => value.id === key));
  return hasMatch;
};

export const getRolesFromAuth0User = (user: User): string[] => {
  let roles: string[] = [];

  for (const key in user) {
    if (key.endsWith('/roles')) {
      roles = user[key];
      break;
    }
  }

  return roles;
};

export const cleanAndLowerCaseString = (inputString: string): string => {
  const cleanedString = inputString.replace(/\s+/g, '').toLowerCase();
  return cleanedString;
};

export const isUserSuperAdmin = (roles: string[]): boolean => roles.includes(ROLES.SUPER_ADMIN);

export const isUserPCAdmin = (roles: string[]): boolean => roles.includes(ROLES.PC_ADMIN);

export const getAzureBlobFileName = (fileNameString: string): string => {
  const generateArr = fileNameString.split('/');
  const accessLastIndex = generateArr.length - 1;
  const getFileName = generateArr[accessLastIndex];

  return getFileName;
};

export const setAuthentication = (): void => localStorage.setItem(IS_AUTHENTICATED, 'true');

export const removeAuthentication = (): void => localStorage.removeItem(IS_AUTHENTICATED);

export const getAuthentication = (): string | null => localStorage.getItem(IS_AUTHENTICATED);

export const extractEmailFromString = (emailString: string): string => {
  const str = emailString;
  const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;
  const email = str.match(emailRegex);
  if (!email) return '';
  return email[0];
};

export const leadingZeroFormatNumber = (number: number): string => {
  if (number < 10) return `0${number}`;
  return `${number}`;
};

export const isAccessTokenExpired = (token: string): boolean => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(value => `%${`00${value.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join(''),
  );
  const { exp } = JSON.parse(jsonPayload);
  const currentTimestamp = Math.floor(Date.now() / 1000);
  return currentTimestamp + CHECK_BEFORE_EXPIRE_TIME > exp;
};

export const setManualPortalNavigation = (from: string): void => localStorage.setItem(NAVIGATE_FROM, from);
export const getManualPortalNavigation = (): string | null => localStorage.getItem(NAVIGATE_FROM);

export const formatDateApi = (date: Dayjs | null): string | null => {
  const formatDate = dayjs(date).format(DATE_FORMAT.FOR_API);
  if (formatDate !== DATE_FORMAT.INVALID_DATE) {
    return formatDate;
  }
  return null;
};

export const convertIntoDate = (date: string): Dayjs => dayjs(date);

export const getFormattedDate = (date: string): string => dayjs(date).format(DATE_FORMAT.WITH_TIME_AND_TEXT);

export const getFormattedDateInWords = (date: string): string => dayjs(date).format(DATE_FORMAT.TEXT_DATE);

export const formatDateView = (date: string): string => {
  const formatDate = dayjs(date).format(DATE_FORMAT.FOR_VIEW);
  if (formatDate !== DATE_FORMAT.INVALID_DATE) {
    return formatDate;
  }
  return '';
};

export const pipeSeparatedStringForTextAndValue = (obj: { text: string; value: string }): string => {
  if (obj) {
    return `${obj.value}|${obj.text}`;
  }
  return '';
};

type MentionObject = { [key: string]: unknown };
type MentionObjectWithValue = MentionObject & { value: string };
export const getMentionOptions = <T extends MentionObject>(arr: T[], key: keyof T): MentionObjectWithValue[] =>
  arr.map(obj => ({ ...obj, value: String(obj[key]) }));

export const extractMentionFromHtml = (
  htmlString: string,
  mentionArr: BeautifulMentionsMenuItem[],
): BeautifulMentionsMenuItem[] => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  // Get all span elements with the data-lexical-beautiful-mention attribute
  const spans = doc.querySelectorAll('span[data-lexical-beautiful-mention="true"]');

  // Extract the data-lexical-beautiful-mention-data attribute values and parse them as JSON
  const dataObjects = Array.from(spans).map(
    span => JSON.parse(span.getAttribute('data-lexical-beautiful-mention-data') as string) as MentionObject[],
  );

  // Filter the array to only include objects that match the data objects
  const matchingObjects = mentionArr.filter(obj =>
    dataObjects.some(dataObj => obj.trigger === '@' && JSON.stringify(obj.data) === JSON.stringify(dataObj)),
  );

  return matchingObjects;
};

export const extractMentionData = (htmlString: string): BeautifulMentionsMenuItem[] => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, HTML_PARSER);
  const mentionElements = doc.querySelectorAll('[data-lexical-beautiful-mention-data]');

  const mentionData: BeautifulMentionsMenuItem[] = Array.from(mentionElements).map(element => {
    const getData = element.getAttribute('data-lexical-beautiful-mention-data') as string;
    const getValue = element.getAttribute('data-lexical-beautiful-mention-value') as string;
    const getTrigger = element.getAttribute('data-lexical-beautiful-mention-trigger') as string;
    const parsedData = JSON.parse(getData);

    return {
      trigger: getTrigger,
      value: getValue,
      displayValue: getValue,
      data: parsedData,
    };
  });
  return mentionData;
};

export const convertBlobUrlToFile = async (url: string, filename: string): Promise<File> => {
  const res = await fetch(url);
  const blob = await res.blob();
  const file = new File([blob], filename, { type: COMMENT_FILE_MIME_TYPES });
  return file;
};

export const extractFileExtensions = (mimeTypes: string[]): string[] =>
  mimeTypes.map(mimeType => {
    const parts = mimeType.split('/');
    return `.${parts[1]}`;
  });

export const envToBool = (envValue: string): boolean => envValue === 'true';
