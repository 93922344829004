import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import translation from './en/translation.json';

i18next
  .use(initReactI18next)
  .init({
    lng: 'en',
    fallbackLng: 'en',
    resources: {
      en: {
        translation,
      },
    },
  })
  .catch(err => {
    const { message } = err as { message: string };
    throw new Error(message);
  });

export default i18next;
