import { makeStyles } from '../../hooks';

export const useStyles = makeStyles()(theme => ({
  wrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  codeText: {
    fontSize: '3rem',
    lineHeight: '56px',
    color: theme.palette.primary.dark,
  },
  title: {
    color: theme.palette.common.black,
    textTransform: 'capitalize',
  },
  description: {
    maxWidth: '296px',
    textAlign: 'center',
  },
}));
