// all redux part like reducer,action goes here.
import { combineReducers } from '@reduxjs/toolkit';
import { appApiBuilder } from '../services';
import authSlice from './auth';
import errorSlice from './error';
import editorSlice from './editor';
import reportsSlice from './reports';

export const rootReducer = combineReducers({
  auth: authSlice.reducer,
  error: errorSlice.reducer,
  editor: editorSlice.reducer,
  reports: reportsSlice.reducer,
  [appApiBuilder.reducerPath]: appApiBuilder.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;
