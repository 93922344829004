import { ReactElement, ReactNode } from 'react';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

interface Props {
  children: ReactNode;
  scrollBarHeight?: number;
}
interface Props extends React.HTMLAttributes<HTMLElement> {
  scrollableNodeProps?: object;
  options?: object;
}

type AppScrollBarProps = Props;

const Scrollbar = (props: AppScrollBarProps): ReactElement => {
  const { children, scrollBarHeight = 318, ...other } = props;
  return (
    <SimpleBar {...other} autoHide={false} forceVisible scrollbarMinSize={100} scrollbarMaxSize={scrollBarHeight}>
      {children}
    </SimpleBar>
  );
};

export default Scrollbar;
