import { ReactElement, ReactNode, memo } from 'react';
import { DialogTitleProps, DialogTitle as MuiDialogTitle } from '@mui/material';

interface Props {
  children: ReactNode;
}

type AppDialogTitleProps = Props & DialogTitleProps;

const DialogTitle = (props: AppDialogTitleProps): ReactElement => {
  const { children, ...other } = props;
  return <MuiDialogTitle {...other}>{children}</MuiDialogTitle>;
};

export default memo(DialogTitle);
