import { alpha } from '@mui/material';
import { makeStyles } from '../../../hooks';

interface StyleProps {
  position: 'start' | 'center' | 'end';
}

export const useStyle = makeStyles<StyleProps>()((theme, { position }) => ({
  paginationContainer: {
    display: 'flex',
    flexDirection: 'row',
    listStyle: 'none',
    alignItems: 'baseline',
    justifyContent: position,
    gap: theme.spacing(1),
    margin: 0,
    '& .MuiButtonBase-root': {
      '&.Mui-disabled': {
        color: alpha(theme.palette.secondary.main, 0.6),
      },
    },
  },
  item: {
    minWidth: 'auto',
    width: '35px',
    height: '35px',
    padding: '0',
    fontWeight: 400,
    fontSize: '14px',
    textTransform: 'capitalize',
    borderRadius: '10px',
    ':hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  selectedItem: {
    color: theme.palette.secondary.main,
  },
  selectedPage: {
    color: theme.palette.common.white,
    cursor: 'auto',
    pointerEvents: 'none',
    backgroundColor: theme.palette.primary.main,
  },
  defaultItem: {
    color: theme.palette.grey[700],
  },
  previousItem: {
    paddingLeft: 0,
    fontWeight: 700,
    fontSize: '14px',
    textTransform: 'capitalize',
  },
  nextItem: {
    paddingRight: 0,
    fontWeight: 700,
    fontSize: '14px',
    textTransform: 'capitalize',
  },
}));
