import { makeStyles } from '../../hooks';

export const useStyles = makeStyles()({
  reportContainer: {
    height: '100%',
    '& iframe': {
      border: 'none',
    },
  },
});
