import { appApiBuilder } from '..';
import {
  IPortfolioByCompanyId,
  IAddPortfolioCompanyDetailsByCompanyId,
  IAddPortfolioCompanyDetailsByCompanyIdResponse,
  IUpdatePortfolioCompanyDetailsByCompanyId,
  IUpdateCompanyByIdResponse,
  IDepartmentsByCompanyId,
  IConceptsResponse,
  IGlAccountsResponse,
} from './companies-interface';
import { API_PATH, DEFAULT_PAGE, DEFAULT_PAGE_LIMIT, METHODS } from '../../constants';

const { COMPANIES, PORTFOLIOS, DEPARTMENTS, CONCEPTS, GL_ACCOUNTS } = API_PATH;

export const extendedApi = appApiBuilder.injectEndpoints({
  endpoints: builder => ({
    getPortfolioByCompanyId: builder.query<IPortfolioByCompanyId[], string | undefined>({
      query: companyId => `${COMPANIES}/${companyId}/${PORTFOLIOS}`,
    }),
    addPortfolioCompanyDetailsByCompanyId: builder.mutation<
      IAddPortfolioCompanyDetailsByCompanyIdResponse,
      IAddPortfolioCompanyDetailsByCompanyId
    >({
      query: ({ companyId, ...other }) => ({
        url: `${COMPANIES}/${companyId}/${PORTFOLIOS}`,
        method: METHODS.POST,
        body: { ...other },
      }),
    }),
    updatePortfolioCompanyDetailsByCompanyId: builder.mutation<
      IUpdateCompanyByIdResponse,
      IUpdatePortfolioCompanyDetailsByCompanyId
    >({
      query: ({ companyId, ...other }) => ({
        url: `${COMPANIES}/${companyId}`,
        method: METHODS.PUT,
        body: { ...other },
      }),
    }),
    getCompanyById: builder.query<IPortfolioByCompanyId, string>({
      query: companyId => `${COMPANIES}/${companyId}`,
    }),
    getDepartmentsByCompanyId: builder.query<IDepartmentsByCompanyId[], string>({
      query: companyId => `${COMPANIES}/${companyId}/${DEPARTMENTS}`,
    }),
    getConceptsByCompanyId: builder.query<IConceptsResponse, { companyId: string; search: string }>({
      query: ({ companyId, search }) => {
        const query = search ? `&search=${search}` : '';
        return `${COMPANIES}/${companyId}/${CONCEPTS}?page=${DEFAULT_PAGE}&limit=${DEFAULT_PAGE_LIMIT}${query}`;
      },
      transformResponse(response: IConceptsResponse) {
        const conceptsModifyResponse = response.concepts.map(value => ({
          id: value._id,
          value: value.concept_id,
          text: value.name,
        }));
        return { concepts: conceptsModifyResponse, totalCount: response.totalCount };
      },
    }),
    getGlAccountsByCompanyId: builder.query<IGlAccountsResponse, { companyId: string; search: string }>({
      query: ({ companyId, search }) => {
        const query = search ? `&search=${search}` : '';
        return `${COMPANIES}/${companyId}/${GL_ACCOUNTS}?page=${DEFAULT_PAGE}&limit=${DEFAULT_PAGE_LIMIT}${query}`;
      },
      transformResponse(response: IGlAccountsResponse) {
        const glAccountsModifyResponse = response.glAccounts.map(value => ({
          id: value._id,
          value: value.gl_account_id,
          text: value.name,
        }));
        return { glAccounts: glAccountsModifyResponse, totalCount: response.totalCount };
      },
    }),
  }),
});

export const {
  useGetPortfolioByCompanyIdQuery,
  useAddPortfolioCompanyDetailsByCompanyIdMutation,
  useUpdatePortfolioCompanyDetailsByCompanyIdMutation,
  useGetCompanyByIdQuery,
  useLazyGetDepartmentsByCompanyIdQuery,
  useGetConceptsByCompanyIdQuery,
  useGetGlAccountsByCompanyIdQuery,
} = extendedApi;
