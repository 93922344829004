import { ReactElement } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { CLEAR_EDITOR_COMMAND } from 'lexical';
import { $generateHtmlFromNodes } from '@lexical/html';
import { useTranslation } from 'react-i18next';
import { Stack } from '../stack';
import { Button } from '../button';

interface Props {
  onCancelClick: () => void;
  onSaveClick: (htmlString: string) => void;
  loading?: boolean;
  isEdit?: boolean;
  disabled?: boolean;
}

const SubmitAndClearPlugin = (props: Props): ReactElement => {
  const { onCancelClick, onSaveClick, loading, isEdit = false, disabled = false } = props;
  const { t: tCommon } = useTranslation('translation', { keyPrefix: 'common' });
  const [editor] = useLexicalComposerContext();

  const onCancelClickHandler = (): void => {
    editor.update(() => {
      editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
      onCancelClick();
    });
  };
  const onSaveClickHandler = (): void => {
    editor.update(() => {
      const htmlString = $generateHtmlFromNodes(editor, null);
      onSaveClick(htmlString);
    });
  };
  return (
    <Stack direction='row' justifyContent='flex-end' spacing={2}>
      <Button size='small' variant='outlined' onClick={onCancelClickHandler}>
        {tCommon('cancel')}
      </Button>
      <Button size='small' variant='contained' onClick={onSaveClickHandler} disabled={disabled} loading={loading}>
        {isEdit ? tCommon('update') : tCommon('save')}
      </Button>
    </Stack>
  );
};

export default SubmitAndClearPlugin;
