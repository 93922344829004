import { DrawerProps, Drawer as MuiDrawer } from '@mui/material';
import { ReactElement, ReactNode } from 'react';
interface Props {
  children: ReactNode;
}
type AppDrawerProps = Props & DrawerProps;
const Drawer = (props: AppDrawerProps): ReactElement => {
  const { children, ...other } = props;
  return <MuiDrawer {...other}>{children}</MuiDrawer>;
};

export default Drawer;
