import { ReactElement, memo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import dayjs, { Dayjs } from 'dayjs';
import { Modal } from '../modal';
import { Stack, Button, GridContainer, GridItem, TextField, DatePicker } from '../shared';
import { capitalStructureDebtSchema } from '../../validation-schemas/portfolio-entry-schema';

export interface DebtSummaryFormValues {
  tranche: string;
  debt_date: Dayjs | null;
  maturity_date: Dayjs | null;
  rate: string;
  third_party: string;
  parent_company_amount: string;
  total: string;
}

interface Props {
  open: boolean;
  onCancelOrCloseClick: (open: boolean) => void;
  onDebtClick: (values: DebtSummaryFormValues) => void;
  loading?: boolean;
  isEdit?: boolean;
  parentCompanyName: string;
  data?: DebtSummaryFormValues;
}
const DebtFormModal = (props: Props): ReactElement => {
  const { open, isEdit = false, loading, onCancelOrCloseClick, parentCompanyName, onDebtClick, data } = props;
  const { t: tCommon } = useTranslation('translation', { keyPrefix: 'common' });
  const { t: tCapStructure } = useTranslation('translation', {
    keyPrefix: 'company.manual-portal-entry.capital-structure',
  });
  const title = isEdit ? tCapStructure('update-debt') : tCapStructure('add-debt');

  const initialValues = {
    tranche: '',
    debt_date: null,
    maturity_date: null,
    rate: '',
    third_party: '',
    parent_company_amount: '',
    total: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema: capitalStructureDebtSchema(),
    onSubmit: values => {
      onDebtClick(values);
    },
  });
  const { handleSubmit, touched, errors, handleChange, values, setFieldValue, resetForm } = formik;

  const resetFormValues = (): void => {
    resetForm();
  };
  const onCancelOrCloseClickHandler = useCallback((): void => {
    onCancelOrCloseClick(false);
    resetFormValues();
  }, [open, onCancelOrCloseClick]);

  const dateChangeHandler = useCallback(
    async (name: string, value: Dayjs | null): Promise<void> => {
      if (dayjs(value).toString() !== 'Invalid Date') {
        await setFieldValue(name, dayjs(value).toString());
      } else {
        await setFieldValue(name, null);
      }
    },
    [setFieldValue],
  );

  useEffect(() => {
    if (open) {
      resetFormValues();
    }
  }, [open]);

  /* eslint-disable @typescript-eslint/no-floating-promises */
  useEffect(() => {
    if (isEdit && data) {
      setFieldValue('tranche', data.tranche);
      setFieldValue('debt_date', data.debt_date);
      setFieldValue('maturity_date', data.maturity_date);
      setFieldValue('rate', data.rate);
      setFieldValue('third_party', data.third_party);
      setFieldValue('parent_company_amount', data.parent_company_amount);
      setFieldValue('total', data.total);
    }
  }, [isEdit, data, open]);
  /* eslint-enable @typescript-eslint/no-floating-promises */

  return (
    <Modal
      title={title}
      open={open}
      actionButtons={
        <Stack direction='row' spacing={2}>
          <Button variant='outlined' onClick={onCancelOrCloseClickHandler} data-testid='cancel-btn'>
            {tCommon('cancel')}
          </Button>
          <Button variant='contained' loading={loading} onClick={(): void => handleSubmit()} data-testid='save-btn'>
            {title}
          </Button>
        </Stack>
      }
      onClose={onCancelOrCloseClickHandler}
      scrollContent='body'
    >
      <GridContainer spacing={2}>
        <GridItem xs={12}>
          <TextField
            label={tCapStructure('tranche')}
            name='tranche'
            value={values.tranche}
            fullWidth
            onChange={handleChange}
            required
            error={Boolean(touched.tranche && errors.tranche)}
            helperText={touched.tranche && errors.tranche}
          />
        </GridItem>
        <GridItem xs={12}>
          <DatePicker
            label={tCapStructure('debt-entry-date')}
            name='debt_date'
            value={values.debt_date}
            onChange={(date: Dayjs | null): Promise<void> => dateChangeHandler('debt_date', date)}
            required
            error={Boolean(touched.debt_date && errors.debt_date)}
            helperText={touched.debt_date && errors.debt_date}
          />
        </GridItem>
        <GridItem xs={12}>
          <DatePicker
            label={tCapStructure('maturity-date')}
            name='maturity_date'
            value={values.maturity_date}
            onChange={(date: Dayjs | null): Promise<void> => dateChangeHandler('maturity_date', date)}
            error={Boolean(touched.maturity_date && errors.maturity_date)}
            helperText={touched.maturity_date && errors.maturity_date}
            required={false}
          />
        </GridItem>
        <GridItem xs={12}>
          <TextField
            label={tCapStructure('rate')}
            name='rate'
            value={values.rate}
            fullWidth
            onChange={handleChange}
            required
            error={Boolean(touched.rate && errors.rate)}
            helperText={touched.rate && errors.rate}
          />
        </GridItem>
        <GridItem xs={12}>
          <TextField
            label={tCapStructure('third-party')}
            name='third_party'
            isNumberField
            value={values.third_party}
            fullWidth
            onChange={handleChange}
            required
            error={Boolean(touched.third_party && errors.third_party)}
            helperText={touched.third_party && errors.third_party}
            allowDecimalValue
          />
        </GridItem>
        <GridItem xs={12}>
          <TextField
            label={parentCompanyName}
            name='parent_company_amount'
            isNumberField
            value={values.parent_company_amount}
            fullWidth
            onChange={handleChange}
            required
            error={Boolean(touched.parent_company_amount && errors.parent_company_amount)}
            helperText={touched.parent_company_amount && errors.parent_company_amount}
            allowDecimalValue
          />
        </GridItem>
        <GridItem xs={12}>
          <TextField
            label={tCapStructure('total')}
            name='total'
            isNumberField
            value={values.total}
            fullWidth
            onChange={handleChange}
            required
            error={Boolean(touched.total && errors.total)}
            helperText={touched.total && errors.total}
            allowDecimalValue
          />
        </GridItem>
      </GridContainer>
    </Modal>
  );
};

export default memo(DebtFormModal);
