import { Table as MuiTable, TableProps } from '@mui/material';
import { ReactElement, ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

type AppTableProps = Props & TableProps;

const Table = (props: AppTableProps): ReactElement => {
  const { children, ...other } = props;
  return <MuiTable {...other}>{children}</MuiTable>;
};

export default Table;
