import { makeStyles } from '../../hooks';

export const useStyles = makeStyles()(theme => ({
  activeIcon: {
    color: theme.palette.primary.main,
  },
  completedIcon: {
    color: theme.palette.primary.main,
  },
  disabledIcon: {
    color: theme.palette.grey[400],
  },
  labelWrapper: {
    '& .MuiStepLabel-labelContainer': {
      '& .MuiStepLabel-label': {
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '24px',
        textTransform: 'capitalize',
        marginTop: '4px',
        '&.Mui-completed': {
          color: theme.palette.primary.main,
        },
        '&.Mui-active': {
          color: theme.palette.common.black,
        },
        '&.Mui-disabled': {
          color: theme.palette.grey[400],
        },
      },
    },
  },
}));
