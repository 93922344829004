import { makeStyles } from '../../../hooks';
import { SIDEBAR_CONSTANTS } from '../../../constants';

const { EXPANDED_WIDTH, MINI_WIDTH } = SIDEBAR_CONSTANTS.SIDEBAR_WIDTH.DESKTOP;
interface StyleProps {
  isDrawerOpen?: boolean;
}
export const useStyles = makeStyles<StyleProps>()((theme, { isDrawerOpen }) => ({
  drawer: {
    width: isDrawerOpen ? EXPANDED_WIDTH : MINI_WIDTH,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    '& .MuiDrawer-paper': {
      position: 'relative',
      backgroundColor: theme.palette.common.white,
      overflow: 'hidden',
      width: isDrawerOpen ? EXPANDED_WIDTH : MINI_WIDTH,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: isDrawerOpen ? theme.transitions.duration.enteringScreen : theme.transitions.duration.leavingScreen,
      }),
      height: 'calc(100vh - 72px)',
      borderRadius: '25px',
      boxShadow: theme.customShadows.sideBar,
      border: 'none',
    },
  },

  desktopHamburgerIconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing(3.5),
    paddingBottom: theme.spacing(1.5),
    paddingLeft: 0,
    '& svg.logo-expand': {
      width: '100%',
      height: '44px',
    },
    '& svg.logo-mini': {
      width: '100%',
      height: '35px',
    },
  },
  listItem: {
    display: 'block',
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    borderBottomLeftRadius: '30px',
    borderTopLeftRadius: '30px',
    marginLeft: '20px',
    width: 'auto',
    '& .MuiSvgIcon-root': {
      color: theme.palette.secondary.main,
      width: '32px',
      height: '32px',
    },
    '& .MuiListItemText-root': {
      color: theme.palette.secondary.main,
    },
    ':hover': {
      backgroundColor: theme.palette.info.lighter,
      '& .MuiSvgIcon-root': {
        color: theme.palette.common.white,
      },
      '& .MuiListItemText-root': {
        color: theme.palette.common.white,
      },
    },
  },
  selectedListItem: {
    backgroundColor: theme.palette.secondary.main,
    '& .MuiSvgIcon-root': {
      color: theme.palette.common.white,
    },
    '& .MuiListItemText-root': {
      color: theme.palette.common.white,
    },
    ':hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  listItemButton: {
    minHeight: 48,
    padding: theme.spacing(1.5, 2.5),
    ':hover': {
      backgroundColor: 'transparent',
    },
  },
  listItemIcon: {
    minWidth: 0,
    justifyContent: 'center',
    marginRight: isDrawerOpen ? theme.spacing(1) : 0,
  },
  listItemText: {
    opacity: isDrawerOpen ? 1 : 0,
    color: theme.palette.grey[800],
    textTransform: 'capitalize',
    '& .MuiListItemText-primary': {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 400,
    },
  },
  routerLink: {
    textDecoration: 'none',
  },
  sideBarMainContent: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  sidebarFooter: {
    height: '56px',
  },
  sideBarScrollbar: {
    backgroundColor: theme.palette.grey[200],
    maxHeight: 'calc(100vh - 377px)',
    minHeight: 'calc(100vh - 377px)',
    '& .simplebar-content': {
      overflow: 'hidden',
    },
    '& .simplebar-track.simplebar-vertical': {
      right: '4px',
      '& .simplebar-scrollbar.simplebar-visible': {
        '::before': {
          backgroundColor: theme.palette.secondary.main,
          opacity: 1,
          width: '4px',
          borderRadius: '6px',
        },
      },
    },
    '& .simplebar-track.simplebar-horizontal': {
      display: 'none',
    },
  },
}));

export const useSideBarFooterMenuStyles = makeStyles()(theme => ({
  menuWrapper: {
    height: '100%',
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '& .MuiSvgIcon-root': {
      width: 40,
      height: 40,
      color: theme.palette.common.white,
    },
    ':hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  profileInfoWrapper: {
    alignItems: 'baseline',
    flexDirection: 'column',
  },
  profileMenuMain: {
    '& .MuiMenu-paper': {
      filter: `drop-shadow(${theme.customShadows.sideBarDrawer})`,
      minWidth: '300px',
      transform: 'translate(0px, -76px) !important',
      transformOrigin: 'unset !important',
      border: `1px solid ${theme.palette.secondary.lightest}`,
      borderBottomLeftRadius: 0,
      [theme.breakpoints.down('md')]: {
        transform: 'translate(-15px, -40px) !important',
        minWidth: '280px',
      },
    },
    '& ul.MuiList-root': {
      paddingBottom: '15px',
      '& li.MuiListItem-root': {
        padding: '12px 24px',
        ':focus-visible': {
          outline: 'none',
        },
      },
      '& li.MuiMenuItem-root': {
        padding: '12px 24px',
        '& .MuiListItemIcon-root': {
          minWidth: 0,
          paddingRight: '8px',
        },
      },
    },
  },

  profileName: {
    color: theme.palette.secondary.main,
    fontWeight: 500,
  },
  profileRole: {
    color: theme.palette.secondary.main,
  },
  profileEmail: {
    color: theme.palette.grey[400],
  },
  profileLink: {
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  profileIcon: {
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  profileDivider: {
    margin: '0px auto 12px auto',
    width: '84%',
    borderWidth: '1px',
  },
}));
