import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface EditorState {
  editorFiles: File[];
}

const initialState: EditorState = {
  editorFiles: [],
};

export const editorSlice = createSlice({
  name: 'editor',
  initialState,
  reducers: {
    setEditorFiles: (state, action: PayloadAction<File[]>) => {
      state.editorFiles = action.payload;
    },
  },
});
