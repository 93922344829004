import { PropsWithChildren, ReactElement, ReactNode, useCallback, useEffect, useState } from 'react';
import {
  CellProps,
  ColumnInstance,
  CustomColumn,
  HeaderProps,
  Row,
  TableOptions,
  useRowSelect,
  useTable,
} from 'react-table';
import { useTranslation } from 'react-i18next';
import {
  CheckBox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Image,
  LoadingSpinner,
  Stack,
} from '..';
import { useStyles } from './style';
import { DrawerButtonMenu } from '../../drawer-button-menu';
import { FileNotFoundIcon } from '../../../assets/icons';
import { isArrayWithLength } from '../../../utils';
import { NoContentFound } from '../../not-content-found';
import { SilverLoadingGif } from '../../../assets/images';

type TableData<T extends Record<string, unknown>> = T[];
interface ActionBtn<T extends Record<string, unknown>> {
  id: string;
  text?: string;
  icon?: string;
  onActionBtnClick: (id: string, row: Row<T>) => void;
  dynamicRenderText?: (row: Row<T>) => string;
  dynamicRenderIcon?: (row: Row<T>) => string;
}
export interface ReactTableProps<T extends Record<string, unknown>> extends TableOptions<T> {
  data: TableData<T>;
  columns: CustomColumn<T>[];
  isRowSelect?: boolean;
  onSelectedRowsChange?: (selectedData: TableData<T>) => void;
  isActionBtn?: boolean;
  actionBtn?: ActionBtn<T>[];
  isLoading?: boolean;
  selectedRowId?: string;
  isActionLoading?: boolean;
  isLocalTable?: boolean;
  notFoundDescription?: string;
}

const ReactTable = <T extends Record<string, unknown>>(props: PropsWithChildren<ReactTableProps<T>>): ReactElement => {
  const {
    data = [],
    columns = [],
    isRowSelect = false,
    onSelectedRowsChange = (): void => {},
    isActionBtn = false,
    actionBtn,
    isLoading,
    selectedRowId,
    isActionLoading,
    isLocalTable = false,
    notFoundDescription = '',
  } = props;

  const { classes } = useStyles({ isLocalTable });
  const { t: tCommon } = useTranslation('translation', { keyPrefix: 'common' });
  const [rowId, setRowId] = useState<string>('');

  const onMenuIconClickHandler = useCallback(
    (id: string): void => {
      setRowId(id);
    },
    [rowId],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, selectedFlatRows } = useTable<T>(
    { columns, data },
    useRowSelect,
    hooks => {
      if (isRowSelect) {
        hooks.visibleColumns.push(
          (column: ColumnInstance<T>[]) =>
            [
              {
                id: 'select',
                Header: (headerRow: HeaderProps<T>): ReactNode => (
                  <CheckBox {...headerRow.getToggleAllRowsSelectedProps()} data-testid='header-cell-checkbox' />
                ),
                Cell: (cell: CellProps<T>): ReactNode => {
                  const { row } = cell;
                  return <CheckBox {...row.getToggleRowSelectedProps()} data-testid='body-cell-checkbox' />;
                },
              },
              ...column,
            ] as ColumnInstance<T>[],
        );
      }
      if (isActionBtn) {
        hooks.visibleColumns.push(
          (column: ColumnInstance<T>[]) =>
            [
              ...column,
              {
                id: 'action',
                Cell: ({ row }: { row: Row<T> }): ReactNode => {
                  const menuItems = actionBtn?.map(item => ({
                    id: item.id,
                    text: item.dynamicRenderText ? item.dynamicRenderText(row) : item.text,
                    icon: item.dynamicRenderIcon ? item.dynamicRenderIcon(row) : item.icon,
                    onItemClick: (): void => item.onActionBtnClick(item.id, row),
                  }));

                  return (
                    <Stack direction='row' spacing={{ md: 3 }} justifyContent='center'>
                      {!isLocalTable && (
                        <Image
                          src={SilverLoadingGif}
                          alt='silver-loading-spinner'
                          className={
                            selectedRowId === row.id && isActionLoading
                              ? classes.showSilverSpinner
                              : classes.hideSilverSpinner
                          }
                          width={30}
                          height={30}
                        />
                      )}

                      <DrawerButtonMenu menuItems={menuItems} onIconClick={onMenuIconClickHandler} rowId={row.id} />
                    </Stack>
                  );
                },
              },
            ] as ColumnInstance<T>[],
        );
      }
    },
  );

  useEffect(() => onSelectedRowsChange(selectedFlatRows.map(row => row.original)), [selectedFlatRows]);

  const generateColumnWidth = (cell: ColumnInstance<T>): string => {
    if (cell.id === 'select') {
      return '50px';
    }
    if (cell.id === 'action') {
      return isLocalTable ? '50px' : '80px';
    }
    if (cell.width === 150 || cell.width === 'auto') {
      return 'auto';
    }
    return `${cell.width}px`;
  };

  const generateBodyRows = (): ReactNode => {
    if (isLoading && !isArrayWithLength(rows)) {
      return (
        <TableRow data-testid='loading-spinner-row'>
          <TableCell colSpan={headerGroups[0].headers.length} className={classes.loadingSpinner}>
            <LoadingSpinner />
          </TableCell>
        </TableRow>
      );
    }
    if (isArrayWithLength(rows)) {
      return rows.map((row: Row<T>) => {
        prepareRow(row);

        return (
          <TableRow
            className={`${classes.tableRow} ${row.isSelected || rowId === row.id ? classes.selectedRow : ''}`}
            {...row.getRowProps()}
            key={row.getRowProps().key}
            data-testid='table-body-row'
          >
            {row.cells.map(cell => {
              const { column } = cell as { column: CustomColumn<T> };
              if (column.hideHeaderAndColumn) return null;
              return (
                <TableCell
                  padding={column.id === 'select' ? 'checkbox' : 'normal'}
                  className={`${classes.tableCell} ${column.id === 'action' ? classes.actionCell : ''}`}
                  {...cell.getCellProps()}
                  key={cell.getCellProps().key}
                  align={column.id === 'action' ? 'center' : 'left'}
                  sx={{ width: generateColumnWidth(column as ColumnInstance<T>) }}
                >
                  {cell.render('Cell')}
                </TableCell>
              );
            })}
          </TableRow>
        );
      });
    }
    return (
      <TableRow data-testid='no-content-found-row'>
        <TableCell colSpan={headerGroups[0].headers.length} className={classes.contentNotFoundWrapper}>
          <NoContentFound
            icon={<Image src={FileNotFoundIcon} alt='file-not-found-icon' />}
            title={tCommon('no-data-found')}
            description={notFoundDescription || tCommon('we-could-not-find-data')}
          />
        </TableCell>
      </TableRow>
    );
  };

  const generateTable = (): ReactNode => (
    <Table aria-label='react-table' {...getTableProps()}>
      <TableHead className={classes.tableHead}>
        {headerGroups.map(headerGroup => (
          <TableRow
            className={classes.tableRow}
            {...headerGroup.getHeaderGroupProps()}
            key={headerGroup.getHeaderGroupProps().key}
            data-testid='table-head-row'
          >
            {headerGroup.headers.map(column => {
              if ((column as CustomColumn<T>).hideHeaderAndColumn) return null;
              return (
                <TableCell
                  padding={column.id === 'select' ? 'checkbox' : 'normal'}
                  className={classes.tableCell}
                  {...column.getHeaderProps()}
                  key={column.id}
                  sx={{ width: generateColumnWidth(column) }}
                >
                  {column.render('Header')}
                </TableCell>
              );
            })}
          </TableRow>
        ))}
      </TableHead>
      <TableBody className={classes.tableBody} {...getTableBodyProps()}>
        {generateBodyRows()}
      </TableBody>
    </Table>
  );

  return <TableContainer className={classes.tableContainer}>{generateTable()}</TableContainer>;
};

export default ReactTable;
