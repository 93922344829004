import { makeStyles } from '../../../hooks';

export const useStyles = makeStyles()({
  reportContainer: {
    height: '70vh',
    minHeight: '100%',
  },
  companyLogo: {
    width: 'auto',
    height: '50px',
    objectFit: 'contain',
    display: 'block',
  },
});
