import { ReactElement, ReactNode } from 'react';
import { CssBaseline, ThemeProvider as MUIThemeProvider } from '@mui/material';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import theme from './theme';

export const muiCache = createCache({
  key: 'css',
  prepend: true,
});

interface Props {
  children: ReactNode;
}

const ThemeProvider = (props: Props): ReactElement => {
  const { children } = props;
  return (
    <CacheProvider value={muiCache}>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </CacheProvider>
  );
};

export default ThemeProvider;
