import { ReactElement } from 'react';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';

interface Props {
  htmlString: string;
}

const HtmlRenderer = (props: Props): ReactElement => {
  const { htmlString } = props;
  const sanitizedHtmlString = DOMPurify.sanitize(htmlString);
  const parsedContent = parse(sanitizedHtmlString);
  return <div>{parsedContent}</div>;
};

export default HtmlRenderer;
