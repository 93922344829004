import { ReactElement, ReactNode } from 'react';
import { ContainerProps } from '@mui/material';
import { Container } from '../shared';
import { useStyles } from './style';

interface Props {
  children: ReactNode;
  className?: string;
}
type AppContainerProps = Props & ContainerProps;
const Page = (props: AppContainerProps): ReactElement => {
  const { children, className = '', ...other } = props;
  const { classes } = useStyles();
  return (
    <Container maxWidth='xl' disableGutters {...other} className={`${classes.pageContainer} ${className && className}`}>
      {children}
    </Container>
  );
};

export default Page;
