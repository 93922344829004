import { TableBody as MuiTableBody, TableBodyProps } from '@mui/material';
import { ReactElement, ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

type AppTableBodyProps = Props & TableBodyProps;

const TableBody = (props: AppTableBodyProps): ReactElement => {
  const { children, ...other } = props;
  return <MuiTableBody {...other}>{children}</MuiTableBody>;
};

export default TableBody;
