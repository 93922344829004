import { ReactElement, memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { Modal } from '../modal';
import { Button, GridContainer, GridItem, Select, Stack, TextField } from '../shared';
import { YearDropdown } from '../year-dropdown';
import { MonthDropdown } from '../month-dropdown';
import { headcountSchema } from '../../validation-schemas/portfolio-entry-schema';
import { useLazyGetDepartmentsByCompanyIdQuery } from '../../services/companies/companies-service';
import { GetOptions, getOptions } from '../../utils';

export interface AddHeadcountFormValues {
  year: number;
  month: number;
  department: string;
  payroll_total: number;
  employee_count: number;
  full_time_employee_count: number;
  part_time_employee_count: number;
  turnover: number;
  contract: number;
}

interface Props {
  open: boolean;
  onCancelOrCloseClick: (open: boolean) => void;
  onAddHeadcount?: (values: AddHeadcountFormValues) => void;
  loading?: boolean;
  isEdit?: boolean;
  data?: AddHeadcountFormValues;
  companyId: string;
}

const HeadcountFormModal = (props: Props): ReactElement => {
  const {
    open,
    onCancelOrCloseClick,
    onAddHeadcount = (): void => {},
    loading = false,
    isEdit = false,
    data,
    companyId,
  } = props;

  const { t: tHeadcount } = useTranslation('translation', { keyPrefix: 'company.manual-portal-entry.headcount' });
  const { t: tCommon } = useTranslation('translation', { keyPrefix: 'common' });
  const [departmentOptions, setDepartmentOptions] = useState<GetOptions[]>([]);
  const [getDepartmentApi, { isLoading, isFetching }] = useLazyGetDepartmentsByCompanyIdQuery();

  const initialValues = {
    year: '',
    month: '',
    department: '',
    payroll_total: '',
    employee_count: '',
    full_time_employee_count: '',
    part_time_employee_count: '',
    turnover: '',
    contract: '',
  };

  const title = isEdit ? tHeadcount('update') : tHeadcount('add');

  const formik = useFormik({
    initialValues,
    validationSchema: headcountSchema(),
    onSubmit: values => {
      onAddHeadcount(values as unknown as AddHeadcountFormValues);
    },
  });

  const { handleSubmit, touched, errors, handleChange, values, setFieldValue, resetForm } = formik;

  const resetFormValues = (): void => {
    resetForm();
  };

  const onCancelOrCloseClickHandler = useCallback((): void => {
    onCancelOrCloseClick(false);
    resetFormValues();
  }, [open, onCancelOrCloseClick]);

  const onYearChangeHandler = useCallback(async (year: number): Promise<void> => {
    await setFieldValue('year', year);
  }, []);

  const onMonthChangeHandler = useCallback(async (month: number): Promise<void> => {
    await setFieldValue('month', month);
  }, []);

  useEffect(() => {
    if (open) {
      resetFormValues();
      getDepartmentApi(companyId)
        .unwrap()
        .then(response => {
          const options = getOptions(response);
          setDepartmentOptions(options);
        })
        .catch(() => {
          setDepartmentOptions([]);
        });
    }
  }, [open, companyId]);

  /* eslint-disable @typescript-eslint/no-floating-promises */
  useEffect(() => {
    if (isEdit && data) {
      setFieldValue('year', data.year);
      setFieldValue('month', data.month);
      setFieldValue('department', departmentOptions.length ? data.department : '');
      setFieldValue('payroll_total', data.payroll_total);
      setFieldValue('employee_count', data.employee_count);
      setFieldValue('full_time_employee_count', data.full_time_employee_count);
      setFieldValue('part_time_employee_count', data.part_time_employee_count);
      setFieldValue('turnover', data.turnover);
      setFieldValue('contract', data.contract);
    }
  }, [open, companyId, data, isEdit, departmentOptions]);
  /* eslint-enable @typescript-eslint/no-floating-promises */

  return (
    <Modal
      title={title}
      open={open}
      actionButtons={
        <Stack direction='row' spacing={2}>
          <Button variant='outlined' onClick={onCancelOrCloseClickHandler} data-testid='cancel-btn'>
            {tCommon('cancel')}
          </Button>
          <Button variant='contained' onClick={(): void => handleSubmit()} loading={loading} data-testid='save-btn'>
            {title}
          </Button>
        </Stack>
      }
      onClose={onCancelOrCloseClickHandler}
      scrollContent='body'
    >
      <GridContainer spacing={2}>
        <GridItem xs={12} data-testid='year-form-dropdown'>
          <YearDropdown
            onYearChangeHandler={onYearChangeHandler}
            value={Number(values.year)}
            required
            error={Boolean(touched.year && errors.year)}
            helperText={(touched.year && errors.year) as string}
          />
        </GridItem>
        <GridItem xs={12} data-testid='month-form-dropdown'>
          <MonthDropdown
            onMonthChangeHandler={onMonthChangeHandler}
            value={Number(values.month)}
            required
            error={Boolean(touched.month && errors.month)}
            helperText={(touched.month && errors.month) as string}
          />
        </GridItem>

        <GridItem xs={12} sm={12}>
          <Select
            inputLabel={tHeadcount('department')}
            id='department'
            value={values.department}
            name='department'
            options={departmentOptions}
            onChange={handleChange}
            required
            error={Boolean(touched.department && errors.department)}
            helperText={(touched.department && errors.department) as string}
            isLoading={isFetching || isLoading}
            data-testid='department-dropdown'
          />
        </GridItem>
        <GridItem xs={12}>
          <TextField
            label={tHeadcount('payroll-total')}
            isNumberField
            name='payroll_total'
            value={values.payroll_total}
            fullWidth
            onChange={handleChange}
            required
            error={Boolean(touched.payroll_total && errors.payroll_total)}
            helperText={touched.payroll_total && errors.payroll_total}
            allowDecimalValue
            inputProps={{
              'data-testid': 'payroll-total',
            }}
          />
        </GridItem>
        <GridItem xs={12}>
          <TextField
            label={tHeadcount('employee-count')}
            name='employee_count'
            isNumberField
            value={values.employee_count}
            fullWidth
            onChange={handleChange}
            required
            error={Boolean(touched.employee_count && errors.employee_count)}
            helperText={touched.employee_count && errors.employee_count}
            inputProps={{
              'data-testid': 'employee-count',
            }}
          />
        </GridItem>
        <GridItem xs={12}>
          <TextField
            label={tHeadcount('full-time-emp-count')}
            name='full_time_employee_count'
            isNumberField
            value={values.full_time_employee_count}
            fullWidth
            onChange={handleChange}
            required
            error={Boolean(touched.full_time_employee_count && errors.full_time_employee_count)}
            helperText={touched.full_time_employee_count && errors.full_time_employee_count}
            inputProps={{
              'data-testid': 'full-time-emp-count',
            }}
          />
        </GridItem>
        <GridItem xs={12}>
          <TextField
            label={tHeadcount('part-time-emp-count')}
            name='part_time_employee_count'
            isNumberField
            value={values.part_time_employee_count}
            fullWidth
            onChange={handleChange}
            required
            error={Boolean(touched.part_time_employee_count && errors.part_time_employee_count)}
            helperText={touched.part_time_employee_count && errors.part_time_employee_count}
            inputProps={{
              'data-testid': 'part-time-emp-count',
            }}
          />
        </GridItem>
        <GridItem xs={12}>
          <TextField
            label={tHeadcount('turnover')}
            name='turnover'
            isNumberField
            value={values.turnover}
            fullWidth
            onChange={handleChange}
            required
            error={Boolean(touched.turnover && errors.turnover)}
            helperText={touched.turnover && errors.turnover}
            inputProps={{
              'data-testid': 'turnover',
            }}
          />
        </GridItem>
        <GridItem xs={12}>
          <TextField
            label={tHeadcount('contract')}
            name='contract'
            isNumberField
            value={values.contract}
            fullWidth
            onChange={handleChange}
            required
            error={Boolean(touched.contract && errors.contract)}
            helperText={touched.contract && errors.contract}
            inputProps={{
              'data-testid': 'contract',
            }}
          />
        </GridItem>
      </GridContainer>
    </Modal>
  );
};

export default memo(HeadcountFormModal);
