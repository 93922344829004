import { TableContainer as MuiTableContainer, TableContainerProps } from '@mui/material';
import { ReactElement, ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

type AppTableContainer = Props & TableContainerProps;

const TableContainer = (props: AppTableContainer): ReactElement => {
  const { children, ...other } = props;

  return <MuiTableContainer {...other}>{children}</MuiTableContainer>;
};

export default TableContainer;
