import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams, Link as RouterLink } from 'react-router-dom';
import { Row } from 'react-table';
import { useFormik } from 'formik';
import { useMutation, useQuery } from '@apollo/client';
import { NavigationHeader } from '../../../components/layout';
import {
  Box,
  Button,
  GridContainer,
  GridItem,
  Image,
  Link,
  LoadingSpinner,
  ReactTable,
  Stack,
  TextField,
  Typography,
} from '../../../components/shared';
import { useGetCompanyByIdQuery } from '../../../services/companies/companies-service';
import { useBlobGetFiles, useNavigationPathForPortal } from '../../../hooks';
import { useStyles } from './style';
import { ConfirmationModal, DebtFormModal, Page, YearDropdown } from '../../../components';
import {
  ACTION_IDS,
  CAP_STRUCTURE_COLUMNS,
  FILTER_PARAMS,
  REPORT_TYPE,
  RESPONSE_TYPE,
  TOAST_TIMER,
} from '../../../constants';
import { capitalStructureEquityValueSchema } from '../../../validation-schemas/portfolio-entry-schema';
import {
  AddCircleOutlineIcon,
  DeleteOutlinedIcon,
  EditOutlinedIcon,
  WarningTriangleOutlinedIcon,
} from '../../../assets/icons';
import {
  CapitalStructureResponse,
  CapitalStructureVariables,
  DELETE_REPORT,
  DebtSummary,
  DebtSummaryInput,
  DeleteReportDataResponse,
  DeleteReportDataVariables,
  EquityValue,
  EquityValueInput,
  GET_REPORT_DATA,
  UPSERT_CAPITAL_STRUCTURE,
  UpsertCapitalStructureResponse,
} from '../../../graphql';
import { DebtSummaryFormValues } from '../../../components/debt-form-modal/DebtFormModal';
import { convertIntoDate, formatDateApi, formatDateView } from '../../../utils';
import { UseToast } from '../../../hooks/useToast';

const CapitalStructure = (): ReactElement => {
  const { classes } = useStyles();
  const { t: tCompany } = useTranslation('translation', { keyPrefix: 'company' });
  const { t: tCommon } = useTranslation('translation', { keyPrefix: 'common' });
  const { t: tCapStructure } = useTranslation('translation', {
    keyPrefix: 'company.manual-portal-entry.capital-structure',
  });
  const { t: tManualPortal } = useTranslation('translation', { keyPrefix: 'company.manual-portal-entry' });
  const { t: tError } = useTranslation('translation', { keyPrefix: 'common.errors' });
  const [searchParams] = useSearchParams();
  const { YEAR } = FILTER_PARAMS;
  const yearParams = searchParams.get(YEAR);
  const { companyId = '', reportId = '' } = useParams();
  const [addDebtFormModalOpen, setAddDebtFormModalOpen] = useState(false);
  const [updateDebtFormModalOpen, setUpdateDebtFormModalOpen] = useState(false);
  const [isEditDebtForm, setIsEditDebtForm] = useState(false);
  const [debtId, setDebtId] = useState('');
  const [editDebtData, setEditDebtData] = useState<DebtSummaryFormValues>({} as DebtSummaryFormValues);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const { currentData: companyDetails, isError } = useGetCompanyByIdQuery(companyId, {
    skip: !companyId,
  });
  const title = `${companyDetails?.name} - ${tCompany('manual-portal-entry.title')}`;
  const companyLogo = companyDetails?.logo;
  const parentCompanyId = companyDetails?.parent_id;

  const { currentData: parentCompanyDetails } = useGetCompanyByIdQuery(parentCompanyId as string, {
    skip: !parentCompanyId,
  });
  const parentCompanyName = parentCompanyDetails?.name;
  const parentCompanyTableHeader = parentCompanyName
    ? `${parentCompanyName} ${tCapStructure('parent_company_amount')}`
    : tCapStructure('parent_company_amount');

  const navigation = useNavigationPathForPortal(companyId);
  const { getAzureBlob } = useBlobGetFiles();
  const { EDIT, CANCEL, DELETE, CLOSE } = ACTION_IDS;
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { data, loading, refetch } = useQuery<CapitalStructureResponse, CapitalStructureVariables>(GET_REPORT_DATA, {
    variables: {
      filters: {
        report_id: reportId,
        company_id: companyId,
        report_type: REPORT_TYPE.CAPITAL_STRUCTURE,
        year: Number(yearParams),
      },
    },
    skip: !yearParams || !companyId || !reportId,
  });

  const [UpsertCapStructureApi, { loading: upsertLoading }] = useMutation<
    UpsertCapitalStructureResponse,
    { input: DebtSummaryInput | EquityValueInput }
  >(UPSERT_CAPITAL_STRUCTURE);
  const [DeleteDebtApi, { loading: deleteLoading }] = useMutation<DeleteReportDataResponse, DeleteReportDataVariables>(
    DELETE_REPORT,
  );

  const { showToast } = UseToast();

  const debtSummaryData = data?.getReportData?.capStructureData.debt_summary || [];
  const equityValueData = data?.getReportData?.capStructureData.equity_value || [];

  const formFields = Array(12).fill(tCapStructure('period_short'));
  const initialEquityValues =
    formFields.map((value, index) => ({
      period: `${value}${index + 1}`,
      value: '',
    })) || [];

  const callUpsertApi = (name: string, value: DebtSummary | EquityValue[]): void => {
    const formDataInput = {
      report_id: reportId,
      company_id: companyId,
      report_type: REPORT_TYPE.CAPITAL_STRUCTURE,
      year: Number(yearParams),
      data: {
        [name]: value,
      },
    } as unknown as DebtSummaryInput | EquityValueInput;
    UpsertCapStructureApi({ variables: { input: formDataInput } })
      .then(async () => {
        if (isEditDebtForm) {
          setUpdateDebtFormModalOpen(false);
          setDebtId('');
          setIsEditDebtForm(false);
          setEditDebtData({} as DebtSummaryFormValues);
        }
        setAddDebtFormModalOpen(false);
        showToast(
          tManualPortal('success.upload-success'),
          tManualPortal('success.updates-published-shortly'),
          RESPONSE_TYPE.SUCCESS,
          TOAST_TIMER.MANUAL_PORTAL,
        );
        await refetch();
      })
      .catch(() => {
        showToast(tError('error'), tManualPortal('error.occurred'), RESPONSE_TYPE.ERROR);
      });
  };

  const formik = useFormik({
    initialValues: {
      equity_value: initialEquityValues,
    },
    validationSchema: capitalStructureEquityValueSchema(),
    onSubmit: values => {
      const equityValueArr = values.equity_value
        .filter(equityObj => equityObj.value !== '')
        .map(({ period, value }) => ({ period, value: Number(value) })) as unknown as EquityValue[];

      callUpsertApi('equity_value', equityValueArr);
    },
    validateOnChange: false,
    validateOnBlur: true,
  });

  const { handleSubmit, handleChange, values, setFieldValue } = formik;

  /* eslint-disable @typescript-eslint/no-floating-promises */
  useEffect(() => {
    if (equityValueData.length > 0) {
      initialEquityValues.forEach((initialValue, index) => {
        const equityData = equityValueData.find(equityObj => equityObj.period === initialValue.period);

        if (equityData) {
          setFieldValue(`equity_value[${index}].value`, equityData.value);
        } else {
          setFieldValue(`equity_value[${index}].value`, '');
        }
      });
    } else {
      setFieldValue('equity_value', initialEquityValues);
    }
  }, [JSON.stringify(equityValueData)]);
  /* eslint-enable @typescript-eslint/no-floating-promises */

  const capStructureColumn = useMemo(() => {
    const copyColumns = [...CAP_STRUCTURE_COLUMNS(parentCompanyTableHeader)];
    const debtDateIndex = 1;
    const debtDateColumn = {
      accessor: 'debt_date',
      Header: tCapStructure('debt-entry-date'),
      Cell: ({ row }: { row: Row<DebtSummary> }) => <Box>{formatDateView(row.original.debt_date)}</Box>,
    };
    const maturityDateIndex = 2;
    const maturityDateColumn = {
      accessor: 'maturity_date',
      Header: tCapStructure('maturity-date'),
      Cell: ({ row }: { row: Row<DebtSummary> }) => <Box>{formatDateView(row.original.maturity_date as string)}</Box>,
    };

    copyColumns.splice(debtDateIndex, 0, debtDateColumn);
    copyColumns.splice(maturityDateIndex, 0, maturityDateColumn);

    return copyColumns;
  }, [debtSummaryData, parentCompanyTableHeader]);

  const onCancelOrCloseClickHandler = useCallback((): void => {
    if (isEditDebtForm) {
      setUpdateDebtFormModalOpen(false);
    }
    setAddDebtFormModalOpen(false);
  }, [addDebtFormModalOpen, isEditDebtForm, updateDebtFormModalOpen]);

  const onDebtClickHandler = useCallback(
    (formValues: DebtSummaryFormValues): void => {
      let debtSummaryInput = {
        tranche: formValues.tranche,
        debt_date: formatDateApi(formValues.debt_date),
        maturity_date: formatDateApi(formValues.maturity_date),
        rate: formValues.rate,
        third_party: Number(formValues.third_party),
        parent_company_amount: Number(formValues.parent_company_amount),
        total: Number(formValues.total),
      } as unknown as DebtSummary;
      if (debtId && isEditDebtForm) {
        debtSummaryInput = {
          ...debtSummaryInput,
          id: debtId,
        };
      }
      callUpsertApi('debt_summary', debtSummaryInput);
    },
    [
      addDebtFormModalOpen,
      companyId,
      reportId,
      UpsertCapStructureApi,
      refetch,
      updateDebtFormModalOpen,
      isEditDebtForm,
      debtId,
      editDebtData,
    ],
  );
  const onActionBtnClick = (id: string, row: Row<DebtSummary>): void => {
    if (id === EDIT) {
      setUpdateDebtFormModalOpen(true);
      setIsEditDebtForm(true);
      const {
        debt_date,
        maturity_date,
        parent_company_amount,
        rate,
        third_party,
        total,
        tranche,
        id: dId = '',
      } = row.original;

      setDebtId(dId);
      setEditDebtData({
        debt_date: convertIntoDate(debt_date),
        maturity_date: maturity_date ? convertIntoDate(maturity_date) : null,
        parent_company_amount,
        rate,
        third_party,
        total,
        tranche,
      } as unknown as DebtSummaryFormValues);
    }
    if (id === DELETE) {
      setIsEditDebtForm(false);
      setDebtId(row.original.id as string);
      setConfirmationModalOpen(true);
    }
  };
  const actionBtn = [
    {
      id: EDIT,
      text: tCommon('edit'),
      icon: EditOutlinedIcon,
      onActionBtnClick,
    },
    {
      id: DELETE,
      text: tCommon('delete'),
      icon: DeleteOutlinedIcon,
      onActionBtnClick,
    },
  ];

  const onConfirmationClickHandler = useCallback(
    (id: string): void => {
      if (id === DELETE) {
        DeleteDebtApi({ variables: { id: debtId, report_type: REPORT_TYPE.CAPITAL_STRUCTURE } })
          .then(async () => {
            setConfirmationModalOpen(false);
            showToast(tCommon('success'), tManualPortal('success.delete'), RESPONSE_TYPE.SUCCESS);
            setDebtId('');
            await refetch();
          })
          .catch(() => {
            showToast(tError('error'), tManualPortal('error.occurred'), RESPONSE_TYPE.ERROR);
          });
      }
      if (id === CANCEL || id === CLOSE) {
        setConfirmationModalOpen(false);
      }
    },
    [debtId, confirmationModalOpen],
  );

  return (
    <>
      <NavigationHeader
        title={
          <Typography variant='h1'>
            {companyDetails ? title : isError ? tCompany('manual-portal-entry.title') : ''}
          </Typography>
        }
        navigateTo={navigation}
      >
        {companyLogo && (
          <Image src={getAzureBlob(companyLogo)?.url} alt={companyLogo} className={classes.companyLogo} />
        )}
      </NavigationHeader>

      <Page>
        <Box pt={{ md: 6, sm: 8, xs: 5 }}>
          <GridContainer spacing={{ xs: 2, sm: 2, md: 2 }} justifyContent='center'>
            <GridItem xs={12} sm={11} md={8}>
              <GridContainer spacing={{ xs: 2, sm: 2, md: 2 }}>
                <GridItem xs={12}>
                  <Typography variant='h2'>{tCapStructure('details')}</Typography>
                </GridItem>
                <GridItem xs={12}>
                  <YearDropdown isFilter value={Number(yearParams)} />
                </GridItem>
                <GridItem xs={12} pt={3}>
                  <Typography variant='h2'>{tCapStructure('equity-value')}</Typography>
                </GridItem>

                {loading ? (
                  <LoadingSpinner />
                ) : (
                  <GridContainer xs={12} spacing={{ xs: 2, sm: 2, md: 3 }} alignItems='center'>
                    {values.equity_value.map(({ period, value }, index) => {
                      const valueIndex = `equity_value[${index}].value`;
                      const periodIndex = `equity_value[${index}].period`;

                      return (
                        <GridItem xs={4} sm={4} md={4} key={period}>
                          <TextField value={periodIndex} name={periodIndex} type='hidden' sx={{ display: 'none' }} />
                          <TextField
                            label={period}
                            name={valueIndex}
                            value={value}
                            onChange={handleChange}
                            isNumberField
                            allowDecimalValue
                            fullWidth
                          />
                        </GridItem>
                      );
                    })}
                  </GridContainer>
                )}
                <GridItem xs={12} pt={2.5} px={1.5}>
                  <Stack direction='row' justifyContent='space-between'>
                    <Typography variant='h2'>{tCapStructure('debt-summary')}</Typography>
                    <Link
                      variant='primaryLink'
                      startIcon={<AddCircleOutlineIcon />}
                      component={Button}
                      onClick={(): void => setAddDebtFormModalOpen(true)}
                    >
                      {tCapStructure('add-debt')}
                    </Link>
                  </Stack>
                </GridItem>
                <GridItem xs={12}>
                  <ReactTable
                    data={debtSummaryData}
                    columns={capStructureColumn}
                    isLocalTable
                    isLoading={loading}
                    isActionBtn
                    actionBtn={actionBtn}
                    notFoundDescription={tCapStructure('no-data-added-description')}
                  />
                </GridItem>
                <GridItem xs={12} textAlign='right' py={3}>
                  <Stack spacing={2} direction='row' justifyContent='end'>
                    <Button variant='outlined' component={RouterLink} to={`/${navigation}`}>
                      {tCommon('back')}
                    </Button>
                    <Button
                      variant='contained'
                      loading={upsertLoading && !addDebtFormModalOpen && !updateDebtFormModalOpen}
                      onClick={(): void => handleSubmit()}
                    >
                      {tCommon('save')}
                    </Button>
                  </Stack>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </Box>
      </Page>
      <DebtFormModal
        open={addDebtFormModalOpen}
        onCancelOrCloseClick={onCancelOrCloseClickHandler}
        onDebtClick={onDebtClickHandler}
        loading={upsertLoading}
        parentCompanyName={parentCompanyTableHeader}
      />
      <DebtFormModal
        open={updateDebtFormModalOpen}
        onCancelOrCloseClick={onCancelOrCloseClickHandler}
        onDebtClick={onDebtClickHandler}
        loading={upsertLoading}
        isEdit={isEditDebtForm}
        data={editDebtData}
        parentCompanyName={parentCompanyTableHeader}
      />
      <ConfirmationModal
        open={confirmationModalOpen}
        icon={<WarningTriangleOutlinedIcon className={classes.confirmationIcon} />}
        confirmationTitle={tCommon('delete-confirmation')}
        description={tCommon('delete-confirmation-message')}
        buttons={[
          {
            id: CANCEL,
            label: tCommon('cancel'),
            color: 'primary',
            variant: 'outlined',
            onClick: onConfirmationClickHandler,
          },
          {
            id: DELETE,
            label: tCommon('delete'),
            color: 'error',
            onClick: onConfirmationClickHandler,
            loading: deleteLoading,
            'data-testid': 'delete-btn',
          },
        ]}
        onCloseClick={onConfirmationClickHandler}
      />
    </>
  );
};

export default CapitalStructure;
