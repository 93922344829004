import { ReactElement, ReactNode, useMemo } from 'react';
import { FieldArray, FormikProvider, getIn, useFormik } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Button, GridContainer, GridItem, Link, Stack, TextField, Typography } from '../shared';
import { AddCircleOutlineIcon, DeleteIcon } from '../../assets/icons';
import { RequiredInfoStaticLabel } from '../required-info-static-label';
import { userValidationSchema } from '../../validation-schemas';
import { MAX_ADD_USER_COUNT } from '../../constants';
import { useStyles } from './style';
import { useLazyValidateUserByEmailQuery } from '../../services/users/users-service';

export interface PCAdminUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

interface Props {
  onAddUserClick: (values: PCAdminUser[]) => void;
  onCancelClick: () => void;
  isLoading?: boolean;
  userAlreadyExist?: { email: string; isExist: boolean };
}

const PortfolioAddUser = (props: Props): ReactElement => {
  const { onAddUserClick, onCancelClick, isLoading, userAlreadyExist = { email: '', isExist: false } } = props;
  const { classes } = useStyles();
  const { t: tCommon } = useTranslation('translation', { keyPrefix: 'common' });
  const { t: tUser } = useTranslation('translation', { keyPrefix: 'user' });
  const [validateUserByEmail, { isLoading: validateLoading, isFetching }] = useLazyValidateUserByEmailQuery();

  const initialUser: PCAdminUser = {
    id: Math.random().toString(),
    firstName: '',
    lastName: '',
    email: '',
  };
  const addNewUser: PCAdminUser = {
    id: Math.random().toString(),
    firstName: '',
    lastName: '',
    email: '',
  };
  const formik = useFormik({
    initialValues: {
      users: [initialUser],
    },
    validationSchema: userValidationSchema(),
    onSubmit: async values => {
      const { users } = values;

      const alreadyExistIndexes: number[] = [];
      for (let index = 0; index < users.length; index++) {
        const { email } = users[index];

        // eslint-disable-next-line no-await-in-loop
        const response = await validateUserByEmail(email);

        if (response.data) {
          alreadyExistIndexes.push(index);
        }
      }

      if (alreadyExistIndexes.length) {
        for (let index = 0; index < alreadyExistIndexes.length; index++) {
          const currentIndex = alreadyExistIndexes[index];
          formik.setFieldError(`users[${currentIndex}].email`, tUser('email-already-exists'));
        }
      } else {
        onAddUserClick(values.users);
      }
    },
  });

  const { values, errors, touched, handleSubmit, setFieldValue, setFieldError } = formik;

  useMemo(() => {
    if (userAlreadyExist.isExist) {
      const userIndex = values.users.map(user => user.email).lastIndexOf(userAlreadyExist.email);
      setFieldError(`users[${userIndex}].email`, tUser('email-already-exists'));
    }
  }, [userAlreadyExist.email, userAlreadyExist.isExist, errors.users]);

  return (
    <GridContainer rowSpacing={{ md: 7, xs: 7, sm: 19 }} columnSpacing={10} justifyContent='center'>
      <GridItem xs={9} sm={12} md={9}>
        <GridContainer spacing={3}>
          <GridItem xs={12} py={0}>
            <Typography variant='h4' data-testid='user-details-text'>
              {tUser('details')}
            </Typography>
          </GridItem>
          <FormikProvider value={formik}>
            <FieldArray
              name='users'
              validateOnChange
              render={(arrayHelpers): ReactNode => (
                <GridItem xs={12} py={0}>
                  {values.users.map((user, index) => {
                    const firstName = `users[${index}].firstName`;
                    const touchedFirstName = getIn(touched, firstName);
                    const errorFirstName = getIn(errors, firstName);
                    const lastName = `users[${index}].lastName`;
                    const touchedLastName = getIn(touched, lastName);
                    const errorLastName = getIn(errors, lastName);
                    const email = `users[${index}].email`;
                    const touchedEmail = getIn(touched, email);
                    const errorEmail = getIn(errors, email);
                    const userCount = index + 1;
                    return (
                      <Box
                        key={`users[${user.id}]`}
                        data-testid='add-user-wrapper'
                        className={classes.addUserContainer}
                      >
                        <GridItem xs={12} py={0} pt={1.5}>
                          <Stack direction='row' justifyContent='space-between'>
                            <Typography variant='h4'>
                              <Trans i18nKey='user.with_count' count={userCount}>
                                User {{ userCount }}
                              </Trans>
                            </Typography>
                            {index > 0 && (
                              <Link
                                startIcon={<DeleteIcon />}
                                onClick={(): void => arrayHelpers.remove(index)}
                                data-testid='remove-user-btn'
                                variant='secondaryLink'
                              >
                                {tUser('remove')}
                              </Link>
                            )}
                          </Stack>
                        </GridItem>
                        <GridItem xs={12}>
                          <TextField
                            label={tUser('first-name')}
                            name={firstName}
                            value={user.firstName}
                            onChange={async (event): Promise<void> => {
                              await setFieldValue(firstName, event.target.value);
                            }}
                            error={Boolean(touchedFirstName && errorFirstName)}
                            helperText={touchedFirstName && errorFirstName}
                            fullWidth
                            required
                            data-testid='add-user-form-field'
                          />
                        </GridItem>
                        <GridItem xs={12}>
                          <TextField
                            label={tUser('last-name')}
                            name={lastName}
                            value={user.lastName}
                            onChange={async (event): Promise<void> => {
                              await setFieldValue(lastName, event.target.value);
                            }}
                            error={Boolean(touchedLastName && errorLastName)}
                            helperText={touchedLastName && errorLastName}
                            fullWidth
                            required
                            data-testid='add-user-form-field'
                          />
                        </GridItem>
                        <GridItem xs={12}>
                          <TextField
                            label={tUser('email')}
                            name={email}
                            value={user.email}
                            onChange={async (event): Promise<void> => {
                              await setFieldValue(email, event.target.value);
                            }}
                            error={Boolean(touchedEmail && errorEmail)}
                            helperText={touchedEmail && errorEmail}
                            fullWidth
                            required
                            data-testid='add-user-form-field'
                          />
                        </GridItem>
                      </Box>
                    );
                  })}
                  <GridItem xs={12} pt={0} px={0}>
                    <Link
                      variant='primaryLink'
                      startIcon={<AddCircleOutlineIcon />}
                      onClick={(): void => arrayHelpers.push(addNewUser)}
                      disabled={values.users.length === MAX_ADD_USER_COUNT}
                      data-testid='add-new-user-btn'
                    >
                      {tUser('add-new')}
                    </Link>
                  </GridItem>
                </GridItem>
              )}
            />
            <GridItem xs={12} px={1} py={1}>
              <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <RequiredInfoStaticLabel />
                <Box>
                  <Stack direction='row' spacing={2}>
                    <Button variant='outlined' onClick={onCancelClick} data-testid='cancel-btn'>
                      {tCommon('do-this-later')}
                    </Button>
                    <Button
                      variant='contained'
                      onClick={(): void => {
                        handleSubmit();
                      }}
                      loading={isLoading || validateLoading || isFetching}
                      data-testid='complete-add-user-btn'
                    >
                      {tUser('complete-and-add', { count: values.users.length })}
                    </Button>
                  </Stack>
                </Box>
              </Stack>
            </GridItem>
          </FormikProvider>
        </GridContainer>
      </GridItem>
    </GridContainer>
  );
};

export default PortfolioAddUser;
