import {
  ChangeEvent,
  ForwardedRef,
  ReactElement,
  ReactNode,
  SyntheticEvent,
  forwardRef,
  memo,
  useTransition,
} from 'react';
import { AutocompleteRenderInputParams, UseAutocompleteProps } from '@mui/material';
import { TextField } from '../text-field';
import { DownArrowIcon } from '../../../assets/icons';
import { AppAutocomplete } from './style';
import { GetOptions } from '../../../utils';

interface Props {
  label: string;
  options: GetOptions[];
  onChange: (event: ChangeEvent<object>, value: GetOptions | null) => void;
  error?: boolean;
  helperText?: string;
  loading?: boolean;
  onInputHandler?: (value: string) => void;
}

type AppAutoCompleteProps = UseAutocompleteProps<GetOptions, false, false, false> & Props;

const Autocomplete = forwardRef(
  (props: AppAutoCompleteProps, ref: ForwardedRef<typeof AppAutocomplete>): ReactElement => {
    const { label, options, onChange, error, helperText, loading, onInputHandler = (): void => {}, ...other } = props;
    const [, startTransition] = useTransition();

    const onInputChangeHandler = (event: SyntheticEvent, value: string): void => {
      startTransition(() => {
        if (value) {
          onInputHandler(value);
        }
      });
    };

    return (
      <AppAutocomplete
        ref={ref}
        disablePortal
        autoHighlight
        clearOnEscape
        id='combo-box'
        options={options}
        getOptionLabel={(option): string => option.text || ''}
        renderOption={(renderProps, option): ReactNode => (
          <li key={option?.value || option?.id} {...renderProps}>
            {option.text}
          </li>
        )}
        onChange={onChange}
        renderInput={(params: AutocompleteRenderInputParams): ReactElement => (
          <TextField {...params} label={label} error={error} helperText={helperText} />
        )}
        isOptionEqualToValue={(option, value): boolean => option.id === value?.id}
        popupIcon={<DownArrowIcon />}
        loading={loading}
        onInputChange={onInputChangeHandler}
        {...other}
      />
    );
  },
);

export default memo(Autocomplete);
