import { EnhancedStore, PreloadedState, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { rootReducer } from './root-reducer';
import { appApiBuilder } from '../services';
import { rtkQueryErrorLogger } from '../services/error-handling-middleware';

export function setupStore(preloadedState?: PreloadedState<RootState>): EnhancedStore<RootState> {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(appApiBuilder.middleware, rtkQueryErrorLogger),
  });
}
const store = setupStore({});
setupListeners(store.dispatch);

export default store;
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
