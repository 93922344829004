import { ReactElement } from 'react';
import { Box, Step, StepLabel, Stepper } from '../shared';
import CustomStepIcon from './CustomStepIcon';
import { CustomStepConnector } from './CustomStepConnector';
import { useStyles } from './style';

interface StepItem {
  label: string;
  component: React.ReactNode;
}

interface Props {
  currentActiveIndex: number;
  stepperData: StepItem[];
}

const HorizontalStepper = (props: Props): ReactElement => {
  const { currentActiveIndex = 0, stepperData = [] } = props;
  const { classes } = useStyles();

  return (
    <>
      <Box>
        <Stepper activeStep={currentActiveIndex} alternativeLabel connector={<CustomStepConnector />}>
          {stepperData.map(step => (
            <Step key={step.label}>
              <StepLabel StepIconComponent={CustomStepIcon} className={classes.labelWrapper}>
                {step.label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Box>
        {stepperData.length > 0 && stepperData[currentActiveIndex] && stepperData[currentActiveIndex].component}
      </Box>
    </>
  );
};

export default HorizontalStepper;
