import { ReactElement, useCallback, useMemo, useState } from 'react';
import { useParams, useSearchParams, Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import { Row } from 'react-table';
import { useMediaQuery, useTheme } from '@mui/material';
import { NavigationHeader } from '../../../components/layout';
import {
  Box,
  Button,
  GridContainer,
  GridItem,
  Image,
  Link,
  ReactTable,
  Stack,
  Typography,
} from '../../../components/shared';
import {
  AddCircleOutlineIcon,
  DeleteOutlinedIcon,
  EditOutlinedIcon,
  WarningTriangleOutlinedIcon,
} from '../../../assets/icons';
import {
  ACTION_IDS,
  FILTER_PARAMS,
  HEADCOUNT_COLUMNS,
  REPORT_TYPE,
  RESPONSE_TYPE,
  TOAST_TIMER,
} from '../../../constants';
import { ConfirmationModal, HeadcountFormModal, MonthDropdown, Page, YearDropdown } from '../../../components';
import {
  AddHeadcount,
  AddHeadcountResponse,
  DELETE_REPORT,
  DeleteReportDataResponse,
  DeleteReportDataVariables,
  DepartmentData,
  GET_REPORT_DATA,
  HeadcountFilterVariables,
  HeadcountResponse,
  UPSERT_HEADCOUNT,
} from '../../../graphql';
import { AddHeadcountFormValues } from '../../../components/headcount-form-modal/HeadcountFormModal';
import { UseToast } from '../../../hooks/useToast';
import { leadingZeroFormatNumber } from '../../../utils';
import { useGetCompanyByIdQuery } from '../../../services/companies/companies-service';
import { useStyles } from './style';
import { useBlobGetFiles, useNavigationPathForPortal } from '../../../hooks';

const Headcount = (): ReactElement => {
  const { classes } = useStyles();
  const [addHeadcountModalOpen, setAddHeadcountModalOpen] = useState(false);
  const [updateHeadcountModalOpen, setUpdateHeadcountModalOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [isEditHeadcount, setIsEditHeadcount] = useState(false);
  const [editHeadcountData, setEditHeadcountData] = useState<AddHeadcountFormValues>({} as AddHeadcountFormValues);
  const [departmentId, setDepartmentId] = useState<string>('');
  const [searchParams] = useSearchParams();
  const { YEAR, MONTH } = FILTER_PARAMS;
  const yearParams = searchParams.get(YEAR);
  const monthParams = searchParams.get(MONTH);
  const { companyId = '', reportId = '' } = useParams();
  const { t: tCommon } = useTranslation('translation', { keyPrefix: 'common' });
  const { t: tCompany } = useTranslation('translation', { keyPrefix: 'company' });
  const { t: tHeadcount } = useTranslation('translation', { keyPrefix: 'company.manual-portal-entry.headcount' });
  const { t: tError } = useTranslation('translation', { keyPrefix: 'common.errors' });
  const { t: tManualPortal } = useTranslation('translation', { keyPrefix: 'company.manual-portal-entry' });
  const { EDIT, DELETE, CANCEL, CLOSE } = ACTION_IDS;
  const theme = useTheme();
  const isDesktopView = useMediaQuery(theme.breakpoints.up('md'));
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { data, loading, refetch } = useQuery<HeadcountResponse, HeadcountFilterVariables>(GET_REPORT_DATA, {
    variables: {
      filters: {
        report_id: reportId,
        company_id: companyId,
        report_type: REPORT_TYPE.HEADCOUNT,
        year: Number(yearParams),
        month: Number(monthParams),
      },
    },
    skip: yearParams === null || monthParams === null || companyId === '' || reportId === '',
  });

  const [AddHeadcountApi, { loading: addLoading, reset: addHeadcountReset }] = useMutation<
    AddHeadcountResponse,
    { input: AddHeadcount }
  >(UPSERT_HEADCOUNT);
  const [DeleteHeadcountApi, { loading: deleteLoading }] = useMutation<
    DeleteReportDataResponse,
    DeleteReportDataVariables
  >(DELETE_REPORT);

  const { showToast } = UseToast();

  const reportData = data?.getReportData;

  const { currentData: companyDetails, isError } = useGetCompanyByIdQuery(companyId, {
    skip: companyId === undefined || companyId === '',
  });

  const navigation = useNavigationPathForPortal(companyId);

  const onHeadcountAddClickHandler = useCallback((): void => {
    setIsEditHeadcount(false);
    setAddHeadcountModalOpen(true);
  }, [addHeadcountModalOpen]);

  const onCancelOrCloseClickHandler = useCallback((): void => {
    if (isEditHeadcount) {
      setUpdateHeadcountModalOpen(false);
    } else setAddHeadcountModalOpen(false);
  }, [addHeadcountModalOpen, isEditHeadcount, updateHeadcountModalOpen]);

  const headcountColumn = useMemo(() => HEADCOUNT_COLUMNS(isDesktopView), [reportData, isDesktopView]);

  const onAddHeadcountClickHandler = useCallback(
    (values: AddHeadcountFormValues): void => {
      const formData = {
        report_id: reportId,
        company_id: companyId,
        report_type: REPORT_TYPE.HEADCOUNT,
        year: values.year,
        month: values.month,
        data: {
          department_id: values.department,
          employee_count: Number(values.employee_count),
          payroll_total: Number(values.payroll_total),
          full_time: Number(values.full_time_employee_count),
          part_time: Number(values.part_time_employee_count),
          turnover: Number(values.turnover),
          contract: Number(values.contract),
        },
      };

      AddHeadcountApi({ variables: { input: formData } })
        .then(async () => {
          if (isEditHeadcount) {
            setUpdateHeadcountModalOpen(false);
          } else {
            setAddHeadcountModalOpen(false);
          }
          showToast(
            tManualPortal('success.upload-success'),
            tManualPortal('success.updates-published-shortly'),
            RESPONSE_TYPE.SUCCESS,
            TOAST_TIMER.MANUAL_PORTAL,
          );
          addHeadcountReset();
          await refetch();
        })
        .catch(() => {
          showToast(tError('error'), tManualPortal('error.occurred'), RESPONSE_TYPE.ERROR);
        });
    },
    [addHeadcountModalOpen, AddHeadcountApi, companyId, reportId, isEditHeadcount],
  );

  const onConfirmationClickHandler = useCallback(
    (id: string): void => {
      if (id === DELETE) {
        DeleteHeadcountApi({ variables: { id: departmentId, report_type: REPORT_TYPE.HEADCOUNT } })
          .then(async () => {
            setConfirmationModalOpen(false);
            showToast(tCommon('success'), tManualPortal('success.delete'), RESPONSE_TYPE.SUCCESS);
            await refetch();
          })
          .catch(() => {
            showToast(tError('error'), tManualPortal('error.occurred'), RESPONSE_TYPE.ERROR);
          });
      }
      if (id === CANCEL || id === CLOSE) {
        setConfirmationModalOpen(false);
      }
    },
    [departmentId, confirmationModalOpen],
  );

  const onActionBtnClick = (id: string, row: Row<DepartmentData>): void => {
    if (id === EDIT) {
      setUpdateHeadcountModalOpen(true);
      setIsEditHeadcount(true);
      setEditHeadcountData({
        year: Number(yearParams),
        month: Number(monthParams),
        department: row.original.department_id,
        payroll_total: row.original.payroll_total,
        employee_count: row.original.employee_count,
        full_time_employee_count: row.original.full_time,
        part_time_employee_count: row.original.part_time,
        turnover: row.original.turnover,
        contract: row.original.contract,
      });
    }
    if (id === DELETE) {
      setDepartmentId(row.original.id);
      setConfirmationModalOpen(true);
    }
  };

  const actionBtn = [
    {
      id: EDIT,
      text: tCommon('edit'),
      icon: EditOutlinedIcon,
      onActionBtnClick,
    },
    {
      id: DELETE,
      text: tCommon('delete'),
      icon: DeleteOutlinedIcon,
      onActionBtnClick,
    },
  ];

  const title = `${companyDetails?.name} - ${tCompany('manual-portal-entry.title')}`;
  const { getAzureBlob } = useBlobGetFiles();
  const companyLogo = companyDetails?.logo;

  return (
    <>
      <NavigationHeader
        title={
          <Typography variant='h1'>
            {companyDetails ? title : isError ? tCompany('manual-portal-entry.title') : ''}
          </Typography>
        }
        navigateTo={navigation}
      >
        {companyLogo && (
          <Image src={getAzureBlob(companyLogo)?.url} alt={companyLogo} className={classes.companyLogo} />
        )}
      </NavigationHeader>

      <Page>
        <Box pt={{ md: 6, sm: 10, xs: 5 }}>
          <GridContainer spacing={{ xs: 2, sm: 2, md: 2 }} justifyContent='center'>
            <GridItem xs={12} sm={11} md={8.5}>
              <GridContainer spacing={{ xs: 2, sm: 2, md: 2 }}>
                <GridItem xs={12}>
                  <Typography variant='h2'>{tHeadcount('details')}</Typography>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <YearDropdown isFilter value={Number(yearParams)} />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <MonthDropdown isFilter value={Number(monthParams)} />
                </GridItem>
                <GridItem xs={12} pt={3}>
                  <Stack direction='row' justifyContent='space-between'>
                    <Typography variant='h2'>
                      {yearParams &&
                        monthParams &&
                        `${yearParams}-${leadingZeroFormatNumber(Number(monthParams))} ${tHeadcount('summary')}`}
                    </Typography>
                    <Link
                      variant='primaryLink'
                      startIcon={<AddCircleOutlineIcon />}
                      onClick={onHeadcountAddClickHandler}
                      data-testid='add-btn'
                      component={Button}
                    >
                      {tHeadcount('add')}
                    </Link>
                  </Stack>
                </GridItem>
                <GridItem xs={12}>
                  <ReactTable
                    data={reportData?.headcountData || []}
                    columns={headcountColumn}
                    isLocalTable
                    isLoading={loading}
                    isActionBtn
                    actionBtn={actionBtn}
                    notFoundDescription={tHeadcount('no-data-added-description')}
                  />
                </GridItem>
                <GridItem xs={12} textAlign='right' py={{ md: 5, sm: 3, xs: 3 }}>
                  <Button variant='outlined' data-testid='cancel-btn' component={RouterLink} to={`/${navigation}`}>
                    {tCommon('back')}
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </Box>
      </Page>
      <HeadcountFormModal
        open={addHeadcountModalOpen}
        onCancelOrCloseClick={onCancelOrCloseClickHandler}
        onAddHeadcount={onAddHeadcountClickHandler}
        loading={addLoading}
        companyId={companyId}
      />
      <HeadcountFormModal
        open={updateHeadcountModalOpen}
        onCancelOrCloseClick={onCancelOrCloseClickHandler}
        onAddHeadcount={onAddHeadcountClickHandler}
        loading={addLoading}
        isEdit={isEditHeadcount}
        data={editHeadcountData}
        companyId={companyId}
      />
      <ConfirmationModal
        open={confirmationModalOpen}
        icon={<WarningTriangleOutlinedIcon className={classes.confirmationIcon} />}
        confirmationTitle={tCommon('delete-confirmation')}
        description={tCommon('delete-confirmation-message')}
        buttons={[
          {
            id: CANCEL,
            label: tCommon('cancel'),
            color: 'primary',
            variant: 'outlined',
            onClick: onConfirmationClickHandler,
          },
          {
            id: DELETE,
            label: tCommon('delete'),
            color: 'error',
            onClick: onConfirmationClickHandler,
            loading: deleteLoading,
            'data-testid': 'delete-btn',
          },
        ]}
        onCloseClick={onConfirmationClickHandler}
      />
    </>
  );
};

export default Headcount;
