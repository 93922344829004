import { StepConnector } from '@mui/material';
import { withStyles } from '../../hooks';

export const CustomStepConnector = withStyles(StepConnector, theme => ({
  active: {
    '& .MuiStepConnector-lineHorizontal': {
      borderColor: theme.palette.primary.main,
    },
  },
  completed: {
    '& .MuiStepConnector-lineHorizontal': {
      borderColor: theme.palette.primary.main,
    },
  },
  disabled: {
    '& .MuiStepConnector-lineHorizontal': {
      borderColor: theme.palette.grey[400],
      opacity: 0.4,
    },
  },
  root: {
    position: 'absolute',
    top: '10px',
    left: 'calc(-50% + 15px)',
    right: 'calc(50% + 15px)',
    '& .MuiStepConnector-lineHorizontal': {
      borderRadius: '4px',
      borderWidth: theme.spacing(0.5),
    },
  },
}));
