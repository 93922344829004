import { ReactElement, memo } from 'react';
import { Link } from 'react-router-dom';
import { useStyles } from './style';
import { Box, IconButton, Image } from '../../shared';
import { HamburgerIcon } from '../../../assets/icons';
import { SignInLogo } from '../../../assets/logos';
import { ROUTES } from '../../../constants';

interface Props {
  onHamburgerClick?: () => void;
  isLoginHeader?: boolean;
}

const TabletHeader = (props: Props): ReactElement => {
  const { onHamburgerClick = (): void => {}, isLoginHeader = false } = props;
  const { classes } = useStyles({ isLoginHeader });

  return (
    <Box className={classes.tabletContainer}>
      <Box className={classes.tabletContainerWrapper}>
        {!isLoginHeader && (
          <Box className={classes.tabletHamburgerContainer}>
            <IconButton onClick={onHamburgerClick}>
              <HamburgerIcon />
            </IconButton>
          </Box>
        )}
        <Box className={classes.tabletLogoContainer}>
          <Link to={`/${ROUTES.DASHBOARD}`}>
            <Image src={SignInLogo} alt='logo' className={classes.tabletLogo} />
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(TabletHeader);
