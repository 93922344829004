import { makeStyles } from '../../../hooks';

export const useStyles = makeStyles()(theme => ({
  previewMainWrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  previewFileWrapper: {
    width: '100%',
    padding: `${theme.spacing(1.5)} ${theme.spacing(1)}`,
    backgroundColor: theme.palette.background.lightblue,
    position: 'relative',
    borderRadius: '10px',
  },
  previewImageWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  previewImage: {
    width: '72px',
    height: '72px',
    borderRadius: '10px',
    [theme.breakpoints.down('md')]: {
      objectFit: 'cover',
    },
  },
  previewImageNameText: {
    textTransform: 'lowercase',
  },
  previewImageSizeText: {
    color: theme.palette.grey[600],
  },
  closeIconWrapper: {
    textAlign: 'right',
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    position: 'absolute',
    top: 0,
    right: 0,
    '& .MuiSvgIcon-root': {
      color: theme.palette.secondary.main,
    },
  },
  errorPreviewWrapper: {
    border: `1px solid ${theme.palette.error.main}`,
  },
  errorTextWrapper: {
    paddingLeft: theme.spacing(2),
  },
}));
