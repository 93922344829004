import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import {
  BlobFilePreview,
  Box,
  Button,
  Dropzone,
  GridContainer,
  GridItem,
  LoadingSpinner,
  Stack,
  TextField,
} from '../shared';
import { ALLOWED_IMAGE_DIMENSIONS } from '../../constants';
import { RequiredInfoStaticLabel } from '../required-info-static-label';
import { portfolioCompanySchema } from '../../validation-schemas';
import { useGetCompanyByIdQuery } from '../../services/companies/companies-service';

interface FileWithPreview extends File {
  preview?: string;
}
export interface ImageInfo {
  file: FileWithPreview;
  errorMessage?: string;
  error: boolean;
}

export interface CompanyDetailsForms {
  companyName: string;
  companyUrl: string;
  companyLogo: ImageInfo[] | string[];
}

interface Props {
  onContinueClick?: (type: string, values: CompanyDetailsForms) => void;
  onCancelClick: () => void;
  isLoading?: boolean;
  isEdit?: boolean;
  onUpdateClick?: (values: CompanyDetailsForms) => Promise<void>;
  companyId?: string;
  companyExists?: boolean;
}

const PortfolioCompanyDetails = (props: Props): ReactElement => {
  const { t: tCommon } = useTranslation('translation', { keyPrefix: 'common' });
  const { t: tCompany } = useTranslation('translation', { keyPrefix: 'company' });
  const { onContinueClick, onCancelClick, isLoading, isEdit, onUpdateClick, companyId, companyExists = false } = props;
  const {
    currentData: companyDetails,
    isLoading: companyLoading,
    isFetching: companyFetching,
  } = useGetCompanyByIdQuery(companyId as string, { skip: !isEdit && companyId === undefined });

  const initialValues: CompanyDetailsForms = {
    companyName: '',
    companyUrl: '',
    companyLogo: [],
  };

  const formik = useFormik({
    initialValues,
    validationSchema: portfolioCompanySchema(),
    onSubmit: async values => {
      if (isEdit) await onUpdateClick?.(values);
      onContinueClick?.('portfolioCompanyDetails', values);
    },
  });

  const { handleSubmit, touched, errors, handleChange, values, setFieldValue, setValues, setFieldError } = formik;

  /* eslint-disable @typescript-eslint/no-floating-promises */
  useMemo(async (): Promise<void> => {
    if (isEdit && companyId && companyDetails) {
      await setValues({
        companyName: companyDetails?.name || '',
        companyUrl: companyDetails?.url || '',
        companyLogo: companyDetails?.logo ? [companyDetails?.logo] : [],
      });
    }
  }, [companyDetails]);
  /* eslint-enable @typescript-eslint/no-floating-promises */
  useMemo((): void => {
    if (companyExists) {
      setFieldError('companyName', tCompany('exists'));
    }
  }, [companyExists, errors.companyName]);

  const handleImagesChange = async (images: ImageInfo[]): Promise<void> => {
    if (images.length) {
      await setFieldValue('companyLogo', images);
    }
  };

  const onCloseClick = async (index: number): Promise<void> => {
    const filesArr = [...values.companyLogo];
    filesArr.splice(index, 1);
    await setFieldValue('companyLogo', filesArr);
  };

  if (companyLoading || companyFetching) {
    return <LoadingSpinner />;
  }

  return (
    <GridContainer
      rowSpacing={{ md: isEdit ? 17 : 5, sm: isEdit ? 17 : 17 }}
      columnSpacing={{ md: isEdit ? 17 : 5, sm: isEdit ? 17 : 17 }}
      justifyContent='center'
    >
      <GridItem xs={12} md={9} sm={12}>
        <GridContainer spacing={3}>
          <GridItem xs={12}>
            <TextField
              label={tCompany('company-name')}
              name='companyName'
              value={values.companyName}
              onChange={handleChange}
              data-testid='company-details-form'
              error={Boolean(touched.companyName && errors.companyName)}
              helperText={touched.companyName && errors.companyName}
              fullWidth
              required
            />
          </GridItem>
          <GridItem xs={12}>
            <TextField
              label={tCompany('company-url')}
              name='companyUrl'
              value={values.companyUrl}
              onChange={handleChange}
              error={Boolean(touched.companyUrl && errors.companyUrl)}
              helperText={touched.companyUrl && errors.companyUrl}
              fullWidth
              data-testid='company-details-form'
            />
          </GridItem>
          <GridItem xs={12}>
            {isEdit && values.companyLogo.length > 0 && !(values.companyLogo[0] as ImageInfo).file ? (
              <BlobFilePreview files={values.companyLogo as string[]} onCloseBtnClickHandler={onCloseClick} />
            ) : (
              <Dropzone
                isSingleUpload
                uploadFormatText={tCommon('dropzone.upload-image-format-text')}
                noteText={tCommon('dropzone.upload-image-note-text')}
                onImagesChange={handleImagesChange}
                maxDimensions={ALLOWED_IMAGE_DIMENSIONS}
                dataTestId='company-details-form'
              />
            )}
          </GridItem>
          <GridItem xs={12}>
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
              <RequiredInfoStaticLabel />
              <Box>
                <Stack direction='row' spacing={2}>
                  <Button variant='outlined' onClick={onCancelClick} data-testid='cancel-btn'>
                    {tCommon('cancel')}
                  </Button>
                  <Button
                    variant='contained'
                    onClick={(): void => {
                      handleSubmit();
                    }}
                    data-testid='continue-btn'
                    loading={isLoading}
                  >
                    {isEdit ? tCommon('update') : tCompany('create')}
                  </Button>
                </Stack>
              </Box>
            </Stack>
          </GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>
  );
};

export default PortfolioCompanyDetails;
