import { makeStyles } from '../../hooks';

interface StyleProps {
  isSmallDropdown?: boolean;
}

export const useStyles = makeStyles<StyleProps>()((theme, { isSmallDropdown }) => ({
  popperContainer: {
    zIndex: 1300,
  },
  menuMainContainer: {
    boxShadow: theme.customShadows.default,
    margin: '8px 0px',
  },
  listWrapper: {
    paddingTop: isSmallDropdown ? '8px' : '15px',
    paddingBottom: isSmallDropdown ? '8px' : '13px',
    minWidth: '230px',
    '& li.MuiListItem-root': {
      padding: '12px 24px',
      ':focus-visible': {
        outline: 'none',
      },
    },
    '& li.MuiMenuItem-root': {
      padding: isSmallDropdown ? '6px 12px' : '13px 24px',
      '& .MuiListItemIcon-root': {
        minWidth: 0,
        paddingRight: '8px',
      },
    },
  },
  menuBtn: {
    width: '32px',
    height: '32px',
    borderRadius: '4px',
    backgroundColor: 'transparent',
  },
  menuBtnActive: {
    backgroundColor: theme.palette.background.lightblue,
    ':hover': {
      backgroundColor: theme.palette.background.lightblue,
    },
  },
  menuItem: {
    '&.Mui-selected': {
      '& span.MuiTypography-subtitle4': {
        color: theme.palette.common.white,
      },
      '& h5.MuiTypography-h5': {
        color: theme.palette.common.white,
      },
    },
  },
  titleBtn: {
    color: theme.palette.common.black,

    ':hover': {
      color: theme.palette.common.black,
      backgroundColor: theme.palette.common.white,
    },
  },
}));
