import { KeyboardEvent, ReactElement, ReactNode, SyntheticEvent, memo, useRef, useState } from 'react';
import { ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from '@mui/material';
import { Box, Button, IconButton, Image, ListItemIcon, Typography } from '../shared';
import { DownArrowIcon, MoreHorizontalIcon, UpArrowIcon } from '../../assets/icons';
import { useStyles } from './style';
import { KEYS } from '../../constants';

interface IMenuItem {
  id: string;
  text?: string;
  icon?: string;
  onItemClick: () => void;
  selected?: boolean;
  disabled?: boolean;
}
interface Props {
  btnIcon?: string;
  menuItems: IMenuItem[] | undefined;
  onIconClick?: (isOpen: string) => void;
  rowId?: string;
  selectedTitle?: string;
  isSmallDropdown?: boolean;
  startIcon?: ReactNode;
}

const DrawerButtonMenu = (props: Props): ReactElement => {
  const {
    menuItems,
    btnIcon = MoreHorizontalIcon,
    onIconClick = (): void => {},
    rowId = '',
    selectedTitle = '',
    isSmallDropdown = false,
    startIcon,
  } = props;
  const { classes } = useStyles({ isSmallDropdown });
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = (id: string): void => {
    setOpen(prevOpen => !prevOpen);
    onIconClick(id);
  };

  const handleListKeyDown = (event: KeyboardEvent): void => {
    if (event.key === KEYS.TAB) {
      event.preventDefault();
      onIconClick('');
      setOpen(false);
    } else if (event.key === KEYS.ESCAPE) {
      onIconClick('');
      setOpen(false);
    }
  };

  const handleClose = (event: Event | SyntheticEvent): void => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };
  const onMenuItemClickHandler = (item: IMenuItem, event: Event | SyntheticEvent): void => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    onIconClick('');
    setOpen(false);
    const { onItemClick } = item;
    onItemClick();
  };

  const onBlurHandler = (): void => {
    onIconClick('');
  };

  return (
    <Box data-testid='drawer-button-menu'>
      {selectedTitle ? (
        <Button
          ref={anchorRef}
          id='position-menu-button'
          aria-controls={open ? 'drawer-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup='true'
          onClick={(): void => handleToggle(rowId)}
          className={classes.titleBtn}
          disableRipple
          data-testid='drawer-btn'
          variant='text'
          size='small'
          endIcon={open ? <UpArrowIcon /> : <DownArrowIcon />}
          startIcon={startIcon}
        >
          {selectedTitle}
        </Button>
      ) : (
        <IconButton
          ref={anchorRef}
          id='position-menu-button'
          aria-controls={open ? 'drawer-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup='true'
          onClick={(): void => handleToggle(rowId)}
          className={`${classes.menuBtn} ${open ? classes.menuBtnActive : ''}`}
          disableRipple
          data-testid='drawer-btn'
        >
          <Image src={btnIcon} alt='btn-icon' />
        </IconButton>
      )}
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role='menu'
        placement='bottom-end'
        transition
        disablePortal
        className={classes.popperContainer}
      >
        {({ TransitionProps }): ReactNode => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'right top',
            }}
          >
            <Paper className={classes.menuMainContainer} onBlur={onBlurHandler}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id='drawer-menu'
                  aria-labelledby='drawer-button'
                  onKeyDown={handleListKeyDown}
                  className={classes.listWrapper}
                >
                  {menuItems?.map(item => (
                    <MenuItem
                      onClick={(event: Event | SyntheticEvent): void => onMenuItemClickHandler(item, event)}
                      key={item.id}
                      data-testid='drawer-menu'
                      selected={item.selected}
                      className={classes.menuItem}
                      disabled={item.disabled}
                    >
                      {item.icon && (
                        <ListItemIcon>
                          <Image src={item.icon} alt={item.icon} />
                        </ListItemIcon>
                      )}
                      <Typography variant={isSmallDropdown ? 'h5' : 'subtitle4'} color='secondary.main'>
                        {item.text}
                      </Typography>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};

export default memo(DrawerButtonMenu);
