import { useEffect, useState } from 'react';
import { getManualPortalNavigation, isUserSuperAdmin } from '../utils'; // adjust the import path according to your project structure
import { PORTAL_BACK_SELECTION, ROUTES } from '../constants';
import { useAppSelector } from './redux-dispatch-selector';
import { getUserRole } from '../redux/auth';

export const useNavigationPathForPortal = (companyId: string): string => {
  const [navigation, setNavigation] = useState<string>('');
  const currentUserRole = useAppSelector(getUserRole);
  const superAdminUser = isUserSuperAdmin(currentUserRole);
  const { MANUAL_PORTAL_ENTRY, COMPANY, OVERVIEW, DASHBOARD } = ROUTES;

  useEffect(() => {
    const navigateFrom = getManualPortalNavigation();
    if (superAdminUser) {
      if (navigateFrom === PORTAL_BACK_SELECTION.DROPDOWN) {
        setNavigation(`${COMPANY}/${companyId}/${OVERVIEW}`);
      } else {
        setNavigation(`${MANUAL_PORTAL_ENTRY}/${companyId}`);
      }
    } else {
      setNavigation(DASHBOARD);
    }
  }, [companyId]);

  return navigation;
};
