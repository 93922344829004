import { useCallback, useEffect } from 'react';
import { $insertNodes, $getRoot } from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $generateNodesFromDOM } from '@lexical/html';
import { HTML_PARSER } from '../../../constants';

interface Props {
  htmlString: string;
}

const GetDataPlugin = (props: Props): null => {
  const { htmlString } = props;
  const [editor] = useLexicalComposerContext();
  const insertHtml = useCallback(
    (html: string) => {
      editor.update(() => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, HTML_PARSER);
        const nodes = $generateNodesFromDOM(editor, doc);
        $getRoot().clear();
        $insertNodes(nodes);
      });
    },
    [editor, htmlString],
  );

  useEffect(() => {
    insertHtml(htmlString);
  }, [insertHtml]);
  return null;
};

export default GetDataPlugin;
