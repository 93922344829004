import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Error, Page } from '../../components';
import { FileNotFoundIcon } from '../../assets/icons';
import { useStyles } from './style';

const NotFound: FC = () => {
  const { classes } = useStyles();
  const { t: tError } = useTranslation('translation', { keyPrefix: 'common.errors.404' });

  return (
    <Page className={classes.container}>
      <Error
        code={tError('status')}
        message={{ title: tError('page-not-found'), description: tError('sorry-page-not-exist') }}
        icon={FileNotFoundIcon}
      />
    </Page>
  );
};

export default NotFound;
