import { makeStyles } from '../../hooks';

export const useStyles = makeStyles()(theme => ({
  container: {
    height: '100%',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
}));
