import { gql } from '@apollo/client';
import {
  CAPITAL_STRUCTURE_FRAGMENT,
  FIVE_YEAR_PLAN_FRAGMENT,
  HEADCOUNT_FRAGMENT,
  PUBLIC_EQUITY_FRAGMENT,
  THIRTEEN_WEEK_CF,
  VALUATION_FRAGMENT,
} from './fragment';

export const GET_REPORT_DATA = gql`
  ${HEADCOUNT_FRAGMENT}
  ${PUBLIC_EQUITY_FRAGMENT}
  ${VALUATION_FRAGMENT}
  ${FIVE_YEAR_PLAN_FRAGMENT}
  ${THIRTEEN_WEEK_CF}
  ${CAPITAL_STRUCTURE_FRAGMENT}
  query ReportDataQuery($filters: ReportDataFilters!) {
    getReportData(filters: $filters) {
      ... on Headcount {
        id
        report_id
        company_id
        report_type
        year
        month
        ...HeadcountFragment
      }
      ... on PublicEquity {
        id
        company_id
        report_id
        report_type
        year
        month
        ...PublicEquityFragment
      }
      ... on Valuation {
        id
        company_id
        report_id
        report_type
        year
        month
        ...ValuationFragment
      }
      ... on FiveYearPlan {
        id
        report_type
        report_id
        company_id
        year
        month
        ...FiveYearPlanFragment
      }
      ... on ThirteenWeekCf {
        id
        report_type
        report_id
        company_id
        year
        concept
        gl_account
        ...ThirteenWeekCfFragment
      }
      ... on CapitalStructure {
        id
        report_type
        report_id
        company_id
        year
        ...CapitalStructureFragment
      }
    }
  }
`;
