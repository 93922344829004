import { TableRow as MuiTableRow, TableRowProps } from '@mui/material';
import { ReactElement, ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

type AppTableRow = Props & TableRowProps;

const TableRow = (props: AppTableRow): ReactElement => {
  const { children, ...other } = props;
  return <MuiTableRow {...other}>{children}</MuiTableRow>;
};

export default TableRow;
