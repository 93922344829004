import { string, array, number, date, object } from 'yup';
import i18next from '../i18n/config';
import {
  ArrayValidation,
  AutoCompleteValidation,
  DateValidation,
  NameValidation,
  NumberValidation,
  StringValidation,
  UrlAndEmailValidation,
} from './types';

const { t } = i18next;

const URL_VALIDATION_REGEX =
  /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

const EMAIL_VALIDATION_REGEX =
  /^(?!.*\.{2})([a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;

const NAME_VALIDATION_REGEX = /^[a-zA-Z]([a-zA-Z0-9 .'-]*)$/;

export const STRING_VALIDATION = (): StringValidation => ({
  REQUIRED: string().trim().required(t('common.validation.required')),
  NOT_REQUIRED: string().notRequired(),
});

export const NUMBER_VALIDATION = (): NumberValidation => ({
  REQUIRED: number().required(t('common.validation.required')),
  NOT_REQUIRED: number().notRequired(),
  REQUIRED_INTEGER: number().required(t('common.validation.required')).truncate(),
});

export const ARRAY_VALIDATION = (): ArrayValidation => ({
  REQUIRED: array().required(t('common.validation.required')),
  NOT_REQUIRED: array().notRequired(),
});

export const URL_VALIDATION = (): UrlAndEmailValidation => ({
  VALIDATE: string().trim().matches(URL_VALIDATION_REGEX, t('common.validation.invalid-url')),
  REQUIRED: string().trim().matches(URL_VALIDATION_REGEX, t('common.validation.invalid-url')).required(),
});

export const EMAIL_VALIDATION = (): UrlAndEmailValidation => ({
  VALIDATE: string().trim().matches(EMAIL_VALIDATION_REGEX, t('common.validation.valid-email')),
  REQUIRED: string()
    .trim()
    .matches(EMAIL_VALIDATION_REGEX, t('common.validation.valid-email'))
    .required(t('common.validation.required')),
});

export const NAME_VALIDATION = (): NameValidation => ({
  VALIDATE: string().trim().matches(NAME_VALIDATION_REGEX, t('common.validation.valid-name')),
  REQUIRED: string()
    .trim()
    .matches(NAME_VALIDATION_REGEX, t('common.validation.valid-name'))
    .required(t('common.validation.required')),
});

const baseDateSchema = date().typeError(t('common.validation.invalid-date')).nullable();

export const DATE_VALIDATION = (): DateValidation => ({
  REQUIRED: baseDateSchema.required(t('common.validation.required')),
  NOT_REQUIRED: baseDateSchema.notRequired(),
});

export const AUTOCOMPLETE_VALIDATION = (): AutoCompleteValidation => ({
  REQUIRED: object()
    .shape({
      id: STRING_VALIDATION().REQUIRED,
      text: STRING_VALIDATION().REQUIRED,
      value: STRING_VALIDATION().REQUIRED,
    })
    .nullable()
    .required(t('common.validation.required')),
  NOT_REQUIRED: object()
    .shape({
      id: STRING_VALIDATION().REQUIRED,
      text: STRING_VALIDATION().REQUIRED,
      value: STRING_VALIDATION().REQUIRED,
    })
    .nullable()
    .notRequired(),
});
