import { Chip as MuiChip, alpha } from '@mui/material';

import { withStyles } from '../../../hooks';

export const AppChip = withStyles(MuiChip, theme => ({
  labelMedium: {
    padding: '4px 8px',
  },
  sizeMedium: {
    margin: '4px',
  },
  outlined: {
    borderRadius: '10px',
    border: `1px solid ${alpha(theme.palette.secondary.main, 0.6)}`,
    '& .MuiChip-label': {
      fontSize: '14px',
      color: alpha(theme.palette.secondary.main, 0.6),
    },
  },
  filled: {
    borderRadius: '4px',
    backgroundColor: theme.palette.background.lightblue,
    '& .MuiChip-label': {
      fontSize: '16px',
      fontWeight: 600,
      color: theme.palette.secondary.main,
    },
  },
}));
