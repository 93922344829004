import { ReactElement, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip } from '../shared';
import { FileSuccessDefaultIcon, FileSuccessGreenIcon } from '../../assets/icons';
import { ConfirmationModal } from '../confirmation-modal';
import { ACTION_IDS, RESPONSE_TYPE } from '../../constants';
import { useStyles } from './style';
import { useAddReviewReportMutation } from '../../services/reports/reports-service';
import { UseToast } from '../../hooks/useToast';
import { ResponseError } from '../../services';

interface Props {
  companyId: string;
  isReportReviewed?: boolean;
}

const ReportReviewed = (props: Props): ReactElement => {
  const { companyId, isReportReviewed = false } = props;
  const { t: tCommon } = useTranslation('translation', { keyPrefix: 'common' });
  const { t: tReviewReport } = useTranslation('translation', { keyPrefix: 'review-report' });
  const { t: tError } = useTranslation('translation', { keyPrefix: 'common.errors' });
  const { t: tApiError } = useTranslation('translation');
  const [confirmationModalOpen, setConfirmationModalOpen] = useState<boolean>(false);
  const { classes } = useStyles({ isConfirmationOpen: confirmationModalOpen });
  const { CANCEL, PROCEED, CLOSE } = ACTION_IDS;
  const [AddReviewReportApi, { isLoading }] = useAddReviewReportMutation();
  const { showToast } = UseToast();

  const onDefaultButtonClickHandler = (): void => {
    setConfirmationModalOpen(true);
  };

  const onConfirmationClickHandler = useCallback(
    (id: string): void => {
      if (id === PROCEED) {
        AddReviewReportApi(companyId)
          .unwrap()
          .then(() => {
            showToast(tReviewReport('success.title'), tReviewReport('success.description'), RESPONSE_TYPE.SUCCESS);
            setConfirmationModalOpen(false);
          })
          .catch((err: ResponseError) => {
            showToast(tError('error'), tApiError(err.data.errorCode, ''), RESPONSE_TYPE.ERROR);
          });
      }
      if (id === CANCEL || id === CLOSE) {
        setConfirmationModalOpen(false);
      }
    },
    [confirmationModalOpen],
  );

  const showButton = (): ReactElement | null => {
    if (isReportReviewed) {
      return (
        <Tooltip
          title={tReviewReport('reports-reviewed')}
          arrow
          placement='bottom-start'
          PopperProps={{ className: classes.reviewTooltip }}
        >
          <IconButton className={classes.iconButton} data-testid='success-reviewed-btn'>
            <FileSuccessGreenIcon />
          </IconButton>
        </Tooltip>
      );
    }
    return (
      <Tooltip
        title={tReviewReport('review-all-pc-report')}
        arrow
        placement='bottom-start'
        PopperProps={{ className: classes.reviewTooltip }}
      >
        <IconButton
          onClick={onDefaultButtonClickHandler}
          className={classes.iconButton}
          data-testid='default-reviewed-btn'
        >
          <FileSuccessDefaultIcon />
        </IconButton>
      </Tooltip>
    );
  };

  return (
    <>
      {showButton()}
      <ConfirmationModal
        open={confirmationModalOpen}
        confirmationTitle={tReviewReport('confirmation.title')}
        description={tReviewReport('confirmation.description')}
        buttons={[
          {
            id: CANCEL,
            label: tCommon('cancel'),
            color: 'primary',
            variant: 'outlined',
            onClick: onConfirmationClickHandler,
          },
          {
            id: PROCEED,
            label: tCommon('proceed'),
            onClick: onConfirmationClickHandler,
            loading: isLoading,
            'data-testid': 'proceed-btn',
          },
        ]}
        onCloseClick={onConfirmationClickHandler}
      />
    </>
  );
};

export default ReportReviewed;
