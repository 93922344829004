import { SvgIconTypeMap } from '@mui/material';
import { FunctionComponent, SVGProps } from 'react';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import i18next from '../i18n/config';
import { HomeIcon, TwoPersonIcon } from '../assets/icons';
import { ROUTES, ROUTE_MODULE } from './route-constants';
export const SIDEBAR_CONSTANTS = {
  SIDEBAR_WIDTH: {
    DESKTOP: {
      MINI_WIDTH: 116,
      EXPANDED_WIDTH: 244,
    },
  },
};

export interface IDrawerItems {
  text: string;
  icon:
    | (OverridableComponent<SvgIconTypeMap<object, 'svg'>> & {
        muiName: string;
      })
    | string
    | FunctionComponent<
        SVGProps<SVGSVGElement> & {
          title?: string | undefined;
        }
      >;
  path: string;
  module?: string;
}

export const GET_DRAWER_ITEMS = (reports: IDrawerItems[]): IDrawerItems[] => [
  {
    text: i18next.t('common.home'),
    icon: HomeIcon,
    path: ROUTES.DASHBOARD,
    module: ROUTE_MODULE.DASHBOARD_MODULE,
  },
  ...reports,
  {
    text: i18next.t('user.user', { count: 2 }),
    icon: TwoPersonIcon,
    path: ROUTES.USERS,
    module: ROUTE_MODULE.USER_MODULE,
  },
];
