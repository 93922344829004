import { ReactElement, ReactNode, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Image, LoadingSpinner, Stack, Typography } from '../../../components/shared';
import { useGetAllReportsByCompanyIdQuery, useGetEmbedReportQuery } from '../../../services/reports/reports-service';
import { FILTER_PARAMS, HttpStatusCode, ROUTES } from '../../../constants';
import { IEmbedConfig, IEmbedReport } from '../../../services/reports/reports-interface';
import { ChatBox, ChatButtonIcon, EmbedReport, Error, ManualDataDropdown, Page } from '../../../components';
import { ReportNotFoundIcon } from '../../../assets/icons';
import { useStyles } from './style';
import { ResponseError } from '../../../services';
import { useAppSelector, useBlobGetFiles } from '../../../hooks';
import { getUserRole, getUserState } from '../../../redux/auth';
import { isUserSuperAdmin } from '../../../utils';
import { NavigationHeader } from '../../../components/layout';
import { useGetCompanyByIdQuery } from '../../../services/companies/companies-service';
import { useGetCommentsQuery } from '../../../services/comment/comment-service';
import { CommentData } from '../../../services/comment/comment-interface';

const CompanyReport = (): ReactElement => {
  const { classes } = useStyles();
  const { t: tReport } = useTranslation('translation', { keyPrefix: 'report' });
  const { companyId, reportId } = useParams();
  const { company_id } = useAppSelector(getUserState);
  const currentUserRole = useAppSelector(getUserRole);
  const [searchParams, setSearchParams] = useSearchParams();
  const companyIdOnRolesBasis = isUserSuperAdmin(currentUserRole) ? companyId : company_id;
  const initialEmbedConfig: IEmbedConfig = {
    embed_url: '',
    embed_token: '',
  };
  const initialData: IEmbedReport = {
    _id: '',
    name: '',
    label: '',
    embed_config: initialEmbedConfig,
  };
  const {
    currentData: embedReport = initialData,
    isLoading,
    isFetching,
    isError,
    error,
    isSuccess,
  } = useGetEmbedReportQuery(
    { companyId: companyIdOnRolesBasis as string, reportId: reportId as string },
    { skip: companyIdOnRolesBasis === undefined || companyIdOnRolesBasis === '' || reportId === undefined },
  );
  const { embed_config } = embedReport;

  const { embed_token = '', embed_url = '' } = embed_config;
  const { currentData: companyDetails } = useGetCompanyByIdQuery(companyIdOnRolesBasis as string, {
    skip: companyIdOnRolesBasis === undefined,
  });
  const { getAzureBlob } = useBlobGetFiles();
  const companyLogo = companyDetails?.logo;
  const [showChatBox, setShowChatBox] = useState(false);
  const {
    currentData,
    isLoading: commentLoading,
    isFetching: commentFetching,
  } = useGetCommentsQuery(
    { companyId: companyIdOnRolesBasis as string, reportId: reportId as string, filter: searchParams.toString() },
    { skip: !companyIdOnRolesBasis || !reportId },
  );

  const { currentData: reportsData = [] } = useGetAllReportsByCompanyIdQuery(companyIdOnRolesBasis as string, {
    skip: companyIdOnRolesBasis === undefined || companyIdOnRolesBasis === '',
  });

  const reportCommentsData = (currentData?.data as CommentData[]) || [];
  const { FILTER } = FILTER_PARAMS;

  useEffect(() => {
    if (isError) {
      setShowChatBox(false);
    }
  }, [isError]);

  const showCommentBoxAndScrollIntoView = (): void => {
    const commentBox = document.getElementById('comment-box');
    setShowChatBox(true);
    setTimeout(() => {
      commentBox?.scrollIntoView({ behavior: 'smooth' });
    }, 500);
  };

  const onChatButtonClickHandler = (): void => {
    showCommentBoxAndScrollIntoView();
  };

  const onChatBoxCloseClickHandler = (): void => {
    setShowChatBox(false);
    searchParams.delete(FILTER);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (searchParams.get(FILTER) && embed_url) {
      showCommentBoxAndScrollIntoView();
    }
  }, [embed_url]);

  useEffect(() => {
    if (reportId) setShowChatBox(false);
  }, [reportId]);

  const reportData = reportsData.find(eachReport => eachReport._id === reportId);
  const reportTitle = reportData?.label;

  const renderEmbedReport = (): ReactNode => {
    if (isLoading || isFetching) {
      return (
        <Page>
          <LoadingSpinner />
        </Page>
      );
    }
    if (isError && (error as ResponseError).status === HttpStatusCode.BAD_REQUEST) {
      return (
        <Page className={classes.reportContainer}>
          <Error
            icon={ReportNotFoundIcon}
            message={{ title: tReport('not-found.title'), description: tReport('not-found.description') }}
          />
        </Page>
      );
    }
    return <EmbedReport embed_token={embed_token} embed_url={embed_url} key={reportId} />;
  };

  return (
    <>
      <NavigationHeader
        title={
          <Typography variant='h1' data-testid='report-title'>
            {reportTitle}
          </Typography>
        }
        navigateTo={ROUTES.DASHBOARD}
      >
        {companyLogo && (
          <Image src={getAzureBlob(companyLogo)?.url} alt={companyLogo} className={classes.companyLogo} />
        )}
      </NavigationHeader>

      <Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={2} pt={0.5} pb={2.5}>
        <Box>
          <ManualDataDropdown companyId={companyIdOnRolesBasis as string} />
        </Box>
        {isSuccess && (
          <Box>
            <ChatButtonIcon count={reportCommentsData.length} onClick={onChatButtonClickHandler} />
          </Box>
        )}
      </Stack>

      <Box className={classes.reportContainer} data-testid='report-container'>
        {renderEmbedReport()}
        <Box py={2} id='comment-box' mt={2}>
          {showChatBox && (
            <ChatBox
              onChatBoxClose={onChatBoxCloseClickHandler}
              companyId={companyIdOnRolesBasis as string}
              commentData={reportCommentsData}
              reportId={reportId as string}
              isLoading={commentLoading || commentFetching}
            />
          )}
        </Box>
      </Box>
    </>
  );
};

export default CompanyReport;
