import { ReactElement } from 'react';
import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import { useStyles } from './style';

interface Props {
  embed_url: string;
  embed_token: string;
}

const EmbedReport = (props: Props): ReactElement => {
  const { embed_url, embed_token } = props;
  const { classes } = useStyles();

  return (
    <PowerBIEmbed
      embedConfig={{
        type: 'report',
        embedUrl: embed_url,
        tokenType: models.TokenType.Embed,
        accessToken: embed_token,
        settings: {
          background: models.BackgroundType.Transparent,
          filterPaneEnabled: false,
          navContentPaneEnabled: false,
          layoutType: models.LayoutType.Custom,
          customLayout: {
            displayOption: models.DisplayOption.FitToWidth,
          },
        },
      }}
      cssClassName={classes.reportContainer}
    />
  );
};

export default EmbedReport;
