import { Fragment, ReactElement, useEffect } from 'react';
import { useParams, useSearchParams, Link as RouterLink } from 'react-router-dom';
import { getIn, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import { NavigationHeader } from '../../../components/layout';
import {
  Box,
  Button,
  GridContainer,
  GridItem,
  Image,
  LoadingSpinner,
  Stack,
  TextField,
  Typography,
} from '../../../components/shared';
import { useBlobGetFiles, useNavigationPathForPortal } from '../../../hooks';
import { MonthDropdown, Page, YearDropdown } from '../../../components';
import { FILTER_PARAMS, REPORT_TYPE, RESPONSE_TYPE, TOAST_TIMER } from '../../../constants';
import { fiveYearPlanSchema } from '../../../validation-schemas/portfolio-entry-schema';
import {
  FIveYearPlanInput,
  FiveYearPlanData,
  FiveYearPlanFilterVariables,
  FiveYearPlanResponse,
  GET_REPORT_DATA,
  UPSERT_FIVE_YEAR_PLAN,
  UpsertFiveYearPlanResponse,
} from '../../../graphql';
import { useGetCompanyByIdQuery } from '../../../services/companies/companies-service';
import { UseToast } from '../../../hooks/useToast';
import { useStyles } from './style';

const FiveYearPlan = (): ReactElement => {
  const { classes } = useStyles();
  const { t: tCommon } = useTranslation('translation', { keyPrefix: 'common' });
  const { t: tManualPortal } = useTranslation('translation', { keyPrefix: 'company.manual-portal-entry' });
  const { t: t5YearPlan } = useTranslation('translation', { keyPrefix: 'company.manual-portal-entry.five-year-plan' });
  const { t: tError } = useTranslation('translation', { keyPrefix: 'common.errors' });
  const [searchParams] = useSearchParams();
  const { YEAR, MONTH } = FILTER_PARAMS;
  const yearParams = searchParams.get(YEAR);
  const monthParams = searchParams.get(MONTH);
  const { companyId = '', reportId = '' } = useParams();
  const navigation = useNavigationPathForPortal(companyId);
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { data, refetch, loading } = useQuery<FiveYearPlanResponse, FiveYearPlanFilterVariables>(GET_REPORT_DATA, {
    variables: {
      filters: {
        report_id: reportId,
        company_id: companyId,
        report_type: REPORT_TYPE.FIVE_YEAR_PLAN,
        year: Number(yearParams),
        month: Number(monthParams),
      },
    },
    skip: yearParams === null || monthParams === null || companyId === '' || reportId === '',
  });
  const { currentData: companyDetails, isError } = useGetCompanyByIdQuery(companyId, {
    skip: companyId === undefined || companyId === '',
  });
  const [UpsertFiveYearPlanApi, { loading: upsertLoading }] = useMutation<
    UpsertFiveYearPlanResponse,
    { input: FIveYearPlanInput }
  >(UPSERT_FIVE_YEAR_PLAN);

  const { showToast } = UseToast();

  const title = `${companyDetails?.name} - ${t5YearPlan('add')}`;
  const { getAzureBlob } = useBlobGetFiles();
  const companyLogo = companyDetails?.logo;

  const fiveYearPlanData = data?.getReportData?.fiveYearPlanData || [];

  const formFields = Array(5).fill(t5YearPlan('fy'));

  const initialValues =
    formFields.map((year, index) => ({
      financial_year: `${year} ${index + 1}`,
      revenue: '',
      ebitda: '',
    })) || fiveYearPlanData;

  const formik = useFormik({
    initialValues,
    validationSchema: fiveYearPlanSchema(),
    onSubmit: values => {
      const fiveYearPlanArray = values.map(({ financial_year, revenue, ebitda }) => ({
        financial_year,
        revenue: Number(revenue),
        ebitda: Number(ebitda),
      })) as unknown as FiveYearPlanData[];

      const formData = {
        report_id: reportId,
        company_id: companyId,
        report_type: REPORT_TYPE.FIVE_YEAR_PLAN,
        month: Number(monthParams),
        year: Number(yearParams),
        data: fiveYearPlanArray,
      };
      UpsertFiveYearPlanApi({ variables: { input: formData } })
        .then(async () => {
          showToast(
            tManualPortal('success.upload-success'),
            tManualPortal('success.updates-published-shortly'),
            RESPONSE_TYPE.SUCCESS,
            TOAST_TIMER.MANUAL_PORTAL,
          );
          await refetch();
        })
        .catch(() => {
          showToast(tError('error'), tManualPortal('error.occurred'), RESPONSE_TYPE.ERROR);
        });
    },
  });

  const { handleSubmit, touched, errors, handleChange, values, setValues, resetForm } = formik;

  /* eslint-disable @typescript-eslint/no-floating-promises */
  useEffect(() => {
    if (data) {
      if (data?.getReportData) {
        if (data?.getReportData?.fiveYearPlanData && data?.getReportData?.fiveYearPlanData.length) {
          const fiveYearPlan = data.getReportData.fiveYearPlanData.map(({ __typename, ...rest }) => rest);
          setValues(fiveYearPlan);
        }
      } else {
        resetForm();
      }
    }
  }, [yearParams, monthParams, data]);
  /* eslint-enable @typescript-eslint/no-floating-promises */

  return (
    <>
      <NavigationHeader
        title={<Typography variant='h1'>{companyDetails ? title : isError ? t5YearPlan('add') : ''}</Typography>}
        navigateTo={navigation}
      >
        {companyLogo && (
          <Image src={getAzureBlob(companyLogo)?.url} alt={companyLogo} className={classes.companyLogo} />
        )}
      </NavigationHeader>
      <Page>
        <Box pt={{ md: 6, sm: 9, xs: 5 }}>
          <GridContainer spacing={{ xs: 2, sm: 2, md: 2 }} justifyContent='center'>
            <GridContainer xs={12} sm={10} md={7} spacing={{ xs: 2, sm: 2, md: 2 }}>
              <GridItem xs={6}>
                <YearDropdown isFilter value={Number(yearParams)} />
              </GridItem>
              <GridItem xs={6}>
                <MonthDropdown isFilter value={Number(monthParams)} isSixMonthInterval />
              </GridItem>
              <GridItem xs={12} pt={3}>
                <Typography variant='h2'>{tManualPortal('enter-data')}</Typography>
              </GridItem>
              {loading ? (
                <LoadingSpinner />
              ) : (
                <>
                  <GridContainer
                    xs={12}
                    rowSpacing={{ xs: 2, sm: 2, md: 2 }}
                    columnSpacing={{ xs: 2, sm: 2, md: 3 }}
                    alignItems='center'
                  >
                    {formFields.map((year, index) => {
                      const revenue = `[${index}].revenue`;
                      const revenueError = getIn(errors, revenue);
                      const revenueTouched = getIn(touched, revenue);
                      const ebitda = `[${index}].ebitda`;
                      const ebitdaError = getIn(errors, ebitda);
                      const ebitdaTouched = getIn(touched, ebitda);
                      const yearWithIndex = `${year} ${index + 1}`;
                      return (
                        <Fragment key={yearWithIndex}>
                          <GridItem xs={12} sm={4} md={4}>
                            <Typography variant='subtitle3'>{yearWithIndex}</Typography>
                            <TextField
                              value={yearWithIndex}
                              name={`[${index}].financial_year`}
                              type='hidden'
                              sx={{ display: 'none' }}
                              inputProps={{ 'data-testid': `financial_year_${index}` }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={4} md={4}>
                            <TextField
                              label={`${yearWithIndex} ${tManualPortal('revenue')}`}
                              name={revenue}
                              value={values[index]?.revenue || ''}
                              onChange={handleChange}
                              error={Boolean(revenueTouched && revenueError)}
                              helperText={revenueTouched && revenueError}
                              isNumberField
                              allowDecimalValue
                              inputProps={{ 'data-testid': `revenue_${index}` }}
                              fullWidth
                            />
                          </GridItem>
                          <GridItem xs={12} sm={4} md={4}>
                            <TextField
                              label={`${yearWithIndex} ${tManualPortal('ebidta')}`}
                              name={ebitda}
                              value={values[index]?.ebitda || ''}
                              onChange={handleChange}
                              error={Boolean(ebitdaTouched && ebitdaError)}
                              helperText={ebitdaTouched && ebitdaError}
                              isNumberField
                              allowDecimalValue
                              inputProps={{ 'data-testid': `ebitda_${index}` }}
                              fullWidth
                            />
                          </GridItem>
                        </Fragment>
                      );
                    })}
                  </GridContainer>
                  <GridItem xs={12} textAlign='right' py={3}>
                    <Stack spacing={2} direction='row' justifyContent='end'>
                      <Button variant='outlined' data-testid='cancel-btn' component={RouterLink} to={`/${navigation}`}>
                        {tCommon('back')}
                      </Button>
                      <Button
                        variant='contained'
                        data-testid='save-btn'
                        onClick={(): void => {
                          handleSubmit();
                        }}
                        loading={upsertLoading}
                      >
                        {tCommon('save')}
                      </Button>
                    </Stack>
                  </GridItem>
                </>
              )}
            </GridContainer>
          </GridContainer>
        </Box>
      </Page>
    </>
  );
};

export default FiveYearPlan;
