import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useAppDispatch } from './redux-dispatch-selector';
import { setAccessToken } from '../redux/auth';
import { isAccessTokenExpired } from '../utils';

export const useUpdateAccessTokenOnActivity = (token: string): void => {
  const { getAccessTokenSilently, logout } = useAuth0();
  const dispatch = useAppDispatch();

  const updateToken = (): void => {
    if (token) {
      if (isAccessTokenExpired(token)) {
        getAccessTokenSilently()
          .then(accessToken => {
            dispatch(setAccessToken(accessToken));
          })
          .catch(async () => {
            await logout({ logoutParams: { returnTo: window.location.origin } });
          });
      }
    }
  };

  useEffect(() => {
    window.addEventListener('mousemove', updateToken);
    window.addEventListener('keydown', updateToken);

    return () => {
      window.removeEventListener('mousemove', updateToken);
      window.removeEventListener('keydown', updateToken);
    };
  }, [token]);
};
