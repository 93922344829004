import { ReactElement, memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { SelectChangeEvent } from '@mui/material';
import { Select } from '../shared';
import { FILTER_PARAMS } from '../../constants';

interface Props {
  onYearChangeHandler?: (year: number) => void;
  value?: number;
  isFilter?: boolean;
  error?: boolean;
  required?: boolean;
  helperText?: string;
}

const YearDropdown = (props: Props): ReactElement => {
  const {
    onYearChangeHandler = (): void => {},
    value = '',
    isFilter = false,
    error = false,
    required = false,
    helperText = '',
  } = props;
  const { t: tCommon } = useTranslation('translation', { keyPrefix: 'common' });
  const currentYear = new Date().getFullYear();
  const nextYear = currentYear + 1;

  const yearOptions = [
    { id: currentYear.toString(), text: currentYear, value: currentYear },
    { id: nextYear.toString(), text: nextYear, value: nextYear },
  ];

  const [searchParams, setSearchParams] = useSearchParams();
  const { YEAR } = FILTER_PARAMS;

  useEffect(() => {
    const yearParams = searchParams.get(YEAR);
    if (!yearParams && isFilter) {
      searchParams.append(YEAR, currentYear.toString());
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  const handleChange = (event: SelectChangeEvent<unknown>): void => {
    const year = Number(event.target.value);
    if (isFilter) {
      searchParams.set(YEAR, year.toString());
      setSearchParams(searchParams);
    } else {
      onYearChangeHandler(year);
    }
  };

  return (
    <Select
      inputLabel={tCommon('year')}
      id='year'
      value={value || ''}
      name='year'
      options={yearOptions}
      onChange={handleChange}
      error={error}
      required={required}
      helperText={helperText}
      data-testid='year-dropdown'
    />
  );
};

export default memo(YearDropdown);
