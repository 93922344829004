import { makeStyles } from '../../../hooks';

export const useStyles = makeStyles()(theme => ({
  dropzoneMainWrapper: {
    width: '100%',
    minHeight: '226px',
    border: `2px dashed ${theme.palette.primary.main}`,
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    padding: theme.spacing(1),
    ':hover': {
      border: `2px dashed ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.background.lightblue,
    },
  },
  dropzoneError: {
    border: `2px dashed ${theme.palette.error.main}`,
    ':hover': {
      border: `2px dashed ${theme.palette.error.main}`,
    },
  },
  dropzoneIconImage: {
    width: '80px',
    height: '80px',
  },
  dropzoneInsideContainerText: {
    color: theme.palette.common.black,
  },
  dropzoneNoteText: {
    color: theme.palette.grey[600],
  },
  previewFileWrapper: {
    width: '100%',
    padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
    backgroundColor: theme.palette.grey[100],
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  previewImageWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  previewImage: {
    maxWidth: '120px',
    maxHeight: '72px',
  },
  previewImageNameText: {
    textTransform: 'lowercase',
  },
  previewImageSizeText: {
    color: theme.palette.grey[600],
  },
  closeIconWrapper: {
    textAlign: 'right',
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
  },
}));
