import { Button as MuiButton } from '@mui/material';
import { withStyles, makeStyles } from '../../../hooks';

export const AppStyledButton = withStyles(MuiButton, theme => ({
  root: {
    borderRadius: '30px',
  },
  sizeLarge: {
    padding: '16px 24px',
    fontSize: '1rem',
    lineHeight: '24px',
    [theme.breakpoints.down('md')]: {
      padding: '12px 24px',
    },
  },
  sizeMedium: {
    padding: '12px 24px',
    fontSize: '1rem',
    lineHeight: '24px',
  },
  sizeSmall: {
    padding: '8px 24px',
    fontSize: '1rem',
    lineHeight: '24px',
  },
  containedPrimary: {
    ':hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    ':active': {
      backgroundColor: theme.palette.primary.dark,
      boxShadow: 'none',
    },
    ':disabled': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.lighter,
    },
  },
  outlinedPrimary: {
    backgroundColor: theme.palette.common.white,
    border: `2px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    ':hover': {
      border: `2px solid ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
    },
    ':active': {
      backgroundColor: theme.palette.primary.dark,
    },
    ':disabled': {
      border: `2px solid ${theme.palette.primary.lighter}`,
      color: theme.palette.primary.lighter,
    },
  },
  textPrimary: {
    color: theme.palette.primary.main,
    ':disabled': {
      color: theme.palette.primary.lighter,
    },
    ':hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
    },
  },
}));

export const AppIconButton = withStyles(MuiButton, theme => ({
  containedPrimary: {
    ':hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    ':active': {
      backgroundColor: theme.palette.primary.dark,
      boxShadow: 'none',
    },
    ':disabled': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.lighter,
    },
  },
  outlinedPrimary: {
    ':hover': {
      border: `2px solid ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
    },
    ':active': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  sizeLarge: {
    padding: '12px',
    minWidth: '40px',
    minHeight: '40px',
  },
  sizeMedium: {
    padding: '8px',
    minWidth: '40px',
    minHeight: '40px',
    '& .MuiSvgIcon-root': {
      width: '15px',
      height: '15px',
    },
  },
  sizeSmall: {
    padding: '5px',
    minWidth: '32px',
    minHeight: '32px',
    '& .MuiSvgIcon-root': {
      width: '15px',
      height: '15px',
    },
  },
}));

export const useStyles = makeStyles()(theme => ({
  buttonRoot: {
    position: 'relative',
    color: 'transparent',
    pointerEvents: 'none',
    cursor: 'default',
  },
  loadingSpinner: {
    display: 'flex',
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%)',
    visibility: 'visible',
    '& .MuiCircularProgress-svg': {
      width: '16px',
      height: '16px',
      color: theme.palette.common.white,
    },
  },
}));
