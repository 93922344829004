import { appApiBuilder } from '..';

import { API_PATH, API_TAGS, METHODS } from '../../constants';
import {
  IAttachReportReqBody,
  IAttachReportResponse,
  IEmbedReport,
  IEmbedReportDashboard,
  IReportsByCompanyId,
  IReportsResponse,
  IReviewReportStatusResponse,
  IReviewedReportsResponse,
  IUpdateReportReqBody,
} from './reports-interface';

const { REPORTS, COMPANIES, EMBED, DASHBOARD, REVIEW_REPORT_STATUS, REVIEW_REPORT } = API_PATH;
const { REVIEW_REPORT: REVIEW_REPORT_TAG } = API_TAGS;

export const extendedApi = appApiBuilder.injectEndpoints({
  endpoints: builder => ({
    getAllReports: builder.query<IReportsResponse[], void>({
      query: () => REPORTS,
    }),
    attachReportsToPortfolioCompany: builder.mutation<IAttachReportResponse, IAttachReportReqBody>({
      query: ({ companyId, data }) => ({
        url: `${COMPANIES}/${companyId}/${REPORTS}`,
        method: METHODS.POST,
        body: data,
      }),
    }),
    getAllReportsByCompanyId: builder.query<IReportsByCompanyId[], string>({
      query: companyId => `${COMPANIES}/${companyId}/${REPORTS}`,
    }),
    updateReportsByCompanyId: builder.mutation<IAttachReportResponse, IUpdateReportReqBody>({
      query: ({ companyId, data }) => ({
        url: `${COMPANIES}/${companyId}/${REPORTS}`,
        method: METHODS.PUT,
        body: data,
      }),
    }),
    getEmbedReport: builder.query<IEmbedReport, { companyId: string; reportId: string }>({
      query: ({ companyId, reportId }) => `${COMPANIES}/${companyId}/${REPORTS}/${reportId}/${EMBED}`,
    }),
    getEmbedReportDashboard: builder.query<IEmbedReportDashboard, { companyId: string }>({
      query: ({ companyId }) => `${COMPANIES}/${companyId}/${DASHBOARD}`,
    }),
    getReviewedReportsByCompanyId: builder.query<IReviewedReportsResponse[], string>({
      query: companyId => `${COMPANIES}/${companyId}/${REVIEW_REPORT}`,
    }),
    reviewReportStatus: builder.query<IReviewReportStatusResponse, string>({
      query: companyId => `${COMPANIES}/${companyId}/${REVIEW_REPORT_STATUS}`,
      providesTags: [REVIEW_REPORT_TAG],
    }),
    addReviewReport: builder.mutation<IAttachReportResponse, string>({
      query: companyId => ({
        url: `${COMPANIES}/${companyId}/${REVIEW_REPORT}`,
        method: METHODS.POST,
      }),
      invalidatesTags: [REVIEW_REPORT_TAG],
    }),
  }),
});

export const {
  useGetAllReportsQuery,
  useLazyGetAllReportsQuery,
  useAttachReportsToPortfolioCompanyMutation,
  useGetAllReportsByCompanyIdQuery,
  useUpdateReportsByCompanyIdMutation,
  useGetEmbedReportQuery,
  useGetEmbedReportDashboardQuery,
  useGetReviewedReportsByCompanyIdQuery,
  useReviewReportStatusQuery,
  useAddReviewReportMutation,
} = extendedApi;
