import { makeStyles } from '../../../hooks';
interface StyleProps {
  isLocalTable?: boolean;
}

export const useStyles = makeStyles<StyleProps>()((theme, { isLocalTable }) => ({
  tableContainer: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: isLocalTable ? '10px' : theme.spacing(0.5),
    overflowX: 'auto',
    overflowY: 'hidden',
    '::-webkit-scrollbar': {
      height: '4px',
    },
    '::-webkit-scrollbar-track': {
      background: theme.palette.background.lightblue,
    },
    '::-webkit-scrollbar-thumb': {
      background: theme.palette.secondary.main,
      borderRadius: '6px',
      ':hover': {
        background: theme.palette.secondary.main,
      },
    },
  },
  tableCell: {
    padding: '8px',
    '&.MuiTableCell-head': {
      textTransform: 'capitalize',
      color: theme.palette.secondary.main,
      fontSize: theme.spacing(1.5),
      fontWeight: 400,
      lineHeight: theme.spacing(2),
      paddingTop: isLocalTable ? theme.spacing(2) : theme.spacing(1),
      ':first-of-type': {
        paddingLeft: isLocalTable ? theme.spacing(3) : 0,
      },
    },
    '&.MuiTableCell-body': {
      fontSize: isLocalTable ? '12px' : '14px',
      fontWeight: 400,
      lineHeight: isLocalTable ? '16px' : '20px',
      paddingTop: isLocalTable ? theme.spacing(0.5) : theme.spacing(1),
      paddingBottom: isLocalTable ? theme.spacing(0.5) : theme.spacing(1),
      color: theme.palette.common.black,
      ':first-of-type': {
        paddingLeft: isLocalTable ? theme.spacing(3) : 0,
      },
    },
    '&.MuiTableCell-paddingCheckbox': {
      padding: 0,
      paddingLeft: 7,
    },
  },
  tableRow: {
    ':first-of-type': {
      '& .MuiTableCell-root.MuiTableCell-body': {
        paddingTop: isLocalTable ? theme.spacing(2) : theme.spacing(1),
        '&.MuiTableCell-paddingCheckbox': {
          paddingTop: 0,
        },
      },
    },
    ':last-of-type': {
      '& .MuiTableCell-root.MuiTableCell-body': {
        borderBottom: 'none',
        paddingBottom: isLocalTable ? theme.spacing(2) : theme.spacing(1),
        '&.MuiTableCell-paddingCheckbox': {
          paddingBottom: 0,
        },
      },
    },
  },
  tableHead: {
    position: 'sticky',
    top: 0,
    zIndex: 10,
    backgroundColor: theme.palette.common.white,
    display: 'table',
    tableLayout: 'fixed',
    width: '100%',
    '& th': {
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
  },
  tableBody: {
    maxHeight: '50vh',
    tableLayout: 'fixed',
    display: 'block',
    overflowY: 'auto',
    overflowX: 'hidden',
    '::-webkit-scrollbar': {
      width: '4px',
    },
    '::-webkit-scrollbar-track': {
      background: theme.palette.background.lightblue,
    },
    '::-webkit-scrollbar-thumb': {
      background: theme.palette.secondary.main,
      borderRadius: '6px',
      ':hover': {
        background: theme.palette.secondary.main,
      },
    },
    '& tr': {
      display: 'table',
      tableLayout: 'fixed',
      width: '100%',
    },
  },
  selectedRow: {
    backgroundColor: theme.palette.background.lightblue,
  },
  hideSilverSpinner: {
    visibility: 'hidden',
    width: isLocalTable ? '16px' : '32px',
    height: isLocalTable ? '16px' : '32px',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  showSilverSpinner: {
    visibility: 'visible',
    [theme.breakpoints.down('md')]: {
      display: 'inline-block',
    },
  },
  actionCell: {
    paddingRight: '25px',
    [theme.breakpoints.down('md')]: {
      paddingRight: 10,
    },
    '& .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium': {
      height: isLocalTable ? '20px' : '32px',
      width: isLocalTable ? '20px' : '32px',
      '& img': {
        width: isLocalTable ? '16px' : '24px',
        height: isLocalTable ? '16px' : '24px',
      },
    },
    '& .MuiList-root#drawer-menu': {
      minWidth: isLocalTable ? '145px' : '230px',
      '& .MuiButtonBase-root.MuiMenuItem-root': {
        padding: isLocalTable ? '12px 16px' : '12px 24px',
      },
    },
  },
  loadingSpinner: {
    '& .MuiBox-root': {
      minHeight: isLocalTable ? '150px' : '300px',
    },
  },
  contentNotFoundWrapper: {
    '& [class$="notContentWrapper"]': {
      minHeight: isLocalTable ? '150px' : '300px',
      '& img': {
        width: isLocalTable ? 56 : 86,
        height: isLocalTable ? 56 : 86,
      },
      '& .MuiStack-root': {
        maxWidth: '300px',
      },
    },
  },
}));
