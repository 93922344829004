import { ReactElement, memo, useCallback, useEffect } from 'react';
import { useFormik } from 'formik';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Modal } from '../modal';
import { Box, Button, DatePicker, GridContainer, GridItem, Stack, TextField, Typography } from '../shared';
import { YearDropdown } from '../year-dropdown';
import { MonthDropdown } from '../month-dropdown';
import { publicEquitySchema } from '../../validation-schemas/portfolio-entry-schema';

interface ValueEntry {
  value: number;
}

interface ValueEntryWithMargin extends ValueEntry {
  margin: number;
}

export interface PublicEquityFormValues {
  year: string;
  month: string;
  company_name: string;
  ticker: string;
  entry_date: Dayjs | null;
  revenue: ValueEntry;
  enterprise_value: ValueEntry;
  ebitda: ValueEntryWithMargin;
  tev_ltm_ebitda: ValueEntry;
  tev_ntm_ebitda: ValueEntry;
  leverage_ratio: ValueEntry;
}

interface Props {
  open: boolean;
  onCancelOrCloseClick: (open: boolean) => void;
  loading?: boolean;
  onAddPublicEquities?: (values: PublicEquityFormValues) => void;
  isEdit?: boolean;
  data?: PublicEquityFormValues;
}

const PublicEquitiesFormModal = (props: Props): ReactElement => {
  const {
    open,
    onCancelOrCloseClick,
    loading = false,
    onAddPublicEquities = (): void => {},
    isEdit = false,
    data,
  } = props;
  const { t: tCommon } = useTranslation('translation', { keyPrefix: 'common' });
  const { t: tCompany } = useTranslation('translation', { keyPrefix: 'company' });
  const { t: tPublicEquity } = useTranslation('translation', {
    keyPrefix: 'company.manual-portal-entry.public-equities',
  });
  const { t: tManualPortal } = useTranslation('translation', {
    keyPrefix: 'company.manual-portal-entry',
  });
  const title = isEdit ? tPublicEquity('update') : tPublicEquity('add');

  const {
    year,
    month,
    company_name,
    ticker,
    revenue,
    enterprise_value,
    ebitda,
    leverage_ratio,
    tev_ltm_ebitda,
    tev_ntm_ebitda,
    entry_date,
  } = data ?? {};
  const initialValues = {
    year: year || '',
    month: month || '',
    company_name: company_name || '',
    ticker: ticker || '',
    entry_date: entry_date || null,
    revenue: {
      value: revenue?.value || '',
    },
    enterprise_value: {
      value: enterprise_value?.value || '',
    },
    ebitda: {
      value: ebitda?.value || '',
      margin: ebitda?.margin || '',
    },
    tev_ltm_ebitda: {
      value: tev_ltm_ebitda?.value || '',
    },
    tev_ntm_ebitda: {
      value: tev_ntm_ebitda?.value || '',
    },
    leverage_ratio: {
      value: leverage_ratio?.value || '',
    },
  };

  const formik = useFormik({
    initialValues,
    validationSchema: publicEquitySchema(),
    onSubmit: values => {
      onAddPublicEquities(values as unknown as PublicEquityFormValues);
    },
  });
  const { handleSubmit, touched, errors, handleChange, values, setFieldValue, resetForm, setValues } = formik;

  const resetFormValues = (): void => {
    resetForm();
  };

  const onCancelOrCloseClickHandler = useCallback((): void => {
    onCancelOrCloseClick(false);
    resetFormValues();
  }, [open, onCancelOrCloseClick]);

  const onYearChangeHandler = useCallback(async (selectedYear: number): Promise<void> => {
    await setFieldValue('year', selectedYear);
  }, []);

  const onMonthChangeHandler = useCallback(async (selectedMonth: number): Promise<void> => {
    await setFieldValue('month', selectedMonth);
  }, []);

  const dateChangeHandler = useCallback(
    async (name: string, value: Dayjs | null): Promise<void> => {
      if (dayjs(value).toString() !== 'Invalid Date') {
        await setFieldValue(name, dayjs(value).toString());
      } else {
        await setFieldValue(name, null);
      }
    },
    [setFieldValue],
  );

  useEffect(() => {
    if (open) {
      resetFormValues();
    }
  }, [open]);

  /* eslint-disable @typescript-eslint/no-floating-promises */
  useEffect(() => {
    if (isEdit && data) {
      setValues({ ...data });
    } else {
      setValues({
        ...initialValues,
        year: values.year,
        month: values.month,
      });
    }
  }, [isEdit, data, open]);
  /* eslint-enable @typescript-eslint/no-floating-promises */

  return (
    <Modal
      title={title}
      open={open}
      actionButtons={
        <Stack direction='row' spacing={2}>
          <Button variant='outlined' onClick={onCancelOrCloseClickHandler}>
            {tCommon('cancel')}
          </Button>
          <Button
            variant='contained'
            type='submit'
            onClick={(): void => handleSubmit()}
            loading={loading}
            data-testid='save-btn'
          >
            {title}
          </Button>
        </Stack>
      }
      onClose={onCancelOrCloseClickHandler}
      scrollContent='body'
    >
      <GridContainer rowSpacing={3} columnSpacing={2}>
        <GridItem xs={12} data-testid='year-form-dropdown'>
          <YearDropdown
            onYearChangeHandler={onYearChangeHandler}
            value={Number(values.year)}
            required
            error={Boolean(touched.year && errors.year)}
            helperText={(touched.year && errors.year) as string}
          />
        </GridItem>
        <GridItem xs={12} data-testid='month-form-dropdown'>
          <MonthDropdown
            onMonthChangeHandler={onMonthChangeHandler}
            value={Number(values.month)}
            required
            error={Boolean(touched.month && errors.month)}
            helperText={(touched.month && errors.month) as string}
          />
        </GridItem>
        <GridItem xs={12}>
          <DatePicker
            label={tManualPortal('entry-date')}
            name='entry_date'
            value={values.entry_date}
            onChange={(date: Dayjs | null): Promise<void> => dateChangeHandler('entry_date', date)}
            required
            error={Boolean(touched.entry_date && errors.entry_date)}
            helperText={touched.entry_date && errors.entry_date}
            data-testid='entry-date'
          />
        </GridItem>
        <GridItem xs={12}>
          <TextField
            label={tCompany('company-name')}
            name='company_name'
            value={values.company_name}
            fullWidth
            onChange={handleChange}
            required
            error={Boolean(touched.company_name && errors.company_name)}
            helperText={touched.company_name && errors.company_name}
            allowDecimalValue
            inputProps={{
              'data-testid': 'company-name',
            }}
          />
        </GridItem>
        <GridItem xs={12}>
          <TextField
            label={tPublicEquity('ticker')}
            name='ticker'
            value={values.ticker}
            fullWidth
            onChange={handleChange}
            required
            error={Boolean(touched.ticker && errors.ticker)}
            helperText={touched.ticker && errors.ticker}
            allowDecimalValue
            inputProps={{
              'data-testid': 'ticker',
            }}
          />
        </GridItem>
        <GridItem xs={12}>
          <Box pb={2}>
            <Typography variant='modalSubTitle'>{tManualPortal('revenue')}</Typography>
          </Box>
          <Stack direction='row' justifyContent='space-between' spacing={2}>
            <TextField
              label={tManualPortal('value')}
              isNumberField
              name='revenue.value'
              value={values.revenue.value}
              fullWidth
              onChange={handleChange}
              required
              error={Boolean(touched.revenue?.value && errors.revenue?.value)}
              helperText={touched.revenue?.value && errors.revenue?.value}
              allowDecimalValue
              inputProps={{
                'data-testid': 'revenue-value',
              }}
            />
          </Stack>
        </GridItem>
        <GridItem xs={12}>
          <Box pb={2}>
            <Typography variant='modalSubTitle'>{tPublicEquity('enterprise-value')}</Typography>
          </Box>
          <Stack direction='row' justifyContent='space-between' spacing={2}>
            <TextField
              label={tManualPortal('value')}
              isNumberField
              name='enterprise_value.value'
              value={values.enterprise_value.value}
              fullWidth
              onChange={handleChange}
              required
              error={Boolean(touched.enterprise_value?.value && errors.enterprise_value?.value)}
              helperText={touched.enterprise_value?.value && errors.enterprise_value?.value}
              allowDecimalValue
              inputProps={{
                'data-testid': 'enterprise-value',
              }}
            />
          </Stack>
        </GridItem>
        <GridItem xs={12}>
          <Box pb={2}>
            <Typography variant='modalSubTitle'>{tManualPortal('ebidta')}</Typography>
          </Box>
          <Stack direction='row' justifyContent='space-between' spacing={2}>
            <TextField
              label={tManualPortal('value')}
              isNumberField
              name='ebitda.value'
              value={values.ebitda.value}
              fullWidth
              onChange={handleChange}
              required
              error={Boolean(touched.ebitda?.value && errors.ebitda?.value)}
              helperText={touched.ebitda?.value && errors.ebitda?.value}
              allowDecimalValue
              inputProps={{
                'data-testid': 'ebitda-value',
              }}
            />
            <TextField
              label={tPublicEquity('margin-percent')}
              isNumberField
              name='ebitda.margin'
              value={values.ebitda.margin}
              fullWidth
              onChange={handleChange}
              required
              error={Boolean(touched.ebitda?.margin && errors.ebitda?.margin)}
              helperText={touched.ebitda?.margin && errors.ebitda?.margin}
              allowDecimalValue
              inputProps={{
                'data-testid': 'margin-percent',
              }}
            />
          </Stack>
        </GridItem>
        <GridItem xs={12}>
          <Box pb={2}>
            <Typography variant='modalSubTitle'>{`${tPublicEquity('tev-ltm')} ${tManualPortal('ebidta')}`}</Typography>
          </Box>
          <Stack direction='row' justifyContent='space-between' spacing={2}>
            <TextField
              label={tManualPortal('value')}
              isNumberField
              name='tev_ltm_ebitda.value'
              value={values.tev_ltm_ebitda.value}
              fullWidth
              onChange={handleChange}
              required
              error={Boolean(touched.tev_ltm_ebitda?.value && errors.tev_ltm_ebitda?.value)}
              helperText={touched.tev_ltm_ebitda?.value && errors.tev_ltm_ebitda?.value}
              allowDecimalValue
              inputProps={{
                'data-testid': 'tev-ltm-ebitda',
              }}
            />
          </Stack>
        </GridItem>
        <GridItem xs={12}>
          <Box pb={2}>
            <Typography variant='modalSubTitle'>{`${tPublicEquity('tev-ntm')} ${tManualPortal('ebidta')}`}</Typography>
          </Box>
          <Stack direction='row' justifyContent='space-between' spacing={2}>
            <TextField
              label={tManualPortal('value')}
              isNumberField
              name='tev_ntm_ebitda.value'
              value={values.tev_ntm_ebitda.value}
              fullWidth
              onChange={handleChange}
              required
              error={Boolean(touched.tev_ntm_ebitda?.value && errors.tev_ntm_ebitda?.value)}
              helperText={touched.tev_ntm_ebitda?.value && errors.tev_ntm_ebitda?.value}
              allowDecimalValue
              inputProps={{
                'data-testid': 'tev-ntm-ebitda',
              }}
            />
          </Stack>
        </GridItem>
        <GridItem xs={12}>
          <Box pb={2}>
            <Typography variant='modalSubTitle'>{tPublicEquity('leverage-ratio')}</Typography>
          </Box>
          <Stack direction='row' justifyContent='space-between' spacing={2}>
            <TextField
              label={tManualPortal('value')}
              isNumberField
              name='leverage_ratio.value'
              value={values.leverage_ratio.value}
              fullWidth
              onChange={handleChange}
              required
              error={Boolean(touched.leverage_ratio?.value && errors.leverage_ratio?.value)}
              helperText={touched.leverage_ratio?.value && errors.leverage_ratio?.value}
              allowDecimalValue
              inputProps={{
                'data-testid': 'leverage-ratio',
              }}
            />
          </Stack>
        </GridItem>
      </GridContainer>
    </Modal>
  );
};

export default memo(PublicEquitiesFormModal);
