import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { DRAG_DROP_PASTE } from '@lexical/rich-text';
import { COMMAND_PRIORITY_LOW } from 'lexical';
import { useEffect } from 'react';
import { useAppSelector } from '../../../hooks';
import { getEditorFilesState } from '../../../redux/editor';
import UseEditorFilesWithValidation from './UseEditorFilesWithValidation';

const DragDropPaste = (): null => {
  const [editor] = useLexicalComposerContext();

  const editorFiles = useAppSelector(getEditorFilesState);

  const { editorFilesValidate } = UseEditorFilesWithValidation();
  /* eslint-disable arrow-body-style */
  useEffect(() => {
    return editor.registerCommand(
      DRAG_DROP_PASTE,
      files => {
        ((): void => {
          editorFilesValidate(files);
        })();
        return true;
      },
      COMMAND_PRIORITY_LOW,
    );
  }, [editor, editorFiles]);
  /* eslint-enable arrow-body-style */

  return null;
};

export default DragDropPaste;
