import { makeStyles } from '../../../hooks';

interface StyleProps {
  height?: number;
}

export const useStyles = makeStyles<StyleProps>()((theme, { height }) => ({
  container: {
    display: 'flex',
    minHeight: `${height}px`,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
}));
