import { PayloadAction, ThunkMiddleware, isRejectedWithValue } from '@reduxjs/toolkit';
import { setIsNotFoundError, setIsServerError } from '../redux/error';
import { RootState } from '../redux/root-reducer';
import { API_ENDPOINTS } from '../constants';

const { validateUserByEmail } = API_ENDPOINTS;

export const rtkQueryErrorLogger: ThunkMiddleware<RootState> =
  ({ dispatch }) =>
  next =>
  (action: PayloadAction<{ status: number }> & { meta: { arg: { endpointName: string } } }) => {
    if (isRejectedWithValue(action)) {
      if (action.payload.status >= 500 && action.payload.status < 600) {
        dispatch(setIsServerError(true));
      } else if (action.payload.status === 404 && action?.meta?.arg?.endpointName !== validateUserByEmail) {
        dispatch(setIsNotFoundError(true));
      }
    }
    return next(action);
  };
