import { Typography as MuiTypography, TypographyProps } from '@mui/material';
import { ElementType, ReactElement, ReactNode } from 'react';

interface Props {
  children: ReactNode;
  component?: ElementType;
}

type AppTypographyProps = Props & TypographyProps;

const Typography = (props: AppTypographyProps): ReactElement => {
  const { children, ...other } = props;
  return <MuiTypography {...other}>{children}</MuiTypography>;
};

export default Typography;
