import { ReactElement, ReactNode, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  ChatBox,
  ChatButtonIcon,
  EmbedReport,
  Error,
  ManualDataDropdown,
  Page,
  ReportReviewed,
} from '../../../components';
import { Box, Image, LoadingSpinner, Stack, Typography } from '../../../components/shared';
import { FILTER_PARAMS, HttpStatusCode, ROUTES } from '../../../constants';
import { ReportNotFoundIcon } from '../../../assets/icons';
import { useGetEmbedReportDashboardQuery, useReviewReportStatusQuery } from '../../../services/reports/reports-service';
import { ResponseError } from '../../../services';
import { IEmbedReportDashboard } from '../../../services/reports/reports-interface';
import { useStyles } from './style';
import { useAppSelector, useBlobGetFiles } from '../../../hooks';
import { getUserRole, getUserState } from '../../../redux/auth';
import { isUserPCAdmin, isUserSuperAdmin } from '../../../utils';
import { NavigationHeader } from '../../../components/layout';
import { useGetCompanyByIdQuery } from '../../../services/companies/companies-service';
import { useGetCommentsQuery } from '../../../services/comment/comment-service';
import { CommentData } from '../../../services/comment/comment-interface';
import { ReviewListMenu } from '../../../components/review-list-menu';

const ReportDashboard = (): ReactElement => {
  const { classes } = useStyles();
  const { t: tReport } = useTranslation('translation', { keyPrefix: 'report' });
  const { t: tCompany } = useTranslation('translation', { keyPrefix: 'company' });
  const initialConfig: IEmbedReportDashboard = {
    embed_url: '',
    embed_token: '',
  };
  const { company_id } = useAppSelector(getUserState);
  const currentUserRole = useAppSelector(getUserRole);
  const { companyId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const superAdminUser = isUserSuperAdmin(currentUserRole);
  const pcAdminUser = isUserPCAdmin(currentUserRole);
  const [showChatBox, setShowChatBox] = useState(false);
  const companyIdOnRolesBasis = superAdminUser ? companyId : company_id;
  const {
    currentData: dashboardResponse = initialConfig,
    isError,
    error,
    isFetching,
    isLoading,
    isSuccess,
  } = useGetEmbedReportDashboardQuery(
    { companyId: companyIdOnRolesBasis as string },
    { skip: companyIdOnRolesBasis === undefined || companyIdOnRolesBasis === '' },
  );
  const { currentData: companyDetails } = useGetCompanyByIdQuery(companyIdOnRolesBasis as string, {
    skip: !companyIdOnRolesBasis,
  });
  const { embed_token = '', embed_url = '' } = dashboardResponse;
  const { getAzureBlob } = useBlobGetFiles();
  const companyLogo = companyDetails?.logo;
  const {
    currentData,
    isLoading: commentLoading,
    isFetching: commentFetching,
  } = useGetCommentsQuery(
    { companyId: companyIdOnRolesBasis as string, filter: searchParams.toString() },
    {
      skip: !companyIdOnRolesBasis,
    },
  );
  const commentsData = (currentData?.data as CommentData[]) || [];
  const { FILTER } = FILTER_PARAMS;
  const { currentData: reportReviewed } = useReviewReportStatusQuery(companyIdOnRolesBasis as string, {
    skip: !companyIdOnRolesBasis,
  });

  useEffect(() => {
    if (isError) {
      setShowChatBox(false);
    }
  }, [isError]);

  const showCommentBoxAndScrollIntoView = (): void => {
    const commentBox = document.getElementById('comment-box');
    setShowChatBox(true);
    setTimeout(() => {
      commentBox?.scrollIntoView({ behavior: 'smooth' });
    }, 500);
  };

  const onChatButtonClickHandler = (): void => {
    showCommentBoxAndScrollIntoView();
  };

  const onChatBoxCloseClickHandler = (): void => {
    setShowChatBox(false);
    searchParams.delete(FILTER);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (searchParams.get(FILTER) && embed_url) {
      showCommentBoxAndScrollIntoView();
    }
  }, [embed_url]);

  const renderReportDashboard = (): ReactNode => {
    if (isLoading || isFetching) {
      return (
        <Page>
          <LoadingSpinner />
        </Page>
      );
    }
    if (isError && (error as ResponseError).status === HttpStatusCode.BAD_REQUEST) {
      return (
        <Page className={classes.reportContainer}>
          <Error
            icon={ReportNotFoundIcon}
            message={{ title: tReport('not-found.title'), description: tReport('not-found.description') }}
          />
        </Page>
      );
    }
    return <EmbedReport embed_token={embed_token} embed_url={embed_url} />;
  };

  return (
    <>
      <NavigationHeader
        title={
          <Typography variant='h1' data-testid='report-title'>
            {tCompany('overview')}
          </Typography>
        }
        navigateTo={ROUTES.DASHBOARD}
        isBackButton={superAdminUser}
      >
        {companyLogo && (
          <Image src={getAzureBlob(companyLogo)?.url} alt={companyLogo} className={classes.companyLogo} />
        )}
      </NavigationHeader>
      <Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={1.5} pt={0.5} pb={2.5}>
        <Box>
          <ManualDataDropdown companyId={companyIdOnRolesBasis as string} />
        </Box>
        {isSuccess && (
          <Box>
            <ChatButtonIcon
              count={commentsData.length}
              onClick={onChatButtonClickHandler}
              isChatBoxOpen={showChatBox}
            />
          </Box>
        )}
        {pcAdminUser && (
          <ReportReviewed
            companyId={companyIdOnRolesBasis as string}
            isReportReviewed={reportReviewed?.isReviewedThisMonth}
          />
        )}
        {superAdminUser && (
          <ReviewListMenu
            companyId={companyIdOnRolesBasis as string}
            isReportReviewed={reportReviewed?.isReviewedThisMonth}
          />
        )}
      </Stack>
      <Box className={classes.reportContainer} data-testid='report-container'>
        {renderReportDashboard()}
        <Box py={2} id='comment-box' mt={2}>
          {showChatBox && (
            <ChatBox
              onChatBoxClose={onChatBoxCloseClickHandler}
              companyId={companyIdOnRolesBasis as string}
              commentData={commentsData}
              isLoading={commentLoading || commentFetching}
            />
          )}
        </Box>
      </Box>
    </>
  );
};

export default ReportDashboard;
