import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Image, Stack, Typography } from '../shared';
import { useStyles } from './style';
import { ROUTES } from '../../constants';

interface ErrorMessage {
  title: string;
  description: string;
}

interface Props {
  code?: string;
  message: ErrorMessage;
  icon: string;
}

const Error = (props: Props): ReactElement => {
  const { code = '', message, icon = '' } = props;
  const { title, description } = message;
  const { classes } = useStyles();
  const { t: tCommon } = useTranslation('translation', { keyPrefix: 'common' });
  const navigate = useNavigate();

  const onHomeClickHandler = (): void => {
    navigate(`/${ROUTES.DASHBOARD}`);
    setTimeout(() => {
      navigate(0);
    }, 100);
  };

  const onBackClickHandler = (): void => {
    navigate(-1);
    setTimeout(() => {
      navigate(0);
    }, 100);
  };

  return (
    <Stack direction='column' spacing={3.5} className={classes.wrapper}>
      <Box>
        <Image src={icon} alt='error-image' />
      </Box>
      <Box>
        <Stack spacing={1} alignItems='center'>
          {code && (
            <Typography variant='h1' fontSize='3rem' lineHeight='56px'>
              {code}
            </Typography>
          )}
          <Typography variant='h2' className={classes.title}>
            {title}
          </Typography>
          <Typography variant='body3' className={classes.description}>
            {description}
          </Typography>
        </Stack>
      </Box>
      <Box pt={0.5}>
        <Stack direction='row' spacing={2} justifyContent='center'>
          <Button variant='outlined' onClick={onBackClickHandler}>
            {tCommon('back')}
          </Button>
          <Button variant='contained' onClick={onHomeClickHandler}>
            {tCommon('home')}
          </Button>
        </Stack>
      </Box>
    </Stack>
  );
};

export default Error;
