import { ReactElement, useState } from 'react';
import { Divider, ListItem, ListItemIcon, Menu, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { User, useAuth0 } from '@auth0/auth0-react';
import { Box, Stack, Typography } from '../../shared';
import { AdminIcon, LogoutIcon } from '../../../assets/icons';
import { useSideBarFooterMenuStyles } from './style';
import { getRolesFromAuth0User } from '../../../utils';

interface Props {
  drawerOpen?: boolean;
}

const SideBarFooterMenu = (props: Props): ReactElement => {
  const { drawerOpen = true } = props;
  const { classes } = useSideBarFooterMenuStyles();
  const { user, logout } = useAuth0();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { t } = useTranslation('translation', { keyPrefix: 'logout' });
  const { t: tUser } = useTranslation('translation', { keyPrefix: 'user' });
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };
  const logoutHandler = async (): Promise<void> => {
    await logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  const getRoles = getRolesFromAuth0User(user as User);
  return (
    <>
      <Box role='button' className={classes.menuWrapper} onClick={handleClick}>
        <Stack direction='row' alignItems='center' spacing={1}>
          <AdminIcon />
          {drawerOpen && (
            <Box>
              <Typography variant='h3' color='common.white'>
                {tUser('profile')}
              </Typography>
            </Box>
          )}
        </Stack>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id='profile-menu'
        open={open}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
        className={classes.profileMenuMain}
      >
        <ListItem className={classes.profileInfoWrapper}>
          <Typography variant='h3' className={classes.profileName}>
            {user?.name}
          </Typography>
          <Typography variant='h4' className={classes.profileRole}>
            {getRoles.toString()}
          </Typography>
          <Typography variant='body1' className={classes.profileEmail}>
            {user?.email}
          </Typography>
        </ListItem>
        <Divider variant='middle' className={classes.profileDivider} />
        {/* comment with JIST-1123 */}
        {/* <MenuItem>
          <ListItemIcon className={classes.profileIcon}>
            <PersonIcon />
          </ListItemIcon>
          <Typography variant='secondaryLink' className={classes.profileLink}>
            {tUser('my-profile')}
          </Typography>
        </MenuItem> */}
        <MenuItem onClick={logoutHandler}>
          <ListItemIcon className={classes.profileIcon}>
            <LogoutIcon />
          </ListItemIcon>
          <Typography variant='secondaryLink' className={classes.profileLink}>
            {t('logout')}
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default SideBarFooterMenu;
