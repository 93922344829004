import { ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { pathToRegexp } from 'path-to-regexp';
import { protectedRoutesConfig, publicRoutesConfig } from '../routes/routes-config';

export const useDynamicPageTitle = (defaultTitle: string): ReactNode => {
  const { isAuthenticated } = useAuth0();
  const { i18n } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    const protectedRoutes = protectedRoutesConfig();
    const publicRoutes = publicRoutesConfig();

    const allRoutes = [...protectedRoutes, ...publicRoutes];

    const currentRoute = allRoutes.find(route => {
      if (route.path) {
        const match = pathToRegexp(`/${route.path}`).exec(location.pathname);
        return !!match;
      }
      return false;
    });

    const routeTitle = currentRoute?.title || defaultTitle;

    document.title = routeTitle;
  }, [location, isAuthenticated, i18n.language]);

  return null;
};
