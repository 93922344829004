import { ReactElement, ReactNode, memo } from 'react';
import { DialogContentProps, DialogContent as MuiDialogContent } from '@mui/material';

interface Props {
  children: ReactNode;
}
type AppDialogContentProps = Props & DialogContentProps;

const DialogContent = (props: AppDialogContentProps): ReactElement => {
  const { children, ...other } = props;
  return <MuiDialogContent {...other}>{children}</MuiDialogContent>;
};

export default memo(DialogContent);
