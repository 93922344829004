import { makeStyles } from '../../hooks';

export const useStyles = makeStyles()(theme => ({
  pageRoot: {
    height: '100%',
    position: 'relative',
    background: theme.palette.grey['A100'],
    [theme.breakpoints.up('md')]: {
      overflow: 'hidden',
    },
  },
  pageWrapper: {
    height: '100%',
  },

  accessDeniedWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      display: 'block',
      height: 'auto',
      margin: 'auto',
      maxWidth: '374px',
      textAlign: 'center',
    },
  },

  logo: {
    width: '192px',
    height: '54px',
  },
  title: {
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '24px',
  },
  textWrapper: {
    marginBottom: '32px',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      marginBottom: '16px',
    },
  },
  contactLink: {
    verticalAlign: 'baseline',
  },
}));
