import { BlobClient, BlobServiceClient } from '@azure/storage-blob';

interface BlobSizeResult {
  size: number;
}
interface UseBlobGetFilesResult {
  getAzureBlob: (blobName: string) => BlobClient | null;
  getAzureBlobSize: (blobName: string) => Promise<BlobSizeResult>;
}

export const useBlobGetFiles = (): UseBlobGetFilesResult => {
  const { REACT_APP_AZURE_BLOB_SAS_URL: sasUrl, REACT_APP_AZURE_BLOB_CONTAINER_NAME: containerName } = process.env;

  const blobServiceClient = new BlobServiceClient(sasUrl as string);
  const containerClient = blobServiceClient.getContainerClient(containerName as string);

  const getAzureBlob = (blobName: string): BlobClient | null => {
    try {
      const blobClient = containerClient.getBlobClient(blobName);
      return blobClient;
    } catch (error) {
      return null;
    }
  };

  const getAzureBlobSize = async (blobName: string): Promise<BlobSizeResult> => {
    try {
      const blobClient = containerClient.getBlobClient(blobName);
      const properties = await blobClient.getProperties();
      const contentLength = properties.contentLength || 0;
      return { size: contentLength };
    } catch (error) {
      return { size: 0 };
    }
  };
  return { getAzureBlob, getAzureBlobSize };
};
