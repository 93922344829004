import { Tooltip as MuiTooltip } from '@mui/material';
import { withStyles } from '../../../hooks';

export const AppTooltip = withStyles(MuiTooltip, (theme, { variant, transform }) => ({
  tooltip: {
    backgroundColor: variant === 'light' ? theme.palette.primary.lightest : theme.palette.common.black,
    color: variant === 'light' ? theme.palette.secondary.main : theme.palette.common.white,
    fontSize: 16,
    fontWeight: 300,
    lineHeight: '24px',
    padding: '8px 24px',
    textTransform: transform,
    borderRadius: '30px',
  },
  tooltipPlacementRight: {
    marginLeft: '8px !important',
  },
  arrow: {
    color: variant === 'light' ? theme.palette.primary.lightest : theme.palette.common.black,
  },
  popper: {
    '&[data-popper-placement*="right"] .MuiTooltip-arrow': {
      marginLeft: '-8px',
    },
  },
}));
