import { ReactElement, useEffect, useState } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { alpha, useTheme } from '@mui/material';
import { $getRoot } from 'lexical';
import { useTranslation } from 'react-i18next';
import { Typography } from '../typography';
import { Box } from '../box';
import { Stack } from '../stack';
import { CHARACTER_LIMIT } from './config';

interface Props {
  isEditorValid: (isValid: boolean) => void;
}
const CharacterLimitPlugin = (props: Props): ReactElement => {
  const { isEditorValid } = props;
  const [editor] = useLexicalComposerContext();
  const [characterCount, setCharacterCount] = useState(0);
  const [isLimitExceeded, setIsLimitExceeded] = useState(false);
  const theme = useTheme();
  const { t: tEditor } = useTranslation('translation', { keyPrefix: 'editor' });

  const updateCharacterCount = (): void => {
    editor.getEditorState().read(() => {
      const root = $getRoot();
      let text = '';
      root.getChildren().forEach(node => {
        text += node.getTextContent();
      });
      setCharacterCount(text.length);
    });
  };

  const handleUpdate = (): void => {
    editor.update(() => {
      const root = $getRoot();

      let text = '';
      root.getChildren().forEach(node => {
        text += node.getTextContent();
      });

      if (text.length > CHARACTER_LIMIT) {
        setIsLimitExceeded(true);
      } else {
        setIsLimitExceeded(false);
      }
    });
  };
  useEffect(() => {
    updateCharacterCount();
    const unregisterListener = editor.registerUpdateListener(() => {
      updateCharacterCount();
      handleUpdate();
    });

    return () => {
      unregisterListener();
    };
  }, [editor]);

  useEffect(() => {
    isEditorValid(isLimitExceeded);
  }, [isLimitExceeded]);

  return (
    <Box mb={1}>
      <Stack direction='row' justifyContent='space-between'>
        <Typography variant='body3' color={isLimitExceeded ? 'error.main' : alpha(theme.palette.secondary.main, 0.6)}>
          {characterCount}/{CHARACTER_LIMIT}
        </Typography>

        {isLimitExceeded && (
          <Typography variant='body2' color='error.main'>
            {tEditor('character-limit-exceeded')}
          </Typography>
        )}
      </Stack>
    </Box>
  );
};

export default CharacterLimitPlugin;
