import { ReactElement } from 'react';
import { TooltipProps } from '@mui/material';
import { AppTooltip } from './style';

interface Props {
  variant?: 'light' | 'dark';
  transform?: 'initial' | 'capitalize' | 'uppercase' | 'lowercase';
}
type AppTooltipProps = Props & TooltipProps;
const Tooltip = (props: AppTooltipProps): ReactElement => {
  const { variant = 'light', transform = 'initial', ...other } = props;

  return <AppTooltip {...other} variant={variant} transform={transform} />;
};

export default Tooltip;
