import { InputLabel, Select, alpha } from '@mui/material';
import { makeStyles, withStyles } from '../../../hooks';
export const AppSelect = withStyles(Select, theme => ({
  root: {
    fontSize: '16px',
    '& .MuiFormLabel-root.MuiInputLabel-root': {
      '&.Mui-focused': {
        color: theme.palette.common.black,
      },
      '&.Mui-error': {
        color: theme.palette.common.black,
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.secondary.main, 0.3),
    },
    '& .MuiFormHelperText-root': {
      marginTop: '8px',
    },

    '&.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: alpha(theme.palette.secondary.main, 0.3),
        borderWidth: '1px',
      },
    },
    '&.Mui-error': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderWidth: '1px',
        borderColor: theme.palette.error.main,
      },
    },
    '& .MuiSelect-select': {
      padding: '12px 16px',
      color: theme.palette.common.black,
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.secondary.main,
    },
  },
}));
export const AppSelectLabel = withStyles(InputLabel, theme => ({
  root: {
    color: alpha(theme.palette.secondary.main, 0.6),
    fontSize: '16px',
    lineHeight: '17px',
    '&.Mui-focused': {
      color: alpha(theme.palette.secondary.main, 0.6),
      lineHeight: '24px',
    },
    '&.Mui-error': {
      color: theme.palette.error.main,
    },
    '& .MuiFormLabel-asterisk.MuiInputLabel-asterisk': {
      color: theme.palette.error.main,
    },
    '&.MuiFormLabel-filled': {
      lineHeight: '24px',
    },
  },
}));

export const useStyles = makeStyles()(theme => ({
  selectMenu: {
    filter: `drop-shadow(${theme.customShadows.drawer})`,
    borderRadius: '4px',
    '::-webkit-scrollbar': {
      width: '4px',
    },
    '::-webkit-scrollbar-track': {
      background: theme.palette.background.lightblue,
    },
    '::-webkit-scrollbar-thumb': {
      background: theme.palette.secondary.main,
      borderRadius: '6px',
      ':hover': {
        background: theme.palette.secondary.main,
      },
    },
  },
  menuItem: {
    fontSize: '16px',
    lineHeight: '24px',
    color: theme.palette.secondary.main,
    padding: '12px 16px',
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    '&.Mui-selected:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
  loadingSpinner: {
    display: 'flex',
    justifyContent: 'center',
    '&.Mui-selected': {
      backgroundColor: theme.palette.common.white,
    },
  },
}));
