import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useStyles } from './style';
import { HorizontalStepper, Page, PortfolioAddUser, PortfolioCompanyDetails, SelectReports } from '../../../components';
import { Box, Typography } from '../../../components/shared';
import { AZURE_BLOB_STORAGE, HttpStatusCode, RESPONSE_TYPE, ROUTES } from '../../../constants';
import {
  useAddPortfolioCompanyDetailsByCompanyIdMutation,
  useUpdatePortfolioCompanyDetailsByCompanyIdMutation,
} from '../../../services/companies/companies-service';
import { CompanyDetailsForms, ImageInfo } from '../../../components/portfolio-company-details/PortfolioCompanyDetails';
import { useAppSelector, useBlobUploadFiles, useScrollToTop } from '../../../hooks';
import { useAttachReportsToPortfolioCompanyMutation } from '../../../services/reports/reports-service';
import { PCAdminUser } from '../../../components/portfolio-add-user/PortfolioAddUser';
import { useAddPortfolioCompanyUsersMutation } from '../../../services/users/users-service';
import { getUserState } from '../../../redux/auth';
import { UseToast } from '../../../hooks/useToast';
import { ResponseError } from '../../../services';
import { NavigationHeader } from '../../../components/layout';
import { extractEmailFromString } from '../../../utils';

interface StepItem {
  label: string;
  component: React.ReactNode;
}

const AddPortfolioCompany: FC = () => {
  const { classes } = useStyles();
  const { t: tCompany } = useTranslation('translation', { keyPrefix: 'company' });
  const { t: tReport } = useTranslation('translation', { keyPrefix: 'report' });
  const { t: tUser } = useTranslation('translation', { keyPrefix: 'user' });
  const { t: tError } = useTranslation('translation', { keyPrefix: 'common.errors' });
  const { t: tApiError } = useTranslation('translation');
  const navigate = useNavigate();
  const { company_id } = useAppSelector(getUserState);
  const [addCompanyPortfolioApi] = useAddPortfolioCompanyDetailsByCompanyIdMutation();
  const [updateCompanyPortfolioApi] = useUpdatePortfolioCompanyDetailsByCompanyIdMutation();
  const [attachReportApi] = useAttachReportsToPortfolioCompanyMutation();
  const [AddPortfolioCompanyUsers] = useAddPortfolioCompanyUsersMutation();
  const { uploadFiles } = useBlobUploadFiles();
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [companyId, setCompanyId] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const { DIRECTORY_NAME, IMAGE_NAME } = AZURE_BLOB_STORAGE;
  const { showToast } = UseToast();
  const [companyExists, setCompanyExists] = useState(false);
  const [userAlreadyExist, setUserAlreadyExist] = useState({ email: '', isExist: false });
  useScrollToTop([currentIndex]);

  type ValuesType = CompanyDetailsForms | string[];

  const isCompanyDetailsForms = (values: ValuesType): values is CompanyDetailsForms =>
    typeof values === 'object' && 'companyName' in values && 'companyUrl' in values && 'companyLogo' in values;

  const isSelectReportForms = (values: ValuesType): values is string[] => typeof values === 'object';

  const continueClickHandler = <T extends ValuesType>(type: string, values: T): void => {
    setLoading(true);
    if (type === 'portfolioCompanyDetails' && isCompanyDetailsForms(values)) {
      const data = {
        companyId: company_id,
        name: values.companyName,
        url: values.companyUrl,
      };
      addCompanyPortfolioApi(data)
        .unwrap()
        .then(res => {
          setCompanyId(res._id);
          if (values.companyLogo.length > 0) {
            uploadFiles(DIRECTORY_NAME, res._id, values.companyLogo as ImageInfo[], IMAGE_NAME)
              .then(filesName => {
                const filesUploadData = {
                  companyId: res._id,
                  logo: filesName,
                };
                updateCompanyPortfolioApi(filesUploadData)
                  .unwrap()
                  .then(() => {
                    setCurrentIndex(currentIndex + 1);
                    setLoading(false);
                    showToast(tCompany('success.creation'), tCompany('success.created'), RESPONSE_TYPE.SUCCESS);
                  })
                  .catch((updateApiError: ResponseError) => {
                    setLoading(false);
                    showToast(tError('error'), tApiError(updateApiError.data.errorCode, ''), RESPONSE_TYPE.ERROR);
                  });
              })
              .catch((uploadFilesErr: string) => {
                setLoading(false);
                showToast(tError('error'), uploadFilesErr, RESPONSE_TYPE.ERROR);
              });
          } else {
            setCurrentIndex(currentIndex + 1);
            setLoading(false);
            showToast(tCompany('success.creation'), tCompany('success.created'), RESPONSE_TYPE.SUCCESS);
          }
        })
        .catch((addApiErr: ResponseError) => {
          setLoading(false);
          if (addApiErr.status === HttpStatusCode.FORBIDDEN) {
            setCompanyExists(true);
          } else {
            showToast(tError('error'), tApiError(addApiErr.data.errorCode, ''), RESPONSE_TYPE.ERROR);
          }
        });
    }
    if (type === 'selectReports' && isSelectReportForms(values)) {
      const reqBody = {
        companyId,
        data: values,
      };
      attachReportApi(reqBody)
        .unwrap()
        .then(() => {
          setLoading(false);
          setCurrentIndex(currentIndex + 1);
          showToast(tReport('success.reports'), tReport('success.saved'), RESPONSE_TYPE.SUCCESS);
        })
        .catch((reportApiErr: ResponseError) => {
          setLoading(false);
          showToast(tError('error'), tApiError(reportApiErr.data.errorCode, ''), RESPONSE_TYPE.ERROR);
        });
    }
  };

  const addUserClickHandler = (values: PCAdminUser[]): void => {
    setLoading(true);
    const data = values.map(value => ({
      first_name: value.firstName,
      last_name: value.lastName,
      email: value.email,
    }));
    const reqBody = {
      companyId,
      data,
    };
    AddPortfolioCompanyUsers(reqBody)
      .unwrap()
      .then(() => {
        setLoading(false);
        navigate(`/${ROUTES.DASHBOARD}`, { replace: true });
        showToast(tUser('success.user'), tUser('success.added'), RESPONSE_TYPE.SUCCESS);
      })
      .catch((addUserApiError: ResponseError) => {
        setLoading(false);
        if (addUserApiError.status === HttpStatusCode.FORBIDDEN) {
          const getEmail = extractEmailFromString(addUserApiError.data.error);
          setUserAlreadyExist({ email: getEmail, isExist: true });
        } else {
          showToast(tError('error'), tApiError(addUserApiError.data.errorCode, ''), RESPONSE_TYPE.ERROR);
        }
      });
  };

  const onCancelClickHandler = (): void => {
    navigate(`/${ROUTES.DASHBOARD}`);
  };
  const onLaterClickHandler = (): void => {
    setCurrentIndex(currentIndex + 1);
  };
  const steps: StepItem[] = [
    {
      label: tCompany('portfolio-company-details'),
      component: (
        <PortfolioCompanyDetails
          onContinueClick={continueClickHandler}
          onCancelClick={onCancelClickHandler}
          isLoading={loading}
          companyExists={companyExists}
        />
      ),
    },
    {
      label: tReport('select-reports'),
      component: (
        <SelectReports onContinueClick={continueClickHandler} onLaterClick={onLaterClickHandler} isLoading={loading} />
      ),
    },
    {
      label: tUser('add', { count: 2 }),
      component: (
        <PortfolioAddUser
          onAddUserClick={addUserClickHandler}
          onCancelClick={onCancelClickHandler}
          isLoading={loading}
          userAlreadyExist={userAlreadyExist}
        />
      ),
    },
  ];

  const showBreadCrumbTitle = (index: number): string => {
    switch (index) {
      case 0:
        return tCompany('add-portfolio');
      case 1:
        return tReport('select-reports');
      case 2:
        return tUser('add', { count: 2 });
      default:
        return '';
    }
  };

  return (
    <>
      <NavigationHeader
        title={<Typography variant='h1'>{showBreadCrumbTitle(currentIndex)}</Typography>}
        navigateTo={ROUTES.DASHBOARD}
      />
      <Page>
        <Box className={classes.stepperWrapper}>
          <HorizontalStepper currentActiveIndex={currentIndex} stepperData={steps} />
        </Box>
      </Page>
    </>
  );
};

export default AddPortfolioCompany;
