import { isMimeType } from '@lexical/utils';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { getEditorFilesState, setEditorFiles } from '../../../redux/editor';
import { extractFileExtensions, isValidFileSize } from '../../../utils';
import { ACCEPTABLE_IMAGE_TYPES, MAX_FILE_LIMIT, MAX_FILE_SIZE_IN_MB } from './config';
import { RESPONSE_TYPE } from '../../../constants';
import { UseToast } from '../../../hooks/useToast';

interface FileValidation {
  editorFilesValidate: (files: FileList | File[]) => void;
}

const UseEditorFilesWithValidation = (): FileValidation => {
  const editorFiles = useAppSelector(getEditorFilesState);
  const dispatch = useAppDispatch();
  const { t: tEditor } = useTranslation('translation', { keyPrefix: 'editor' });
  const { t: tError } = useTranslation('translation', { keyPrefix: 'common.errors' });
  const { showToast } = UseToast();

  const editorFilesValidate = (files: FileList | File[]): void => {
    if (!files) return;
    const filesArr = Object.values(files);
    const modifyFiles = [];

    for (const file of filesArr) {
      if (isValidFileSize(file, MAX_FILE_SIZE_IN_MB)) {
        if (isMimeType(file, ACCEPTABLE_IMAGE_TYPES)) {
          modifyFiles.push(file);
        } else {
          const imageExtension = extractFileExtensions(ACCEPTABLE_IMAGE_TYPES).join(', ');
          showToast(
            tError('error'),
            tEditor('file-type-not-supported', {
              fileTypes: imageExtension,
            }),
            RESPONSE_TYPE.ERROR,
          );
        }
      } else {
        showToast(
          tError('error'),
          tEditor('file-size-should-be', { fileSize: MAX_FILE_SIZE_IN_MB }),
          RESPONSE_TYPE.ERROR,
        );
      }
    }

    const combinedFiles = [...editorFiles, ...modifyFiles];

    if (combinedFiles.length > MAX_FILE_LIMIT) {
      showToast(
        tError('error'),
        tEditor('max-file-upload-limit', { maxFileUploadLimit: MAX_FILE_LIMIT }),
        RESPONSE_TYPE.ERROR,
      );
      combinedFiles.splice(MAX_FILE_LIMIT);
    }

    dispatch(setEditorFiles(combinedFiles));
  };

  return { editorFilesValidate };
};

export default UseEditorFilesWithValidation;
