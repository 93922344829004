import { useEffect } from 'react';

// this is for restriction of input type number when scroll with mouse number getting change now it restricted.

export const useRestrictScrollOnNumberInput = (): void => {
  useEffect(() => {
    const handleWheel = (): void => {
      const { activeElement } = document as { activeElement: HTMLElement | null };
      if (activeElement && 'type' in activeElement && (activeElement as HTMLInputElement).type === 'number') {
        activeElement.blur();
      }
    };

    document.addEventListener('wheel', handleWheel);

    return () => {
      document.removeEventListener('wheel', handleWheel);
    };
  }, []);
};
