import { ButtonBaseProps, ButtonProps, CircularProgress } from '@mui/material';
import { ElementType, ReactNode, forwardRef } from 'react';
import { AppStyledButton, useStyles } from './style';
import { Box } from '../box';

interface Props {
  children: ReactNode;
  component?: ElementType;
  to?: string;
  loading?: boolean;
  size?: 'small' | 'medium' | 'large';
}

type AppButtonProps = Props & ButtonProps & ButtonBaseProps;

const Button = forwardRef<HTMLButtonElement, AppButtonProps>((props, ref) => {
  const { children, size = 'large', loading, ...other } = props;
  const { classes } = useStyles();
  return (
    <AppStyledButton className={loading ? classes.buttonRoot : ''} ref={ref} {...other} size={size} disableRipple>
      {loading && (
        <Box className={classes.loadingSpinner}>
          <CircularProgress size='small' />
        </Box>
      )}
      {children}
    </AppStyledButton>
  );
});

export default Button;
