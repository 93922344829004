import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '../box';
import { useStyles } from './style';
import { GridContainer } from '../grid-container';
import { GridItem } from '../grid-item';
import { Image } from '../image';
import { useBlobGetFiles } from '../../../hooks';
import { Typography } from '../typography';
import { IconButton } from '../button';
import { CloseIcon } from '../../../assets/icons';
import { getAzureBlobFileName, getFileSize } from '../../../utils';

interface Props {
  files: string[];
  onCloseBtnClickHandler?: (index: number) => void;
}
const BlobFilePreview = (props: Props): ReactElement => {
  const { files, onCloseBtnClickHandler = (): void => {} } = props;
  const { classes } = useStyles();
  const { getAzureBlob, getAzureBlobSize } = useBlobGetFiles();
  const [blobSizes, setBlobSizes] = useState<number[]>([]);
  const { t: tCommon } = useTranslation('translation', { keyPrefix: 'common' });

  useEffect(() => {
    for (let index = 0; index < files.length; index++) {
      const element = files[index];
      getAzureBlobSize(element)
        .then(res => {
          setBlobSizes(prev => [...prev, res.size]);
        })
        .catch(() => {
          setBlobSizes(prev => [...prev, 0]);
        });
    }
  }, []);

  return (
    <Box data-testid='blob-files-container'>
      {files.map((value, index) => {
        const azureBlobUrl = getAzureBlob(value)?.url;
        const azureBlobName = getAzureBlob(value)?.name as string;

        return (
          <Box key={value} className={classes.previewMainWrapper} data-testid='files-preview'>
            <Box className={classes.previewFileWrapper}>
              <GridContainer alignItems='center' columnSpacing={2}>
                <GridItem>
                  <Box className={classes.previewImageWrapper}>
                    <Image
                      src={azureBlobUrl}
                      alt={getAzureBlobFileName(azureBlobName)}
                      className={classes.previewImage}
                      data-testid='preview-image'
                    />
                  </Box>
                </GridItem>
                <GridItem xs={9} sm={7.5} md={7.5}>
                  <Box>
                    <Typography
                      variant='subtitle2'
                      className={classes.previewImageNameText}
                      data-testid='preview-image-name'
                    >
                      {getAzureBlobFileName(azureBlobName)}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant='body2'
                      className={classes.previewImageSizeText}
                      data-testid='preview-image-size'
                    >
                      {blobSizes[index] ? getFileSize(blobSizes[index]) : tCommon('loading')}
                    </Typography>
                  </Box>
                </GridItem>

                <Box className={classes.closeIconWrapper}>
                  <IconButton onClick={(): void => onCloseBtnClickHandler(index)} data-testid='close-icon-btn'>
                    <CloseIcon />
                  </IconButton>
                </Box>
              </GridContainer>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default BlobFilePreview;
