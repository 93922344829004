import { makeStyles } from '../../hooks';
interface StyleProps {
  isConfirmationOpen?: boolean;
}

export const useStyles = makeStyles<StyleProps>()((theme, { isConfirmationOpen }) => ({
  iconButton: {
    borderRadius: '4px',
    padding: '0px',
    backgroundColor: isConfirmationOpen ? theme.palette.background.lightblue : 'transparent',
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.main,
    },
  },
  reviewTooltip: {
    '& .MuiTooltip-tooltipPlacementBottom': {
      paddingBottom: '44px',
      paddingTop: '16px',
      paddingLeft: '16px',
      paddingRight: '16px',
      borderRadius: '25px',
      minWidth: '228px',
      marginTop: '4px !important',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
}));
