import { ReactElement, ReactNode, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, CheckBox, GridContainer, GridItem, LoadingSpinner, Stack, Typography } from '../shared';
import { useStyles } from './style';
import { ReportCards } from '../report-cards';
import { useGetAllReportsByCompanyIdQuery } from '../../services/reports/reports-service';
import { IReportsResponse } from '../../services/reports/reports-interface';
import { useAppSelector } from '../../hooks';
import { getReportsFetchState, getReportsLoadingState, getReportsState } from '../../redux/reports';

interface Props {
  onContinueClick?: (type: string, values: string[]) => void;
  onUpdateClick?: (values: string[]) => void;
  onCancelClick?: () => void;
  onLaterClick?: () => void;
  isLoading?: boolean;
  isEdit?: boolean;
  companyId?: string;
}

const SelectReports = (props: Props): ReactElement => {
  const {
    onContinueClick = (): void => {},
    onCancelClick = (): void => {},
    onLaterClick = (): void => {},
    isLoading,
    isEdit,
    onUpdateClick = (): void => {},
    companyId,
  } = props;
  const { classes } = useStyles();
  const { t: tReport } = useTranslation('translation', { keyPrefix: 'report' });
  const { t: tCommon } = useTranslation('translation', { keyPrefix: 'common' });
  const { t: tUser } = useTranslation('translation', { keyPrefix: 'user' });
  const [cardCheckedStates, setCardCheckedStates] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState(false);

  let reportsData: IReportsResponse[] = [];
  let reportsFetching;
  let reportsLoading;

  if (!isEdit) {
    reportsData = useAppSelector(getReportsState) as IReportsResponse[];
    reportsFetching = useAppSelector(getReportsFetchState);
    reportsLoading = useAppSelector(getReportsLoadingState);
  }

  const {
    currentData: getReportsData = [],
    isLoading: getReportsLoading,
    isFetching: getReportsFetching,
  } = useGetAllReportsByCompanyIdQuery(companyId as string, { skip: !isEdit && companyId === undefined });

  const isReportLoading = reportsLoading || getReportsLoading || reportsFetching || getReportsFetching;

  const onSelectAllReportClickHandler = (): void => {
    if (selectAll) {
      setCardCheckedStates([]);
    } else {
      let cardIds: string[] = [];
      if (isEdit) {
        cardIds = getReportsData.map(card => card._id);
      } else {
        cardIds = reportsData.map(card => card._id);
      }
      setCardCheckedStates(cardIds);
    }
  };

  const onReportCardClickHandler = (cardId: string): void => {
    if (cardCheckedStates.includes(cardId)) {
      const removeFilterExistingCardArr = cardCheckedStates.filter(stateId => stateId !== cardId);
      setCardCheckedStates(removeFilterExistingCardArr);
    } else {
      const addCardIdInArr = [...cardCheckedStates, cardId];
      setCardCheckedStates(addCardIdInArr);
    }
  };

  useEffect(() => {
    if (isEdit) {
      const selectedReports = getReportsData.filter(value => value.selected).map(value => value._id);
      setCardCheckedStates(selectedReports);
    }
  }, [JSON.stringify(getReportsData)]);

  const finalReportsData = useMemo(() => {
    let reports: IReportsResponse[] = [];
    if (isEdit) {
      reports = getReportsData.map(value => ({ _id: value._id, default_label: value.label, name: value.name }));
    } else {
      reports = reportsData;
    }
    return reports;
  }, [reportsData, getReportsData]);

  useEffect(() => {
    if (finalReportsData.length) {
      setSelectAll(finalReportsData.length === cardCheckedStates.length);
    }
  }, [cardCheckedStates, finalReportsData]);

  const showReportCards = (): ReactNode =>
    finalReportsData?.map(value => (
      <ReportCards
        key={value._id}
        data={value}
        onReportCardClickHandler={onReportCardClickHandler}
        cardCheckedStates={cardCheckedStates}
      />
    ));
  if (isReportLoading) {
    return <LoadingSpinner />;
  }
  return (
    <Box mt={isEdit ? 0 : 2}>
      <GridContainer columnSpacing={3}>
        <GridItem xs={12}>
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <Box>
              <Typography variant='subtitle3' textTransform='capitalize' data-testid='reports-selected-count'>
                {cardCheckedStates.length} {tReport('reports-selected')}
              </Typography>
            </Box>
            <Box>
              <CheckBox
                label={tReport('select-all-reports')}
                labelPlacement='end'
                labelClassName={classes.checkBoxLabel}
                onChange={onSelectAllReportClickHandler}
                checked={selectAll}
                data-testid='select-all-reports-checkbox'
              />
            </Box>
          </Stack>
        </GridItem>
      </GridContainer>
      <GridContainer spacing={{ md: 3, sm: 2, xs: 2 }}>{showReportCards()}</GridContainer>
      <Box px={1.5} py={2.5}>
        <Stack direction='row' spacing={2} justifyContent='flex-end'>
          <Button variant='outlined' onClick={isEdit ? onCancelClick : onLaterClick} data-testid='cancel-btn'>
            {isEdit ? tCommon('cancel') : tCommon('do-this-later')}
          </Button>
          <Button
            variant='contained'
            onClick={(): void => {
              if (isEdit) onUpdateClick(cardCheckedStates);
              onContinueClick('selectReports', cardCheckedStates);
            }}
            data-testid='continue-add-user-btn'
            disabled={cardCheckedStates.length === 0}
            loading={isLoading}
          >
            {isEdit ? tCommon('update') : tUser('continue-to-add')}
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};

export default SelectReports;
