import { gql } from '@apollo/client';

export const HEADCOUNT_FRAGMENT = gql`
  fragment HeadcountFragment on Headcount {
    headcountData: data {
      id
      department_id
      department_name
      employee_count
      payroll_total
      full_time
      part_time
      turnover
      contract
    }
  }
`;

export const PUBLIC_EQUITY_FRAGMENT = gql`
  fragment PublicEquityFragment on PublicEquity {
    publicEquityData: data {
      id
      company_name
      ticker
      entry_date
      revenue {
        value
      }
      enterprise_value {
        value
      }
      ebitda {
        value
        margin
      }
      tev_ltm_ebitda {
        value
      }
      tev_ntm_ebitda {
        value
      }
      leverage_ratio {
        value
      }
    }
  }
`;

export const VALUATION_FRAGMENT = gql`
  fragment ValuationFragment on Valuation {
    valuationData: data {
      enterprise_value_high
      enterprise_value_low
      equity_value_high
      equity_value_low
    }
  }
`;

export const FIVE_YEAR_PLAN_FRAGMENT = gql`
  fragment FiveYearPlanFragment on FiveYearPlan {
    fiveYearPlanData: data {
      financial_year
      revenue
      ebitda
    }
  }
`;

export const THIRTEEN_WEEK_CF = gql`
  fragment ThirteenWeekCfFragment on ThirteenWeekCf {
    thirteenWeekCfData: data {
      week
      value
    }
  }
`;

export const CAPITAL_STRUCTURE_FRAGMENT = gql`
  fragment CapitalStructureFragment on CapitalStructure {
    capStructureData: data {
      equity_value {
        period
        value
      }
      debt_summary {
        id
        tranche
        maturity_date
        debt_date
        rate
        third_party
        parent_company_amount
        total
      }
    }
  }
`;
