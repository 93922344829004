import { array, object } from 'yup';
import { AUTOCOMPLETE_VALIDATION, DATE_VALIDATION, NUMBER_VALIDATION, STRING_VALIDATION } from './common-schema';
import {
  HeadcountSchema,
  CapitalStructureDebtSchema,
  CapitalStructureEquityValueSchema,
  FiveYearPlanSchema,
  PublicEquitySchema,
  ThirteenWeekCashFlowSchema,
  ValuationSchema,
} from './types';

export const headcountSchema = (): HeadcountSchema =>
  object().shape({
    year: NUMBER_VALIDATION().REQUIRED,
    month: NUMBER_VALIDATION().REQUIRED,
    department: STRING_VALIDATION().REQUIRED,
    payroll_total: NUMBER_VALIDATION().REQUIRED_INTEGER,
    employee_count: NUMBER_VALIDATION().REQUIRED,
    full_time_employee_count: NUMBER_VALIDATION().REQUIRED,
    part_time_employee_count: NUMBER_VALIDATION().REQUIRED,
    turnover: NUMBER_VALIDATION().REQUIRED,
    contract: NUMBER_VALIDATION().REQUIRED,
  });

export const publicEquitySchema = (): PublicEquitySchema =>
  object().shape({
    year: NUMBER_VALIDATION().REQUIRED,
    month: NUMBER_VALIDATION().REQUIRED,
    company_name: STRING_VALIDATION().REQUIRED,
    ticker: STRING_VALIDATION().REQUIRED,
    entry_date: DATE_VALIDATION().REQUIRED,
    revenue: object().shape({
      value: NUMBER_VALIDATION().REQUIRED,
    }),
    enterprise_value: object().shape({
      value: NUMBER_VALIDATION().REQUIRED,
    }),
    ebitda: object().shape({
      value: NUMBER_VALIDATION().REQUIRED,
      margin: NUMBER_VALIDATION().REQUIRED,
    }),
    tev_ltm_ebitda: object().shape({
      value: NUMBER_VALIDATION().REQUIRED,
    }),
    tev_ntm_ebitda: object().shape({
      value: NUMBER_VALIDATION().REQUIRED,
    }),
    leverage_ratio: object().shape({
      value: NUMBER_VALIDATION().REQUIRED,
    }),
  });

export const valuationSchema = (): ValuationSchema =>
  object().shape({
    enterprise_value_high: NUMBER_VALIDATION().REQUIRED,
    enterprise_value_low: NUMBER_VALIDATION().REQUIRED,
    equity_value_high: NUMBER_VALIDATION().REQUIRED,
    equity_value_low: NUMBER_VALIDATION().REQUIRED,
  });

export const fiveYearPlanSchema = (): FiveYearPlanSchema =>
  array().of(
    object().shape({
      financial_year: STRING_VALIDATION().NOT_REQUIRED,
      revenue: NUMBER_VALIDATION().REQUIRED,
      ebitda: NUMBER_VALIDATION().REQUIRED,
    }),
  );

export const thirteenWeekCashFlowSchema = (): ThirteenWeekCashFlowSchema =>
  object().shape({
    concept: AUTOCOMPLETE_VALIDATION().REQUIRED,
    gl_account: AUTOCOMPLETE_VALIDATION().REQUIRED,
    weeks: array()
      .of(
        object().shape({
          week: NUMBER_VALIDATION().NOT_REQUIRED,
          value: NUMBER_VALIDATION().NOT_REQUIRED,
        }),
      )
      .notRequired(),
  });

export const capitalStructureEquityValueSchema = (): CapitalStructureEquityValueSchema =>
  object().shape({
    equity_value: array()
      .of(
        object().shape({
          period: STRING_VALIDATION().NOT_REQUIRED,
          value: NUMBER_VALIDATION().NOT_REQUIRED,
        }),
      )
      .notRequired(),
  });

export const capitalStructureDebtSchema = (): CapitalStructureDebtSchema =>
  object().shape({
    tranche: STRING_VALIDATION().REQUIRED,
    debt_date: DATE_VALIDATION().REQUIRED,
    maturity_date: DATE_VALIDATION().NOT_REQUIRED,
    rate: STRING_VALIDATION().REQUIRED,
    third_party: NUMBER_VALIDATION().REQUIRED,
    parent_company_amount: NUMBER_VALIDATION().REQUIRED,
    total: NUMBER_VALIDATION().REQUIRED,
  });
