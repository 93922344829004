import { makeStyles } from '../../hooks';

export const useStyles = makeStyles()(theme => ({
  cardWrapper: {
    border: `1px solid ${theme.palette.grey[300]}`,
    height: '100%',
  },
  cardSelectedWrapper: {
    border: `1px solid ${theme.palette.primary.main}`,
    boxShadow: theme.customShadows.primary,
  },
  cardActionArea: {
    padding: theme.spacing(2),
    cursor: 'pointer',
    height: '100%',
  },
  cardSummaryText: {
    color: theme.palette.grey[600],
  },
  reportIcon: {
    '& .MuiSvgIcon-root': {
      color: theme.palette.secondary.main,
    },
  },
}));
