import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, IconButton } from '../shared';
import { ChatIcon } from '../../assets/icons';
import { useStyles } from './style';
import { COUNT_EXCEED_LIMIT } from '../../constants';

interface Props {
  count: number;
  onClick?: () => void;
  isChatBoxOpen?: boolean;
}

const ChatButtonIcon = (props: Props): ReactElement => {
  const { count = 0, onClick = (): void => {}, isChatBoxOpen = false } = props;
  const { classes } = useStyles({ isChatBoxOpen });
  const { t: tComment } = useTranslation('translation', { keyPrefix: 'comment' });

  return (
    <Box>
      <IconButton className={classes.iconButton} onClick={onClick}>
        <Box className={classes.iconWrapper}>
          <ChatIcon />
          {count ? (
            <Box className={classes.countBox}>{count > COUNT_EXCEED_LIMIT ? tComment('99+') : count}</Box>
          ) : null}
        </Box>
      </IconButton>
    </Box>
  );
};

export default ChatButtonIcon;
