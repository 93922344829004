import { ReactElement } from 'react';
import { useStyles } from './style';
import { Box } from '../box';
import { GridContainer } from '../grid-container';
import { GridItem } from '../grid-item';
import { Image } from '../image';
import { Typography } from '../typography';
import { IconButton } from '../button';
import { CloseIcon } from '../../../assets/icons';
import { getFileSize } from '../../../utils';

interface FileWithPreview extends File {
  preview?: string;
}
interface ImageInfo {
  file: FileWithPreview;
  errorMessage?: string;
  error: boolean;
}
interface Props {
  files: ImageInfo[];
  onCloseBtnClickHandler?: (index: number) => void;
}

const FilesPreview = (props: Props): ReactElement => {
  const { files = [], onCloseBtnClickHandler = (): void => {} } = props;
  const { classes } = useStyles();

  return (
    <Box data-testid='preview-files-container'>
      {files.map((value, index) => (
        <Box key={value.file.name} className={classes.previewMainWrapper} data-testid='files-preview'>
          <Box className={`${classes.previewFileWrapper} ${value.error ? classes.errorPreviewWrapper : ''}`}>
            <GridContainer alignItems='center' columnSpacing={2}>
              <GridItem>
                <Box className={classes.previewImageWrapper}>
                  <Image
                    src={value.file.preview}
                    alt={value.file.name}
                    className={classes.previewImage}
                    onLoad={(): void => {
                      URL.revokeObjectURL(value.file.preview || '');
                    }}
                    data-testid='preview-image'
                  />
                </Box>
              </GridItem>
              <GridItem xs={9} sm={7.5} md={7.5}>
                <Box>
                  <Typography
                    variant='subtitle2'
                    className={classes.previewImageNameText}
                    data-testid='preview-image-name'
                  >
                    {value.file.name}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant='body2' className={classes.previewImageSizeText} data-testid='preview-image-size'>
                    {getFileSize(value.file.size)}
                  </Typography>
                </Box>
              </GridItem>

              <Box className={classes.closeIconWrapper}>
                <IconButton onClick={(): void => onCloseBtnClickHandler(index)} data-testid='close-icon-btn'>
                  <CloseIcon />
                </IconButton>
              </Box>
            </GridContainer>
          </Box>
          {value.error && (
            <Box my={1} className={classes.errorTextWrapper}>
              <Typography color='error' data-testid='preview-image-error-message'>
                {value.errorMessage}
              </Typography>
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default FilesPreview;
