import { Auth0Provider, CacheLocation } from '@auth0/auth0-react';
import React, { PropsWithChildren } from 'react';
import { ERROR_TEXT_CONSTANTS } from './constants';

interface Auth0ProviderWithNavigateProps {
  children: React.ReactNode;
}

const Auth0ProviderWithNavigate = ({
  children,
}: PropsWithChildren<Auth0ProviderWithNavigateProps>): JSX.Element | null => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const redirectUri = process.env.REACT_APP_AUTH0_CALLBACK_URL;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
  const cacheLocation = process.env.REACT_APP_AUTH0_CACHE_LOCATION as CacheLocation;

  if (!(domain && clientId && redirectUri)) {
    throw new Error(ERROR_TEXT_CONSTANTS.AUTH0_ERROR);
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: redirectUri,
        audience,
      }}
      cacheLocation={cacheLocation}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithNavigate;
