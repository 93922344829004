import { ChangeEvent, ReactElement, useEffect, useState, useTransition } from 'react';
import { useSearchParams } from 'react-router-dom';
import { TextFieldProps } from '@mui/material';
import { Box, TextField } from '../shared';
import { SearchIcon } from '../../assets/icons';
import { FILTER_PARAMS } from '../../constants';
import { useStyles } from './style';

interface Props {
  size?: 'medium' | 'large';
}

type AppSearchBarProps = Props & TextFieldProps;

const SearchBar = (props: AppSearchBarProps): ReactElement => {
  const { size = 'large', ...other } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const getSearchedValue = searchParams.get('search');
  const [search, setSearch] = useState(getSearchedValue || '');
  const [, startTransition] = useTransition();
  const { SEARCH, PAGE } = FILTER_PARAMS;
  const { classes } = useStyles({ size });

  useEffect(() => {
    setSearch(getSearchedValue || '');
  }, [getSearchedValue]);

  const onSearchChangeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    setSearch(value);
    startTransition(() => {
      if (value) {
        searchParams.delete(PAGE);
        searchParams.set(SEARCH, value);
      } else {
        searchParams.delete(SEARCH);
        searchParams.delete(PAGE);
      }
      setSearchParams(searchParams);
    });
  };
  return (
    <Box>
      <TextField
        fullWidth
        value={search}
        onChange={onSearchChangeHandler}
        InputProps={{ endAdornment: <SearchIcon data-testid='search-icon' /> }}
        key='search'
        {...other}
        data-testid='search-bar'
        className={classes.searchBarContainer}
      />
    </Box>
  );
};

export default SearchBar;
