import { SIDEBAR_CONSTANTS } from '../../../constants';
import { makeStyles } from '../../../hooks';

const { EXPANDED_WIDTH, MINI_WIDTH } = SIDEBAR_CONSTANTS.SIDEBAR_WIDTH.DESKTOP;
interface StyleProps {
  isDrawerOpen?: boolean;
}
export const useStyles = makeStyles<StyleProps>()((theme, { isDrawerOpen }) => ({
  mainContainer: {
    flexGrow: 1,
    padding: theme.spacing(4.5),
    display: 'flex',
    backgroundColor: theme.palette.grey['A100'],
    gap: theme.spacing(4.5),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0),
      gap: theme.spacing(0),
      flexDirection: 'column',
    },
  },
  mainContent: {
    flexGrow: 1,
    backgroundColor: 'transparent',
    marginLeft: isDrawerOpen ? `calc(${EXPANDED_WIDTH}px + 36px)` : `calc(${MINI_WIDTH}px + 36px)`,
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      padding: theme.spacing(0, 4, 4, 4),
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      padding: theme.spacing(0, 2, 4, 2),
    },
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: isDrawerOpen ? theme.transitions.duration.enteringScreen : theme.transitions.duration.leavingScreen,
    }),
    minHeight: 'calc(100vh - 64px)',
  },
  sideBarWithArrowContainer: {
    height: '100%',
    position: 'fixed',
    top: '36px',
    zIndex: 1200,
  },
  sideBarWithArrowIconWrapper: {
    position: 'relative',
  },
  ArrowIconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    right: -1,
    bottom: 32,
    transform: 'translate(24px, -24px)',
    width: '24px',
    height: '32px',
    backgroundColor: theme.palette.info.lightest,
    zIndex: 1200,
    cursor: 'pointer',
    borderTopRightRadius: '5px',
    borderBottomRightRadius: '5px',
    '& .MuiSvgIcon-root': {
      color: theme.palette.secondary.main,
      width: '18px',
      height: '18px',
    },
  },
}));
