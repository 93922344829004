import { object } from 'yup';
import { ARRAY_VALIDATION, STRING_VALIDATION, URL_VALIDATION } from './common-schema';
import { PortfolioCompanySchema } from './types';

export const portfolioCompanySchema = (): PortfolioCompanySchema =>
  object().shape({
    companyName: STRING_VALIDATION().REQUIRED,
    companyUrl: URL_VALIDATION().VALIDATE,
    companyLogo: ARRAY_VALIDATION().NOT_REQUIRED,
  });
