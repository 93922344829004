import { ReactElement, ReactNode } from 'react';
import { useStyles } from './style';
import { Box, Stack, Typography } from '../shared';

interface Props {
  icon?: ReactNode;
  title?: string;
  description?: string;
}

const NoContentFound = (props: Props): ReactElement => {
  const { classes } = useStyles();
  const { icon = '', title = '', description = '' } = props;

  return (
    <Box className={classes.notContentWrapper} data-testid='no-content-found-container'>
      <Stack spacing={1.5}>
        {icon && <Box>{icon}</Box>}
        {title && (
          <Box>
            <Typography variant='h2'>{title}</Typography>
          </Box>
        )}
        {description && (
          <Box>
            <Typography variant='body3'>{description}</Typography>
          </Box>
        )}
      </Stack>
    </Box>
  );
};

export default NoContentFound;
