import toast from 'react-hot-toast';
import { CustomToast } from '../components/shared';
import { RESPONSE_TYPE, TOAST_TIMER } from '../constants';

interface IUseToast {
  showToast: (title: string, message: string, type: RESPONSE_TYPE, duration?: number) => string;
}
export const UseToast = (): IUseToast => {
  const showToast = (title: string, message: string, type: RESPONSE_TYPE, duration = TOAST_TIMER.COMMON): string =>
    toast.custom(<CustomToast title={title} message={message} type={type} />, { id: type, duration });
  return { showToast };
};
