import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IReportsResponse } from '../../services/reports/reports-interface';

interface ReportsState {
  reports: Array<IReportsResponse>;
  isLoading: boolean;
  isFetch: boolean;
}

const initialState: ReportsState = {
  reports: [],
  isLoading: false,
  isFetch: false,
};

export const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setAllReports: (state, action: PayloadAction<ReportsState>) => {
      state.reports = action.payload.reports;
      state.isFetch = action.payload.isFetch;
      state.isLoading = action.payload.isLoading;
    },
  },
});
