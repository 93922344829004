import { ReactElement, ReactNode, memo } from 'react';
import { DialogProps, Dialog as MuiDialog } from '@mui/material';
interface Props {
  children: ReactNode;
}

type AppDialogProps = Props & DialogProps;

const Dialog = (prop: AppDialogProps): ReactElement => {
  const { children, ...other } = prop;

  return <MuiDialog {...other}>{children}</MuiDialog>;
};

export default memo(Dialog);
