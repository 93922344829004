import { makeStyles } from '../../../hooks';

export const useStyles = makeStyles()(theme => ({
  backArrowIcon: {
    paddingLeft: 0,
  },
  confirmationIcon: {
    width: '48px',
    height: '42px',
    color: theme.palette.error.main,
  },
  companyLogo: {
    width: 'auto',
    height: '50px',
    objectFit: 'contain',
    display: 'block',
  },
}));
