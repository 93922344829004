import { TableHead as MuiTableHead, TableHeadProps } from '@mui/material';
import { ReactElement, ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

type AppTableHeadProps = Props & TableHeadProps;

const TableHead = (props: AppTableHeadProps): ReactElement => {
  const { children, ...other } = props;
  return <MuiTableHead {...other}>{children}</MuiTableHead>;
};

export default TableHead;
