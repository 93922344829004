import { LinkProps as MuiLinkProps } from '@mui/material';
import { ElementType, ReactElement, ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { AppLink } from './style';
import { Box } from '../box';
import { Button } from '../button';

interface Props {
  children: ReactNode;
  underline?: 'none' | 'hover' | 'always';
  disabled?: boolean;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  component?: ElementType;
}

interface RouterLinkProps {
  to?: string;
  replace?: boolean;
  // Additional props specific to react-router Link component
}
type AppLinkProps = MuiLinkProps & RouterLinkProps & Props;

const Link = (props: AppLinkProps): ReactElement => {
  const {
    underline = 'none',
    disabled = false,
    startIcon,
    endIcon,
    children,
    component = RouterLink,
    ...others
  } = props;

  return (
    <AppLink component={disabled ? Button : component} {...others} underline={underline} disabled={disabled}>
      {startIcon && (
        <Box component='span' className='start-icon'>
          {startIcon}
        </Box>
      )}
      {children}
      {endIcon && (
        <Box component='span' className='end-icon'>
          {endIcon}
        </Box>
      )}
    </AppLink>
  );
};

export default Link;
