import { makeStyles } from '../../../hooks';

export const useStyles = makeStyles()(theme => ({
  updateReportsContainer: {
    padding: theme.spacing(0, 1.5, 0.5, 1.5),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1, 1, 0.5, 1),
    },
  },
  companyLogo: {
    width: 'auto',
    height: '50px',
    objectFit: 'contain',
    display: 'block',
  },
}));
