import { TextFieldProps } from '@mui/material';
import { ChangeEvent, ReactElement } from 'react';
import { AppTextField } from './style';
import { NUMBER_FIELD_REGEX } from '../../../constants';

interface Props {
  allowNegativeValue?: boolean;
  allowDecimalValue?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  isNumberField?: boolean;
}

type AppTextFieldProps = Props & Omit<TextFieldProps, '???'>;

const TextField = (props: AppTextFieldProps): ReactElement => {
  const {
    allowNegativeValue = false,
    allowDecimalValue = false,
    onChange = (): void => {},
    isNumberField = false,
    ...others
  } = props;

  const { ALLOW_DECIMAL_ONLY, ALLOW_NEGATIVE_AND_DECIMAL, ALLOW_NEGATIVE_ONLY, ALLOW_NUMBER_ONLY } = NUMBER_FIELD_REGEX;

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    let regex;
    if (isNumberField) {
      switch (true) {
        case allowNegativeValue && allowDecimalValue:
          regex = ALLOW_NEGATIVE_AND_DECIMAL;
          break;
        case allowNegativeValue:
          regex = ALLOW_NEGATIVE_ONLY;
          break;
        case allowDecimalValue:
          regex = ALLOW_DECIMAL_ONLY;
          break;
        default:
          regex = ALLOW_NUMBER_ONLY;
      }
      if (!regex.test(value) && value !== '') {
        event.preventDefault();
        return;
      }
    }
    onChange(event);
  };

  return <AppTextField {...others} onChange={onChangeHandler} />;
};

export default TextField;
