import { makeStyles } from '../../hooks';

interface StyleProps {
  size?: 'medium' | 'large';
}
export const useStyles = makeStyles<StyleProps>()((theme, { size }) => ({
  searchBarContainer: {
    '.MuiInputBase-input::placeholder': {
      color: theme.palette.grey[700],
      opacity: 1,
    },
    '& .MuiInputBase-input': {
      padding: size === 'large' ? '16px 16px' : '12px 16px',
    },
  },
}));
