import { makeStyles } from '../../hooks';

export const useStyles = makeStyles()(theme => ({
  addUserContainer: {
    backgroundColor: theme.palette.background.lightblue,
    borderRadius: '10px',
    padding: theme.spacing(0.5, 0.5),
    margin: theme.spacing(2, 0),
    ':first-of-type': {
      margin: theme.spacing(1, 0),
    },
    '& .MuiInputBase-root.MuiOutlinedInput-root': {
      backgroundColor: theme.palette.common.white,
    },
  },
}));
