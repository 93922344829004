import { FunctionComponent, SVGProps } from 'react';
import {
  BarChartAnalysisIcon,
  BarChartIcon,
  DonutChartIcon,
  FileSettingsIcon,
  PersonGroupIcon,
  PaymentIcon,
  StarOutlineIcon,
  TableChartIcon,
  CoinTurningUsd,
  RoundArrowUsd,
  SettingUsd,
  BarChartMeter,
  KPIChain,
  GrowthSearch,
  CoinUpDownUsd,
  BarChartSetting,
  MoneyBundle,
  MonitorGraph,
} from '../assets/icons';
import i18next from '../i18n/config';
import { cleanAndLowerCaseString } from '../utils';

export interface Reports {
  [key: string]: {
    icon:
      | string
      | FunctionComponent<
          SVGProps<SVGSVGElement> & {
            title?: string | undefined;
          }
        >;
    description: string;
  };
}

export const ALLOWED_IMAGE_DIMENSIONS = {
  width: 160,
  height: 160,
};

export const GET_REPORTS = (): Reports => ({
  'Profit & Loss': {
    icon: BarChartIcon,
    description: i18next.t('report.cards-summary.profit-and-loss.description'),
  },
  'Profit & Loss Trend': {
    icon: BarChartAnalysisIcon,
    description: i18next.t('report.cards-summary.profit-and-loss-trend.description'),
  },
  'Balance Sheet': {
    icon: TableChartIcon,
    description: i18next.t('report.cards-summary.balance-sheet.description'),
  },
  '13 Week Cash Flow': {
    icon: RoundArrowUsd,
    description: i18next.t('report.cards-summary.13-week-cash-flow.description'),
  },
  'Cash Flow': {
    icon: CoinTurningUsd,
    description: i18next.t('report.cards-summary.cash-flow.description'),
  },
  'Five Year Plan': {
    icon: FileSettingsIcon,
    description: i18next.t('report.cards-summary.five-year-plan.description'),
  },
  'Public Equities': {
    icon: DonutChartIcon,
    description: i18next.t('report.cards-summary.public-equities.description'),
  },
  'Capital Structure': {
    icon: PaymentIcon,
    description: i18next.t('report.cards-summary.capital-structure.description'),
  },
  Valuation: {
    icon: StarOutlineIcon,
    description: i18next.t('report.cards-summary.valuation.description'),
  },
  Headcount: {
    icon: PersonGroupIcon,
    description: i18next.t('report.cards-summary.headcount.description'),
  },
  'Profit & Loss Management': {
    icon: BarChartSetting,
    description: i18next.t('report.cards-summary.profit-and-loss-management.description'),
  },
  'Profit & Loss 12 Month': {
    icon: CoinUpDownUsd,
    description: i18next.t('report.cards-summary.profit-and-loss-12-month.description'),
  },
  'Key Performance Indicator Trending': {
    icon: GrowthSearch,
    description: i18next.t('report.cards-summary.kpi-trending.description'),
  },
  'Key Performance Indicator by Store': {
    icon: KPIChain,
    description: i18next.t('report.cards-summary.kpi-by-store.description'),
  },
  'Revenue Week to Date': {
    icon: MoneyBundle,
    description: i18next.t('report.cards-summary.revenue-wtd.description'),
  },
  'Key Performance Indicator by Concept': {
    icon: BarChartMeter,
    description: i18next.t('report.cards-summary.kpi-by-concept.description'),
  },
  'Net Working Capital': {
    icon: SettingUsd,
    description: i18next.t('report.cards-summary.net-working-capital.description'),
  },
  'Enhanced Dashboard': {
    icon: MonitorGraph,
    description: i18next.t('report.cards-summary.enhanced-dashboard.description'),
  },
});

export const MAX_ADD_USER_COUNT = 5;

export const DEFAULT_PAGE_LIMIT = 20;

export const DEFAULT_PAGE = 1;

export const FILTER_PARAMS = {
  SEARCH: 'search',
  PAGE: 'page',
  ROLE: 'role_id',
  COMPANY: 'company_id',
  STATUS: 'status',
  YEAR: 'year',
  MONTH: 'month',
  FILTER: 'filter',
};

export const ROLES = {
  SUPER_ADMIN: cleanAndLowerCaseString('Super Admin'),
  PC_ADMIN: cleanAndLowerCaseString('PC Admin'),
};

export const METHODS = {
  POST: 'POST',
  GET: 'GET',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

export const AZURE_BLOB_STORAGE = {
  DIRECTORY_NAME: 'companies',
  IMAGE_NAME: 'logo',
};

export const KEYS = {
  TAB: 'Tab',
  ESCAPE: 'Escape',
  ENTER: 'Enter',
};

export const IS_AUTHENTICATED = 'isAuthenticated';

export const NAVIGATE_FROM = 'navigateFrom';
export const PORTAL_BACK_SELECTION = {
  DROPDOWN: 'dropdown',
  REPORT_CARD: 'report-card',
};

export const API_ENDPOINTS = {
  validateUserByEmail: 'validateUserByEmail',
};

export const FORM_DATA_ENDPOINTS = {
  ADD_COMMENT: 'addComment',
  UPDATE_COMMENT: 'updateComment',
};

export const ACTION_IDS = {
  EDIT: 'edit',
  DELETE: 'delete',
  CANCEL: 'cancel',
  CLOSE: 'close',
  RESEND_INVITE: 'resend-invite',
  ACTIVATE_DEACTIVATE: 'activate-deactivate',
  PROCEED: 'proceed',
};

// for update access token.
export const CHECK_BEFORE_EXPIRE_TIME = 60;

export const API_TAGS = {
  USERS: 'Users',
  COMMENT: 'Comment',
  REVIEW_REPORT: 'ReviewReport',
};

export const TOAST_TIMER = {
  COMMON: 2000,
  MANUAL_PORTAL: 4000,
};

export const SIX_MONTH_INTERVAL = {
  FIRST_HALF: '06',
  SECOND_HALF: '12',
};

export const COUNT_EXCEED_LIMIT = 99;

export const FORM_DATA = {
  COMMENT: 'comment',
  USER_ID: 'user_id',
  ATTACHMENTS: 'attachments',
  TAGGED_USERS: 'tagged_users',
};

export const DATE_FORMAT = {
  FOR_API: 'YYYY-MM-DD',
  FOR_VIEW: 'MM-DD-YYYY',
  INVALID_DATE: 'Invalid Date',
  WITH_TIME_AND_TEXT: 'MMMM DD, YYYY [at] hh:mm A',
  TEXT_DATE: 'MMMM DD, YYYY',
};

export const COMMENT_FILE_MIME_TYPES = 'image/png';
export const HTML_PARSER = 'text/html';

export const NUMBER_FIELD_REGEX = {
  ALLOW_NEGATIVE_AND_DECIMAL: /^-?\d*\.?\d*$/,
  ALLOW_NEGATIVE_ONLY: /^-?\d*$/,
  ALLOW_DECIMAL_ONLY: /^\d*\.?\d*$/,
  ALLOW_NUMBER_ONLY: /^\d*$/,
};
