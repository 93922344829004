import i18next from '../i18n/config';

/* eslint-disable @typescript-eslint/naming-convention */
interface ITitle {
  DASHBOARD: string;
  ADD_PORTFOLIO_COMPANY: string;
  UPDATE_PORTFOLIO_COMPANY: string;
  UPDATE_REPORTS: string;
  COMPANY: string;
  REPORT: string;
  USERS: string;
  USER: string;
  ADD_USER: string;
  LOGIN: string;
  INTERNAL_SERVER_ERROR: string;
  NOT_FOUND: string;
  DEFAULT: string;
  ACCESS_DENIED: string;
  HEADCOUNT: string;
  MANUAL_PORTAL_ENTRY: string;
  PUBLIC_EQUITIES: string;
  VALUATION: string;
  FIVE_YEAR_PLAN: string;
  THIRTEEN_WEEK_CASH_FLOW: string;
  CAPITAL_STRUCTURE: string;
}
/* eslint-enable @typescript-eslint/naming-convention */

const { t } = i18next;

export const ROUTES = {
  DASHBOARD: 'dashboard',
  ADD_PORTFOLIO_COMPANY: 'add-portfolio-company',
  UPDATE_PORTFOLIO_COMPANY: 'update-company-details',
  UPDATE_REPORTS: 'update-reports',
  COMPANY: 'companies',
  REPORT: 'reports',
  USERS: 'users',
  ADD_USER: 'add-user',
  LOGIN: 'login',
  PEN_TEST_LOGIN: 'pentest-login',
  INTERNAL_SERVER_ERROR: 'internal-server-error',
  NOT_FOUND: 'not-found',
  OVERVIEW: 'overview',
  ACCESS_DENIED: 'access-denied',
  HEADCOUNT: 'headcount',
  MANUAL_PORTAL_ENTRY: 'manual-portal-entry',
  PUBLIC_EQUITIES: 'public-equities',
  VALUATION: 'valuation',
  FIVE_YEAR_PLAN: 'five-year-plan',
  THIRTEEN_WEEK_CASH_FLOW: 'thirteen-week-cash-flow',
  CAPITAL_STRUCTURE: 'capital-structure',
};

export const TITLES = (): ITitle => ({
  DASHBOARD: t('common.home'),
  ADD_PORTFOLIO_COMPANY: t('company.add-portfolio'),
  UPDATE_PORTFOLIO_COMPANY: t('company.update-details', { count: 2 }),
  UPDATE_REPORTS: t('report.update', { count: 2 }),
  COMPANY: t('company.overview'),
  REPORT: t('report.title'),
  USERS: t('user.user', { count: 2 }),
  USER: t('user.user', { count: 1 }),
  ADD_USER: t('user.add', { count: 1 }),
  LOGIN: t('login.title'),
  INTERNAL_SERVER_ERROR: t('common.errors.500.internal-server-error'),
  NOT_FOUND: t('common.errors.404.page-not-found'),
  DEFAULT: t('common.loading'),
  ACCESS_DENIED: t('user.access-denied.title'),
  HEADCOUNT: t('company.manual-portal-entry.headcount.title'),
  MANUAL_PORTAL_ENTRY: t('company.manual-portal-entry.title'),
  PUBLIC_EQUITIES: t('company.manual-portal-entry.public-equities.title', { count: 2 }),
  VALUATION: t('company.manual-portal-entry.valuation.title'),
  FIVE_YEAR_PLAN: t('company.manual-portal-entry.five-year-plan.title'),
  THIRTEEN_WEEK_CASH_FLOW: t('company.manual-portal-entry.thirteen-week-cash-flow.title'),
  CAPITAL_STRUCTURE: t('company.manual-portal-entry.capital-structure.title'),
});

export const ROUTE_MODULE = {
  DASHBOARD_MODULE: 'dashboard',
  USER_MODULE: 'user',
  OVERVIEW_MODULE: 'overview',
};

// manual portal entry routes mapping again report api response name
export const ROUTE_MAPPING_AGAINST_REPORT_NAME: { [key: string]: string } = {
  Headcount: ROUTES.HEADCOUNT,
  'Public Equities': ROUTES.PUBLIC_EQUITIES,
  Valuation: ROUTES.VALUATION,
  'Five Year Plan': ROUTES.FIVE_YEAR_PLAN,
  '13 Week Cash Flow': ROUTES.THIRTEEN_WEEK_CASH_FLOW,
  'Capital Structure': ROUTES.CAPITAL_STRUCTURE,
};
