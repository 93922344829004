import toast from 'react-hot-toast';
import { ReactElement } from 'react';
import {
  CheckCircleOutlineIcon,
  CloseCircleOutlinedIcon,
  WarningCircleOutlinedIcon,
  WarningTriangleOutlinedIcon,
} from '../../../assets/icons';
import { useStyles } from './style';
import { Box } from '../box';
import { Stack } from '../stack';
import { Typography } from '../typography';
import { IconButton } from '../button';
import { Image } from '../image';
import { RESPONSE_TYPE } from '../../../constants';

export interface Props {
  title: string;
  message: string;
  type: RESPONSE_TYPE;
}

const CustomToast = (props: Props): ReactElement => {
  const { title, message, type } = props;
  const { classes } = useStyles();
  const onCloseIconClickHandler = (): void => {
    toast.remove();
  };

  const TOAST_TYPE = {
    success: {
      containerClass: classes.successToastContainer,
      iconClass: classes.successIcon,
      icon: <CheckCircleOutlineIcon />,
      titleColor: 'success.main',
      id: 'success',
    },
    warning: {
      containerClass: classes.warningToastContainer,
      iconClass: classes.warningIcon,
      icon: <WarningCircleOutlinedIcon />,
      titleColor: 'warning.main',
      id: 'warning',
    },
    error: {
      containerClass: classes.errorToastContainer,
      iconClass: classes.errorIcon,
      icon: <WarningTriangleOutlinedIcon />,
      titleColor: 'error.main',
      id: 'error',
    },
  };

  return (
    <Box
      className={`${classes.toastContainer} ${TOAST_TYPE[type].containerClass}`}
      data-testid={`toast-${TOAST_TYPE[type].id}`}
    >
      <Box mb={1}>
        <Stack direction='row' flexWrap='wrap' justifyContent='space-between' alignItems='center'>
          <Box display='flex' alignItems='center'>
            <Box pr={1} className={TOAST_TYPE[type].iconClass}>
              {TOAST_TYPE[type].icon}
            </Box>
            <Box>
              <Typography variant='subtitle4' color={TOAST_TYPE[type].titleColor} data-testid='toast-title'>
                {title}
              </Typography>
            </Box>
          </Box>
          <Box>
            <IconButton onClick={onCloseIconClickHandler} data-testid='toast-close-btn'>
              <Image src={CloseCircleOutlinedIcon} alt='close-icon' />
            </IconButton>
          </Box>
        </Stack>
      </Box>
      <Box>
        <Typography variant='h3' color='grey.600' data-testid='toast-message'>
          {message}
        </Typography>
      </Box>
    </Box>
  );
};

export default CustomToast;
