import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomColumn, Row } from 'react-table';
import { useSearchParams, Link as RouterLink } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';
import {
  Box,
  Button,
  GridContainer,
  GridItem,
  LetterAvatar,
  Link,
  Pagination,
  ReactTable,
  Stack,
  Typography,
} from '../../../components/shared';
import { ConfirmationModal, Filter, Page, SearchBar } from '../../../components';
import {
  DownArrowIcon,
  FilterAltOutlinedIcon,
  MailIcon,
  PersonCancelIcon,
  PersonCheckIcon,
  UpArrowIcon,
} from '../../../assets/icons';
import { getNameInitials, getOptions, isArrayWithLength, isSearchParamsMatch, isUserSuperAdmin } from '../../../utils';
import { ACTION_IDS, FILTER_PARAMS, RESPONSE_TYPE, ROLES, ROUTES, USER_COLUMNS } from '../../../constants';
import { FilterElement } from '../../../components/filter/Filter';
import {
  useGetUserRolesQuery,
  useGetUsersByCompanyIdQuery,
  useGetUsersQuery,
  useResendInviteUsersMutation,
  useToggleUserStatusMutation,
} from '../../../services/users/users-service';
import { IToggleUserStatusReqBody, IUserList, IUsersResponse } from '../../../services/users/users-interface';
import { useGetPortfolioByCompanyIdQuery } from '../../../services/companies/companies-service';
import { useAppSelector } from '../../../hooks';
import { getUserRole, getUserState } from '../../../redux/auth';
import { UseToast } from '../../../hooks/useToast';
import { ResponseError } from '../../../services';
import { NavigationHeader } from '../../../components/layout';
import { useStyles } from './style';

const Users: FC = () => {
  const { t: tUser } = useTranslation('translation', { keyPrefix: 'user' });
  const { t: tCompany } = useTranslation('translation', { keyPrefix: 'company' });
  const { t: tCommon } = useTranslation('translation', { keyPrefix: 'common' });
  const { t: tApiError } = useTranslation('translation');

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showDeleteBtn, setShowDeleteBtn] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [selectedRowId, setSelectedRowId] = useState<string>('');
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [userStatusWithEmail, setUserStatusWithEmail] = useState<IToggleUserStatusReqBody>({
    email: '',
    active: false,
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const { company_id } = useAppSelector(getUserState);
  const userRole = useAppSelector(getUserRole);
  const isSuperAdmin = isUserSuperAdmin(userRole);

  const [resendInviteApi, { isLoading: resendInviteLoading }] = useResendInviteUsersMutation();
  const [toggleUserApi, { isLoading: toggleUserLoading }] = useToggleUserStatusMutation();
  const { showToast } = UseToast();
  const { classes } = useStyles();
  const theme = useTheme();
  const isDesktopView = useMediaQuery(theme.breakpoints.up('md'));
  const userColumns = useMemo(
    () => (isSuperAdmin ? USER_COLUMNS(isDesktopView).ADMIN_USERS : USER_COLUMNS(isDesktopView).PC_USERS),
    [isDesktopView],
  );

  const initialUsersData: IUsersResponse = {
    current_page: 0,
    per_page: 0,
    total_pages: 0,
    total_users: 0,
    users: [],
  };

  const {
    currentData: adminUsersData = initialUsersData,
    isLoading: adminUsersLoading,
    isFetching: adminUsersFetching,
  } = useGetUsersQuery(searchParams.toString(), { skip: !isSuperAdmin });

  const {
    currentData: pcUsersData = initialUsersData,
    isLoading: pcUsersLoading,
    isFetching: pcUsersFetching,
  } = useGetUsersByCompanyIdQuery(
    { companyId: company_id, args: searchParams.toString() },
    { skip: isSuperAdmin || company_id === '' },
  );

  const { currentData: userRoles = [] } = useGetUserRolesQuery(undefined, { skip: !isSuperAdmin });

  const { currentData: assignedCompanies = [] } = useGetPortfolioByCompanyIdQuery(company_id, {
    skip: !isSuperAdmin || company_id === '',
  });
  const { PAGE, COMPANY, ROLE, STATUS } = FILTER_PARAMS;
  const { SUPER_ADMIN, PC_ADMIN } = ROLES;
  const { RESEND_INVITE, ACTIVATE_DEACTIVATE, CANCEL, CLOSE } = ACTION_IDS;

  const columns: CustomColumn<IUserList>[] = [
    {
      id: 'avatar',
      Cell: ({ row }: { row: Row<IUserList> }) => (
        <LetterAvatar size={32}>{getNameInitials(row.original.name)}</LetterAvatar>
      ),
      width: 50,
    },
    {
      Header: tUser('name-or-email-id'),
      id: 'combine-email-name',
      hideHeaderAndColumn: isDesktopView,
      Cell: ({ row }: { row: Row<IUserList> }) => (
        <Box>
          <Box className={classes.nameAndEmailText}>{row.original.name}</Box>
          <Box className={classes.nameAndEmailText}>{row.original.email}</Box>
        </Box>
      ),
      width: isDesktopView ? 'auto' : 240,
    },
    ...userColumns,
  ];

  const handleSelectedRowsChange = (selectedData: IUserList[]): void => {
    if (selectedData.length > 0) {
      setShowDeleteBtn(true);
    } else {
      setShowDeleteBtn(false);
    }
  };

  const onConfirmationClickHandler = useCallback(
    (id: string): void => {
      if (id === ACTIVATE_DEACTIVATE) {
        toggleUserApi(userStatusWithEmail)
          .unwrap()
          .then(response => {
            showToast(tCommon('success'), response.message, RESPONSE_TYPE.SUCCESS);
            setConfirmationModalOpen(false);
          })
          .catch((error: ResponseError) => {
            showToast(tCommon('errors.error'), tApiError(error.data.errorCode, ''), RESPONSE_TYPE.ERROR);
          });
      }
      if (id === CANCEL || id === CLOSE) {
        setConfirmationModalOpen(false);
      }
    },
    [confirmationModalOpen, isSuperAdmin],
  );

  const onActionBtnClick = (id: string, row: Row<IUserList>): void => {
    if (id === RESEND_INVITE) {
      setSelectedRowId(row.id);
      const data = {
        companyId: company_id,
        userId: row.original._id,
      };
      resendInviteApi(data)
        .unwrap()
        .then(() => {
          showToast(tCommon('success'), tUser('success.email-sent'), RESPONSE_TYPE.SUCCESS);
        })
        .catch((resendInviteError: ResponseError) => {
          showToast(tCommon('errors.error'), tApiError(resendInviteError.data.errorCode, ''), RESPONSE_TYPE.ERROR);
        });
    }
    if (id === ACTIVATE_DEACTIVATE) {
      setConfirmationModalOpen(true);
      setUserStatusWithEmail({ email: row.original.email, active: !row.original.active });
    }
  };

  const actionBtn = [
    /* 
    commented for JIST-1123
    {
      id: 'edit',
      text: tCommon('edit'),
      icon: EditOutlinedIcon,
      onActionBtnClick,
    },
      */
    {
      id: ACTIVATE_DEACTIVATE,
      dynamicRenderText: (row: Row<IUserList>): string => {
        const currentUser = row.original;
        return currentUser.active ? tUser('deactivate') : tUser('activate');
      },
      dynamicRenderIcon: (row: Row<IUserList>): string => {
        const currentUser = row.original;
        return currentUser.active ? PersonCancelIcon : PersonCheckIcon;
      },
      onActionBtnClick,
    },

    {
      id: RESEND_INVITE,
      text: tUser('resend-invite'),
      icon: MailIcon,
      onActionBtnClick,
    },
  ];

  const onFilterBtnClickHandler = (): void => {
    setShowFilter(!showFilter);
  };

  const filterElements: FilterElement[] = [
    {
      type: 'select',
      id: ROLE,
      label: tUser('role', { count: 2 }),
      data: getOptions(userRoles),
      permission: [SUPER_ADMIN],
    },
    {
      type: 'select',
      id: COMPANY,
      label: tCompany('assigned-company', { count: 1 }),
      data: getOptions(assignedCompanies),
      permission: [SUPER_ADMIN],
    },
    {
      type: 'select',
      id: STATUS,
      label: tUser('status'),
      data: [
        { id: 'active', value: 'active', text: tUser('active') },
        { id: 'inactive', value: 'inactive', text: tUser('inactive') },
      ],
      permission: [SUPER_ADMIN, PC_ADMIN],
    },
  ];

  useEffect(() => {
    const isDefaultFilterOpen = isSearchParamsMatch(filterElements);
    setShowFilter(isDefaultFilterOpen);
  }, []);

  const usersData = isSuperAdmin ? adminUsersData : pcUsersData;

  const loadingStatus = adminUsersLoading || adminUsersFetching || pcUsersLoading || pcUsersFetching;

  return (
    <>
      <NavigationHeader
        title={<Typography variant='h1'>{tUser('user', { count: 2 })}</Typography>}
        isBackButton={false}
      />
      <Box pb={2}>
        <Stack direction='row' alignItems='center' justifyContent='end'>
          <Box>
            <Stack direction='row' spacing={2} alignItems='center'>
              {isDesktopView && (
                <Box className={classes.searchBarWrapper}>
                  <SearchBar placeholder={tUser('search')} fullWidth />
                </Box>
              )}
              <Box>
                <Link
                  variant='secondaryLink'
                  startIcon={<FilterAltOutlinedIcon />}
                  endIcon={showFilter ? <UpArrowIcon /> : <DownArrowIcon />}
                  onClick={onFilterBtnClickHandler}
                  data-testid='filter-btn'
                  component={Button}
                >
                  {tCommon('filter')}
                </Link>
              </Box>
              <Box>
                <Button component={RouterLink} to={`/${ROUTES.ADD_USER}`} variant='contained'>
                  {tUser('add-new')}
                </Button>
              </Box>
            </Stack>
          </Box>
        </Stack>
        {!isDesktopView && (
          <Box className={classes.searchBarWrapper}>
            <SearchBar placeholder={tUser('search')} fullWidth />
          </Box>
        )}
        {showFilter && (
          <Box mt={2}>
            <Filter filterElements={filterElements} />
          </Box>
        )}
      </Box>
      <Page className={classes.pageWrapper}>
        <GridContainer spacing={2} py={2} px={2}>
          <GridItem xs={12} md={12}>
            <Stack direction='row' justifyContent='space-between'>
              <Box>
                <Typography variant='subtitle3' data-testid='total-users'>
                  {usersData.total_users} {tUser('user', { count: usersData.total_users })}
                </Typography>
              </Box>
              {/* commented for JIST-1123 */}
              {/* {showDeleteBtn && (
              <Box>
                <Link variant='primaryLink' startIcon={<Image src={DeleteIcon} alt='delete-icon' />}>
                  {tCommon('delete')}
                </Link>
              </Box>
            )} */}
            </Stack>
          </GridItem>
          <GridItem xs={12}>
            <ReactTable
              data={usersData.users}
              columns={columns}
              isRowSelect
              onSelectedRowsChange={handleSelectedRowsChange}
              isActionBtn
              actionBtn={actionBtn}
              isLoading={loadingStatus}
              selectedRowId={selectedRowId}
              isActionLoading={resendInviteLoading}
            />
          </GridItem>
        </GridContainer>
      </Page>
      <Box mt={2}>
        {isArrayWithLength(usersData.users) && (
          <Pagination
            currentPage={usersData.current_page}
            totalPageCount={usersData.total_pages}
            onPageChange={(pageNumber: number): void => {
              searchParams.set(PAGE, pageNumber.toString());
              setSearchParams(searchParams);
            }}
          />
        )}
      </Box>
      <ConfirmationModal
        open={confirmationModalOpen}
        icon={<Box />}
        confirmationTitle={userStatusWithEmail.active ? tUser('activate') : tUser('deactivate')}
        description={userStatusWithEmail.active ? tUser('activate-description') : tUser('deactivate-description')}
        buttons={[
          {
            id: CANCEL,
            label: tCommon('cancel'),
            color: 'primary',
            variant: 'outlined',
            onClick: onConfirmationClickHandler,
          },
          {
            id: ACTIVATE_DEACTIVATE,
            label: userStatusWithEmail.active ? tUser('activate') : tUser('deactivate'),
            onClick: onConfirmationClickHandler,
            loading: toggleUserLoading,
          },
        ]}
        onCloseClick={onConfirmationClickHandler}
      />
    </>
  );
};

export default Users;
