import { BoxProps, Box as MuiBox } from '@mui/material';
import { ReactElement } from 'react';

interface Props {
  alt: string;
  src?: string;
}

type AppImageProps = Props & BoxProps;

const Image = (props: AppImageProps): ReactElement => {
  const { ...other } = props;
  return <MuiBox component='img' {...other} />;
};

export default Image;
