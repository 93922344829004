import { Step as MuiStep, StepProps } from '@mui/material';
import { ReactElement, ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

type AppStepProps = Props & StepProps;

const Step = (props: AppStepProps): ReactElement => {
  const { children, ...other } = props;
  return <MuiStep {...other}>{children}</MuiStep>;
};

export default Step;
