import { makeStyles } from '../../../hooks';

export const useStyles = makeStyles()(theme => ({
  pageWrapper: {
    minHeight: '70vh',
  },
  searchBarWrapper: {
    minWidth: '270px',
    [theme.breakpoints.down('md')]: {
      marginTop: '24px',
    },
  },
  nameAndEmailText: {
    wordBreak: 'break-all',
  },
}));
