import { ReactElement, useCallback, useMemo, useState } from 'react';
import { useParams, useSearchParams, Link as RouterLink } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Row } from 'react-table';
import { useMediaQuery, useTheme } from '@mui/material';
import { NavigationHeader } from '../../../components/layout';
import {
  Box,
  Button,
  GridContainer,
  GridItem,
  Image,
  Link,
  ReactTable,
  Stack,
  Typography,
} from '../../../components/shared';
import { useGetCompanyByIdQuery } from '../../../services/companies/companies-service';
import { useBlobGetFiles, useNavigationPathForPortal } from '../../../hooks';
import { ConfirmationModal, MonthDropdown, Page, PublicEquitiesFormModal, YearDropdown } from '../../../components';
import {
  ACTION_IDS,
  FILTER_PARAMS,
  PUBLIC_EQUITIES_COLUMNS,
  REPORT_TYPE,
  RESPONSE_TYPE,
  TOAST_TIMER,
} from '../../../constants';
import { convertIntoDate, formatDateApi, leadingZeroFormatNumber } from '../../../utils';
import {
  AddCircleOutlineIcon,
  DeleteOutlinedIcon,
  EditOutlinedIcon,
  WarningTriangleOutlinedIcon,
} from '../../../assets/icons';
import {
  AddPublicEquityResponse,
  DELETE_REPORT,
  DeleteReportDataResponse,
  DeleteReportDataVariables,
  GET_REPORT_DATA,
  PublicEquities as IPublicEquities,
  PublicEquityFilterVariables,
  PublicEquityInput,
  PublicEquityResponse,
  UPSERT_PUBLIC_EQUITY,
} from '../../../graphql';
import { PublicEquityFormValues } from '../../../components/public-equities-form-modal/PublicEquitiesFormModal';
import { UseToast } from '../../../hooks/useToast';
import { useStyles } from './style';

const PublicEquities = (): ReactElement => {
  const { t: tCommon } = useTranslation('translation', { keyPrefix: 'common' });
  const { t: tCompany } = useTranslation('translation', { keyPrefix: 'company' });
  const { t: tPublicEquity } = useTranslation('translation', {
    keyPrefix: 'company.manual-portal-entry.public-equities',
  });
  const { t: tManualPortal } = useTranslation('translation', { keyPrefix: 'company.manual-portal-entry' });
  const { t: tError } = useTranslation('translation', { keyPrefix: 'common.errors' });
  const { companyId = '', reportId = '' } = useParams();
  const { currentData: companyDetails, isError } = useGetCompanyByIdQuery(companyId, {
    skip: companyId === undefined || companyId === '',
  });
  const navigation = useNavigationPathForPortal(companyId);
  const title = `${companyDetails?.name} - ${tCompany('manual-portal-entry.title')}`;
  const { getAzureBlob } = useBlobGetFiles();
  const companyLogo = companyDetails?.logo;
  const [searchParams] = useSearchParams();
  const { YEAR, MONTH } = FILTER_PARAMS;
  const { EDIT, DELETE, CANCEL, CLOSE } = ACTION_IDS;
  const yearParams = searchParams.get(YEAR);
  const monthParams = searchParams.get(MONTH);
  const theme = useTheme();
  const isDesktopView = useMediaQuery(theme.breakpoints.up('md'));
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { data, loading, refetch } = useQuery<PublicEquityResponse, PublicEquityFilterVariables>(GET_REPORT_DATA, {
    variables: {
      filters: {
        report_id: reportId,
        company_id: companyId,
        report_type: REPORT_TYPE.PUBLIC_EQUITY,
        year: Number(yearParams),
        month: Number(monthParams),
      },
    },
    skip: yearParams === null || monthParams === null || companyId === '' || reportId === '',
  });

  const [AddPublicEquityApi, { loading: addLoading }] = useMutation<
    AddPublicEquityResponse,
    { input: PublicEquityInput }
  >(UPSERT_PUBLIC_EQUITY);
  const [DeletePublicEquityApi, { loading: deleteLoading }] = useMutation<
    DeleteReportDataResponse,
    DeleteReportDataVariables
  >(DELETE_REPORT);
  const { showToast } = UseToast();

  const reportData = data?.getReportData;

  const publicEquitiesColumn = useMemo(() => PUBLIC_EQUITIES_COLUMNS(isDesktopView), [reportData, isDesktopView]);
  const [addPublicEquityModalOpen, setAddPublicEquityModalOpen] = useState(false);
  const [updatePublicEquityModalOpen, setUpdatePublicEquityModalOpen] = useState(false);
  const [isEditPublicEquities, setIsEditPublicEquities] = useState(false);
  const [editPublicEquityData, setEditPublicEquityData] = useState<PublicEquityFormValues>(
    {} as PublicEquityFormValues,
  );
  const [publicEquityId, setPublicEquityId] = useState('');
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const { classes } = useStyles();
  const onCancelOrCloseClickHandler = useCallback((): void => {
    if (isEditPublicEquities) {
      setUpdatePublicEquityModalOpen(false);
    }
    setAddPublicEquityModalOpen(false);
  }, [addPublicEquityModalOpen, isEditPublicEquities, updatePublicEquityModalOpen]);

  const onAddPublicEquityHandler = useCallback(
    (values: PublicEquityFormValues): void => {
      const formValues = {
        company_name: values.company_name,
        ticker: values.ticker,
        entry_date: formatDateApi(values.entry_date),
        revenue: {
          value: Number(values.revenue.value),
        },
        enterprise_value: {
          value: Number(values.enterprise_value.value),
        },
        ebitda: {
          value: Number(values.ebitda.value),
          margin: Number(values.ebitda.margin),
        },
        tev_ltm_ebitda: {
          value: Number(values.tev_ltm_ebitda.value),
        },
        tev_ntm_ebitda: {
          value: Number(values.tev_ntm_ebitda.value),
        },
        leverage_ratio: {
          value: Number(values.leverage_ratio.value),
        },
      };
      let formData = {
        report_type: REPORT_TYPE.PUBLIC_EQUITY,
        report_id: reportId,
        company_id: companyId,
        year: Number(values.year),
        month: Number(values.month),
        data: {},
      };
      if (isEditPublicEquities) {
        formData = {
          ...formData,
          data: { ...formValues, id: publicEquityId },
        } as PublicEquityInput;
      } else {
        formData = {
          ...formData,
          data: { ...formValues },
        } as PublicEquityInput;
      }

      AddPublicEquityApi({ variables: { input: formData } })
        .then(async () => {
          if (isEditPublicEquities) {
            setUpdatePublicEquityModalOpen(false);
            setPublicEquityId('');
            setIsEditPublicEquities(false);
            setEditPublicEquityData({} as PublicEquityFormValues);
          } else {
            setAddPublicEquityModalOpen(false);
          }
          showToast(
            tManualPortal('success.upload-success'),
            tManualPortal('success.updates-published-shortly'),
            RESPONSE_TYPE.SUCCESS,
            TOAST_TIMER.MANUAL_PORTAL,
          );
          await refetch();
        })
        .catch(() => {
          showToast(tError('error'), tManualPortal('error.occurred'), RESPONSE_TYPE.ERROR);
        });
    },
    [
      addPublicEquityModalOpen,
      companyId,
      reportId,
      AddPublicEquityApi,
      updatePublicEquityModalOpen,
      isEditPublicEquities,
      refetch,
      publicEquityId,
    ],
  );

  const onActionBtnClick = (id: string, row: Row<IPublicEquities>): void => {
    if (id === EDIT) {
      setUpdatePublicEquityModalOpen(true);
      setIsEditPublicEquities(true);
      const {
        id: peId,
        company_name,
        ticker,
        ebitda,
        enterprise_value,
        leverage_ratio,
        revenue,
        tev_ltm_ebitda,
        tev_ntm_ebitda,
        entry_date,
      } = row.original;

      setPublicEquityId(peId);
      setEditPublicEquityData({
        year: yearParams || '',
        month: monthParams || '',
        company_name,
        ticker,
        entry_date: convertIntoDate(entry_date),
        revenue: {
          value: revenue.value,
        },
        enterprise_value: {
          value: enterprise_value.value,
        },
        ebitda: {
          value: ebitda.value,
          margin: ebitda.margin,
        },
        tev_ltm_ebitda: {
          value: tev_ltm_ebitda.value,
        },
        leverage_ratio: {
          value: leverage_ratio.value,
        },
        tev_ntm_ebitda: {
          value: tev_ntm_ebitda.value,
        },
      } as PublicEquityFormValues);
    }
    if (id === DELETE) {
      setIsEditPublicEquities(false);
      setPublicEquityId(row.original.id);
      setConfirmationModalOpen(true);
    }
  };

  const onConfirmationClickHandler = useCallback(
    (id: string): void => {
      if (id === DELETE) {
        DeletePublicEquityApi({ variables: { id: publicEquityId, report_type: REPORT_TYPE.PUBLIC_EQUITY } })
          .then(async () => {
            setConfirmationModalOpen(false);
            showToast(tCommon('success'), tManualPortal('success.delete'), RESPONSE_TYPE.SUCCESS);
            setPublicEquityId('');
            await refetch();
          })
          .catch(() => {
            showToast(tError('error'), tManualPortal('error.occurred'), RESPONSE_TYPE.ERROR);
          });
      }
      if (id === CANCEL || id === CLOSE) {
        setConfirmationModalOpen(false);
      }
    },
    [publicEquityId, confirmationModalOpen],
  );

  const actionBtn = [
    {
      id: EDIT,
      text: tCommon('edit'),
      icon: EditOutlinedIcon,
      onActionBtnClick,
    },
    {
      id: DELETE,
      text: tCommon('delete'),
      icon: DeleteOutlinedIcon,
      onActionBtnClick,
    },
  ];
  return (
    <>
      <NavigationHeader
        title={
          <Typography variant='h1'>
            {companyDetails ? title : isError ? tCompany('manual-portal-entry.title') : ''}
          </Typography>
        }
        navigateTo={navigation}
      >
        {companyLogo && (
          <Image src={getAzureBlob(companyLogo)?.url} alt={companyLogo} className={classes.companyLogo} />
        )}
      </NavigationHeader>
      <Page>
        <Box pt={{ md: 6, sm: 8, xs: 5 }}>
          <GridContainer spacing={{ xs: 2, sm: 2, md: 2 }} justifyContent='center'>
            <GridItem xs={12} sm={11} md={8}>
              <GridContainer spacing={{ xs: 2, sm: 2, md: 2 }}>
                <GridItem xs={12}>
                  <Typography variant='h2'>{tPublicEquity('title', { count: 2 })}</Typography>
                </GridItem>
                <GridItem xs={6}>
                  <YearDropdown isFilter value={Number(yearParams)} />
                </GridItem>
                <GridItem xs={6}>
                  <MonthDropdown isFilter value={Number(monthParams)} />
                </GridItem>
                <GridItem xs={12} pt={3}>
                  <Stack direction='row' justifyContent='space-between'>
                    <Typography variant='h2'>
                      {yearParams &&
                        monthParams &&
                        `${yearParams}-${leadingZeroFormatNumber(Number(monthParams))} ${tPublicEquity('summary')}`}
                    </Typography>
                    <Link
                      variant='primaryLink'
                      startIcon={<AddCircleOutlineIcon />}
                      data-testid='add-btn'
                      component={Button}
                      onClick={(): void => setAddPublicEquityModalOpen(true)}
                    >
                      {tPublicEquity('add')}
                    </Link>
                  </Stack>
                </GridItem>
                <GridItem xs={12}>
                  <ReactTable
                    data={reportData?.publicEquityData || []}
                    columns={publicEquitiesColumn}
                    isLocalTable
                    isLoading={loading}
                    isActionBtn
                    actionBtn={actionBtn}
                    notFoundDescription={tPublicEquity('no-data-added-description')}
                  />
                </GridItem>
                <GridItem xs={12} textAlign='right' py={{ md: 5, sm: 3, xs: 3 }}>
                  <Button variant='outlined' data-testid='cancel-btn' component={RouterLink} to={`/${navigation}`}>
                    {tCommon('back')}
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </Box>
      </Page>
      <PublicEquitiesFormModal
        open={addPublicEquityModalOpen}
        onCancelOrCloseClick={onCancelOrCloseClickHandler}
        onAddPublicEquities={onAddPublicEquityHandler}
        loading={addLoading}
      />
      <PublicEquitiesFormModal
        open={updatePublicEquityModalOpen}
        onCancelOrCloseClick={onCancelOrCloseClickHandler}
        onAddPublicEquities={onAddPublicEquityHandler}
        loading={addLoading}
        isEdit={isEditPublicEquities}
        data={editPublicEquityData}
      />
      <ConfirmationModal
        open={confirmationModalOpen}
        icon={<WarningTriangleOutlinedIcon className={classes.confirmationIcon} />}
        confirmationTitle={tCommon('delete-confirmation')}
        description={tCommon('delete-confirmation-message')}
        buttons={[
          {
            id: CANCEL,
            label: tCommon('cancel'),
            color: 'primary',
            variant: 'outlined',
            onClick: onConfirmationClickHandler,
          },
          {
            id: DELETE,
            label: tCommon('delete'),
            color: 'error',
            onClick: onConfirmationClickHandler,
            loading: deleteLoading,
            'data-testid': 'delete-btn',
          },
        ]}
        onCloseClick={onConfirmationClickHandler}
      />
    </>
  );
};

export default PublicEquities;
