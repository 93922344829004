import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const PenTestLogin = (): null => {
  const { loginWithRedirect } = useAuth0();

  /* eslint-disable @typescript-eslint/no-floating-promises */
  useEffect(() => {
    loginWithRedirect();
  }, []);
  /* eslint-enable @typescript-eslint/no-floating-promises */

  return null;
};

export default PenTestLogin;
