import { ReactElement, ReactNode, memo } from 'react';
import { Dialog, IconButton, DialogTitle, DialogContent, DialogActions } from '../shared';
import { CloseIcon } from '../../assets/icons';
import { useStyles } from './style';

interface Props {
  title: string | ReactNode;
  children: ReactNode;
  open: boolean;
  actionButtons?: ReactNode;
  onClose?: () => void;
  fullScreen?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  scrollContent?: 'body' | 'paper';
  className?: string;
}

const Modal = (props: Props): ReactElement => {
  const {
    title = '',
    children,
    open = false,
    actionButtons,
    onClose = (): void => {},
    fullScreen = false,
    maxWidth = 'sm',
    scrollContent = 'body',
    className = '',
  } = props;
  const { classes } = useStyles();

  return (
    <Dialog
      open={open}
      aria-labelledby='modal-title'
      onClose={(event, reason): void => {
        if (reason && reason === 'backdropClick') return;
        onClose();
      }}
      fullScreen={fullScreen}
      maxWidth={maxWidth}
      scroll={scrollContent}
      classes={{ paper: className }}
      data-testid='modal'
    >
      <DialogTitle id='modal-title' variant='h2' className={classes.modalTitle}>
        {title}
      </DialogTitle>
      <IconButton onClick={onClose} className={classes.closeIcon} data-testid='close-icon'>
        <CloseIcon />
      </IconButton>
      <DialogContent className={classes.modalContent}>{children}</DialogContent>
      <DialogActions className={classes.modalActions}>{actionButtons}</DialogActions>
    </Dialog>
  );
};

export default memo(Modal);
