import { BoxProps, Box as MuiBox } from '@mui/material';
import { ElementType, ReactElement, ReactNode } from 'react';

interface Props {
  children?: ReactNode;
  component?: ElementType;
}

type AppBoxProps = Props & BoxProps;

const Box = (props: AppBoxProps): ReactElement => {
  const { children, ...other } = props;
  return <MuiBox {...other}>{children}</MuiBox>;
};

export default Box;
