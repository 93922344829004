import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { alpha } from '@mui/material';
import { withStyles } from '../../../hooks';

export const AppDatePicker = withStyles(MuiDatePicker, theme => ({
  root: {
    '& .MuiFormLabel-root.MuiInputLabel-root': {
      color: alpha(theme.palette.secondary.main, 0.6),
      lineHeight: 1,
      fontSize: '16px',
      '&.Mui-focused': {
        lineHeight: '20px',
      },
      '&.Mui-error': {
        color: theme.palette.error.main,
      },
      '&.Mui-disabled': {
        color: theme.palette.grey[400],
      },
      '& .MuiFormLabel-asterisk.MuiInputLabel-asterisk': {
        color: theme.palette.error.main,
      },
    },
    '& .MuiInputBase-root.MuiOutlinedInput-root': {
      fontSize: '16px',
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.common.black,
      },
      '&.Mui-focused': {
        '& .MuiInputBase-input.MuiOutlinedInput-input': {
          color: theme.palette.common.black,
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderWidth: '1px',
          borderColor: alpha(theme.palette.secondary.main, 0.3),
        },
      },
      '&.Mui-error': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderWidth: '1px',
          borderColor: theme.palette.error.main,
        },
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.secondary.main, 0.3),
    },
    '& .MuiFormHelperText-root': {
      marginTop: '8px',
      marginLeft: '16px',
    },
    '& .MuiInputAdornment-root': {
      '& .MuiSvgIcon-root': {
        color: theme.palette.common.black,
      },
    },
    '& .MuiInputBase-input': {
      padding: '12px 16px',
      ':-webkit-autofill': {
        WebkitTextFillColor: 'inherit',
        transition: 'background-color 5000s ease-in-out 0s',
      },
      '&[type="number"]': {
        MozAppearance: 'textfield',
        '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
          WebkitAppearance: 'none',
          margin: 0,
        },
      },
    },
  },
}));
