import { makeStyles } from '../../../hooks';
interface StyleProps {
  isLoginHeader?: boolean;
}

export const useStyles = makeStyles<StyleProps>()((theme, { isLoginHeader }) => ({
  tabletContainer: {
    position: 'sticky',
    top: 0,
    zIndex: 1300,
    width: '100%',
    backgroundColor: theme.palette.grey['A100'],
    minHeight: isLoginHeader ? '124px' : '64px',
  },
  tabletContainerWrapper: {
    position: 'relative',
    borderBottom: `2px solid ${theme.palette.divider}`,
  },
  tabletHamburgerContainer: {
    position: 'absolute',
    top: '50%',
    left: 0,
    transform: 'translate(16px, -50%)',
  },
  tabletLogoContainer: {
    textAlign: 'center',
    padding: theme.spacing(2, 4),
    minHeight: isLoginHeader ? '124px' : '64px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tabletLogo: {
    width: '200px',
    height: isLoginHeader ? '56px' : '44px',
  },
}));
