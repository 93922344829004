import { makeStyles } from '../../../hooks';

export const useStyles = makeStyles()(theme => ({
  drawerWrapper: {
    zIndex: 1300,
  },
  drawerContainer: {
    width: 280,
  },
  paperContainer: {
    borderTopRightRadius: 25,
    borderBottomRightRadius: 25,
  },
  sideBarMainContent: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  sidebarWrapper: {
    paddingLeft: '32px',
  },
  sideBarScrollbar: {
    backgroundColor: theme.palette.grey[200],
    maxHeight: 'calc(100vh - 365px)',
    '& .simplebar-content': {
      overflow: 'hidden',
    },
    '& .simplebar-track.simplebar-vertical': {
      right: '4px',
      '& .simplebar-scrollbar.simplebar-visible': {
        '::before': {
          backgroundColor: theme.palette.secondary.main,
          opacity: 1,
          width: '4px',
          borderRadius: '6px',
        },
      },
    },
    '& .simplebar-track.simplebar-horizontal': {
      display: 'none',
    },
  },
  listItem: {
    display: 'block',
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    borderBottomLeftRadius: '30px',
    borderTopLeftRadius: '30px',
    '& .MuiSvgIcon-root': {
      color: theme.palette.secondary.main,
      width: '32px',
      height: '32px',
    },
    '& .MuiListItemText-root': {
      color: theme.palette.secondary.main,
    },
    ':hover': {
      backgroundColor: theme.palette.info.lighter,
      '& .MuiSvgIcon-root': {
        color: theme.palette.common.white,
      },
      '& .MuiListItemText-root': {
        color: theme.palette.common.white,
      },
    },
  },
  selectedListItem: {
    backgroundColor: theme.palette.secondary.main,
    '& .MuiSvgIcon-root': {
      color: theme.palette.common.white,
    },
    '& .MuiListItemText-root': {
      color: theme.palette.common.white,
    },
    ':hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  routerLink: {
    textDecoration: 'none',
  },
  listItemButton: {
    minHeight: 48,
    padding: theme.spacing(1.5, 2.5),
    ':hover': {
      backgroundColor: 'transparent',
    },
  },
  listItemIcon: {
    minWidth: 0,
    justifyContent: 'center',
    marginRight: theme.spacing(1),
  },
  listItemText: {
    textTransform: 'capitalize',
    '& .MuiListItemText-primary': {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 400,
    },
  },
  sidebarFooter: {
    height: '56px',
    overflow: 'hidden',
  },
  closeIconWrapper: {
    paddingLeft: '24px',
    paddingTop: '8px',
  },
}));
