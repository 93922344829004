import { ReactElement } from 'react';
import { CircularProgress } from '@mui/material';
import { Box } from '../box';
import { useStyles } from './style';

interface Props {
  height?: number;
}

const LoadingSpinner = (props: Props): ReactElement => {
  const { height = 300 } = props;
  const { classes } = useStyles({ height });
  return (
    <Box className={classes.container} data-testid='spinner'>
      <CircularProgress />
    </Box>
  );
};

export default LoadingSpinner;
