import { TableCell as MuiTableCell, TableCellProps } from '@mui/material';
import { ReactElement, ReactNode } from 'react';

interface Props {
  children?: ReactNode;
}

type AppTableCellProps = Props & TableCellProps;

const TableCell = (props: AppTableCellProps): ReactElement => {
  const { children, ...other } = props;
  return <MuiTableCell {...other}>{children}</MuiTableCell>;
};

export default TableCell;
