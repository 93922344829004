import { Component, ReactNode } from 'react';
import FallBackUI from './FallBackUI';

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    return {
      hasError: true,
    };
  }

  render(): ReactNode {
    const { hasError } = this.state;
    if (hasError) {
      return <FallBackUI />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
