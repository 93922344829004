export enum RESPONSE_TYPE {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
}

export enum HttpStatusCode {
  OK = 200,
  BAD_REQUEST = 400,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  INTERNAL_SERVER_ERROR = 500,
}

export enum REPORT_TYPE {
  HEADCOUNT = 'Headcount',
  PUBLIC_EQUITY = 'PublicEquity',
  VALUATION = 'Valuation',
  FIVE_YEAR_PLAN = 'FiveYearPlan',
  THIRTEEN_WEEK_CF = 'ThirteenWeekCf',
  CAPITAL_STRUCTURE = 'CapitalStructure',
}
