import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { Theme, useMediaQuery } from '@mui/material';
import { Box, Button, Container, GridContainer, GridItem, Stack, Image } from '../../components/shared';
import { useStyles } from './style';
import { SignInLogo } from '../../assets/logos';
import { LoginBanner } from '../../components';
import { TabletHeader } from '../../components/layout';

const Login: FC = () => {
  const { classes } = useStyles();
  const { t } = useTranslation('translation', { keyPrefix: 'login' });
  const { loginWithRedirect } = useAuth0();
  const googleConnection = process.env.REACT_APP_AUTH0_GOOGLE_CONNECTION;
  const microsoftConnection = process.env.REACT_APP_AUTH0_MICROSOFT_CONNECTION;
  const isTabletView = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const loginHandler = async (authConnection: string | undefined): Promise<void> => {
    await loginWithRedirect({
      authorizationParams: {
        connection: authConnection,
      },
    });
  };

  const DesktopView = (): JSX.Element => (
    <GridContainer className={classes.loginPageWrapper}>
      <GridItem md={5} sm={5.5} xs={12} lg={5} xl={3.5}>
        <Box className={classes.loginFormWrapper}>
          <GridItem md={8.4} sm={9} xs={12} lg={7.5} xl={6}>
            <Box py={{ md: 2, sm: 2, sx: 0 }} textAlign='center'>
              <Image src={SignInLogo} alt='sign-in-logo' className={classes.loginLogo} />
            </Box>
            <Box py={{ md: 3, sm: 2, sx: 0 }} className={classes.buttonWrapper}>
              <Stack gap={2} direction='column' justifyContent='center' alignItems='center'>
                <Button
                  variant='outlined'
                  size='large'
                  className={`${classes.loginButtonMicrosoft} ${classes.loginButton}`}
                  onClick={(): Promise<void> => loginHandler(microsoftConnection)}
                >
                  {t('login-with-microsoft')}
                </Button>
                <Button
                  variant='outlined'
                  size='large'
                  className={`${classes.loginButtonGoogle} ${classes.loginButton}`}
                  onClick={(): Promise<void> => loginHandler(googleConnection)}
                >
                  {t('login-with-google')}
                </Button>
              </Stack>
            </Box>
          </GridItem>
        </Box>
      </GridItem>
      <GridItem md={7} sm={6.5} xs={12} lg={7} xl={8.5} height='100%'>
        <LoginBanner />
      </GridItem>
    </GridContainer>
  );
  const TabletView = (): JSX.Element => (
    <>
      <TabletHeader isLoginHeader />
      <LoginBanner />
      <Box>
        <Box pt={{ sm: 8.5, xs: 8 }} pb={{ sm: 4, xs: 4 }} className={classes.buttonWrapper}>
          <Stack gap={3} direction='column' alignItems='center'>
            <Button
              variant='outlined'
              size='large'
              className={`${classes.loginButtonMicrosoft} ${classes.loginButton}`}
              onClick={(): Promise<void> => loginHandler(microsoftConnection)}
            >
              {t('login-with-microsoft')}
            </Button>
            <Button
              variant='outlined'
              size='large'
              className={`${classes.loginButtonGoogle} ${classes.loginButton}`}
              onClick={(): Promise<void> => loginHandler(googleConnection)}
            >
              {t('login-with-google')}
            </Button>
          </Stack>
        </Box>
      </Box>
    </>
  );

  return (
    <Container disableGutters maxWidth={false} className={classes.loginPageRoot}>
      {isTabletView ? TabletView() : DesktopView()}
    </Container>
  );
};

export default Login;
