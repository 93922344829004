import { ReactElement } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Page, SelectReports } from '../../../components';
import { Box, Image, Link, Stack, Typography } from '../../../components/shared';
import { RESPONSE_TYPE, ROUTES } from '../../../constants';
import { useUpdateReportsByCompanyIdMutation } from '../../../services/reports/reports-service';
import { UseToast } from '../../../hooks/useToast';
import { ResponseError } from '../../../services';
import { useStyles } from './style';
import { NavigationHeader } from '../../../components/layout';
import { useGetCompanyByIdQuery } from '../../../services/companies/companies-service';
import { useBlobGetFiles } from '../../../hooks';

const UpdateReports = (): ReactElement => {
  const { t: tCompany } = useTranslation('translation', { keyPrefix: 'company' });
  const { t: tReport } = useTranslation('translation', { keyPrefix: 'report' });
  const { t: tCommon } = useTranslation('translation', { keyPrefix: 'common' });
  const { t: tApiError } = useTranslation('translation');
  const navigate = useNavigate();
  const urlParams = useParams<{ id: string }>();
  const [updateReportByIdApi, { isLoading }] = useUpdateReportsByCompanyIdMutation();
  const { showToast } = UseToast();
  const { classes } = useStyles();
  const { currentData: companyDetails, isError } = useGetCompanyByIdQuery(urlParams.id as string, {
    skip: urlParams.id === undefined,
  });

  const onUpdateClickHandler = (values: string[]): void => {
    const data = {
      companyId: urlParams.id as string,
      data: values,
    };

    updateReportByIdApi(data)
      .unwrap()
      .then(response => {
        showToast(tCommon('success'), response.message, RESPONSE_TYPE.SUCCESS);
        navigate(`/${ROUTES.DASHBOARD}`);
      })
      .catch((err: ResponseError) => {
        showToast(tCommon('errors.error'), tApiError(err.data.errorCode, ''), RESPONSE_TYPE.ERROR);
      });
  };

  const onCancelClickHandler = (): void => {
    navigate(`/${ROUTES.DASHBOARD}`);
  };
  const { getAzureBlob } = useBlobGetFiles();
  const companyLogo = companyDetails?.logo;
  return (
    <>
      <NavigationHeader
        title={
          <Typography variant='h1' data-testid='title'>
            {companyDetails ? companyDetails?.name : isError ? tReport('update', { count: 2 }) : ''}
          </Typography>
        }
        navigateTo={ROUTES.DASHBOARD}
      >
        {companyLogo && (
          <Image src={getAzureBlob(companyLogo)?.url} alt={companyLogo} className={classes.companyLogo} />
        )}
      </NavigationHeader>
      <Box my={3}>
        <Stack direction='row' justifyContent='flex-end'>
          <Link to={`/${ROUTES.UPDATE_PORTFOLIO_COMPANY}/${urlParams.id}`}>
            {tCompany('update-details', { count: 2 })}
          </Link>
          {/*
              commented for JIST-1275
               <Link>{tUser('update', { count: 2 })}</Link> 
               */}
        </Stack>
      </Box>
      <Page>
        <Box className={classes.updateReportsContainer}>
          <SelectReports
            onUpdateClick={onUpdateClickHandler}
            onCancelClick={onCancelClickHandler}
            isLoading={isLoading}
            isEdit
            companyId={urlParams.id as string}
          />
        </Box>
      </Page>
    </>
  );
};

export default UpdateReports;
