import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UserState {
  _id: string;
  first_name: string;
  last_name: string;
  email: string;
  company_id: string;
  role_id: string;
}
interface AuthState {
  accessToken: string | null;
  roles: string[];
  user: UserState;
}

const initialState: AuthState = {
  accessToken: null,
  roles: [],
  user: {
    _id: '',
    first_name: '',
    last_name: '',
    email: '',
    company_id: '',
    role_id: '',
  },
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAccessToken: (state, action: PayloadAction<string | null>) => {
      state.accessToken = action.payload;
    },
    setUserRoles: (state, action: PayloadAction<string[]>) => {
      state.roles = action.payload;
    },
    setUserDetails: (state, action: PayloadAction<UserState>) => {
      state.user = action.payload;
    },
  },
});
