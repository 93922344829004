import { Link as MuiLink } from '@mui/material';
import { withStyles } from '../../../hooks';

export const AppLink = withStyles(MuiLink, theme => ({
  root: {
    display: 'inline-flex',
    verticalAlign: 'bottom',
    alignItems: 'center',
    color: theme.palette.primary.main,
    textTransform: 'capitalize',
    lineHeight: 'initial',
    fontWeight: 500,
    fontSize: '1rem',
    ':hover': {
      color: theme.palette.primary.dark,
    },
    ':active': {
      color: theme.palette.primary.main,
    },
    '&.MuiButton-root': {
      padding: 0,
      display: 'inline-flex',
      verticalAlign: 'bottom',
      alignItems: 'center',
      '&:hover': {
        backgroundColor: 'inherit',
      },
    },
    '&.MuiTypography-secondaryLink': {
      color: theme.palette.secondary.main,
      ':hover': {
        color: theme.palette.primary.dark,
      },
      ':active': {
        color: theme.palette.secondary.main,
      },
    },
    '&.Mui-disabled': {
      color: theme.palette.primary.lighter,
    },
    '& .start-icon': {
      display: 'inherit',
      marginRight: theme.spacing(1),
      marginLeft: 0,
    },
    '& .end-icon': {
      display: 'inherit',
      marginLeft: theme.spacing(1),
      marginRight: 0,
    },
    '& .MuiSvgIcon-root': {
      width: '16px',
      height: '16px',
    },
  },
}));
