import { ReactElement, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, GridContainer, GridItem, Typography } from '../../../components/shared';
import { AddUserForm, Page } from '../../../components';
import { IAddUser } from '../../../components/add-user-form/AddUserForm';
import { ROUTES, RESPONSE_TYPE, HttpStatusCode } from '../../../constants';
import { useAddUserByCompanyIdMutation } from '../../../services/users/users-service';
import { useAppSelector } from '../../../hooks';
import { getUserState } from '../../../redux/auth';
import { UseToast } from '../../../hooks/useToast';
import { ResponseError } from '../../../services';
import { NavigationHeader } from '../../../components/layout';

const AddUser = (): ReactElement => {
  const navigate = useNavigate();
  const { showToast } = UseToast();
  const { t: tUser } = useTranslation('translation', { keyPrefix: 'user' });
  const { t: tError } = useTranslation('translation', { keyPrefix: 'common.errors' });
  const { t: tApiError } = useTranslation('translation');
  const { company_id } = useAppSelector(getUserState);
  const [addUserByCompanyId, { isLoading }] = useAddUserByCompanyIdMutation();
  const [isEmailExist, setIsEmailExist] = useState(false);

  const onSubmitClickHandler = (user: IAddUser): void => {
    const { selectCompany, email, firstName, lastName, role } = user;
    const data = {
      companyId: selectCompany || company_id,
      first_name: firstName,
      last_name: lastName,
      email,
      role_id: role,
    };
    addUserByCompanyId(data)
      .unwrap()
      .then(() => {
        showToast(tUser('success.user'), tUser('success.added'), RESPONSE_TYPE.SUCCESS);
        navigate(`/${ROUTES.USERS}`, { replace: true });
      })
      .catch((addUserApiError: ResponseError) => {
        if (addUserApiError.status === HttpStatusCode.FORBIDDEN) {
          setIsEmailExist(true);
        } else {
          showToast(tError('error'), tApiError(addUserApiError.data.errorCode, ''), RESPONSE_TYPE.ERROR);
        }
      });
  };

  const onCancelClickHandler = (): void => {
    navigate(`/${ROUTES.USERS}`);
  };

  return (
    <>
      <NavigationHeader
        title={<Typography variant='h1'>{tUser('user', { count: 2 })}</Typography>}
        navigateTo={ROUTES.USERS}
      />
      <Page>
        <Box pt={{ md: 4.5, sm: 6.5, xs: 7 }}>
          <GridContainer spacing={{ xs: 2, sm: 4, md: 8 }} justifyContent='center'>
            <GridItem xs={12} sm={11} md={8}>
              <AddUserForm
                onSubmitClick={onSubmitClickHandler}
                onCancelClick={onCancelClickHandler}
                isLoading={isLoading}
                isEmailExist={isEmailExist}
              />
            </GridItem>
          </GridContainer>
        </Box>
      </Page>
    </>
  );
};

export default AddUser;
