import {
  ApolloCache,
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  NormalizedCacheObject,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import fetch from 'cross-fetch';
import store from '../redux/store';
import { API_PATH } from '../constants';

const { GRAPHQL } = API_PATH;

const httpLink = createHttpLink({
  uri: `/api/v1/${GRAPHQL}`,
});

const authLink = setContext((_contextSetter, { headers }) => {
  const { accessToken } = store.getState().auth;
  return {
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken}` : '',
    },
  };
});

export type Client = {
  link: ApolloLink | undefined;
  cache: ApolloCache<NormalizedCacheObject>;
  fetch?: typeof fetch;
};

const client: Client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

if (process.env.NODE_ENV === 'test') {
  client.fetch = fetch;
  client.link = authLink.concat(httpLink);
}

export default client;
