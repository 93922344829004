import { gql } from '@apollo/client';

export const UPSERT_HEADCOUNT = gql`
  mutation UpsertHeadcount($input: HeadcountInput!) {
    UpsertHeadcount(input: $input) {
      id
      report_id
      company_id
      report_type
      year
      month
      data {
        id
        department_id
        department_name
        employee_count
        payroll_total
        full_time
        part_time
        turnover
        contract
      }
    }
  }
`;

export const UPSERT_PUBLIC_EQUITY = gql`
  mutation UpsertPublicEquity($input: PublicEquityInput!) {
    UpsertPublicEquity(input: $input) {
      id
      report_type
      report_id
      company_id
      year
      month
      data {
        id
        company_name
        ticker
        entry_date
        revenue {
          value
        }
        enterprise_value {
          value
        }
        ebitda {
          value
          margin
        }
        tev_ltm_ebitda {
          value
        }
        tev_ntm_ebitda {
          value
        }
        leverage_ratio {
          value
        }
      }
    }
  }
`;

export const UPSERT_VALUATION = gql`
  mutation UpsertValuation($input: ValuationInput!) {
    UpsertValuation(input: $input) {
      id
      report_type
      report_id
      company_id
      year
      month
      data {
        enterprise_value_high
        enterprise_value_low
        equity_value_high
        equity_value_low
      }
    }
  }
`;

export const UPSERT_FIVE_YEAR_PLAN = gql`
  mutation UpsertFiveYearPlan($input: FiveYearPlanInput!) {
    UpsertFiveYearPlan(input: $input) {
      id
      report_type
      report_id
      company_id
      year
      month
      data {
        financial_year
        revenue
        ebitda
      }
    }
  }
`;

export const DELETE_REPORT = gql`
  mutation DeleteReportData($id: ID!, $report_type: ReportTypes!) {
    DeleteReportData(id: $id, report_type: $report_type) {
      id
      success
    }
  }
`;

export const UPSERT_THIRTEEN_WEEK_CF = gql`
  mutation UpsertThirteenWeekCf($input: ThirteenWeekCfInput!) {
    UpsertThirteenWeekCf(input: $input) {
      id
      report_type
      report_id
      company_id
      year
      concept
      gl_account
      data {
        week
        value
      }
    }
  }
`;

export const UPSERT_CAPITAL_STRUCTURE = gql`
  mutation UpsertCapitalStructure($input: CapitalStructureInput!) {
    UpsertCapitalStructure(input: $input) {
      id
      report_type
      report_id
      company_id
      year
      data {
        equity_value {
          period
          value
        }
        debt_summary {
          id
          tranche
          maturity_date
          debt_date
          rate
          third_party
          parent_company_amount
          total
        }
      }
    }
  }
`;
