import { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from '../../shared';
import { useStyles } from './style';

const PublicLayout = (): ReactElement => {
  const { classes } = useStyles();
  return (
    <Box className={classes.publicContainer} data-testid='public-layout'>
      <Outlet />
    </Box>
  );
};

export default PublicLayout;
