import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  GridContainer,
  GridItem,
  Image,
  LoadingSpinner,
  Stack,
  Typography,
} from '../../../components/shared';
import { DrawerButtonMenu, Page } from '../../../components';
import { useGetPortfolioByCompanyIdQuery } from '../../../services/companies/companies-service';
import { ImageNotFound } from '../../../assets/icons';
import { useStyles } from './style';
import { ROUTES } from '../../../constants';
import { useAppSelector, useBlobGetFiles } from '../../../hooks';
import { getUserState } from '../../../redux/auth';
import { ZcgLogo } from '../../../assets/logos';
import { NavigationHeader } from '../../../components/layout';

const Dashboard: FC = () => {
  const { classes } = useStyles();
  const { t: tCompany } = useTranslation('translation', { keyPrefix: 'company' });
  const { t: tReport } = useTranslation('translation', { keyPrefix: 'report' });
  const { company_id } = useAppSelector(getUserState);
  const { currentData, isSuccess, isLoading, isFetching } = useGetPortfolioByCompanyIdQuery(company_id, {
    skip: company_id === '',
  });

  const { getAzureBlob } = useBlobGetFiles();
  const navigate = useNavigate();
  const { UPDATE_PORTFOLIO_COMPANY, UPDATE_REPORTS, MANUAL_PORTAL_ENTRY } = ROUTES;

  const onEditMenuItemClickHandler = (route: string, id: string): void => {
    navigate(`/${route}/${id}`);
  };

  const onCardClickHandler = (id: string): void => {
    navigate(`/${ROUTES.COMPANY}/${id}/${ROUTES.OVERVIEW}`);
  };

  /* eslint-disable  consistent-return */
  const portfolioCompanyCards = (): ReactNode => {
    if (isLoading || isFetching) {
      return <LoadingSpinner />;
    }

    if (isSuccess) {
      return (
        <GridContainer spacing={{ md: 3, sm: 2, xs: 2 }}>
          {currentData?.map(value => {
            const menuItems = [
              {
                id: '1',
                text: tCompany('update-details', { count: 2 }),
                onItemClick: (): void => onEditMenuItemClickHandler(UPDATE_PORTFOLIO_COMPANY, value._id),
              },
              {
                id: '2',
                text: tReport('update', { count: 2 }),
                onItemClick: (): void => onEditMenuItemClickHandler(UPDATE_REPORTS, value._id),
              },
              {
                id: '3',
                text: tCompany('manual-portal-entry.import-data'),
                onItemClick: (): void => onEditMenuItemClickHandler(MANUAL_PORTAL_ENTRY, value._id),
              },
              //   { id: '3', text: tUser('update', { count: 2 }), onItemClick: (): void => {} }, for JIST-1123
            ];
            return (
              <GridItem
                xs={12}
                sm={4}
                md={3}
                key={`${value._id}_${value.name}`}
                data-testid='portfolio-card'
                className={classes.cardContainer}
              >
                <Box
                  className={classes.imageBoxContainer}
                  onClick={(event): void => {
                    event.stopPropagation();
                    onCardClickHandler(value._id);
                  }}
                >
                  <Box className={`${classes.imageContainer} ${!value.logo && classes.noImageWrapper}`}>
                    <Image
                      src={value.logo ? getAzureBlob(value.logo)?.url : ImageNotFound}
                      alt={value.name}
                      className={classes.imageElement}
                    />
                  </Box>
                  <Box className={classes.textContainer}>
                    <Typography variant='h2' className={classes.textElement}>
                      {value.name}
                    </Typography>
                  </Box>
                </Box>
                <Box className='card-horizontal-icon' component='span'>
                  <DrawerButtonMenu menuItems={menuItems} />
                </Box>
              </GridItem>
            );
          })}
        </GridContainer>
      );
    }
  };

  /* eslint-enable  consistent-return */

  return (
    <>
      <NavigationHeader title={<ZcgLogo />} isBackButton={false} />

      <Stack pb={2} direction='row' justifyContent='space-between' alignItems='center'>
        <Box>
          <Typography variant='h1' data-testid='overview-heading'>
            {tCompany('portfolio', { count: 2 })}
          </Typography>
        </Box>
        <Box>
          <Button
            component={Link}
            to={`/${ROUTES.ADD_PORTFOLIO_COMPANY}`}
            variant='contained'
            data-testid='add-btn'
            className={classes.addPortfolioBtn}
          >
            {tCompany('add-portfolio')}
          </Button>
        </Box>
      </Stack>
      <Page>
        <Box px={{ md: 1.5, sm: 1, xs: 1 }} py={{ md: 2.5, sm: 1, xs: 1 }}>
          {portfolioCompanyCards()}
        </Box>
      </Page>
    </>
  );
};

export default Dashboard;
