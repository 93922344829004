import { FC, memo, useCallback } from 'react';
import { Chip } from '../chip';
import { Tooltip } from '../tooltip';
import { AttachmentIcon } from '../../../assets/icons';
import { Stack } from '../stack';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { getEditorFilesState, setEditorFiles } from '../../../redux/editor';
import { ACCEPTABLE_IMAGE_TYPES } from './config';
import UseEditorFilesWithValidation from './UseEditorFilesWithValidation';

const FileUploadPlugin: FC = () => {
  const editorFiles = useAppSelector(getEditorFilesState);
  const dispatch = useAppDispatch();
  const { editorFilesValidate } = UseEditorFilesWithValidation();

  const handleFileUpload = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { files } = event.target;
      editorFilesValidate(files as FileList);
    },
    [editorFiles],
  );

  const handleDelete = (index: number): void => {
    const cloneArr = [...editorFiles];
    cloneArr.splice(index, 1);
    dispatch(setEditorFiles(cloneArr));
  };

  return (
    <>
      <input
        id='file-upload-input'
        type='file'
        className='file-upload-input-editor'
        multiple
        onChange={handleFileUpload}
        accept={ACCEPTABLE_IMAGE_TYPES.join(',')}
      />
      <Stack className='chip-list-item' direction='row' my={2} overflow='auto'>
        {editorFiles.map((file, index) => (
          <Tooltip key={`${file.name}-${index + 1}`} title={file.name} placement='top'>
            <Chip
              avatar={<AttachmentIcon />}
              label={file.name}
              onDelete={(): void => handleDelete(index)}
              className='file-chip'
              variant='filled'
            />
          </Tooltip>
        ))}
      </Stack>
    </>
  );
};

export default memo(FileUploadPlugin);
