import { ReactElement } from 'react';
import { Avatar } from '../avatar';
import { useStyle } from './style';

interface Props {
  children: string;
  variant?: 'default' | 'primary';
  size?: number;
  fontWeight?: 'normal' | 'bold';
  fontSize?: number;
  lineHeight?: number;
}

const LetterAvatar = (props: Props): ReactElement => {
  const { children, variant = 'default', size = 32, fontWeight = 'normal', fontSize = 14, lineHeight = 16 } = props;
  const { classes } = useStyle({ variant, size, fontWeight, fontSize, lineHeight });

  return <Avatar className={classes.avatarWrapper}>{children}</Avatar>;
};

export default LetterAvatar;
