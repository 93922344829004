import { makeStyles } from '../../hooks';

export const useStyles = makeStyles()({
  notContentWrapper: {
    textAlign: 'center',
    minHeight: '350px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
