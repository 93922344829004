import { ComponentType, LazyExoticComponent, Suspense } from 'react';
import { LoadingSpinner } from '../shared';

type LazyComponentType = LazyExoticComponent<ComponentType>;

const WithSuspense = <P extends object>(LazyComponent: LazyComponentType): ComponentType<P> => {
  const WrappedWithSuspense: ComponentType<P> = (props: P) => (
    <Suspense fallback={<LoadingSpinner />}>
      <LazyComponent {...props} />
    </Suspense>
  );

  return WrappedWithSuspense;
};

export default WithSuspense;
