import { ReactElement, ReactNode, memo } from 'react';
import { Modal } from '../modal';
import { Box, Button, Stack, Typography } from '../shared';
import { ACTION_IDS } from '../../constants';

export interface ButtonProps {
  id: string;
  label: string;
  variant?: 'outlined' | 'contained';
  color?: 'primary' | 'secondary' | 'error' | 'success' | 'warning';
  loading?: boolean;
  onClick?: (id: string) => void;
  // eslint-disable-next-line  @typescript-eslint/naming-convention
  'data-testid'?: string;
}

interface Props {
  icon?: string | ReactNode;
  open: boolean;
  confirmationTitle?: string;
  description?: string;
  buttons?: ButtonProps[];
  onCloseClick?: (id: string) => void;
}

const ConfirmationModal = (props: Props): ReactElement => {
  const {
    open,
    icon = '',
    confirmationTitle = '',
    description = '',
    buttons = [],
    onCloseClick = (): void => {},
  } = props;

  const { CLOSE } = ACTION_IDS;

  return (
    <Modal
      actionButtons={
        <Stack direction='row' spacing={2}>
          {buttons.length > 0 &&
            buttons.map(button => (
              <Button
                key={button.id}
                variant={button.variant || 'contained'}
                color={button.color || 'primary'}
                loading={button.loading}
                onClick={(): void => button.onClick && button.onClick(button.id)}
                data-testid={button['data-testid']}
              >
                {button.label}
              </Button>
            ))}
        </Stack>
      }
      title={icon}
      open={open}
      onClose={(): void => onCloseClick(CLOSE)}
    >
      <Box px={2}>
        <Box>
          <Typography variant='h1'>{confirmationTitle}</Typography>
        </Box>
        <Box mt={1}>
          <Typography variant='h2' color='common.black'>
            {description}
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
};

export default memo(ConfirmationModal);
