import { CircularProgress, FormControl, FormHelperText, MenuItem, SelectProps } from '@mui/material';
import { ReactElement } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { AppSelect, AppSelectLabel, useStyles } from './style';

interface Options {
  id: string;
  value: string | number;
  text: string | number;
}
interface Props {
  inputLabel: string;
  id: string;
  helperText?: string;
  options: Options[];
  width?: string;
  variant?: string;
  size?: string;
  disabled?: boolean;
  error?: boolean;
  multiple?: boolean;
  required?: boolean;
  isLoading?: boolean;
}
type AppSelectProps = Props & SelectProps;

const Select = (props: AppSelectProps): ReactElement => {
  const {
    inputLabel,
    id,
    helperText,
    options,
    width = '100%',
    variant = 'outlined',
    size = 'medium',
    disabled,
    error,
    multiple,
    required,
    isLoading,
    ...other
  } = props;

  const { classes } = useStyles();

  return (
    <FormControl variant={variant} size={size} disabled={disabled} error={error} required={required} sx={{ width }}>
      {inputLabel && <AppSelectLabel id={`${id}-label`}>{inputLabel}</AppSelectLabel>}
      <AppSelect
        labelId={`${id}-label`}
        id={id}
        label={inputLabel && inputLabel}
        multiple={multiple}
        IconComponent={KeyboardArrowDownIcon}
        {...other}
        MenuProps={{ classes: { paper: classes.selectMenu } }}
      >
        {isLoading && (
          <MenuItem value='' disableRipple className={classes.loadingSpinner}>
            <CircularProgress size={32} />
          </MenuItem>
        )}
        {options &&
          options?.map(option => (
            <MenuItem key={option.id} value={option.value} className={classes.menuItem} disableRipple>
              {option.text}
            </MenuItem>
          ))}
      </AppSelect>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default Select;
