import { makeStyles } from '../../../hooks';

export const useStyles = makeStyles()(theme => ({
  headerContainer: {
    zIndex: 1200,
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2),
    borderRadius: '10px',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      top: 64,
    },
  },
}));
