import { ComponentType, lazy } from 'react';
import { WithSuspense } from '../components';
import { ROLES, ROUTES, ROUTE_MODULE, TITLES } from '../constants';

const DashboardComponent = WithSuspense(
  lazy(() => import('../pages').then(({ Dashboard }) => ({ default: Dashboard }))),
);
const AddPortfolioCompanyComponent = WithSuspense(
  lazy(() => import('../pages').then(({ AddPortfolioCompany }) => ({ default: AddPortfolioCompany }))),
);
const UpdatePortfolioCompanyComponent = WithSuspense(
  lazy(() => import('../pages').then(({ UpdatePortfolioCompany }) => ({ default: UpdatePortfolioCompany }))),
);
const UpdateReportsComponent = WithSuspense(
  lazy(() => import('../pages').then(({ UpdateReports }) => ({ default: UpdateReports }))),
);
const CompanyReportComponent = WithSuspense(
  lazy(() => import('../pages').then(({ CompanyReport }) => ({ default: CompanyReport }))),
);
const ReportDashboardComponent = WithSuspense(
  lazy(() => import('../pages').then(({ ReportDashboard }) => ({ default: ReportDashboard }))),
);
const LoginComponent = WithSuspense(lazy(() => import('../pages').then(({ Login }) => ({ default: Login }))));
const PenTestLoginComponent = WithSuspense(
  lazy(() => import('../pages').then(({ PenTestLogin }) => ({ default: PenTestLogin }))),
);
const UsersComponent = WithSuspense(lazy(() => import('../pages').then(({ Users }) => ({ default: Users }))));
const AddUserComponent = WithSuspense(lazy(() => import('../pages').then(({ AddUser }) => ({ default: AddUser }))));

const NotFoundComponent = WithSuspense(lazy(() => import('../pages').then(({ NotFound }) => ({ default: NotFound }))));
const InternalServerErrorComponent = WithSuspense(
  lazy(() => import('../pages').then(({ InternalServerError }) => ({ default: InternalServerError }))),
);
const AccessDeniedComponent = WithSuspense(
  lazy(() => import('../pages').then(({ AccessDenied }) => ({ default: AccessDenied }))),
);
const HeadcountComponent = WithSuspense(
  lazy(() => import('../pages').then(({ Headcount }) => ({ default: Headcount }))),
);
const ManualPortalReportComponent = WithSuspense(
  lazy(() =>
    import('../pages').then(({ ManualPortalSelectReport }) => ({
      default: ManualPortalSelectReport,
    })),
  ),
);
const PublicEquitiesComponent = WithSuspense(
  lazy(() =>
    import('../pages').then(({ PublicEquities }) => ({
      default: PublicEquities,
    })),
  ),
);
const ValuationComponent = WithSuspense(
  lazy(() => import('../pages').then(({ Valuation }) => ({ default: Valuation }))),
);

const FiveYearPlanComponent = WithSuspense(
  lazy(() => import('../pages').then(({ FiveYearPlan }) => ({ default: FiveYearPlan }))),
);

const ThirteenWeekCashFlowComponent = WithSuspense(
  lazy(() => import('../pages').then(({ ThirteenWeekCashFlow }) => ({ default: ThirteenWeekCashFlow }))),
);

const CapitalStructureComponent = WithSuspense(
  lazy(() => import('../pages').then(({ CapitalStructure }) => ({ default: CapitalStructure }))),
);

export interface RouteConfig {
  component: ComponentType<object>;
  path: string;
  title?: string;
  index?: boolean;
  module?: string;
  permission?: string[];
}

const { SUPER_ADMIN, PC_ADMIN } = ROLES;

export const protectedRoutesConfig = (): RouteConfig[] => [
  {
    component: DashboardComponent,
    path: ROUTES.DASHBOARD,
    title: TITLES().DASHBOARD,
    index: true,
    module: ROUTE_MODULE.DASHBOARD_MODULE,
    permission: [SUPER_ADMIN],
  },
  {
    component: AddPortfolioCompanyComponent,
    path: ROUTES.ADD_PORTFOLIO_COMPANY,
    title: TITLES().ADD_PORTFOLIO_COMPANY,
    module: ROUTE_MODULE.DASHBOARD_MODULE,
    permission: [SUPER_ADMIN],
  },
  {
    component: UpdatePortfolioCompanyComponent,
    path: `${ROUTES.UPDATE_PORTFOLIO_COMPANY}/:id`,
    title: TITLES().UPDATE_PORTFOLIO_COMPANY,
    module: ROUTE_MODULE.DASHBOARD_MODULE,
    permission: [SUPER_ADMIN],
  },
  {
    component: UpdateReportsComponent,
    path: `${ROUTES.UPDATE_REPORTS}/:id`,
    title: TITLES().UPDATE_REPORTS,
    module: ROUTE_MODULE.DASHBOARD_MODULE,
    permission: [SUPER_ADMIN],
  },
  {
    component: ReportDashboardComponent,
    path: `${ROUTES.COMPANY}/:companyId/${ROUTES.OVERVIEW}`,
    title: TITLES().COMPANY,
    module: ROUTE_MODULE.OVERVIEW_MODULE,
    permission: [SUPER_ADMIN],
  },
  {
    component: ReportDashboardComponent,
    path: ROUTES.DASHBOARD,
    title: TITLES().DASHBOARD,
    index: true,
    module: ROUTE_MODULE.DASHBOARD_MODULE,
    permission: [PC_ADMIN],
  },
  {
    component: CompanyReportComponent,
    path: `${ROUTES.COMPANY}/:companyId/${ROUTES.REPORT}/:reportId`,
    title: TITLES().REPORT,
    module: ROUTE_MODULE.DASHBOARD_MODULE,
    permission: [SUPER_ADMIN, PC_ADMIN],
  },
  {
    component: UsersComponent,
    path: ROUTES.USERS,
    title: TITLES().USERS,
    module: ROUTE_MODULE.USER_MODULE,
    permission: [SUPER_ADMIN, PC_ADMIN],
  },
  {
    component: AddUserComponent,
    path: ROUTES.ADD_USER,
    title: TITLES().ADD_USER,
    module: ROUTE_MODULE.USER_MODULE,
    permission: [SUPER_ADMIN, PC_ADMIN],
  },
  {
    component: NotFoundComponent,
    path: ROUTES.NOT_FOUND,
    title: TITLES().NOT_FOUND,
    permission: [SUPER_ADMIN, PC_ADMIN],
  },
  {
    component: InternalServerErrorComponent,
    path: ROUTES.INTERNAL_SERVER_ERROR,
    title: TITLES().INTERNAL_SERVER_ERROR,
    permission: [SUPER_ADMIN, PC_ADMIN],
  },
  {
    component: HeadcountComponent,
    path: `${ROUTES.COMPANY}/:companyId/${ROUTES.REPORT}/:reportId/${ROUTES.HEADCOUNT}`,
    title: TITLES().HEADCOUNT,
    permission: [SUPER_ADMIN, PC_ADMIN],
    module: ROUTE_MODULE.DASHBOARD_MODULE,
  },
  {
    component: ManualPortalReportComponent,
    path: `${ROUTES.MANUAL_PORTAL_ENTRY}/:id`,
    title: TITLES().MANUAL_PORTAL_ENTRY,
    permission: [SUPER_ADMIN],
    module: ROUTE_MODULE.DASHBOARD_MODULE,
  },
  {
    component: PublicEquitiesComponent,
    path: `${ROUTES.COMPANY}/:companyId/${ROUTES.REPORT}/:reportId/${ROUTES.PUBLIC_EQUITIES}`,
    title: TITLES().PUBLIC_EQUITIES,
    permission: [SUPER_ADMIN],
    module: ROUTE_MODULE.DASHBOARD_MODULE,
  },
  {
    component: ValuationComponent,
    path: `${ROUTES.COMPANY}/:companyId/${ROUTES.REPORT}/:reportId/${ROUTES.VALUATION}`,
    title: TITLES().VALUATION,
    permission: [SUPER_ADMIN],
    module: ROUTE_MODULE.DASHBOARD_MODULE,
  },
  {
    component: FiveYearPlanComponent,
    path: `${ROUTES.COMPANY}/:companyId/${ROUTES.REPORT}/:reportId/${ROUTES.FIVE_YEAR_PLAN}`,
    title: TITLES().FIVE_YEAR_PLAN,
    permission: [SUPER_ADMIN],
    module: ROUTE_MODULE.DASHBOARD_MODULE,
  },
  {
    component: ThirteenWeekCashFlowComponent,
    path: `${ROUTES.COMPANY}/:companyId/${ROUTES.REPORT}/:reportId/${ROUTES.THIRTEEN_WEEK_CASH_FLOW}`,
    title: TITLES().THIRTEEN_WEEK_CASH_FLOW,
    permission: [SUPER_ADMIN],
    module: ROUTE_MODULE.DASHBOARD_MODULE,
  },
  {
    component: CapitalStructureComponent,
    path: `${ROUTES.COMPANY}/:companyId/${ROUTES.REPORT}/:reportId/${ROUTES.CAPITAL_STRUCTURE}`,
    title: TITLES().CAPITAL_STRUCTURE,
    permission: [SUPER_ADMIN],
    module: ROUTE_MODULE.DASHBOARD_MODULE,
  },
];

export const publicRoutesConfig = (): RouteConfig[] => [
  {
    component: LoginComponent,
    path: ROUTES.LOGIN,
    title: TITLES().LOGIN,
    index: true,
  },
  {
    component: PenTestLoginComponent,
    path: `${ROUTES.PEN_TEST_LOGIN}`,
    title: TITLES().LOGIN,
  },
  {
    component: NotFoundComponent,
    path: ROUTES.NOT_FOUND,
    title: TITLES().NOT_FOUND,
  },
  {
    component: AccessDeniedComponent,
    path: ROUTES.ACCESS_DENIED,
    title: TITLES().ACCESS_DENIED,
  },
];
