import React, { useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getRoot, $isParagraphNode } from 'lexical';
import { $isHeadingNode } from '@lexical/rich-text';

interface IsEditorEmptyPluginProps {
  onCheckEmpty: (isEmpty: boolean) => void;
}

const IsEditorEmptyPlugin: React.FC<IsEditorEmptyPluginProps> = ({ onCheckEmpty }) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    const checkIfEmpty = (): void => {
      let isEmpty = true;
      editor.getEditorState().read(() => {
        const root = $getRoot();
        if (root.getChildrenSize() !== 0) {
          isEmpty = root
            .getChildren()
            .every(node => ($isParagraphNode(node) || $isHeadingNode(node)) && node.getTextContent().trim() === '');
        }
      });
      onCheckEmpty(isEmpty);
    };

    const unregisterUpdateListener = editor.registerUpdateListener(() => {
      checkIfEmpty();
    });

    checkIfEmpty();

    return () => {
      unregisterUpdateListener();
    };
  }, [editor, onCheckEmpty]);

  return null;
};

export default IsEditorEmptyPlugin;
