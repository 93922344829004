import { ReactElement, memo } from 'react';
import { useStyles } from './style';
import { Box, Card, CheckBox, GridItem, Stack, Typography } from '../shared';
import { IReportsResponse } from '../../services/reports/reports-interface';

import { GET_REPORTS } from '../../constants';
import { DefaultReportIcon } from '../../assets/icons';

interface Props {
  data: IReportsResponse;
  onReportCardClickHandler: (cardId: string, name?: string) => void;
  cardCheckedStates: string[];
}

const ReportCards = (props: Props): ReactElement => {
  const { data, onReportCardClickHandler, cardCheckedStates } = props;
  const { classes } = useStyles();
  const { name, default_label } = data;
  const reports = GET_REPORTS();
  const reportTitle = default_label;
  const reportDescription = reports[name]?.description || '';
  const ReportIcon = reports[name]?.icon || DefaultReportIcon;

  return (
    <GridItem xs={6} sm={4} md={3} key={data._id} data-testid='report-card'>
      <Card
        className={`${classes.cardWrapper} ${cardCheckedStates.includes(data._id) ? classes.cardSelectedWrapper : ''}`}
      >
        <Box
          className={classes.cardActionArea}
          onClick={(): void => onReportCardClickHandler(data._id, name)}
          data-testid='card-action-area'
        >
          <Box>
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
              <Box className={classes.reportIcon} data-testid='report-icon'>
                {ReportIcon && <ReportIcon />}
              </Box>
              <Box>
                <CheckBox color='primary' checked={cardCheckedStates.includes(data._id)} />
              </Box>
            </Stack>
            <Box mt={2}>
              <Typography variant='h2' data-testid='report-title'>
                {reportTitle}
              </Typography>
            </Box>
            <Box mt={1}>
              <Typography variant='body1' className={classes.cardSummaryText} data-testid='report-description'>
                {reportDescription}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Card>
    </GridItem>
  );
};

export default memo(ReportCards);
