import { ReactElement } from 'react';
import { Theme, useMediaQuery } from '@mui/material';
import { Box, Image } from '../shared';
import { LoginBuildingImage, TabletLoginBanner } from '../../assets/images';
import { useStyles } from './style';

const LoginBanner = (): ReactElement => {
  const { classes } = useStyles();
  const isTabletView = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return (
    <Box className={classes.loginImageWrapper}>
      <Image src={isTabletView ? TabletLoginBanner : LoginBuildingImage} alt='image' className={classes.loginImage} />
    </Box>
  );
};

export default LoginBanner;
