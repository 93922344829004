import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { Theme, useMediaQuery } from '@mui/material';
import { Box, Button, GridContainer, GridItem, Select, Stack, TextField, Typography } from '../shared';
import { useGetUserRolesQuery } from '../../services/users/users-service';
import { cleanAndLowerCaseString, getOptions, isUserPCAdmin, isUserSuperAdmin } from '../../utils';
import { useGetPortfolioByCompanyIdQuery } from '../../services/companies/companies-service';
import { RequiredInfoStaticLabel } from '../required-info-static-label';
import { addUserPcAdminValidationSchema, addUserSuperAdminValidationSchema } from '../../validation-schemas';
import { ROLES } from '../../constants';
import { useAppSelector } from '../../hooks';
import { getUserRole, getUserState } from '../../redux/auth';

export interface IAddUser {
  role: string;
  firstName: string;
  lastName: string;
  email: string;
  selectCompany?: string;
}
interface Props {
  onSubmitClick: (user: IAddUser) => void;
  onCancelClick: () => void;
  isLoading: boolean;
  isEmailExist?: boolean;
}

const AddUserForm = (props: Props): ReactElement => {
  const { onSubmitClick, onCancelClick, isLoading, isEmailExist = false } = props;
  const { company_id, role_id } = useAppSelector(getUserState);
  const userLoggedInRole = useAppSelector(getUserRole);
  const { currentData: assignedCompanies = [] } = useGetPortfolioByCompanyIdQuery(company_id, {
    skip: isUserPCAdmin(userLoggedInRole),
  });
  const { currentData: userRoles = [] } = useGetUserRolesQuery(undefined, {
    skip: isUserPCAdmin(userLoggedInRole),
  });
  const { t: tUser } = useTranslation('translation', { keyPrefix: 'user' });
  const { t: tCommon } = useTranslation('translation', { keyPrefix: 'common' });
  const { t: tCompany } = useTranslation('translation', { keyPrefix: 'company' });
  const isResponsive = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const formik = useFormik({
    initialValues: {
      role: '',
      firstName: '',
      lastName: '',
      email: '',
      selectCompany: '',
    },
    validationSchema: isUserSuperAdmin(userLoggedInRole)
      ? addUserSuperAdminValidationSchema()
      : addUserPcAdminValidationSchema(),
    onSubmit: values => {
      let data;
      if (isUserPCAdmin(userLoggedInRole)) {
        data = {
          ...values,
          role: role_id,
        };
      } else {
        const roleId = userRoles.find(value => cleanAndLowerCaseString(value.name) === values.role);
        data = {
          ...values,
          role: roleId?._id as string,
        };
      }
      onSubmitClick(data);
    },
  });

  const { handleSubmit, touched, errors, handleChange, values, setFieldError } = formik;

  useMemo(() => {
    if (isEmailExist) {
      setFieldError('email', tUser('email-already-exists'));
    }
  }, [isEmailExist, errors.email]);

  const roleOptions = userRoles.map(value => ({
    id: value._id,
    text: value.name,
    value: cleanAndLowerCaseString(value.name),
  }));

  return (
    <GridContainer spacing={3}>
      {isUserSuperAdmin(userLoggedInRole) && (
        <>
          <GridItem xs={12}>
            <Typography variant='h2' data-testid='access-text'>
              {tUser('add', { count: 1 })}
            </Typography>
          </GridItem>
          <GridItem xs={12}>
            <Select
              inputLabel={tUser('role', { count: 1 })}
              id='roles'
              value={values.role}
              name='role'
              options={roleOptions}
              required
              error={Boolean(touched.role && errors.role)}
              helperText={(touched.role && errors.role) as string}
              onChange={handleChange}
              data-testid='form-fields'
            />
          </GridItem>
        </>
      )}
      <GridItem xs={12} pb={0.5}>
        <Typography variant='h2' data-testid='details-text'>
          {tUser('details')}
        </Typography>
      </GridItem>
      <GridItem xs={12}>
        <TextField
          label={tUser('first-name')}
          name='firstName'
          value={values.firstName}
          fullWidth
          required
          error={Boolean(touched.firstName && errors.firstName)}
          helperText={(touched.firstName && errors.firstName) as string}
          onChange={handleChange}
          data-testid='form-fields'
        />
      </GridItem>
      <GridItem xs={12}>
        <TextField
          label={tUser('last-name')}
          name='lastName'
          value={values.lastName}
          fullWidth
          required
          error={Boolean(touched.lastName && errors.lastName)}
          helperText={(touched.lastName && errors.lastName) as string}
          onChange={handleChange}
          data-testid='form-fields'
        />
      </GridItem>
      <GridItem xs={12}>
        <TextField
          label={tUser('email')}
          name='email'
          value={values.email}
          fullWidth
          required
          error={Boolean(touched.email && errors.email)}
          helperText={(touched.email && errors.email) as string}
          onChange={handleChange}
          data-testid='form-fields'
        />
      </GridItem>
      {values.role && values.role === ROLES.PC_ADMIN ? (
        <GridItem xs={12}>
          <Select
            inputLabel={tCompany('select-portfolio')}
            id='selectCompany'
            value={values.selectCompany}
            name='selectCompany'
            options={getOptions(assignedCompanies)}
            required
            error={Boolean(touched.selectCompany && errors.selectCompany)}
            helperText={(touched.selectCompany && errors.selectCompany) as string}
            onChange={handleChange}
            data-testid='select-company'
          />
        </GridItem>
      ) : null}
      {isResponsive && (
        <GridItem xs={12} py={0}>
          <RequiredInfoStaticLabel />
        </GridItem>
      )}
      <GridItem xs={12} py={2.5}>
        <Stack direction='row' justifyContent={isResponsive ? 'flex-end' : 'space-between'} alignItems='center'>
          {!isResponsive && <RequiredInfoStaticLabel />}
          <Box>
            <Stack direction='row' spacing={2}>
              <Button variant='outlined' onClick={onCancelClick} data-testid='cancel-btn'>
                {tCommon('cancel')}
              </Button>
              <Button
                variant='contained'
                onClick={(): void => {
                  handleSubmit();
                }}
                loading={isLoading}
                data-testid='add-user-btn'
              >
                {tUser('add-new')}
              </Button>
            </Stack>
          </Box>
        </Stack>
      </GridItem>
    </GridContainer>
  );
};

export default AddUserForm;
