import { Menu as MuiMenu, MenuProps } from '@mui/material';
import { ReactElement, ReactNode } from 'react';

interface Props {
  children: ReactNode;
}
type AppMenuProps = Props & MenuProps;

const Menu = (props: AppMenuProps): ReactElement => {
  const { children, ...other } = props;
  return <MuiMenu {...other}>{children}</MuiMenu>;
};

export default Menu;
