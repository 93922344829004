import { ReactElement, ReactNode } from 'react';
import { ListProps, List as MuiList } from '@mui/material';
interface Props {
  children?: ReactNode;
}
type AppListProps = Props & ListProps;
const List = (props: AppListProps): ReactElement => {
  const { children, ...other } = props;
  return <MuiList {...other}>{children}</MuiList>;
};

export default List;
