import { Divider as MuiDivider, DividerProps } from '@mui/material';
import { ReactElement } from 'react';

type AppDividerProps = DividerProps;

const Divider = (props: AppDividerProps): ReactElement => {
  const { ...other } = props;
  return <MuiDivider {...other} />;
};

export default Divider;
