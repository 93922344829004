import { ReactElement, memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import {
  Box,
  Drawer,
  IconButton,
  Image,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Scrollbar,
} from '../../shared';
import { useStyles } from './style';
import { GET_DRAWER_ITEMS, GET_REPORTS, IDrawerItems, ROUTES, ROUTE_MODULE } from '../../../constants';
import { useGetAllReportsByCompanyIdQuery } from '../../../services/reports/reports-service';
import { isUserSuperAdmin } from '../../../utils';
import { useAppSelector } from '../../../hooks';
import { getUserRole, getUserState } from '../../../redux/auth';
import { DefaultReportIcon, SearchFileIcon, SideBarCloseIcon } from '../../../assets/icons';
import { protectedRoutesConfig } from '../../../routes/routes-config';
import SideBarFooterMenu from '../sidebar/SideBarFooterMenu';

interface Props {
  isTabletSidebarOpen: boolean;
  onTabletSidebarClose: () => void;
}

const TabletSidebar = (props: Props): ReactElement => {
  const { isTabletSidebarOpen, onTabletSidebarClose } = props;
  const { classes } = useStyles();
  const { company_id } = useAppSelector(getUserState);
  const { companyId, reportId } = useParams();
  const location = useLocation();
  const currentUserRole = useAppSelector(getUserRole);
  const companyIdOnRolesBasis = isUserSuperAdmin(currentUserRole) ? companyId : company_id;
  const {
    currentData: reportsData = [],
    isSuccess,
    isError,
  } = useGetAllReportsByCompanyIdQuery(companyIdOnRolesBasis as string, {
    skip: companyIdOnRolesBasis === undefined || companyIdOnRolesBasis === '',
  });
  const { t: tCompany } = useTranslation('translation', { keyPrefix: 'company' });
  const [selectedItem, setSelectedItem] = useState<string>(ROUTES.DASHBOARD);

  const selectedReports = useMemo(() => {
    const reports = GET_REPORTS();
    const userSelectedReports = reportsData
      .filter(report => report.selected)
      .map(report => {
        const reportTitle = report.label;
        const reportIcon = reports[report.name]?.icon || DefaultReportIcon;
        return {
          text: reportTitle,
          icon: reportIcon,
          path: `/${ROUTES.COMPANY}/${companyIdOnRolesBasis}/${ROUTES.REPORT}/${report._id}`,
          module: report._id,
        };
      });
    if (companyId && isUserSuperAdmin(currentUserRole)) {
      userSelectedReports.unshift({
        text: tCompany('overview'),
        icon: SearchFileIcon,
        path: `/${ROUTES.COMPANY}/${companyIdOnRolesBasis}/${ROUTES.OVERVIEW}`,
        module: ROUTE_MODULE.OVERVIEW_MODULE,
      });
    }
    return userSelectedReports;
  }, [reportsData, companyId, currentUserRole]);

  if (selectedReports.length) {
    GET_DRAWER_ITEMS(selectedReports);
  }

  useEffect(() => {
    if (reportId) {
      setSelectedItem(reportId);
    } else {
      const currentPath = location.pathname;
      const filterRoute = protectedRoutesConfig().find(value => {
        const routePath = value.path.split('/')[0];
        const routeCurrentPath = currentPath.split('/')[1];
        return routePath === routeCurrentPath;
      });

      const selectedRoutes = filterRoute?.module || '';
      setSelectedItem(selectedRoutes);
    }
  }, [location.pathname, reportId, isSuccess, isError]);

  const handleItemClick = (path: string): void => {
    setSelectedItem(path);
  };

  const generateListItem = (item: IDrawerItems): ReactElement => {
    const listItem = (
      <Box className={classes.sidebarWrapper} key={item.text}>
        <ListItem
          disablePadding
          className={`${classes.listItem} ${item.module === selectedItem ? classes.selectedListItem : ''}`}
        >
          <RouterLink to={item.path} className={classes.routerLink}>
            <ListItemButton
              className={classes.listItemButton}
              onClick={(): void => handleItemClick(item.path)}
              disableRipple
            >
              <ListItemIcon className={classes.listItemIcon}>
                {typeof item.icon === 'string' ? <Image src={item.icon} alt={item.text} /> : <item.icon />}
              </ListItemIcon>
              <ListItemText className={classes.listItemText}>{item.text}</ListItemText>
            </ListItemButton>
          </RouterLink>
        </ListItem>
      </Box>
    );

    return listItem;
  };

  return (
    <Drawer
      anchor='left'
      open={isTabletSidebarOpen}
      className={classes.drawerWrapper}
      PaperProps={{ className: classes.paperContainer }}
      onClose={onTabletSidebarClose}
    >
      <Box className={classes.sideBarMainContent}>
        <Box
          className={classes.drawerContainer}
          role='presentation'
          onClick={onTabletSidebarClose}
          onKeyDown={onTabletSidebarClose}
        >
          <Box className={classes.closeIconWrapper}>
            <IconButton>
              <SideBarCloseIcon />
            </IconButton>
          </Box>
          <Box>
            <List>
              {GET_DRAWER_ITEMS(selectedReports).map((item, index) => {
                if (index === 1 && selectedReports.length) {
                  return (
                    <Scrollbar className={classes.sideBarScrollbar} key={item.text}>
                      {generateListItem(item)}
                      {GET_DRAWER_ITEMS(selectedReports).map((innerItem, innerIndex) => {
                        if (innerIndex >= index + 1 && innerIndex < selectedReports.length + 1) {
                          return generateListItem(innerItem);
                        }
                        return null;
                      })}
                    </Scrollbar>
                  );
                }
                if (index > 1 && index < selectedReports.length + 1) {
                  return null;
                }
                return generateListItem(item);
              })}
            </List>
          </Box>
        </Box>
        <Box className={classes.sidebarFooter}>
          <SideBarFooterMenu />
        </Box>
      </Box>
    </Drawer>
  );
};

export default memo(TabletSidebar);
