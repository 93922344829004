import { ReactElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { NavigationHeader } from '../../../components/layout';
import { Box, GridContainer, Image, LoadingSpinner, Typography } from '../../../components/shared';
import { useStyles } from './style';
import { PORTAL_BACK_SELECTION, ROUTES, ROUTE_MAPPING_AGAINST_REPORT_NAME } from '../../../constants';
import { Page } from '../../../components';
import { ReportCards } from '../../../components/report-cards';
import { useGetCompanyByIdQuery } from '../../../services/companies/companies-service';
import { setManualPortalNavigation } from '../../../utils';
import { useAppSelector, useBlobGetFiles } from '../../../hooks';
import { getReportsFetchState, getReportsLoadingState, getReportsState } from '../../../redux/reports';
import { IReportsResponse } from '../../../services/reports/reports-interface';

const ManualPortalSelectReport = (): ReactElement => {
  const { classes } = useStyles();
  const { t: tCompany } = useTranslation('translation', { keyPrefix: 'company' });
  const { t: tReport } = useTranslation('translation', { keyPrefix: 'report' });
  const navigate = useNavigate();
  const reportsData = useAppSelector(getReportsState);
  const isFetching = useAppSelector(getReportsFetchState);
  const isLoading = useAppSelector(getReportsLoadingState);
  const { id } = useParams();
  const { COMPANY, REPORT } = ROUTES;
  const { currentData: companyDetails, isError } = useGetCompanyByIdQuery(id as string, {
    skip: id === undefined,
  });

  const isReportLoading = isLoading || isFetching;

  const onReportCardClickHandler = (cardId: string, name: string | undefined): void => {
    if (name && ROUTE_MAPPING_AGAINST_REPORT_NAME[name]) {
      const reportName = ROUTE_MAPPING_AGAINST_REPORT_NAME[name];
      const reportId = cardId;
      navigate(`/${COMPANY}/${id}/${REPORT}/${reportId}/${reportName}`);
      setManualPortalNavigation(PORTAL_BACK_SELECTION.REPORT_CARD);
    }
  };
  const showReportCards = (): ReactNode =>
    (reportsData as Array<IReportsResponse>)?.map(value => {
      if (value.manual_portal_enabled) {
        return (
          <ReportCards
            key={value._id}
            data={value}
            onReportCardClickHandler={onReportCardClickHandler}
            cardCheckedStates={[]}
          />
        );
      }
      return null;
    });

  const title = `${companyDetails?.name} - ${tCompany('manual-portal-entry.title')}`;
  const { getAzureBlob } = useBlobGetFiles();
  const companyLogo = companyDetails?.logo;

  return (
    <>
      <NavigationHeader
        title={
          <Typography variant='h1'>
            {companyDetails ? title : isError ? tCompany('manual-portal-entry.title') : ''}
          </Typography>
        }
        navigateTo={ROUTES.DASHBOARD}
      >
        {companyLogo && (
          <Image src={getAzureBlob(companyLogo)?.url} alt={companyLogo} className={classes.companyLogo} />
        )}
      </NavigationHeader>
      <Page>
        <Box className={classes.updateReportsContainer}>
          <Box px={1.5} py={0.5}>
            <Typography variant='h2'>{tReport('select-reports')}</Typography>
          </Box>
          <GridContainer spacing={{ md: 3, sm: 2, xs: 2 }}>
            {isReportLoading ? <LoadingSpinner /> : showReportCards()}
          </GridContainer>
        </Box>
      </Page>
    </>
  );
};

export default ManualPortalSelectReport;
