import { ReactElement, useCallback, useEffect, useState } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router';
import { User, useAuth0 } from '@auth0/auth0-react';
import { useMediaQuery, useTheme } from '@mui/material';
import { Box, LoadingSpinner } from '../../shared';
import { useStyles } from './style';
import { useUpdateAccessTokenOnActivity, useAppDispatch, useAppSelector, useScrollToTop } from '../../../hooks';
import { getAccessToken, setAccessToken, setUserDetails, setUserRoles } from '../../../redux/auth';
import { cleanAndLowerCaseString, getRolesFromAuth0User, setAuthentication } from '../../../utils';
import { useLazyGetUserDetailsByEmailQuery } from '../../../services/users/users-service';
import { getNotFoundErrorState, getServerErrorState, setIsNotFoundError, setIsServerError } from '../../../redux/error';
import { ROUTES } from '../../../constants';
import { ErrorBoundary } from '../../../pages';
import { ArrowLeftIcon, ArrowRIghtIcon } from '../../../assets/icons';
import { Sidebar } from '../sidebar';
import { TabletSidebar } from '../tablet-sidebar';
import { TabletHeader } from '../tablet-header';
import { useLazyGetAllReportsQuery } from '../../../services/reports/reports-service';
import { setAllReports } from '../../../redux/reports';
import { IReportsResponse } from '../../../services/reports/reports-interface';

const ProtectedLayout = (): ReactElement => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [isTabletDrawerOpen, setIsTabletDrawerOpen] = useState<boolean>(false);
  const { classes } = useStyles({ isDrawerOpen });

  const { getAccessTokenSilently, logout, user } = useAuth0();
  const [getUserDetailsByEmail, { isLoading, isFetching }] = useLazyGetUserDetailsByEmailQuery();
  const [getAllReportsApi, { isLoading: isReportLoading, isFetching: isReportFetching }] = useLazyGetAllReportsQuery();
  const isServerError = useAppSelector(getServerErrorState);
  const isNotFoundError = useAppSelector(getNotFoundErrorState);
  const token = useAppSelector(getAccessToken);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const theme = useTheme();
  const isDesktopView = useMediaQuery(theme.breakpoints.up('md'));

  const dispatch = useAppDispatch();
  useScrollToTop([pathname]);

  useUpdateAccessTokenOnActivity(token as string);

  useEffect(() => {
    getAccessTokenSilently()
      .then(accessToken => {
        dispatch(setAccessToken(accessToken));
        const getRoles = getRolesFromAuth0User(user as User).map(value => cleanAndLowerCaseString(value));
        dispatch(setUserRoles(getRoles));
        if (user?.email) {
          getUserDetailsByEmail(user.email)
            .unwrap()
            .then(response => {
              setAuthentication();
              dispatch(setUserDetails(response));
            })
            .catch(async () => {
              await logout({ logoutParams: { returnTo: window.location.origin } });
            });
        }
        return getAllReportsApi(undefined);
      })
      .then(res => {
        const reports: IReportsResponse[] = res.data || [];
        dispatch(setAllReports({ reports, isFetch: isReportFetching, isLoading: isReportLoading }));
      })
      .catch(async () => {
        await logout({ logoutParams: { returnTo: window.location.origin } });
      });
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (isServerError) {
      navigate(`/${ROUTES.INTERNAL_SERVER_ERROR}`);
      dispatch(setIsServerError(false));
    } else if (isNotFoundError) {
      navigate(`/${ROUTES.NOT_FOUND}`);
      dispatch(setIsNotFoundError(false));
    }
  }, [isServerError, isNotFoundError]);

  const drawerOpenCloseHandler = (): void => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const onHamburgerClickHandler = useCallback(() => {
    setIsTabletDrawerOpen(true);
  }, [isTabletDrawerOpen]);

  const onTabletSideBarCloseClickHandler = useCallback(() => {
    setIsTabletDrawerOpen(false);
  }, [isTabletDrawerOpen]);

  if (isLoading || isFetching) {
    return <LoadingSpinner />;
  }
  return (
    <Box className={classes.mainContainer} data-testid='protected-layout'>
      {isDesktopView ? (
        <Box className={classes.sideBarWithArrowContainer} component='aside'>
          <Box className={classes.sideBarWithArrowIconWrapper}>
            <Box role='button' className={classes.ArrowIconWrapper} onClick={drawerOpenCloseHandler}>
              {isDrawerOpen ? <ArrowLeftIcon /> : <ArrowRIghtIcon />}
            </Box>
            <Sidebar drawerOpen={isDrawerOpen} />
          </Box>
        </Box>
      ) : (
        <>
          <TabletHeader onHamburgerClick={onHamburgerClickHandler} />
          <TabletSidebar
            isTabletSidebarOpen={isTabletDrawerOpen}
            onTabletSidebarClose={onTabletSideBarCloseClickHandler}
          />
        </>
      )}

      <ErrorBoundary>
        <Box component='main' className={classes.mainContent}>
          <Outlet />
        </Box>
      </ErrorBoundary>
    </Box>
  );
};

export default ProtectedLayout;
