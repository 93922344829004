import { forwardRef } from 'react';
import { ChipProps } from '@mui/material';
import { AppChip } from './style';

type AppChipProps = ChipProps;

const Chip = forwardRef<HTMLDivElement, AppChipProps>((props, ref) => {
  const { ...other } = props;
  return <AppChip {...other} ref={ref} />;
});

export default Chip;
