import { Grid2Props } from '@mui/material';
import { ReactElement, ReactNode } from 'react';
import Grid from '@mui/material/Unstable_Grid2';

interface Props {
  children: ReactNode;
}

type AppGridItemProps = Props & Grid2Props;

const GridItem = (props: AppGridItemProps): ReactElement => {
  const { children, ...others } = props;
  return <Grid {...others}>{children}</Grid>;
};

export default GridItem;
