import { ReactElement, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { SelectChangeEvent } from '@mui/material';
import { Button, GridContainer, GridItem, Select, Stack } from '../shared';
import { useStyles } from './style';
import { FILTER_PARAMS } from '../../constants';
import { useAppSelector } from '../../hooks';
import { getUserRole } from '../../redux/auth';

interface Options {
  id: string;
  value: string | number;
  text: string | number;
}
export interface FilterElement {
  id: string;
  type: 'select';
  label: string;
  data?: Options[];
  permission?: string[];
}

interface Props {
  filterElements: FilterElement[];
}

const Filter = (props: Props): ReactElement => {
  const { filterElements } = props;
  const { PAGE } = FILTER_PARAMS;
  const { classes } = useStyles();
  const { t: tCommon } = useTranslation('translation', { keyPrefix: 'common' });
  const [filterOptions, setFilterOptions] = useState<Record<string, unknown>>({});
  const [searchParams, setSearchParams] = useSearchParams();
  const getPageParams = searchParams.get(PAGE);
  const currentUserRole = useAppSelector(getUserRole);

  const handleInputChange = (name: string, value: unknown): void => {
    setFilterOptions({ ...filterOptions, [name]: value });
  };

  useEffect(() => {
    const tempObj: { [index: string]: string } = {};
    for (let index = 0; index < filterElements.length; index++) {
      const element = filterElements[index];
      if (element.data && element.data?.length > 0) tempObj[element.id] = searchParams.get(element.id) || '';
      else tempObj[element.id] = '';
    }

    setFilterOptions(tempObj);
  }, [filterElements]);

  const onFilterClickHandler = (): void => {
    Object.entries(filterOptions).forEach(([key, value]) => {
      if (value) {
        searchParams.set(key, value as string);
      }
    });

    if (getPageParams) {
      searchParams.delete(PAGE);
    }
    setSearchParams(searchParams);
  };

  const onClearAllClickHandler = (): void => {
    setFilterOptions({});
    searchParams.delete(PAGE);
    Object.keys(filterOptions).forEach(key => {
      searchParams.delete(key);
    });
    setSearchParams(searchParams);
  };

  return (
    <GridContainer
      className={classes.filterContainer}
      columnSpacing={2}
      rowSpacing={{ md: 4, sm: 5, xs: 6 }}
      data-testid='filter-main-container'
      alignItems='center'
    >
      {filterElements?.map(
        element =>
          element.permission?.some(value => currentUserRole.includes(value)) && (
            <GridItem xs={12} md key={element.id} data-testid='filter-container'>
              {element.type === 'select' && (
                <Select
                  inputLabel={element.label}
                  id={element.id}
                  defaultValue={(filterOptions[element.id] as string) || ''}
                  value={(filterOptions[element.id] as string) || ''}
                  name={element.id}
                  options={element.data || []}
                  onChange={(event: SelectChangeEvent<unknown>): void =>
                    handleInputChange(element.id, event.target.value)
                  }
                />
              )}
            </GridItem>
          ),
      )}
      <GridItem xs={12} md>
        <Stack direction='row' spacing={2} justifyContent='end'>
          <Button variant='outlined' onClick={onClearAllClickHandler} data-testid='clear-all-btn'>
            {tCommon('clear-all')}
          </Button>
          <Button variant='contained' onClick={onFilterClickHandler} data-testid='apply-filter-btn'>
            {tCommon('apply-filter')}
          </Button>
        </Stack>
      </GridItem>
    </GridContainer>
  );
};

export default memo(Filter);
