import { StepIconProps } from '@mui/material';
import { useStyles } from './style';
import { CheckCircleOutlineIcon, CircleIcon, RadioButtonCheckedIcon } from '../../assets/icons';

const CustomStepIcon: React.FC<StepIconProps> = (props: StepIconProps) => {
  const { active, completed } = props;
  const { classes } = useStyles();

  if (completed) {
    return <CheckCircleOutlineIcon className={classes.completedIcon} />;
  }
  return (
    <div>
      {active ? (
        <RadioButtonCheckedIcon className={classes.activeIcon} />
      ) : (
        <CircleIcon className={classes.disabledIcon} />
      )}
    </div>
  );
};

export default CustomStepIcon;
