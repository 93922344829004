import { ReactElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { useStyles } from './style';
import { StickyHeader } from '../sticky-header';
import { Box, Link, Stack } from '../../shared';
import { ChevronLeft } from '../../../assets/icons';

interface Props {
  title: ReactNode;
  navigateTo?: string;
  children?: ReactNode;
  isBackButton?: boolean;
}

const NavigationHeader = (props: Props): ReactElement => {
  const { classes } = useStyles();
  const { title, navigateTo = '', children, isBackButton = true } = props;
  const { t: tCommon } = useTranslation('translation', { keyPrefix: 'common' });

  return (
    <StickyHeader>
      <Stack direction='row' alignItems='center' justifyContent='space-between' className={classes.navigationWrapper}>
        {isBackButton ? (
          <Box>
            <Link to={`/${navigateTo}`} className={classes.link} startIcon={<ChevronLeft />}>
              {tCommon('back')}
            </Link>
          </Box>
        ) : (
          <Box py={2} />
        )}
        <Box className={classes.titleWrapper}>{title}</Box>
        {children && <Box>{children}</Box>}
      </Stack>
    </StickyHeader>
  );
};

export default NavigationHeader;
