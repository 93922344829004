import { ReactElement, ReactNode } from 'react';
import { ListItemButtonProps, ListItemButton as MuiListItemButton } from '@mui/material';

interface Props {
  children: ReactNode;
}

type AppListItemButtonProps = Props & ListItemButtonProps;

const ListItemButton = (props: AppListItemButtonProps): ReactElement => {
  const { children, ...other } = props;

  return <MuiListItemButton {...other}>{children}</MuiListItemButton>;
};

export default ListItemButton;
