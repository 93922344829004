import { Avatar as MuiAvatar, AvatarProps } from '@mui/material';
import { ReactElement, ReactNode } from 'react';

interface Props {
  children?: ReactNode;
}

type AppAvatarProps = Props & AvatarProps;

const Avatar = (props: AppAvatarProps): ReactElement => {
  const { children, ...other } = props;
  return <MuiAvatar {...other}>{children}</MuiAvatar>;
};

export default Avatar;
