import { ReactElement, ReactNode, memo } from 'react';
import { DialogActionsProps, DialogActions as MuiDialogActions } from '@mui/material';

interface Props {
  children: ReactNode;
}

type AppDialogActionsProps = Props & DialogActionsProps;

const DialogActions = (props: AppDialogActionsProps): ReactElement => {
  const { children, ...other } = props;
  return <MuiDialogActions {...other}>{children}</MuiDialogActions>;
};

export default memo(DialogActions);
