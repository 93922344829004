import { appApiBuilder } from '..';
import { API_PATH, API_TAGS, FILTER_PARAMS, METHODS } from '../../constants';
import { AddCommentResponse, CommentDeleteResponse, GetCommentsResponse, MentionUsers } from './comment-interface';

const { COMPANIES, DASHBOARD, COMMENTS, USERS, MENTION, REPORTS } = API_PATH;
const { COMMENT: COMMENT_TAG } = API_TAGS;
const { SEARCH } = FILTER_PARAMS;

export const extendedApi = appApiBuilder.injectEndpoints({
  endpoints: builder => ({
    addComment: builder.mutation<AddCommentResponse, { companyId: string; data: FormData; reportId: string }>({
      query: ({ companyId, reportId, data }) => {
        const FINAL_URL = reportId ? `${REPORTS}/${reportId}` : `${DASHBOARD}`;
        return {
          url: `${COMPANIES}/${companyId}/${FINAL_URL}/${COMMENTS}`,
          method: METHODS.POST,
          body: data,
        };
      },
      invalidatesTags: [COMMENT_TAG],
    }),
    getComments: builder.query<GetCommentsResponse, { companyId: string; reportId?: string; filter?: string }>({
      query: ({ companyId, reportId, filter = '' }) => {
        const FINAL_URL = reportId ? `${REPORTS}/${reportId}` : `${DASHBOARD}`;
        const FILTER = filter ? `?${filter}` : '';
        return `${COMPANIES}/${companyId}/${FINAL_URL}/${COMMENTS}${FILTER}`;
      },
      providesTags: [COMMENT_TAG],
    }),
    getMentionUsers: builder.query<MentionUsers[], { companyId: string; search: string }>({
      query: ({ companyId, search }) => {
        const query = search ? `?&${SEARCH}=${search}` : '';
        return `${COMPANIES}/${companyId}/${USERS}/${MENTION}${query}`;
      },
    }),
    deleteComment: builder.mutation<CommentDeleteResponse, { companyId: string; reportId: string; commentId: string }>({
      query: ({ companyId, reportId, commentId }) => {
        const FINAL_URL = reportId ? `${REPORTS}/${reportId}` : `${DASHBOARD}`;
        return {
          url: `${COMPANIES}/${companyId}/${FINAL_URL}/${COMMENTS}/${commentId}`,
          method: METHODS.DELETE,
        };
      },
      invalidatesTags: [COMMENT_TAG],
    }),
    updateComment: builder.mutation<
      AddCommentResponse,
      { companyId: string; reportId: string; commentId: string; data: FormData }
    >({
      query: ({ companyId, reportId, commentId, data }) => {
        const FINAL_URL = reportId ? `${REPORTS}/${reportId}` : `${DASHBOARD}`;
        return {
          url: `${COMPANIES}/${companyId}/${FINAL_URL}/${COMMENTS}/${commentId}`,
          method: METHODS.PUT,
          body: data,
        };
      },
      invalidatesTags: [COMMENT_TAG],
    }),
  }),
});

export const {
  useAddCommentMutation,
  useGetMentionUsersQuery,
  useGetCommentsQuery,
  useDeleteCommentMutation,
  useUpdateCommentMutation,
} = extendedApi;
