import React from 'react';
import ReactDOM from 'react-dom/client';
import { ApolloClient, ApolloProvider } from '@apollo/client';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import './rich-text-editor.css';
import reportWebVitals from './reportWebVitals';
import store from './redux/store';
import ThemeProvider from './theme';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900.css';
import i18next from './i18n/config';
import Auth0ProviderWithNavigate from './Auth0ProviderWithNavigate';
import App from './App';
import { Toaster } from './components/shared';
import { client } from './graphql';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <Auth0ProviderWithNavigate>
      <I18nextProvider i18n={i18next} defaultNS='translation'>
        <Provider store={store}>
          <ApolloProvider client={client as ApolloClient<unknown>}>
            <ThemeProvider>
              <BrowserRouter>
                <App />
                <Toaster />
              </BrowserRouter>
            </ThemeProvider>
          </ApolloProvider>
        </Provider>
      </I18nextProvider>
    </Auth0ProviderWithNavigate>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
