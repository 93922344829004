import { ReactElement, memo, useCallback, useEffect, useRef, useState } from 'react';
import { BeautifulMentionsItem, BeautifulMentionsMenuItem, ZeroWidthPlugin } from 'lexical-beautiful-mentions';
import { useTranslation } from 'react-i18next';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
import { editorConfig } from './config';
import ToolbarPlugin from './ToolbarPlugin';
import { Box } from '../box';
import HeadingPlugin from './HeadingPlugin';
import FileUploadPlugin from './FileUploadPlugin';
import MentionPlugin from './MentionPlugin';
import { TextField } from '../text-field';
import SubmitAndClearPlugin from './SubmitAndClearPlugin';
import DragDropPaste from './DragDropPastePlugin';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { getEditorFilesState, setEditorFiles } from '../../../redux/editor';
import { extractMentionFromHtml } from '../../../utils';
import GetDataPlugin from './GetDataPlugin';
import CharacterLimitPlugin from './CharacterLimitPlugin';
import IsEditorEmptyPlugin from './IsEditorEmptyPlugin';

interface Props {
  onSaveClick: (htmlString: string, mentions: BeautifulMentionsMenuItem[], files: File[], isEdit: boolean) => void;
  onMentionQuery?: (trigger: string, queryString: string | undefined | null) => Promise<BeautifulMentionsItem[]>;
  isSuccess?: boolean;
  isLoading?: boolean;
  htmlStringData?: string;
  isEditorEditable?: boolean;
  onCancelClick?: () => void;
  mentionData?: BeautifulMentionsMenuItem[];
  filesData?: File[];
}

const RichTextEditor = (props: Props): ReactElement => {
  const {
    onSaveClick,
    onMentionQuery,
    isSuccess,
    isLoading,
    htmlStringData,
    isEditorEditable = false,
    onCancelClick = (): void => {},
    mentionData: getMentionData = [],
    filesData = [],
  } = props;
  const { t: tComment } = useTranslation('translation', { keyPrefix: 'comment' });
  const { t: tEditor } = useTranslation('translation', { keyPrefix: 'editor' });
  const editorRef = useRef<HTMLDivElement>(null);
  const [isCommentBox, setIsCommentBox] = useState(true);
  const [mentionData, setMentionData] = useState<BeautifulMentionsMenuItem[]>([]);
  const [isEditorValid, setIsEditorValid] = useState<boolean>(false);
  const [isEditorEmpty, setIsEditorEmpty] = useState<boolean>(false);
  const editorFiles = useAppSelector(getEditorFilesState);
  const dispatch = useAppDispatch();

  const onCancelClickHandler = (): void => {
    setIsCommentBox(true);
    dispatch(setEditorFiles([]));
    onCancelClick();
  };

  const onSaveClickHandler = useCallback(
    (htmlString: string): void => {
      const matchingMentions = extractMentionFromHtml(htmlString, mentionData);
      onSaveClick(htmlString, matchingMentions, editorFiles, isEditorEditable);
    },
    [mentionData, editorFiles],
  );

  const onMentionChangeHandler = (item: BeautifulMentionsMenuItem): void => {
    setMentionData(prev => {
      const exists = prev.some(existingItem => existingItem?.data?.id === item?.data?.id);
      if (!exists) {
        return [...prev, item];
      }
      return prev;
    });
  };

  useEffect(() => {
    if (isSuccess) {
      setIsCommentBox(true);
      dispatch(setEditorFiles([]));
      setMentionData([]);
    }
  }, [isSuccess]);

  const setEditStates = useCallback(() => {
    if (isEditorEditable) {
      setIsCommentBox(false);
      setMentionData(getMentionData);
      dispatch(setEditorFiles(filesData));
      if (!isCommentBox) {
        setTimeout(() => {
          editorRef.current?.scrollIntoView({ behavior: 'smooth' });
        }, 100);
      }
    }
  }, [isEditorEditable, isCommentBox, filesData]);

  useEffect(() => {
    setEditStates();
  }, [setEditStates]);

  if (isCommentBox)
    return (
      <TextField
        placeholder={tComment('add-a-comment')}
        fullWidth
        onFocus={(): void => {
          setIsCommentBox(false);
          setTimeout(() => {
            editorRef.current?.scrollIntoView({ behavior: 'smooth' });
          }, 100);
          dispatch(setEditorFiles([]));
        }}
      />
    );

  const editorValidHandler = (isValid: boolean): void => {
    setIsEditorValid(isValid);
  };

  const onCheckIsEditorEmpty = (isEmpty: boolean): void => {
    setIsEditorEmpty(isEmpty);
  };

  return (
    <LexicalComposer initialConfig={editorConfig}>
      <Box className={`editor-container ${isEditorValid ? 'error' : ''}`}>
        <ToolbarPlugin isEditorEmpty={isEditorEmpty} />
        <div className='editor-inner' ref={editorRef}>
          <RichTextPlugin
            contentEditable={<ContentEditable className='editor-input' />}
            placeholder={<Box className='editor-placeholder'>{tEditor('type-@-to-mention')}</Box>}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <HistoryPlugin />
          <AutoFocusPlugin defaultSelection='rootEnd' />
          <IsEditorEmptyPlugin onCheckEmpty={onCheckIsEditorEmpty} />
          <HeadingPlugin />
          <ListPlugin />
          <ZeroWidthPlugin />
          <MentionPlugin
            queryMentions={
              onMentionQuery as (
                trigger: string,
                queryString: string | undefined | null,
              ) => Promise<BeautifulMentionsItem[]>
            }
            onMentionMenuChange={onMentionChangeHandler}
          />
          <FileUploadPlugin />
          <ClearEditorPlugin />
          <DragDropPaste />
          <GetDataPlugin htmlString={htmlStringData || ''} />
        </div>
      </Box>
      <CharacterLimitPlugin isEditorValid={editorValidHandler} />
      <SubmitAndClearPlugin
        onCancelClick={onCancelClickHandler}
        onSaveClick={onSaveClickHandler}
        loading={isLoading}
        isEdit={isEditorEditable}
        disabled={isEditorValid || isEditorEmpty}
      />
    </LexicalComposer>
  );
};

export default memo(RichTextEditor);
