import { object, array, string } from 'yup';
import { EMAIL_VALIDATION, NAME_VALIDATION, STRING_VALIDATION } from './common-schema';
import { ROLES } from '../constants';
import { AddUserPcAdminValidationSchema, AddUserSuperAdminValidationSchema, UserValidationSchema } from './types';

export const userValidationSchema = (): UserValidationSchema =>
  object().shape({
    users: array().of(
      object().shape({
        firstName: NAME_VALIDATION().REQUIRED,
        lastName: NAME_VALIDATION().REQUIRED,
        email: EMAIL_VALIDATION().REQUIRED,
      }),
    ),
  });

export const addUserSuperAdminValidationSchema = (): AddUserSuperAdminValidationSchema =>
  object().shape({
    role: STRING_VALIDATION().REQUIRED,
    firstName: NAME_VALIDATION().REQUIRED,
    lastName: NAME_VALIDATION().REQUIRED,
    email: EMAIL_VALIDATION().REQUIRED,
    selectCompany: string().when('role', {
      is: (role: string) => role === ROLES.PC_ADMIN,
      then: () => STRING_VALIDATION().REQUIRED,
      otherwise: () => STRING_VALIDATION().NOT_REQUIRED,
    }),
  });

export const addUserPcAdminValidationSchema = (): AddUserPcAdminValidationSchema =>
  object().shape({
    firstName: NAME_VALIDATION().REQUIRED,
    lastName: NAME_VALIDATION().REQUIRED,
    email: EMAIL_VALIDATION().REQUIRED,
  });
