import { ContainerProps, Container as MuiContainer } from '@mui/material';
import { ReactElement, ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

type AppContainerProps = Props & ContainerProps;

const Container = (props: AppContainerProps): ReactElement => {
  const { children, ...other } = props;
  return <MuiContainer {...other}>{children}</MuiContainer>;
};

export default Container;
