import { ListItemIcon as MuiListItemIcon, ListItemIconProps } from '@mui/material';
import { ReactElement, ReactNode } from 'react';

interface Props {
  children: ReactNode;
}
type AppListItemIconProps = Props & ListItemIconProps;
const ListItemIcon = (props: AppListItemIconProps): ReactElement => {
  const { children, ...other } = props;
  return <MuiListItemIcon {...other}>{children}</MuiListItemIcon>;
};

export default ListItemIcon;
