import { appApiBuilder } from '..';

import { API_PATH, API_TAGS, DEFAULT_PAGE_LIMIT, METHODS } from '../../constants';
import {
  IAddUserReqBody,
  IAddUserReqBodyByCompanyId,
  IAddUserResponse,
  IAddUserResponseByCompanyId,
  IToggleUserStatusReqBody,
  IUserDetailsByEmail,
  IUserResendInviteReqBody,
  IUserRolesResponse,
  IUsersResponse,
  IUsersSuccessResponse,
  IValidateUserResponse,
} from './users-interface';
import i18next from '../../i18n/config';

const { USERS_BULK, COMPANIES, USERS, ROLES, RESEND_INVITE, EMAIL, SET_STATUS } = API_PATH;
const { USERS: USERS_TAG } = API_TAGS;

const tUser = i18next.getFixedT(null, null, 'user');

export const extendedApi = appApiBuilder.injectEndpoints({
  endpoints: builder => ({
    getUsers: builder.query<IUsersResponse, string>({
      query: args => {
        const query = args ? `&${args}` : '';
        return `${USERS}?limit=${DEFAULT_PAGE_LIMIT}${query}`;
      },
      transformResponse(response: IUsersResponse) {
        const userWithFullNameArr = response.users.map(value => ({
          ...value,
          name: `${value.first_name} ${value.last_name}`,
          status: value.active ? tUser('active') : tUser('inactive'),
        }));
        return { ...response, users: userWithFullNameArr };
      },
      providesTags: result => {
        if (result) {
          return [...result.users.map(({ email }) => ({ type: USERS_TAG, id: email }))];
        }
        return [{ type: USERS_TAG }];
      },
    }),
    getUserDetailsByEmail: builder.query<IUserDetailsByEmail, string>({
      query: email => `${USERS}/${email}`,
    }),
    AddPortfolioCompanyUsers: builder.mutation<IAddUserResponse[], IAddUserReqBody>({
      query: ({ companyId, data }) => ({
        url: `${COMPANIES}/${companyId}/${USERS_BULK}`,
        method: METHODS.POST,
        body: data,
      }),
    }),
    getUserRoles: builder.query<IUserRolesResponse[], void>({
      query: () => ROLES,
    }),
    AddUserByCompanyId: builder.mutation<IAddUserResponseByCompanyId, IAddUserReqBodyByCompanyId>({
      query: ({ companyId, ...data }) => ({
        url: `${COMPANIES}/${companyId}/${USERS}`,
        method: METHODS.POST,
        body: data,
      }),
    }),
    getUsersByCompanyId: builder.query<IUsersResponse, { companyId: string; args: string }>({
      query: ({ companyId, args }) => {
        const query = args ? `&${args}` : '';
        return `${COMPANIES}/${companyId}/${USERS}?limit=${DEFAULT_PAGE_LIMIT}${query}`;
      },
      transformResponse(response: IUsersResponse) {
        const userWithFullNameArr = response.users.map(value => ({
          ...value,
          name: `${value.first_name} ${value.last_name}`,
          status: value.active ? tUser('active') : tUser('inactive'),
        }));
        return { ...response, users: userWithFullNameArr };
      },
    }),
    resendInviteUsers: builder.mutation<IUsersSuccessResponse, IUserResendInviteReqBody>({
      query: ({ companyId, userId }) => ({
        url: `${COMPANIES}/${companyId}/${USERS}/${userId}/${RESEND_INVITE}`,
        method: METHODS.POST,
      }),
    }),
    validateUserByEmail: builder.query<IValidateUserResponse, string>({
      query: email => `${USERS}/${EMAIL}/${email}`,
    }),
    toggleUserStatus: builder.mutation<IUsersSuccessResponse, IToggleUserStatusReqBody>({
      query: data => ({
        url: `${USERS}/${SET_STATUS}`,
        method: METHODS.POST,
        body: data,
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: USERS_TAG, id: arg.email }],
    }),
  }),
});

export const {
  useAddPortfolioCompanyUsersMutation,
  useGetUsersQuery,
  useGetUserRolesQuery,
  useLazyGetUserDetailsByEmailQuery,
  useAddUserByCompanyIdMutation,
  useGetUsersByCompanyIdQuery,
  useResendInviteUsersMutation,
  useLazyValidateUserByEmailQuery,
  useToggleUserStatusMutation,
} = extendedApi;
