import { StepLabel as MuiStepLabel, StepLabelProps } from '@mui/material';
import { ReactElement, ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

type AppStepLabelProps = Props & StepLabelProps;

const StepLabel = (props: AppStepLabelProps): ReactElement => {
  const { children, ...other } = props;
  return <MuiStepLabel {...other}>{children}</MuiStepLabel>;
};

export default StepLabel;
