import { ReactElement, forwardRef, memo } from 'react';
import {
  BeautifulMentionsItem,
  BeautifulMentionsMenuItem,
  BeautifulMentionsMenuItemProps,
  BeautifulMentionsMenuProps,
  BeautifulMentionsPlugin,
  BeautifulMentionsTheme,
} from 'lexical-beautiful-mentions';
import { useTranslation } from 'react-i18next';
import { MENTION_MENU_ITEM_LIMIT, MENTION_TRIGGERS } from './config';
import { List } from '../list';
import { Box } from '../box';
import { ListItem } from '../list-item';

export const beautifulMentionsTheme: BeautifulMentionsTheme = {
  '@': 'text-editor-mention-label',
};

const Menu = ({ loading, ...props }: BeautifulMentionsMenuProps): ReactElement => {
  const { t: tCommon } = useTranslation('translation', { keyPrefix: 'common' });
  if (loading) {
    return <Box className='mention-result-loading'>{tCommon('loading')}</Box>;
  }
  return <List className='text-editor-mention-menu' {...props} />;
};

const EmptyMentionResult = (): ReactElement => {
  const { t: tEditor } = useTranslation('translation', { keyPrefix: 'editor' });
  return <Box className='mention-result-not-found'>{tEditor('no-results-found')}</Box>;
};

const MenuItem = forwardRef<HTMLLIElement, BeautifulMentionsMenuItemProps>(({ selected, item, ...props }, ref) => (
  <ListItem
    className={`text-editor-mention-list  ${
      selected ? 'text-editor-mention-list-selected' : 'text-editor-mention-list-default'
    }`}
    {...props}
    ref={ref}
  />
));

interface Props {
  onMentionMenuChange: (item: BeautifulMentionsMenuItem) => void;
  queryMentions: (trigger: string, queryString: string | undefined | null) => Promise<BeautifulMentionsItem[]>;
}

const MentionPlugin = (props: Props): ReactElement => {
  const { onMentionMenuChange, queryMentions } = props;

  const onMenuItemSelectHandler = (item: BeautifulMentionsMenuItem): void => {
    onMentionMenuChange(item);
  };

  return (
    <BeautifulMentionsPlugin
      menuComponent={Menu}
      menuItemComponent={MenuItem}
      insertOnBlur={false}
      emptyComponent={EmptyMentionResult}
      showMentionsOnDelete
      allowSpaces
      menuItemLimit={MENTION_MENU_ITEM_LIMIT}
      onMenuItemSelect={onMenuItemSelectHandler}
      triggers={MENTION_TRIGGERS}
      onSearch={queryMentions}
    />
  );
};

export default memo(MentionPlugin);
