import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { ListItemNode, ListNode } from '@lexical/list';
import { BeautifulMentionNode, ZeroWidthNode } from 'lexical-beautiful-mentions';
import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { beautifulMentionsTheme } from './MentionPlugin';
import Theme from './classname-theme';

export const editorConfig = {
  namespace: 'RichTextEditor',
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    BeautifulMentionNode,
    QuoteNode,
    CodeNode,
    CodeHighlightNode,
    ZeroWidthNode,
  ],
  onError(error: Error): void {
    throw error;
  },
  theme: { ...Theme, beautifulMentions: beautifulMentionsTheme },
};

export const MENTION_TRIGGERS = ['@'];

export const MENTION_MENU_ITEM_LIMIT = 10;

export const ACCEPTABLE_IMAGE_TYPES = ['image/png'];

export const CHARACTER_LIMIT = 2500;

export const MAX_FILE_SIZE_IN_MB = 1;

export const MAX_FILE_LIMIT = 3;
