import { makeStyles } from '../../../hooks';

export const useStyles = makeStyles()(theme => ({
  imageBoxContainer: {
    border: `1px solid ${theme.palette.grey[300]}`,
    padding: '16px',
    minHeight: '226px',
    borderRadius: '10px',
    [theme.breakpoints.down('md')]: {
      minHeight: '164px',
      padding: '12px',
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: '226px',
      padding: '16px',
    },
  },
  imageContainer: {
    height: '138px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      height: '100px',
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: '200px',
      marginBottom: theme.spacing(3),
    },
  },
  noImageWrapper: {
    backgroundColor: theme.palette.grey[200],
    borderRadius: '10px',
  },
  imageElement: {
    maxHeight: '100%',
    maxWidth: '100%',
    [theme.breakpoints.down('md')]: {
      height: '100%',
      width: '100%',
      objectFit: 'contain',
    },
  },
  textContainer: {
    textAlign: 'center',
  },
  textElement: {
    textTransform: 'capitalize',
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
  },
  addPortfolioBtn: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  cardContainer: {
    cursor: 'pointer',
    position: 'relative',
    '& .card-horizontal-icon': {
      position: 'absolute',
      top: '20px',
      right: '20px',
      '& button#position-menu-button': {
        opacity: 0,
      },
      '& button#position-menu-button[aria-expanded="true"]': {
        opacity: 1,
      },
      [theme.breakpoints.down('md')]: {
        top: '16px',
        right: '16px',
      },
    },
    ':hover .card-horizontal-icon': {
      '& button#position-menu-button': {
        opacity: 1,
      },
    },
    [theme.breakpoints.down('md')]: {
      cursor: 'auto',
      '& .card-horizontal-icon': {
        '& button#position-menu-button': {
          opacity: 1,
        },
      },
    },
  },
}));
