import { makeStyles } from '../../../hooks';

interface StyleProps {
  variant?: 'default' | 'primary';
  size?: number;
  fontWeight?: 'normal' | 'bold';
  fontSize?: number;
  lineHeight?: number;
}

export const useStyle = makeStyles<StyleProps>()((theme, { variant, size, fontWeight, fontSize, lineHeight }) => ({
  avatarWrapper: {
    width: size,
    height: size,
    backgroundColor: variant === 'primary' ? theme.palette.primary.dark : theme.palette.background.lightblue,
    fontSize: `${fontSize}px`,
    lineHeight: `${lineHeight}px`,
    color: variant === 'primary' ? theme.palette.common.white : theme.palette.common.black,
    fontWeight: fontWeight === 'normal' ? 400 : 600,
    textTransform: 'uppercase',
  },
}));
