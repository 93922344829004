import { ReactElement, memo, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BeautifulMentionsItem, BeautifulMentionsMenuItem } from 'lexical-beautiful-mentions';
import { useSearchParams } from 'react-router-dom';
import {
  Box,
  Chip,
  Divider,
  IconButton,
  LetterAvatar,
  LoadingSpinner,
  RichTextEditor,
  Stack,
  Tooltip,
  Typography,
} from '../shared';
import { useStyles } from './style';
import {
  AttachmentIcon,
  CancelCircleIcon,
  CheckCircleOutlineIcon,
  CloseIcon,
  FilterAltOutlinedIcon,
  NoCommentIcon,
  WarningTriangleOutlinedIcon,
} from '../../assets/icons';
import { useAppSelector } from '../../hooks';
import { getUserState } from '../../redux/auth';
import {
  convertBlobUrlToFile,
  extractMentionData,
  getFormattedDate,
  getMentionOptions,
  getNameInitials,
} from '../../utils';
import {
  useAddCommentMutation,
  useDeleteCommentMutation,
  useGetMentionUsersQuery,
  useUpdateCommentMutation,
} from '../../services/comment/comment-service';
import { UseToast } from '../../hooks/useToast';
import { ACTION_IDS, FILTER_PARAMS, FORM_DATA, RESPONSE_TYPE } from '../../constants';
import { ResponseError } from '../../services';
import { CommentData } from '../../services/comment/comment-interface';
import { HtmlRenderer } from '../html-renderer';
import { DrawerButtonMenu } from '../drawer-button-menu';
import { ConfirmationModal } from '../confirmation-modal';
import { NoContentFound } from '../not-content-found';

interface Props {
  onChatBoxClose: () => void;
  companyId: string;
  reportId?: string;
  commentData: CommentData[];
  isLoading?: boolean;
}

interface UsersMentionData {
  [key: string]: unknown;
  id: string;
  name: string;
}

const ChatBox = (props: Props): ReactElement => {
  const { onChatBoxClose, companyId, commentData = [], reportId = '', isLoading } = props;
  const { classes } = useStyles();
  const { showToast } = UseToast();
  const { t: tCommon } = useTranslation('translation', { keyPrefix: 'common' });
  const { t: tError } = useTranslation('translation', { keyPrefix: 'common.errors' });
  const { t: tComment } = useTranslation('translation', { keyPrefix: 'comment' });
  const { t: tCommentFilter } = useTranslation('translation', { keyPrefix: 'comment.filter' });
  const { t: tApiError } = useTranslation('translation');
  const { _id: currentUserId, first_name, last_name } = useAppSelector(getUserState);
  const [queryUsers, setQueryUsers] = useState('');
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [isEditRichtext, setIsEditRichtext] = useState(false);
  const [filesData, setFilesData] = useState<File[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const { FILTER } = FILTER_PARAMS;
  const filterParams = searchParams.get(FILTER);

  const initialCommentData = {
    id: '',
    attachments: [],
    author: { first_name: '', last_name: '', _id: '' },
    comment: '',
    company_id: '',
    created_at: '',
    is_edited: false,
    tagged_users: [],
    user_id: '',
  };
  const [commentDataById, setCommentDataById] = useState<CommentData>(initialCommentData);

  const {
    currentData: mentionUsersData = [],
    isLoading: mentionUsersLoading,
    isFetching: mentionUsersFetching,
  } = useGetMentionUsersQuery({ companyId, search: queryUsers }, { skip: !companyId });

  const [AddCommentApi, { isLoading: addCommentLoading, isSuccess: AddCommentSuccess, reset: resetAddApi }] =
    useAddCommentMutation();
  const [DeleteCommentApi, { isLoading: deleteLoading }] = useDeleteCommentMutation();
  const [
    UpdateCommentApi,
    { isLoading: updateCommentLoading, isSuccess: updateCommentSuccess, reset: resetUpdateApi },
  ] = useUpdateCommentMutation();
  const resetAddRef = useRef(resetAddApi);
  const resetUpdateRef = useRef(resetUpdateApi);
  resetAddRef.current = resetAddApi;
  resetUpdateRef.current = resetUpdateApi;

  const onFilterBtnClickHandler = (name: string): void => {
    searchParams.set(FILTER, name);
    setSearchParams(searchParams);
  };

  const resetEditState = (): void => {
    setIsEditRichtext(false);
    setCommentDataById(initialCommentData);
    setFilesData([]);
  };

  const onSaveClickHandler = (
    htmlString: string,
    mentions: BeautifulMentionsMenuItem[],
    files: File[],
    isEdit: boolean,
  ): void => {
    const { COMMENT, USER_ID, ATTACHMENTS, TAGGED_USERS } = FORM_DATA;
    const mentionUserIds = mentions.map(mention => (mention.data as UsersMentionData).id);
    const uniqueUserIds = [...new Set(mentionUserIds)];
    const formData = new FormData();
    formData.append(COMMENT, htmlString);
    formData.append(USER_ID, currentUserId);
    for (let index = 0; index < files.length; index++) {
      formData.append(ATTACHMENTS, files[index]);
    }
    for (let mentionIndex = 0; mentionIndex < uniqueUserIds.length; mentionIndex++) {
      const userIds = uniqueUserIds[mentionIndex];
      formData.append(TAGGED_USERS, userIds);
    }
    if (isEdit) {
      UpdateCommentApi({ companyId, reportId, data: formData, commentId: commentDataById.id })
        .unwrap()
        .then(() => {
          showToast(tComment('success.comment'), tComment('success.updated'), RESPONSE_TYPE.SUCCESS);
          resetEditState();
          resetUpdateRef.current();
        })
        .catch((addCommentError: ResponseError) => {
          showToast(tError('error'), tApiError(addCommentError.data.errorCode, ''), RESPONSE_TYPE.ERROR);
        });
    } else {
      AddCommentApi({ companyId, reportId, data: formData })
        .unwrap()
        .then(() => {
          showToast(tComment('success.comment'), tComment('success.added'), RESPONSE_TYPE.SUCCESS);
          resetAddRef.current();
        })
        .catch((updateCommentErr: ResponseError) => {
          showToast(tError('error'), tApiError(updateCommentErr.data.errorCode, ''), RESPONSE_TYPE.ERROR);
        });
    }
  };

  /* eslint-disable @typescript-eslint/require-await */
  const onMentionQueryHandler = async (
    _trigger: string,
    queryString: string | undefined | null,
  ): Promise<BeautifulMentionsItem[]> => {
    setQueryUsers(queryString || '');
    const setUsersData: UsersMentionData[] = mentionUsersData.map(user => ({
      id: user._id,
      name: `${user.first_name} ${user.last_name}`,
    }));
    const data = getMentionOptions(setUsersData, 'name') as BeautifulMentionsItem[];

    return mentionUsersLoading || mentionUsersFetching ? [{ value: tCommon('loading') }] : data;
  };
  /* eslint-enable @typescript-eslint/require-await */

  const { EDIT, DELETE, CANCEL, CLOSE } = ACTION_IDS;

  const onEditMenuClickHandler = (action: string, comment: CommentData): void => {
    if (action === DELETE) {
      setConfirmationModalOpen(true);
    }
    if (action === EDIT) {
      setIsEditRichtext(true);
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      Promise.all(comment.attachments.map(item => convertBlobUrlToFile(item.blobUrl, item.filename))).then(
        (files: File[]) => {
          setFilesData(files);
        },
      );
    }
    setCommentDataById(comment);
  };

  const onConfirmationClickHandler = useCallback(
    (id: string): void => {
      if (id === DELETE) {
        DeleteCommentApi({ companyId, reportId, commentId: commentDataById.id })
          .unwrap()
          .then(() => {
            showToast(tComment('success.deleted-success'), tComment('success.deleted'), RESPONSE_TYPE.SUCCESS);
            setConfirmationModalOpen(false);
            setCommentDataById(initialCommentData);
          })
          .catch((deleteCommentErr: ResponseError) => {
            showToast(tError('error'), tApiError(deleteCommentErr.data.errorCode, ''), RESPONSE_TYPE.ERROR);
          });
      }
      if (id === CANCEL || id === CLOSE) {
        setConfirmationModalOpen(false);
      }
    },
    [confirmationModalOpen],
  );

  const COMMENT = [
    tCommentFilter('last-month'),
    tCommentFilter('last-three-months'),
    tCommentFilter('last-six-months'),
    tCommentFilter('last-year'),
  ];

  const FILTER_COMMENTS = COMMENT.map((comment, index) => ({
    id: index.toString(),
    text: comment,
    onItemClick: (): void => onFilterBtnClickHandler(comment),
    selected: comment.includes(searchParams.get(FILTER) as string),
  }));

  const showCommentData = (): ReactElement[] =>
    commentData?.map(comment => {
      const menuItem = [
        {
          id: '1',
          text: tCommon('edit'),
          onItemClick: (): void => onEditMenuClickHandler(EDIT, comment),
        },
        {
          id: '2',
          text: tCommon('delete'),
          onItemClick: (): void => onEditMenuClickHandler(DELETE, comment),
        },
      ];
      return (
        <Stack direction='row' spacing={2} alignItems='baseline' className={classes.chatListWrapper} key={comment.id}>
          <LetterAvatar size={56} fontSize={24} lineHeight={32}>
            {getNameInitials(`${comment.author.first_name} ${comment.author.last_name}`)}
          </LetterAvatar>
          <Box flex={1}>
            <Box mb={1}>
              <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <Box>
                  <Typography variant='subtitle4' color='secondary.main' component='span'>
                    {`${comment.author.first_name} ${comment.author.last_name}`}
                  </Typography>
                  <Typography variant='subtitle3' component='span' ml={1}>
                    {getFormattedDate(comment.created_at)}
                  </Typography>
                </Box>
                <Stack direction='row' spacing={1} alignItems='center'>
                  <Box>
                    <Typography variant='subtitle3' component='span' className={classes.editedTypoBox}>
                      {comment.is_edited && tCommon('edited')}
                    </Typography>
                  </Box>
                  <CheckCircleOutlineIcon />
                  <Box>{comment.author._id === currentUserId && <DrawerButtonMenu menuItems={menuItem} />}</Box>
                </Stack>
              </Stack>
            </Box>
            <HtmlRenderer htmlString={comment.comment} />
            <Stack className='chip-list-item' direction='row' my={2} overflow='auto'>
              {comment.attachments.map(file => (
                <a key={file._id} href={file.blobUrl} download={file.blobUrl} className='editor-view-attachment'>
                  <Tooltip title={file.filename} placement='top'>
                    <Chip avatar={<AttachmentIcon />} label={file.filename} className='file-chip' variant='filled' />
                  </Tooltip>
                </a>
              ))}
            </Stack>
          </Box>
        </Stack>
      );
    });

  return (
    <>
      <Box className={classes.chatBoxMainWrapper}>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Box>
            <Typography variant='h2'>{tComment('comment', { count: 2 })}</Typography>
          </Box>
          <Box>
            <Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={2}>
              <DrawerButtonMenu
                selectedTitle={tCommon('filter')}
                startIcon={<FilterAltOutlinedIcon />}
                menuItems={FILTER_COMMENTS}
              />
              <IconButton onClick={onChatBoxClose} className={classes.closeIcon}>
                <CloseIcon />
              </IconButton>
            </Stack>
          </Box>
        </Stack>
        {filterParams && (
          <Box>
            <Chip
              label={filterParams}
              variant='outlined'
              onDelete={(): void => {
                searchParams.delete(FILTER);
                setSearchParams(searchParams);
              }}
              deleteIcon={<CancelCircleIcon />}
            />
          </Box>
        )}
        <Stack direction='row' spacing={2} alignItems='center' my={3}>
          <LetterAvatar size={56} fontSize={24} lineHeight={32}>
            {getNameInitials(`${first_name} ${last_name}`)}
          </LetterAvatar>

          <Box flex={1}>
            <RichTextEditor
              onSaveClick={onSaveClickHandler}
              onMentionQuery={onMentionQueryHandler}
              isSuccess={AddCommentSuccess || updateCommentSuccess}
              isLoading={addCommentLoading || updateCommentLoading}
              htmlStringData={commentDataById.comment}
              isEditorEditable={isEditRichtext}
              onCancelClick={(): void => resetEditState()}
              mentionData={extractMentionData(commentDataById.comment)}
              filesData={filesData}
            />
          </Box>
        </Stack>
        <Divider className={classes.divider} />
        <Box className={classes.chatBoxWrapper}>
          {isLoading ? (
            <LoadingSpinner />
          ) : commentData.length > 0 ? (
            showCommentData()
          ) : (
            <Box className={classes.noContentFound}>
              <NoContentFound title={tComment('no-comments-found')} icon={<NoCommentIcon />} />
            </Box>
          )}
        </Box>
      </Box>
      <ConfirmationModal
        open={confirmationModalOpen}
        icon={<WarningTriangleOutlinedIcon className={classes.confirmationIcon} />}
        confirmationTitle={tCommon('delete-confirmation')}
        description={tComment('delete-confirmation-message')}
        buttons={[
          {
            id: CANCEL,
            label: tCommon('cancel'),
            color: 'primary',
            variant: 'outlined',
            onClick: onConfirmationClickHandler,
          },
          {
            id: DELETE,
            label: tCommon('delete'),
            color: 'error',
            onClick: onConfirmationClickHandler,
            loading: deleteLoading,
          },
        ]}
        onCloseClick={onConfirmationClickHandler}
      />
    </>
  );
};

export default memo(ChatBox);
