import { makeStyles } from '../../../hooks';

export const useStyles = makeStyles()(theme => ({
  publicContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    background: theme.palette.grey['A100'],
  },
}));
