export const API_PATH = {
  COMPANIES: 'companies',
  PORTFOLIOS: 'portfolios',
  REPORTS: 'reports',
  USERS: 'users',
  USERS_BULK: 'users/bulk',
  ROLES: 'roles',
  RESEND_INVITE: 'resend_invite',
  EMBED: 'embed',
  DASHBOARD: 'dashboard',
  EMAIL: 'email',
  GRAPHQL: 'graphql',
  DEPARTMENTS: 'departments',
  SET_STATUS: 'set-status',
  CONCEPTS: 'concepts',
  GL_ACCOUNTS: 'gl-accounts',
  COMMENTS: 'comments',
  MENTION: 'mention',
  REVIEW_REPORT: 'review_report',
  REVIEW_REPORT_STATUS: 'review_report_status',
};
