import { makeStyles } from '../../../hooks';

export const useStyles = makeStyles()(theme => ({
  toastContainer: {
    boxShadow: theme.customShadows.default,
    maxWidth: '440px',
    width: '100%',
    background: theme.palette.common.white,
    borderRadius: '10px',
    padding: '12px 16px 12px 28px',
    position: 'relative',
    zIndex: 9999,
    overflow: 'hidden',
  },

  successToastContainer: {
    '::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      backgroundColor: theme.palette.success.main,
      width: '16px',
      height: '100%',
    },
  },
  warningToastContainer: {
    '::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      backgroundColor: theme.palette.warning.main,
      width: '16px',
      height: '100%',
    },
  },
  errorToastContainer: {
    '::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      backgroundColor: theme.palette.error.main,
      width: '16px',
      height: '100%',
    },
  },
  successIcon: {
    display: 'inherit',
    '& .MuiSvgIcon-root': {
      color: theme.palette.success.main,
    },
  },
  warningIcon: {
    display: 'inherit',
    '& .MuiSvgIcon-root': {
      color: theme.palette.warning.main,
    },
  },
  errorIcon: {
    display: 'inherit',
    '& .MuiSvgIcon-root': {
      color: theme.palette.error.main,
    },
  },
}));
