import { ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Theme, useMediaQuery } from '@mui/material';
import { Box, Button, Container, GridContainer, GridItem, Image, Link, Typography } from '../../components/shared';
import { useStyles } from './style';
import { LoginBanner } from '../../components';
import { AccessDeniedIcon } from '../../assets/icons';
import { SignInLogo } from '../../assets/logos';
import { TabletHeader } from '../../components/layout';

const AccessDenied = (): ReactElement => {
  const { classes } = useStyles();
  const { t: tUser } = useTranslation('translation', { keyPrefix: 'user' });
  const { t: tCommon } = useTranslation('translation', { keyPrefix: 'common' });
  const navigate = useNavigate();
  const contactSupportEmail = process.env.REACT_APP_CONTACT_SUPPORT_EMAIL;
  const isTabletView = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const onBackClickHandler = (): void => {
    navigate(-1);
    setTimeout(() => {
      navigate(0);
    });
  };

  const DesktopView = (): JSX.Element => (
    <GridContainer className={classes.pageWrapper}>
      <GridItem md={5} sm={5.5} xs={12} lg={5} xl={3.5}>
        <GridContainer height='100%' justifyContent='center'>
          <GridItem md={8} sm={10}>
            <Box className={classes.accessDeniedWrapper}>
              <Box pb={{ md: 6, sm: 6, sx: 2 }}>
                <Image src={SignInLogo} alt='sign-in-logo' className={classes.logo} />
              </Box>
              <Box pb={{ md: 3, sm: 2, sx: 2 }}>
                <Image src={AccessDeniedIcon} alt='access-denied-icon' />
              </Box>
              <Box className={classes.textWrapper}>
                <Typography className={classes.title} mb={1}>
                  {tUser('access-denied.title')}
                </Typography>

                <Typography variant='body3' component='p'>
                  <Trans i18nKey='user.access-denied.description'>
                    This content is restricted to invited users only. You do not have access. Please{' '}
                    <Link className={classes.contactLink} to={contactSupportEmail && `mailto:${contactSupportEmail}`}>
                      Contact Support
                    </Link>{' '}
                    for access.
                  </Trans>
                </Typography>
              </Box>
              <Box>
                <Button variant='outlined' onClick={onBackClickHandler}>
                  {tCommon('back')}
                </Button>
              </Box>
            </Box>
          </GridItem>
        </GridContainer>
      </GridItem>
      <GridItem md={7} sm={6.5} xs={12} lg={7} xl={8.5}>
        <LoginBanner />
      </GridItem>
    </GridContainer>
  );

  const TabletView = (): JSX.Element => (
    <>
      <TabletHeader isLoginHeader />
      <LoginBanner />
      <Box className={classes.accessDeniedWrapper} pt={{ sm: 7, xs: 7 }} pb={{ sm: 4, xs: 4 }}>
        <Box>
          <Image src={AccessDeniedIcon} alt='sign-in-logo' />
        </Box>
        <Box className={classes.textWrapper}>
          <Typography className={classes.title} mb={1}>
            {tUser('access-denied.title')}
          </Typography>

          <Typography variant='body3' component='p'>
            <Trans i18nKey='user.access-denied.description'>
              This content is restricted to invited users only. You do not have access. Please{' '}
              <Link className={classes.contactLink} to={contactSupportEmail && `mailto:${contactSupportEmail}`}>
                Contact Support
              </Link>{' '}
              for access.
            </Trans>
          </Typography>
        </Box>
        <Box>
          <Button variant='outlined' onClick={onBackClickHandler}>
            {tCommon('back')}
          </Button>
        </Box>
      </Box>
    </>
  );

  return (
    <Container disableGutters maxWidth={false} className={classes.pageRoot}>
      {isTabletView ? TabletView() : DesktopView()}
    </Container>
  );
};

export default AccessDenied;
