import { CustomColumn } from 'react-table';
import i18next from '../i18n/config';
import { IUserList } from '../services/users/users-interface';
import { IUserColumns } from './table-column-interface';
import { DepartmentData, PublicEquities } from '../graphql';
import { DebtSummary } from '../graphql/types/capital-structure';

const tCompany = i18next.getFixedT(null, null, 'company');
const tUser = i18next.getFixedT(null, null, 'user');
const tHeadcount = i18next.getFixedT(null, null, 'company.manual-portal-entry.headcount');
const tPublicEquity = i18next.getFixedT(null, null, 'company.manual-portal-entry.public-equities');
const tManualPortalEntry = i18next.getFixedT(null, null, 'company.manual-portal-entry');
const tCapStructure = i18next.getFixedT(null, null, 'company.manual-portal-entry.capital-structure');

export const USER_COLUMNS = (isDesktopView: boolean): IUserColumns => ({
  PC_USERS: [
    {
      Header: tUser('name'),
      accessor: 'name',
      hideHeaderAndColumn: !isDesktopView,
      width: isDesktopView ? 250 : 'auto',
    },
    {
      Header: tUser('email'),
      accessor: 'email',
      hideHeaderAndColumn: !isDesktopView,
      width: isDesktopView ? 350 : 'auto',
    },
    { Header: tUser('status'), accessor: 'status' },
  ],
  get ADMIN_USERS(): CustomColumn<IUserList>[] {
    return [
      ...this.PC_USERS,
      {
        Header: tUser('role', { count: 1 }),
        accessor: 'role_name',
      },
      {
        Header: tCompany('assigned-company', { count: 2 }),
        accessor: 'company_name',
      },
    ];
  },
});

export const HEADCOUNT_COLUMNS = (isDesktopView: boolean): CustomColumn<DepartmentData>[] => [
  { accessor: 'department_name', Header: tHeadcount('department'), width: 200 },
  { accessor: 'employee_count', Header: tHeadcount('emp-count'), width: isDesktopView ? 110 : 85 },
  { accessor: 'payroll_total', Header: tHeadcount('payroll-total'), width: isDesktopView ? 110 : 85 },
  { accessor: 'full_time', Header: tHeadcount('full-time'), width: isDesktopView ? 110 : 85 },
  { accessor: 'part_time', Header: tHeadcount('part-time'), width: isDesktopView ? 110 : 85 },
  { accessor: 'turnover', Header: tHeadcount('turnover'), width: isDesktopView ? 110 : 85 },
];

export const PUBLIC_EQUITIES_COLUMNS = (isDesktopView: boolean): CustomColumn<PublicEquities>[] => [
  { accessor: 'company_name', Header: tCompany('company-name'), width: 170 },
  { accessor: 'ticker', Header: tPublicEquity('ticker'), width: isDesktopView ? 90 : 70 },
  { accessor: 'revenue.value', Header: tManualPortalEntry('revenue'), width: isDesktopView ? 90 : 70 },
  { accessor: 'ebitda.value', Header: tManualPortalEntry('ebidta'), width: isDesktopView ? 90 : 70 },
  { accessor: 'tev_ltm_ebitda.value', Header: tPublicEquity('tev-ltm'), width: isDesktopView ? 90 : 70 },
  { accessor: 'tev_ntm_ebitda.value', Header: tPublicEquity('tev-ntm'), width: isDesktopView ? 90 : 70 },
  { accessor: 'leverage_ratio.value', Header: tPublicEquity('lev'), width: isDesktopView ? 90 : 70 },
];

export const CAP_STRUCTURE_COLUMNS = (parentCompanyTableHeader: string): CustomColumn<DebtSummary>[] => [
  { accessor: 'tranche', Header: tCapStructure('tranche'), width: 120 },
  { accessor: 'rate', Header: tCapStructure('rate'), width: 90 },
  { accessor: 'third_party', Header: tCapStructure('third-party'), width: 90 },
  { accessor: 'parent_company_amount', Header: parentCompanyTableHeader, width: 90 },
  { accessor: 'total', Header: tCapStructure('total'), width: 90 },
];
