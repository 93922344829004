import { alpha } from '@mui/material';
import { makeStyles } from '../../hooks';

export const useStyles = makeStyles()(theme => ({
  chatBoxMainWrapper: {
    filter: `drop-shadow(${theme.customShadows.sideBarDrawer})`,
    backgroundColor: theme.palette.common.white,
    borderRadius: '20px',
    padding: theme.spacing(3),
    width: '880px',
    margin: 'auto',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  closeIcon: {
    padding: 0,
    '& .MuiSvgIcon-root': {
      color: theme.palette.secondary.main,
      width: '24px',
      height: '24px',
    },
  },
  divider: {
    borderColor: alpha(theme.palette.secondary.main, 0.1),
    borderWidth: '1px',
  },
  chatListWrapper: {
    margin: theme.spacing(3, 0),
    ':last-of-type': {
      marginBottom: 0,
    },
  },
  chatBoxWrapper: {
    maxHeight: '300px',
    overflowY: 'auto',
    paddingRight: theme.spacing(2),
    '::-webkit-scrollbar': {
      width: '4px',
    },
    '::-webkit-scrollbar-track': {
      background: theme.palette.background.lightblue,
    },
    '::-webkit-scrollbar-thumb': {
      background: theme.palette.secondary.main,
      borderRadius: '6px',
      ':hover': {
        background: theme.palette.secondary.main,
      },
    },
  },
  confirmationIcon: {
    width: '48px',
    height: '42px',
    color: theme.palette.error.main,
  },
  noContentFound: {
    maxWidth: '275px',
    margin: 'auto',
    '& [class$="notContentWrapper"]': {
      minHeight: '250px',
    },
  },
  editedTypoBox: {
    fontStyle: 'italic',
  },
}));
