import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Error, Page } from '../../components';
import { useStyles } from './style';
import { InternalServerErrorIcon } from '../../assets/icons';

const InternalServerError = (): ReactElement => {
  const { classes } = useStyles();
  const { t: tError } = useTranslation('translation', { keyPrefix: 'common.errors.500' });

  return (
    <Page className={classes.container}>
      <Error
        code={tError('status')}
        message={{ title: tError('internal-server-error'), description: tError('something-went-wrong') }}
        icon={InternalServerErrorIcon}
      />
    </Page>
  );
};

export default InternalServerError;
