import { ReactElement } from 'react';
import { FormControlLabel, Checkbox, CheckboxProps } from '@mui/material';

interface Props {
  label?: string;
  labelPlacement?: 'bottom' | 'end' | 'start' | 'top';
  color?: 'default' | 'primary' | 'secondary' | 'success' | 'warning' | 'info' | 'error';
  labelClassName?: string;
}

type AppCheckBoxProps = Props & CheckboxProps;

const CheckBox = (props: AppCheckBoxProps): ReactElement => {
  const { label = '', labelPlacement = 'start', color = 'primary', labelClassName, ...other } = props;

  return label ? (
    <FormControlLabel
      control={<Checkbox {...other} color={color} />}
      label={label}
      labelPlacement={labelPlacement}
      className={labelClassName}
      componentsProps={{ typography: { variant: 'checkBoxLabel' } }}
    />
  ) : (
    <Checkbox {...other} color={color} />
  );
};

export default CheckBox;
