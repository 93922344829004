import { ReactElement, useEffect } from 'react';
import { useParams, Link as RouterLink, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import { useFormik } from 'formik';
import { NavigationHeader } from '../../../components/layout';
import {
  Box,
  Button,
  GridContainer,
  GridItem,
  Image,
  LoadingSpinner,
  Stack,
  TextField,
  Typography,
} from '../../../components/shared';
import { useGetCompanyByIdQuery } from '../../../services/companies/companies-service';
import { useBlobGetFiles, useNavigationPathForPortal } from '../../../hooks';
import { MonthDropdown, Page, YearDropdown } from '../../../components';
import { FILTER_PARAMS, REPORT_TYPE, RESPONSE_TYPE, TOAST_TIMER } from '../../../constants';
import {
  GET_REPORT_DATA,
  UPSERT_VALUATION,
  UpsertValuationResponse,
  Valuation as IValuation,
  ValuationFilterVariables,
  ValuationInput,
  ValuationResponse,
} from '../../../graphql';
import { valuationSchema } from '../../../validation-schemas/portfolio-entry-schema';
import { UseToast } from '../../../hooks/useToast';
import { useStyles } from './style';

const Valuation = (): ReactElement => {
  const { classes } = useStyles();
  const { t: tManualPortal } = useTranslation('translation', { keyPrefix: 'company.manual-portal-entry' });
  const { t: tValuation } = useTranslation('translation', { keyPrefix: 'company.manual-portal-entry.valuation' });
  const { t: tCommon } = useTranslation('translation', { keyPrefix: 'common' });
  const { t: tError } = useTranslation('translation', { keyPrefix: 'common.errors' });
  const { YEAR, MONTH } = FILTER_PARAMS;
  const [searchParams] = useSearchParams();
  const yearParams = searchParams.get(YEAR);
  const monthParams = searchParams.get(MONTH);
  const { companyId = '', reportId = '' } = useParams();
  const { currentData: companyDetails, isError } = useGetCompanyByIdQuery(companyId, {
    skip: companyId === undefined || companyId === '',
  });
  const title = `${companyDetails?.name} - ${tValuation('add')}`;
  const { getAzureBlob } = useBlobGetFiles();
  const companyLogo = companyDetails?.logo;
  const navigation = useNavigationPathForPortal(companyId);
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { data, loading, refetch } = useQuery<ValuationResponse, ValuationFilterVariables>(GET_REPORT_DATA, {
    variables: {
      filters: {
        report_id: reportId,
        company_id: companyId,
        report_type: REPORT_TYPE.VALUATION,
        year: Number(yearParams),
        month: Number(monthParams),
      },
    },
    skip: yearParams === null || monthParams === null || companyId === '' || reportId === '',
  });

  const [UpsertValuationApi, { loading: upsertLoading }] = useMutation<
    UpsertValuationResponse,
    { input: ValuationInput }
  >(UPSERT_VALUATION);

  const { showToast } = UseToast();

  const valuationData = data?.getReportData?.valuationData;
  const { enterprise_value_high, enterprise_value_low, equity_value_high, equity_value_low } = valuationData ?? {};

  const initialValues = {
    enterprise_value_high: enterprise_value_high || '',
    enterprise_value_low: enterprise_value_low || '',
    equity_value_high: equity_value_high || '',
    equity_value_low: equity_value_low || '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema: valuationSchema(),
    onSubmit: values => {
      const formData = {
        report_type: REPORT_TYPE.VALUATION,
        report_id: reportId,
        company_id: companyId,
        year: Number(yearParams),
        month: Number(monthParams),
        data: {
          enterprise_value_high: Number(values.enterprise_value_high),
          enterprise_value_low: Number(values.enterprise_value_low),
          equity_value_high: Number(values.equity_value_high),
          equity_value_low: Number(values.equity_value_low),
        } as IValuation,
      };

      UpsertValuationApi({ variables: { input: formData } })
        .then(async () => {
          showToast(
            tManualPortal('success.upload-success'),
            tManualPortal('success.updates-published-shortly'),
            RESPONSE_TYPE.SUCCESS,
            TOAST_TIMER.MANUAL_PORTAL,
          );
          await refetch();
        })
        .catch(() => {
          showToast(tError('error'), tManualPortal('error.occurred'), RESPONSE_TYPE.ERROR);
        });
    },
  });

  const { handleSubmit, touched, errors, handleChange, values, setValues, resetForm } = formik;

  useEffect(() => {
    if (valuationData) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      setValues({
        enterprise_value_high,
        enterprise_value_low,
        equity_value_high,
        equity_value_low,
      } as IValuation);
    } else {
      resetForm();
    }
  }, [yearParams, monthParams, valuationData]);

  return (
    <>
      <NavigationHeader
        title={<Typography variant='h1'>{companyDetails ? title : isError ? tValuation('add') : ''}</Typography>}
        navigateTo={navigation}
      >
        {companyLogo && (
          <Image src={getAzureBlob(companyLogo)?.url} alt={companyLogo} className={classes.companyLogo} />
        )}
      </NavigationHeader>
      <Page>
        <Box pt={{ md: 6, sm: 9, xs: 5 }}>
          <GridContainer spacing={{ xs: 2, sm: 2, md: 2 }} justifyContent='center'>
            <GridContainer xs={12} sm={10} md={8} spacing={{ xs: 2, sm: 2, md: 2 }}>
              <GridItem xs={6}>
                <YearDropdown isFilter value={Number(yearParams)} />
              </GridItem>
              <GridItem xs={6}>
                <MonthDropdown isFilter value={Number(monthParams)} isSixMonthInterval />
              </GridItem>
              <GridItem xs={12} pt={3}>
                <Typography variant='h2'>{tManualPortal('enter-data')}</Typography>
              </GridItem>
              {loading ? (
                <LoadingSpinner />
              ) : (
                <>
                  <GridContainer xs={12} spacing={{ xs: 2, sm: 2, md: 2 }} alignItems='center'>
                    <GridItem xs={12} sm={12} md={4}>
                      <Typography variant='subtitle3'>{tValuation('enterprise-value')}</Typography>
                    </GridItem>
                    <GridContainer xs={12} sm={12} md={8} rowSpacing={2} columnSpacing={3}>
                      <GridItem xs={12} sm={6} md={6}>
                        <TextField
                          label={tValuation('high')}
                          fullWidth
                          required
                          value={values.enterprise_value_high}
                          isNumberField
                          allowDecimalValue
                          name='enterprise_value_high'
                          onChange={handleChange}
                          error={Boolean(touched.enterprise_value_high && errors.enterprise_value_high)}
                          helperText={touched.enterprise_value_high && errors.enterprise_value_high}
                          data-testid='enterprise_value_high'
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6}>
                        <TextField
                          label={tValuation('low')}
                          fullWidth
                          required
                          value={values.enterprise_value_low}
                          isNumberField
                          allowDecimalValue
                          name='enterprise_value_low'
                          onChange={handleChange}
                          error={Boolean(touched.enterprise_value_low && errors.enterprise_value_low)}
                          helperText={touched.enterprise_value_low && errors.enterprise_value_low}
                          data-testid='enterprise_value_low'
                        />
                      </GridItem>
                    </GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <Typography variant='subtitle3'>{tValuation('equity-value')}</Typography>
                    </GridItem>
                    <GridContainer xs={12} sm={12} md={8} rowSpacing={2} columnSpacing={3}>
                      <GridItem xs={12} sm={6} md={6}>
                        <TextField
                          label={tValuation('high')}
                          fullWidth
                          required
                          value={values.equity_value_high}
                          isNumberField
                          allowDecimalValue
                          name='equity_value_high'
                          onChange={handleChange}
                          error={Boolean(touched.equity_value_high && errors.equity_value_high)}
                          helperText={touched.equity_value_high && errors.equity_value_high}
                          data-testid='equity_value_high'
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6}>
                        <TextField
                          label={tValuation('low')}
                          fullWidth
                          required
                          value={values.equity_value_low}
                          isNumberField
                          allowDecimalValue
                          name='equity_value_low'
                          onChange={handleChange}
                          error={Boolean(touched.equity_value_low && errors.equity_value_low)}
                          helperText={touched.equity_value_low && errors.equity_value_low}
                          data-testid='equity_value_low'
                        />
                      </GridItem>
                    </GridContainer>
                  </GridContainer>
                  <GridItem xs={12} textAlign='right' py={3}>
                    <Stack spacing={2} direction='row' justifyContent='end'>
                      <Button variant='outlined' data-testid='cancel-btn' component={RouterLink} to={`/${navigation}`}>
                        {tCommon('back')}
                      </Button>
                      <Button
                        variant='contained'
                        data-testid='save-btn'
                        onClick={(): void => handleSubmit()}
                        loading={upsertLoading}
                      >
                        {tCommon('save')}
                      </Button>
                    </Stack>
                  </GridItem>
                </>
              )}
            </GridContainer>
          </GridContainer>
        </Box>
      </Page>
    </>
  );
};

export default Valuation;
