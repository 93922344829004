import { ReactElement, memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectChangeEvent } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { Select } from '../shared';
import { FILTER_PARAMS, SIX_MONTH_INTERVAL } from '../../constants';

interface Props {
  onMonthChangeHandler?: (year: number) => void;
  value?: number;
  isFilter?: boolean;
  error?: boolean;
  required?: boolean;
  helperText?: string;
  isSixMonthInterval?: boolean;
}

const MonthDropdown = (props: Props): ReactElement => {
  const {
    onMonthChangeHandler = (): void => {},
    value = '',
    isFilter = false,
    error = false,
    required = false,
    helperText = '',
    isSixMonthInterval = false,
  } = props;
  const { t: tCommon } = useTranslation('translation', { keyPrefix: 'common' });
  const currentMonth = new Date().getMonth() + 1;
  const [searchParams, setSearchParams] = useSearchParams();
  const { MONTH } = FILTER_PARAMS;

  const allMonths = Array.from({ length: 12 }, (_value, index) => {
    const month = index + 1;
    const monthDigit = month < 10 ? `0${month}` : month.toString();
    return { id: monthDigit, text: monthDigit, value: month };
  });
  const { FIRST_HALF, SECOND_HALF } = SIX_MONTH_INTERVAL;
  const sixMonthInterval = allMonths.filter(month => month.id === FIRST_HALF || month.id === SECOND_HALF);

  const monthOptions = isSixMonthInterval ? sixMonthInterval : allMonths;

  const handleChange = (event: SelectChangeEvent<unknown>): void => {
    const month = Number(event.target.value);
    if (isFilter) {
      searchParams.set(MONTH, month.toString());
      setSearchParams(searchParams);
    } else {
      onMonthChangeHandler(month);
    }
  };

  useEffect(() => {
    const monthParams = searchParams.get(MONTH);
    if (!monthParams && isFilter) {
      const monthToSet = isSixMonthInterval && currentMonth > 6 ? SECOND_HALF : FIRST_HALF;
      const setMonth = isSixMonthInterval ? monthToSet : currentMonth;
      searchParams.append(MONTH, setMonth as string);
      setSearchParams(searchParams);
    }
  }, [searchParams, isFilter, isSixMonthInterval, currentMonth]);

  return (
    <Select
      inputLabel={tCommon('month')}
      id='month'
      value={value || ''}
      name='month'
      options={monthOptions}
      onChange={handleChange}
      error={error}
      required={required}
      helperText={helperText}
      data-testid='month-dropdown'
    />
  );
};

export default memo(MonthDropdown);
