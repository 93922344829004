import { MouseEvent, ReactElement, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button, Link, Menu, MenuItem, Typography } from '../shared';
import { AddCircleOutlineIcon, DownArrowIcon, UpArrowIcon } from '../../assets/icons';
import { useStyles } from './style';
import { PORTAL_BACK_SELECTION, ROUTES, ROUTE_MAPPING_AGAINST_REPORT_NAME } from '../../constants';
import { setManualPortalNavigation } from '../../utils';
import { getReportsState } from '../../redux/reports';
import { useAppSelector } from '../../hooks';
import { IReportsResponse } from '../../services/reports/reports-interface';

interface Props {
  companyId: string;
}

const ManualDataDropdown = (props: Props): ReactElement => {
  const { companyId } = props;
  const { classes } = useStyles();
  const { t: tCompany } = useTranslation('translation', { keyPrefix: 'company' });
  const reportsData = useAppSelector(getReportsState);
  const manualPortalReports = (reportsData as Array<IReportsResponse>).filter(report => report.manual_portal_enabled);
  const navigate = useNavigate();
  const { COMPANY, REPORT } = ROUTES;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const onMenuItemClickHandler = (reportId: string, name: string): void => {
    if (name && ROUTE_MAPPING_AGAINST_REPORT_NAME[name]) {
      const reportName = ROUTE_MAPPING_AGAINST_REPORT_NAME[name];
      navigate(`/${COMPANY}/${companyId}/${REPORT}/${reportId}/${reportName}`);
      setManualPortalNavigation(PORTAL_BACK_SELECTION.DROPDOWN);
    }
    setAnchorEl(null);
  };

  return (
    <>
      <Link
        variant='primaryLink'
        startIcon={<AddCircleOutlineIcon />}
        endIcon={
          open ? (
            <UpArrowIcon />
          ) : (
            <Box component='span' className={classes.downArrowIcon}>
              <DownArrowIcon />
            </Box>
          )
        }
        onClick={handleClick}
        component={Button}
        id='demo-positioned-button'
        aria-controls={open ? 'drawer-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
      >
        {tCompany('manual-portal-entry.import-data')}
      </Link>
      <Menu
        id='demo-positioned-menu'
        aria-labelledby='demo-positioned-button'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        slotProps={{ paper: { className: classes.menuMainContainer } }}
        MenuListProps={{ className: classes.listWrapper }}
      >
        {manualPortalReports?.map(({ name, default_label, _id }) => {
          const reportTitle = default_label;
          return (
            <MenuItem onClick={(): void => onMenuItemClickHandler(_id, name)} className={classes.list} key={_id}>
              <Typography variant='subtitle4' color='secondary.main'>
                {reportTitle}
              </Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default ManualDataDropdown;
