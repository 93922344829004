import { Stack as MuiStack, StackProps } from '@mui/material';
import { ReactElement, ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

type AppStackProps = Props & StackProps;

const Stack = (props: AppStackProps): ReactElement => {
  const { children, ...other } = props;
  return <MuiStack {...other}>{children}</MuiStack>;
};

export default Stack;
