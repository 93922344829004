import { makeStyles } from '../../../hooks';

export const useStyles = makeStyles()(theme => ({
  stepperWrapper: {
    padding: theme.spacing(1.5),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
    },
    marginTop: theme.spacing(0.5),
    '& .MuiStepper-root.MuiStepper-horizontal': {
      paddingTop: theme.spacing(1.5),
    },
    '& .MuiStep-root.MuiStep-horizontal': {
      ':first-of-type': {
        '& .MuiStepLabel-iconContainer': {
          alignSelf: 'flex-start',
          paddingLeft: '19%',
        },
        '& .MuiStepLabel-label': {
          textAlign: 'left',
          paddingLeft: '5px',
        },
      },
      ':last-of-type': {
        '& .MuiStepLabel-iconContainer': {
          alignSelf: 'flex-end',
          paddingRight: '9%',
        },
        '& .MuiStepLabel-label': {
          textAlign: 'right',
          paddingRight: '5px',
        },
      },
      ':nth-of-type(odd)': {
        '& .MuiStepConnector-root.MuiStepConnector-horizontal': {
          transform: 'translateX(15%)',
          left: 'calc(-49% + 16.5px)',
          right: 'calc(54% + 14px)',
          marginRight: '1.5px',
          marginLeft: '1.5px',
          [theme.breakpoints.down('md')]: {
            left: 'calc(-49% + 14px)',
          },
        },
      },
      ':nth-of-type(even)': {
        '& .MuiStepConnector-root.MuiStepConnector-horizontal': {
          transform: 'translateX(-15%)',
          left: 'calc(-39% + 13px)',
          right: 'calc(51% + 15px)',
          marginRight: '1.5px',
          marginLeft: '1.5px',
          [theme.breakpoints.down('md')]: {
            right: 'calc(51% + 14px)',
          },
        },
      },
      '& .MuiStepConnector-root.MuiStepConnector-horizontal': {
        scale: '1.5',
      },
    },
  },
}));
