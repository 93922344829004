import { makeStyles } from '../../hooks';

interface StyleProps {
  isMenuOpen?: boolean;
}

export const useStyles = makeStyles<StyleProps>()((theme, { isMenuOpen }) => ({
  iconButton: {
    borderRadius: '4px',
    padding: '0px',
    backgroundColor: isMenuOpen ? theme.palette.background.lightblue : 'transparent',
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.main,
    },
  },
}));
